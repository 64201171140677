import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, Table } from '@ecster/components';
import { formatAmount } from '@ecster/util';

const byAmount = (a, b) => (a.amount > b.amount ? -1 : a.amount < b.amount ? 1 : 0);
const byFeeType = (a, b) => (a.feeType > b.feeType ? -1 : a.feeType < b.feeType ? 1 : 0);

export const InvoiceRowsPanel = ({ invoice }) => {
    const products = invoice?.invoiceRows;
    const fees = invoice?.feeRows;

    if (!products || !fees) return null;

    const costs = products.filter(p => p.unitPrice >= 0);
    const allCreditRows = products.filter(p => p.unitPrice < 0);
    const costPartNumbers = costs.map(c => c.partNumber);
    const productRowCredits = allCreditRows.filter(c => costPartNumbers.includes(c.partNumber));
    const invoiceCredits = allCreditRows.filter(c => !costPartNumbers.includes(c.partNumber));

    const costRows = costs.reduce((acc, { description, quantity, unitPrice, discount, partNumber }) => {
        const creditRow = partNumber && productRowCredits.find(el => el.partNumber === partNumber);
        const isCredited = !!creditRow;
        const hasDiscount = discount !== 0;

        acc.push({
            description: quantity > 1 ? `${quantity} x ${description}` : description,
            price: formatAmount(quantity * unitPrice, { decimals: 2 }),
            reactKey: `costRow-${description}-${quantity}-${unitPrice}`,
            className: `cost-row ${hasDiscount || isCredited ? 'has-discount-or-credit' : ''}`,
        });

        if (hasDiscount) {
            acc.push({
                description: i18n('invoice.one-off-invoice.invoice-rows.discount'),
                price: formatAmount(discount, { decimals: 2 }),
                reactKey: `costRow-${description}-${quantity}-${unitPrice}-discount`,
                className: 'cost-row is-discount-or-credit',
            });
        }

        if (isCredited) {
            const creditHasDiscount = creditRow.discount !== 0;

            acc.push({
                description: `${i18n('invoice.one-off-invoice.invoice-rows.credit')} ${
                    creditRow.quantity > 1 ? `${creditRow.quantity} x ${creditRow.description}` : creditRow.description
                }`,
                price: formatAmount(creditRow.quantity * creditRow.unitPrice, { decimals: 2 }),
                reactKey: `costRow-${creditRow.description}-${creditRow.quantity}-${creditRow.unitPrice}-credit-row`,
                className: 'cost-row is-discount-or-credit',
            });

            if (creditHasDiscount) {
                acc.push({
                    description: i18n('invoice.one-off-invoice.invoice-rows.discount'),
                    price: formatAmount(Math.abs(creditRow.discount), { decimals: 2 }),
                    reactKey: `costRow-${creditRow.description}-${creditRow.quantity}-${creditRow.unitPrice}-credit-discount`,
                    className: 'cost-row is-discount-or-credit',
                });
            }
        }

        acc[acc.length - 1].className += ' is-last-in-product-group';

        return acc;
    }, []);

    if (costRows.length > 0) costRows[costRows.length - 1].className += ' is-last-cost-row';

    const creditRows = invoiceCredits.map(({ description, quantity, unitPrice }, i) => ({
        description: `${i18n('invoice.one-off-invoice.invoice-rows.credit')} ${
            quantity > 1 ? `${quantity} x ${description}` : description
        }`,
        price: formatAmount(quantity * unitPrice, { decimals: 2 }),
        reactKey: `creditRow-${description}-${quantity}-${unitPrice}`,
        className: 'summary-row',
    }));

    const feeRows = fees
        .sort(byAmount)
        .sort(byFeeType)
        .map(({ feeType, amount }) => ({
            description: i18n(`invoice.one-off-invoice.invoice-rows.fees.${feeType}`),
            price: formatAmount(amount),
            reactKey: `feeRow-${feeType}-${amount}`,
            className: 'summary-row',
        }));

    const summaryRows = [...creditRows, ...feeRows];

    if (summaryRows.length > 0) {
        summaryRows[0].className += ' is-first-summary-row';
        summaryRows[summaryRows.length - 1].className += ' is-last-summary-row';
    }

    const totalRow = {
        description: i18n('invoice.one-off-invoice.invoice-rows.total-amount'),
        price: formatAmount(invoice.amount),
        className: 'strong total-row',
        reactKey: 'totalRow',
    };

    return (
        <div className="invoice-invoice-rows-panel" data-testid="invoice-rows">
            <Panel stretchInMobile>
                <PanelContent narrow>
                    <h3 className="centered">{i18n('invoice.one-off-invoice.invoice-rows.header')}</h3>

                    <Table
                        data={[...costRows, ...summaryRows, totalRow]}
                        headers={i18n('invoice.one-off-invoice.invoice-rows.table-headers', { returnObjects: true })}
                        align={['', 'right']}
                    />
                </PanelContent>
            </Panel>
        </div>
    );
};

InvoiceRowsPanel.propTypes = {
    invoice: PropTypes.object.isRequired,
};

InvoiceRowsPanel.defaultProps = {};

export default InvoiceRowsPanel;
