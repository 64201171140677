import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Cookie } from '@ecster/net';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const {
        loginStatus: { isLoggedIn },
    } = useSelector(state => state.authentication, shallowEqual);
    const location = useLocation();

    // a private route was attempted while not logged in
    // keep it for redirect after login
    if (!isLoggedIn) {
        const currentNextRoute = Cookie.read('nextRoute');
        const hash = window.location.hash.replace(/^#/, '');

        if (!currentNextRoute && hash && !hash.match(/^\/authentication\/logout/)) {
            // state is not usable while not logged in, use cookie
            Cookie.create('nextRoute', hash, { minutes: 2 });
        }
    }

    return (
        <Route {...rest}>
            {isLoggedIn ? (
                <Component />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: location },
                    }}
                />
            )}
        </Route>
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.object.isRequired,
};

export default PrivateRoute;
