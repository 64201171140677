import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { i18n } from '@ecster/i18n';
import {
    Panel,
    PanelContent,
    TableBody as TBody,
    TableHeader as THead,
    TableHeaderColumn as Th,
    Table,
    TableRow as Tr,
    TableRowColumn as Td,
    Mobile,
    TabletOrDesktop,
} from '@ecster/components';

import { formatAmount } from '../../../common/util/format-amount';

const DueDate = ({ invoiceType, dueDateText }) =>
    invoiceType === 'CREDIT' ? (
        <span className="e-gray120">{i18n('invoice.invoice-overview.table.credit-invoice')}</span>
    ) : (
        dueDateText
    );

DueDate.propTypes = {
    invoiceType: PropTypes.string.isRequired,
    dueDateText: PropTypes.string.isRequired,
};

const getIcon = icon => {
    const classes = classNames({
        'invoice-icon-ctr': true,
        'has-icon': icon,
    });
    return <div className={classes}>{typeof icon === 'string' ? <img src={icon} alt="" /> : icon}</div>;
};

export default class InvoicePanel extends React.Component {
    static propTypes = {
        invoices: PropTypes.arrayOf(PropTypes.object),
        header: PropTypes.string,
    };

    static defaultProps = {
        invoices: [],
        header: undefined,
    };

    state = {};

    onClickInvoice = invoice => {
        if (invoice.url) {
            window.location.hash = invoice.url;
        }
    };

    render() {
        const { invoices, header } = this.props;

        if (!invoices || invoices.length === 0) return null;

        return (
            <Panel paddingAll="small" className="invoice-panel">
                <PanelContent fullWidth>
                    {header && (
                        <div className="invoice-panel-header">
                            <h2 className="h3">{header}</h2>
                        </div>
                    )}
                    <Mobile>
                        <Table large>
                            <TBody>
                                {invoices.map((invoice, index) => (
                                    <Tr
                                        id={`invoice-${index + 1}`}
                                        key={`${invoice.invoiceNumber}.${invoice.amount}`}
                                        onClick={() => this.onClickInvoice(invoice)}
                                    >
                                        <Td>
                                            <div className="flex flex-row justify-start">
                                                {getIcon(invoice.icon)}
                                                <div className="flex flex-col">
                                                    <div>{invoice.description}</div>
                                                    <small>
                                                        <DueDate
                                                            invoiceType={invoice.type}
                                                            dueDateText={invoice.dueDateText}
                                                        />
                                                    </small>
                                                </div>
                                            </div>
                                        </Td>
                                        <Td alignRight>
                                            <div className="flex flex-row justify-end">
                                                {formatAmount(invoice.amount)}
                                                <i className="icon-chevron-right" />
                                            </div>
                                            <div className="invoice-badge-ctr">{invoice.badge}</div>
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    </Mobile>

                    <TabletOrDesktop>
                        <Table>
                            <THead white>
                                <Tr className="invoice-table-header-row">
                                    <Th>{i18n('invoice.invoice-overview.table.description')}</Th>
                                    <Th>{i18n('invoice.general.due-date')}</Th>
                                    <Th /> {/* Badge */}
                                    <Th alignRight>{i18n('invoice.invoice-overview.table.amount')}</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {invoices.map((invoice, index) => (
                                    <Tr
                                        id={`invoice-${index + 1}`}
                                        key={`${invoice.invoiceNumber}.${invoice.amount}`}
                                        onClick={() => this.onClickInvoice(invoice)}
                                    >
                                        <Td className="invoice-table-row-col">
                                            <div className="invoice-icon-descr">
                                                {getIcon(invoice.icon)} {invoice.description}
                                            </div>
                                        </Td>
                                        <Td className="invoice-table-row-col">
                                            <DueDate invoiceType={invoice.type} dueDateText={invoice.dueDateText} />
                                        </Td>
                                        <Td className="invoice-table-row-col">{invoice.badge}</Td>
                                        <Td className="invoice-table-row-col" alignRight>
                                            <div className="flex flex-row justify-end">
                                                {formatAmount(invoice.amount)}
                                                <i className="icon-chevron-right" />
                                            </div>
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    </TabletOrDesktop>
                </PanelContent>
            </Panel>
        );
    }
}
