import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_TERMS_BEGIN,
    ACCOUNT_GET_ACCOUNT_TERMS_DISMISS_ERROR,
    ACCOUNT_GET_ACCOUNT_TERMS_FAILURE,
    ACCOUNT_GET_ACCOUNT_TERMS_SUCCESS,
} from './constants';
import { GET_ACCOUNT_TERMS_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_ACCOUNT_TERMS_STATE = 'CLEAR_GET_ACCOUNT_TERMS_STATE';

export const getAccountTerms = (customerId, accountRef) => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_ACCOUNT_TERMS_BEGIN,
    });

    try {
        const key = GET_ACCOUNT_TERMS_URL(customerId, accountRef);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_TERMS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getAccountTerms');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_TERMS_FAILURE,
            data: {
                error: {
                    message: err,
                    action: ACCOUNT_GET_ACCOUNT_TERMS_FAILURE,
                },
            },
        });
    }
};

export const dismissGetAccountTermsError = () => ({ type: ACCOUNT_GET_ACCOUNT_TERMS_DISMISS_ERROR });

export const clearGetAccountTermsState = () => ({ type: CLEAR_GET_ACCOUNT_TERMS_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_TERMS_BEGIN:
            return {
                ...state,
                getAccountTermsPending: true,
                getAccountTermsError: null,
                getAccountTermsIsDone: false,
                getAccountTermsIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_TERMS_SUCCESS:
            return {
                ...state,
                accountTerms: action.data,
                getAccountTermsPending: false,
                getAccountTermsError: null,
                getAccountTermsIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_TERMS_FAILURE:
            return {
                ...state,
                getAccountTermsPending: false,
                getAccountTermsError: action.data.error,
                getAccountTermsIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_TERMS_DISMISS_ERROR:
            return {
                ...state,
                getAccountTermsError: null,
                getAccountTermsIsError: false,
            };

        case CLEAR_GET_ACCOUNT_TERMS_STATE:
            return {
                ...state,
                accountTerms: null,
                getAccountTermsPending: false,
                getAccountTermsError: null,
                getAccountTermsIsDone: false,
                getAccountTermsIsError: false,
            };

        default:
            return state;
    }
}
