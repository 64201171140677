import React from 'react';
import PropTypes from 'prop-types';

import { Select, Option } from '../Select';

const texts = {
    en: {
        condominium: 'Condominium',
        detachedHouse: 'Detached house',
        label: 'Accommodation',
        other: 'Other',
        rental: 'Rental',
        validationMessage: 'Select your type of accommodation',
        withParents: 'FI only',
    },
    fiFI: {
        condominium: 'Osaomistus',
        detachedHouse: 'Oma',
        label: 'Asumismuoto',
        other: 'Muu',
        rental: 'Vuokra',
        validationMessage: 'Valitse nykyinen asumismuotosi',
        withParents: 'Vanhempien luona',
    },
    svFI: {
        condominium: 'Bostadsrätt',
        detachedHouse: 'Villa / radhus',
        label: 'Boendeform',
        other: 'Annan boendeform',
        rental: 'Hyresrätt',
        validationMessage: 'Välj din boendeform',
        withParents: 'Hos föräldrar',
    },
    svSE: {
        condominium: 'Bostadsrätt',
        detachedHouse: 'Villa / radhus',
        label: 'Boendeform',
        other: 'Annan boendeform',
        rental: 'Hyresrätt',
        validationMessage: 'Välj din boendeform',
        withParents: 'FI only',
    },
};

interface ResidenceTypeProps {
    id?: string;

    /**
     * Override default label, default label depends on language and country
     */
    label?: string;

    /**
     * Override default validation maessage, default message depends on language and country
     */
    validationMessage?: string;

    /**
     * The selected value
     */
    value: string;

    /**
     * onChange event handler
     */
    onChange: Function;

    /**
     * The preferred language, lower case ISO code - sv, fi, en etc
     *
     * language and country must combine into a valid locale
     */
    language?: string;

    /**
     * The country, upper case ISO code - SE, FI etc
     *
     * Options may vary depending on country
     *
     * language and country must combine into a valid locale
     */
    country?: string;

    /**
     * Is this value required?
     */
    required?: boolean;
}

export const ResidenceType = React.forwardRef<Select, ResidenceTypeProps>(
    (
        { country, id, label, language, onChange, required, validationMessage, value, ...rest }: ResidenceTypeProps,
        ref
    ) => {
        const languageKey = language === 'en' ? 'en' : language.toLowerCase() + country.toUpperCase();
        const i18n = key => texts[languageKey][key];

        const theLabel = label || i18n('label');
        const theValidationMessage = validationMessage || i18n('validationMessage');

        return (
            <Select
                id={id}
                ref={ref}
                label={theLabel}
                value={value}
                onChange={onChange}
                required={required}
                validationMessage={theValidationMessage}
                {...rest}
            >
                <Option label={i18n('rental')} value="TENANT" key="TENANT" />
                <Option label={i18n('condominium')} value="CONDOMINIUM" key="CONDOMINIUM" />
                <Option label={i18n('detachedHouse')} value="DETACHED_HOUSE" key="DETACHED_HOUSE" />
                {country === 'FI' && <Option label={i18n('withParents')} value="PARENTS" key="PARENTS" />}
                <Option label={i18n('other')} value="OTHER" key="OTHER" />
            </Select>
        );
    }
);

ResidenceType.propTypes = {
    id: PropTypes.string,

    /**
     * Override default label, default label depends on language and country
     */
    label: PropTypes.string,

    /**
     * Override default validation maessage, default message depends on language and country
     */
    validationMessage: PropTypes.string,

    /**
     * The selected value
     */
    value: PropTypes.string.isRequired,

    /**
     * onChange event handler
     */
    onChange: PropTypes.func.isRequired,

    /**
     * The preferred language, lower case ISO code - sv, fi, en etc
     *
     * language and country must combine into a valid locale
     */
    language: PropTypes.string,

    /**
     * The country, upper case ISO code - SE, FI etc
     *
     * Options may vary depending on country
     *
     * language and country must combine into a valid locale
     */
    country: PropTypes.string,

    /**
     * Is this value required?
     */
    required: PropTypes.bool,
};

ResidenceType.defaultProps = {
    id: 'ec-residence-type',
    label: '',
    validationMessage: '',
    language: 'sv',
    country: 'SE',
    required: false,
};
