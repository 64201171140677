import { post } from '@ecster/net/v2';
import {
    CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_BEGIN,
    CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_SUCCESS,
    CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_FAILURE,
    CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_CREATE_CUSTOMER_SERVICE_REQUEST_STATE = 'CLEAR_CREATE_CUSTOMER_SERVICE_REQUEST_STATE';

const CREATE_CUSTOMER_SERVICE_REQUEST_URL = customerId => `/rest/ess/customers/v1/${customerId}/questions`;

export const createCustomerServiceRequest = (customerId, data) => async dispatch => {
    dispatch({
        type: CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_BEGIN,
    });

    try {
        await cache.clear();
        const res = await post(CREATE_CUSTOMER_SERVICE_REQUEST_URL(customerId), data);

        dispatch({
            type: CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'createCustomerServiceRequest');

        dispatch({
            type: CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_FAILURE,
            data: {
                error: {
                    message: err,
                    action: CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_FAILURE,
                },
            },
        });
    }
};

export const dismissCreateCustomerServiceRequestError = () => ({
    type: CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_DISMISS_ERROR,
});

export const clearCreateCustomerServiceRequestState = () => ({ type: CLEAR_CREATE_CUSTOMER_SERVICE_REQUEST_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_BEGIN:
            return {
                ...state,
                createCustomerServiceRequestPending: true,
                createCustomerServiceRequestError: null,
                createCustomerServiceRequestIsDone: false,
                createCustomerServiceRequestIsError: false,
            };

        case CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_SUCCESS:
            return {
                ...state,
                createCustomerServiceRequestPending: false,
                createCustomerServiceRequestError: null,
                createCustomerServiceRequestIsDone: true,
            };

        case CLEAR_CREATE_CUSTOMER_SERVICE_REQUEST_STATE:
            return {
                ...state,
                createCustomerServiceRequestIsDone: false,
                createCustomerServiceRequestIsError: false,
            };

        case CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_FAILURE:
            return {
                ...state,
                createCustomerServiceRequestPending: false,
                createCustomerServiceRequestError: action.data.error,
                createCustomerServiceRequestIsError: true,
            };

        case CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_DISMISS_ERROR:
            return {
                ...state,
                createCustomerServiceRequestError: null,
                createCustomerServiceRequestIsError: false,
            };

        default:
            return state;
    }
}
