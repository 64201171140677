import { del } from '@ecster/net/v2';

import {
    ACCOUNT_DELETE_ACCOUNT_BEGIN,
    ACCOUNT_DELETE_ACCOUNT_SUCCESS,
    ACCOUNT_DELETE_ACCOUNT_FAILURE,
    ACCOUNT_DELETE_ACCOUNT_DISMISS_ERROR,
} from './constants';
import { DELETE_ACCOUNT_URL, GET_ACCOUNT_URL, GET_ACCOUNTS_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

export const deleteAccount = (customerId, accountRef) => async dispatch => {
    dispatch({
        type: ACCOUNT_DELETE_ACCOUNT_BEGIN,
    });

    try {
        const res = await del(DELETE_ACCOUNT_URL(customerId, accountRef));
        await cache.delete(GET_ACCOUNT_URL(customerId, accountRef));
        await cache.delete(GET_ACCOUNTS_URL(customerId));

        dispatch({
            type: ACCOUNT_DELETE_ACCOUNT_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'deleteAccount');

        dispatch({
            type: ACCOUNT_DELETE_ACCOUNT_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissDeleteAccountError = () => ({ type: ACCOUNT_DELETE_ACCOUNT_DISMISS_ERROR });

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_DELETE_ACCOUNT_BEGIN:
            return {
                ...state,
                deleteAccountPending: true,
                deleteAccountError: null,
                deleteAccountIsDone: false,
                deleteAccountIsError: false,
            };

        case ACCOUNT_DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                deleteAccountPending: false,
                deleteAccountError: null,
                deleteAccountIsDone: true,
            };

        case ACCOUNT_DELETE_ACCOUNT_FAILURE:
            return {
                ...state,
                deleteAccountPending: false,
                deleteAccountError: action.data.error,
                deleteAccountIsError: true,
            };

        case ACCOUNT_DELETE_ACCOUNT_DISMISS_ERROR:
            return {
                ...state,
                deleteAccountError: null,
                deleteAccountIsError: false,
            };

        default:
            return state;
    }
}
