import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSession } from "../redux/actions";
import { createSessionCookie } from "../../../common/sessionCookie";
import { WarningMsg } from "./WarningMsg";
import { i18n } from "@ecster/i18n";
import { reportError } from "../../../common/reportError.js";
import { get } from "@ecster/net";

type TokenResponse = {
  response: {
    pollAgain: boolean;
    sessionKey?: string;
  };
};

type Props = { internalToken: string };

export const NoCookieAuthPanel = ({ internalToken }: Props) => {
  const dispatch = useDispatch<any>();
  const [isError, setIsError] = useState(false);
  const [startTime, _] = useState(Date.now());

  useEffect(() => {
    window.history.replaceState(null, "", location.origin + location.hash);

    if (internalToken) {
      pollGetSessionKeyFromToken();
    } else {
      reportError(
        {
          description: "No cookie auth panel: missing token in query params",
        },
        "nets-redirect"
      );
    }
  }, []);

  const pollGetSessionKeyFromToken = async () => {
    try {
      const {
        response: { pollAgain, sessionKey },
      } = await get<TokenResponse>(`/rest/sessions/v2/token/${internalToken}`);
      if (isTimeout()) {
        setIsError(true);
        reportError(
          {
            description:
              "No cookie auth panel: Polling has exceeded 15 seconds",
          },
          "nets-redirect"
        );
      } else {
        if (!pollAgain) {
          createSessionCookie(sessionKey);
          dispatch(getSession());
        } else {
          setTimeout(() => {
            pollGetSessionKeyFromToken();
          }, 1500);
        }
      }
    } catch (e) {
      setIsError(true);
      reportError(
        {
          description:
            "No cookie auth panel: failed to get session key from token",
        },
        "nets-redirect"
      );
    }
  };

  const isTimeout = () => Date.now() - startTime > 15_000;

  return isError || !internalToken ? (
    <WarningMsg
      header={i18n("authentication.nets.failed.header")}
      text={i18n("authentication.nets.failed.text", "p", {
        danger: true,
      })}
      linkText={i18n("authentication.nets.failed.link")}
      linkTarget="/"
    />
  ) : null;
};
