import React from 'react';
import { render } from 'react-dom';
import { getBaseUrl, setBaseUrl, setErrorHandler, setOrigin } from '@ecster/net';

import { createOriginString } from '@ecster/util';

import { i18nInit } from '@ecster/i18n';
import { Cookie } from '@ecster/net';
import { detectDevice, IsKeyboardVisible } from '@ecster/components';
import configStore, { history } from './common/configStore';
import routeConfig from './common/routeConfig';
import { isFI } from './common/country';
import Root from './Root';
import Gateway from './common/Gateway';
import addDeviceClass from './common/util/add-device-class';

import './styles/index.scss';
import { initBackForwardCacheBuster } from './common/util/back-forward-cache';
import { cache } from './common/cacheHandler';
import { createOriginCookie, getOriginCookie } from './common/OriginCookie';

const { EcsterConfig = {} } = window;

const currency = {
    FI: 'EUR',
    SE: 'SEK',
};

const defaultLanguage = {
    FI: 'fi',
    SE: 'sv',
};

const isLoginOrLogoutPage = () =>
    window.location.hash === '#/' ||
    window.location.hash === '#/authentication/logout' ||
    window.location.hash === '#/start';

export const store = configStore();

const App = async ({ store, routeConfig, conf }) => {
    const typeOfDevice = addDeviceClass(); // Adds a corresponding CSS class to the HTML element, depending on type of device used.

    let App;

    // Add country class to the HTML tag, so we can separate the in CSS
    const html = document.querySelector('html');
    html.classList.add(`is-${EcsterConfig.applicationCountry?.toLowerCase()}`);
    html.setAttribute('lang', window.EcsterConfig.locale || 'sv-SE');

    if (typeOfDevice === 'MOBILE') {
        App = (
            <>
                <Gateway config={conf} App={<Root store={store} routeConfig={routeConfig} />} />
                <IsKeyboardVisible />
            </>
        );
    } else {
        App = <Gateway config={conf} App={<Root store={store} routeConfig={routeConfig} />} />;
    }

    render(App, document.getElementById('react-root'));
};

const initI18n = () => {
    const cookieLocale = Cookie.read('locale');
    const [cookieLanguage, cookieCountry] = cookieLocale ? cookieLocale.split('-') : [null, null];
    const { EcsterConfig: conf } = window;

    // country in cookie must be same as application country to avoid inconsistency in aws same domain test environments
    if (conf.applicationCountry === 'FI' && cookieCountry === conf.applicationCountry) {
        conf.language = cookieLanguage;
        conf.locale = cookieLocale;
    } else {
        conf.language = defaultLanguage[conf.applicationCountry];
        conf.locale = `${conf.language}-${conf.applicationCountry}`;
        Cookie.create('locale', conf.locale);
    }

    conf.currency = currency[conf.applicationCountry];
    document.querySelector('html').setAttribute('lang', conf.language);

    return i18nInit('static/i18n', conf.language, conf.applicationCountry);
};

const initApplication = () => {
    const conf = window.EcsterConfig;
    conf.applicationCountry = isFI() ? 'FI' : 'SE'; // URL will be analyzed and then country is determined

    const { isMobile, isTablet, isDesktop } = detectDevice();
    const originId = getOriginCookie() || createOriginCookie();

    setOrigin(createOriginString('mypages', isMobile, isTablet, isDesktop, window, originId));
    window.sessionStorage.setItem('initialBaseUrl', conf.baseURL);

    if (conf) {
        const getBackend = getBaseUrl();
        if (getBackend) {
            conf.baseURL = getBackend;
        } else if (conf.baseURL) {
            const { baseURL } = conf;
            setBaseUrl(baseURL);
        }
    }

    setErrorHandler(error => {
        if (error?.status === 401 && !isLoginOrLogoutPage()) history.push('/authentication/logout');
    });

    initBackForwardCacheBuster();

    initI18n().then(() => {
        App({ store, routeConfig, conf });
    });
};

if (window.EcsterConfig.isCordova) {
    window.EcsterConfig.init = initApplication; // called on deviceready event
} else {
    initApplication();
    console.info('Application is initialized.');
}

cache.init();
