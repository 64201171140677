/* eslint-disable react/no-unused-prop-types, react/require-default-props */
import '../MultiSelect.scss';
import PropTypes from 'prop-types';

import React, { forwardRef } from 'react';

import { MultiSelectProvider } from '../hooks/use-multi-select';
import { ISelectProps, Option } from '../lib/interfaces';
import Dropdown from './dropdown';

export const MultiSelect = forwardRef<{}, ISelectProps>((props: ISelectProps, ref: React.ForwardedRef<{}>) => {
    const { className, label }: ISelectProps = props;

    return (
        <MultiSelectProvider props={props}>
            <div className={`multiselect ${className || 'multi-select'}`}>
                {label && <label>{label}</label>}
                <Dropdown ref={ref} />
            </div>
        </MultiSelectProvider>
    );
});

MultiSelect.defaultProps = {
    value: [],
    hasSelectAll: true,
    className: 'multi-select',
    debounceDuration: 200,
    options: [] as Option[],
    required: false,
};

MultiSelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func,
    valueRenderer: PropTypes.func,
    disabled: PropTypes.bool,
    disableSearch: PropTypes.bool,
    hasSelectAll: PropTypes.bool,
    filterOptions: PropTypes.func,
    overrideStrings: PropTypes.shape({}),
    labelledBy: PropTypes.string,
    className: PropTypes.string,
    onMenuToggle: PropTypes.func,
    ClearIcon: PropTypes.node,
    debounceDuration: PropTypes.number,
    ClearSelectedIcon: PropTypes.node,
    defaultIsOpen: PropTypes.bool,
    isOpen: PropTypes.bool,
    closeOnChangedValue: PropTypes.bool,
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    validationMessage: PropTypes.string,
};

export default MultiSelect;
