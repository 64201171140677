import { COMMON_SHOW_ONBOARDING } from './constants';

export const showOnboarding = () => ({
    type: COMMON_SHOW_ONBOARDING,
});

// don't ES6 this one, rekit gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case COMMON_SHOW_ONBOARDING:
            return {
                ...state,
                onboarding: {
                    show: true,
                },
            };

        default:
            return state;
    }
}
