import { put } from '@ecster/net/v2';

import {
    CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_BEGIN,
    CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_SUCCESS,
    CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_FAILURE,
    CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_DISMISS_ERROR,
} from './constants';
import { UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

export const updateCustomerExtraCardHolderContactInfo = (customerId, data) => async dispatch => {
    dispatch({
        type: CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_BEGIN,
    });

    try {
        await cache.clear();
        const res = await put(UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_URL(customerId), data);

        dispatch({
            type: CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'updateCustomerExtraCardHolderContactInfo');

        dispatch({
            type: CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissUpdateCustomerExtraCardHolderContactInfoError = () => ({
    type: CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_DISMISS_ERROR,
});

export function reducer(state, action) {
    switch (action.type) {
        case CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_BEGIN:
            return {
                ...state,
                updateCustomerExtraCardHolderContactInfoPending: true,
                updateCustomerExtraCardHolderContactInfoError: null,
                updateCustomerExtraCardHolderContactInfoIsDone: false,
                updateCustomerExtraCardHolderContactInfoIsError: false,
            };

        case CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_SUCCESS:
            return {
                ...state,
                updateCustomerExtraCardHolderContactInfoPending: false,
                updateCustomerExtraCardHolderContactInfoError: null,
                updateCustomerExtraCardHolderContactInfoIsDone: true,
            };

        case CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_FAILURE:
            return {
                ...state,
                updateCustomerExtraCardHolderContactInfoPending: false,
                updateCustomerExtraCardHolderContactInfoError: action.data.error,
                updateCustomerExtraCardHolderContactInfoIsError: true,
            };

        case CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_DISMISS_ERROR:
            return {
                ...state,
                updateCustomerExtraCardHolderContactInfoError: null,
                updateCustomerExtraCardHolderContactInfoIsError: false,
            };

        default:
            return state;
    }
}
