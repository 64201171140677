/**
 * Build an origin string to be sent in the X-ecster-origin header
 *
 * Example origin info
 *
 *     prefix - web-app - mobile - 1.0.1234
 *     prefix - web-app - desktop - 1.0.1234
 *     prefix - web-app - desktop - [dev: no version]
 *     prefix - web-app - desktop - IE11 - 1.0.1234
 *     prefix - ios-app - mobile - 4.0.16
 *     prefix - ios-app - tablet - 4.0.16
 *     prefix - android app - mobile - 4.0.15
 *     prefix - android app - tablet - 4.0.15
 *     prefix - android app - tablet - 4.0.15 - extraArg1
 *     prefix - android app - tablet - 4.0.15 - extraArg1 - extraArg2
 *
 * @param {string} prefix
 * @param {boolean} isMobile
 * @param {boolean} isTablet
 * @param {boolean} isDesktop
 * param for mocking
 * @param {object} _window (optional, defaults to window if omitted, use for mocking in tests)
 * optional extra values
 * @param {string} args optional extra parameters to append at the end
 */
export const createOriginString = (prefix, isMobile, isTablet, isDesktop, _window, ...args) => {
    try {
        const global = _window || window;
        const conf = global.EcsterConfig;

        const appName = conf.isWeb
            ? 'web-app'
            : conf.isIOSApp
            ? 'ios-app'
            : conf.isAndroidApp
            ? 'android-app'
            : 'web-app';

        const isIE11 = global.navigator.userAgent.match(/Trident\/7/);
        const origin = [prefix, appName];
        if (isMobile) origin.push('mobile');
        if (isTablet) origin.push('tablet');
        if (isDesktop) origin.push('desktop');
        if (isIE11) origin.push('IE11');
        // regexp (one or more numbers)(a dot)(one or more numbers)(a dot)(one or more numbers)
        origin.push(conf.version.match(/\d+\.\d+\.\d+/) ? conf.version : '[dev: no version]');

        if (args && args.length > 0) {
            args.forEach(arg => {
                if (typeof arg === 'string') origin.push(arg);
            });
        }

        return origin.join(' - ');
    } catch (e) {
        return prefix;
    }
};
