import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent } from '@ecster/components';

import ShowExtraCardSubpanel from './ShowExtraCardSubpanel';

export default class ShowExtraCardsPanel extends React.Component {
    static propTypes = {
        cards: PropTypes.array.isRequired,
        customerId: PropTypes.number.isRequired,
        updateAccountCard: PropTypes.func.isRequired,
        updateCustomerExtraCardHolderContactInfo: PropTypes.func.isRequired,
        updateAccountCardPending: PropTypes.bool.isRequired,
        isWrongCvc: PropTypes.bool.isRequired,
        mainCardIsTemporarilyBlocked: PropTypes.bool,
    };

    static defaultProps = { mainCardIsTemporarilyBlocked: false };

    render() {
        const {
            customerId,
            cards,
            updateAccountCard,
            updateCustomerExtraCardHolderContactInfo,
            updateAccountCardPending,
            mainCardIsTemporarilyBlocked,
            isWrongCvc,
        } = this.props;

        const extraCards = cards.filter(card => card.status !== 'ORDERED');

        return (
            extraCards.length > 0 && (
                <Panel paddingTopBottom="xLarge" stretchInMobile className="card-show-extra-cards-panel">
                    <PanelContent narrow>
                        <h1 className="h3">{i18n('card.show-extra-card.header')}</h1>
                        <div className="extra-cards">
                            {extraCards.map(card => (
                                <ShowExtraCardSubpanel
                                    key={card.cardNumber}
                                    card={card}
                                    updateAccountCard={updateAccountCard}
                                    updateCustomerExtraCardHolderContactInfo={updateCustomerExtraCardHolderContactInfo}
                                    updateAccountCardPending={updateAccountCardPending}
                                    isWrongCvc={isWrongCvc}
                                    customerId={customerId}
                                    mainCardIsTemporarilyBlocked={mainCardIsTemporarilyBlocked}
                                />
                            ))}
                        </div>
                    </PanelContent>
                </Panel>
            )
        );
    }
}
