import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, ResponsivePanel, LabelValue } from '@ecster/components';
import { PaymentMessage } from './PaymentMessage';
import { PaymentFreeMonthMessage } from './PaymentFreeMonthMessage';

import './Header.scss';
import { formatAmount, formatDate } from '../../../../common/util/format';

export const Header = ({ bill, billProps }) => {
    const col1Amount = bill.paymentFreeMonth ? (
        <p className="large-text">{formatAmount(0)}</p>
    ) : (
        <p className="large-text">{formatAmount(billProps.lab2)}</p>
    );

    const col2Info = bill.paymentFreeMonth ? (
        <LabelValue label={i18n('invoice.account-invoices.header.due-date')} value={formatDate(bill.payment.dueDate)} />
    ) : (
        <>
            <LabelValue label={i18n('invoice.account-invoices.header.status')} value={billProps.badge} />
            <LabelValue
                label={i18n('invoice.account-invoices.header.due-date')}
                value={formatDate(bill.payment.dueDate)}
            />
            {billProps.lab1 !== billProps.lab2 && (
                <LabelValue
                    label={i18n('invoice.account-invoices.header.min-payment')}
                    value={formatAmount(billProps.lab1)}
                />
            )}
            {billProps.amountPaidByCustomer > 0 && (
                <LabelValue
                    label={i18n('invoice.account-invoices.header.paid-amount')}
                    value={formatAmount(billProps.amountPaidByCustomer)}
                />
            )}
        </>
    );

    return (
        <div className="account-invoice-header">
            <PaymentFreeMonthMessage bill={bill} billProps={billProps} />
            <Panel greenBG sideMarginsInMobile>
                <PanelContent>
                    <ResponsivePanel verticalLines>
                        <div className="centered">
                            {col1Amount}
                            <p>{i18n('invoice.account-invoices.header.due-amount')}</p>
                        </div>
                        <div>{col2Info}</div>
                    </ResponsivePanel>
                </PanelContent>
            </Panel>
            <PaymentMessage bill={bill} billProps={billProps} />
        </div>
    );
};

Header.propTypes = {
    bill: PropTypes.object.isRequired,
    billProps: PropTypes.object.isRequired,
};
