import { post } from '@ecster/net';

const sendLogEntry = (severity, operation, origin, data, description) => {
    const msgData = typeof data === 'string' ? { info: data } : data;

    try {
        msgData.meta = {};

        msgData.meta.origin = origin;
        msgData.meta.description = description;
        msgData.meta.severity = severity;

        const postData = {
            operation: `${severity}: ${origin}: ${operation}`,
            data: JSON.stringify(msgData),
        };

        post('/rest/log/v1', postData);
    } catch (e) {
        // ignore errors, don't crash application when logging fails
        console.warn('Server logging failed: ', msgData);
    }
};

/**
 * Log an info record to the server
 * @param operation - identifiable name of logging event - e.g. "application started"
 * @param origin - unique application name
 * @param data - (optional) any string or JSON data to be logged
 * @param description - (optional) a short text description
 */
export const info = (operation, origin, data, description = '') => {
    sendLogEntry('info', operation, origin, data, description);
};

/**
 * Log a warning record to the server
 * @param operation - identifiable name of logging event - e.g. "user record incomplete"
 * @param origin - unique application name
 * @param data - (optional) any string or JSON data to be logged
 * @param description - (optional) a short text description
 */
export const warning = (operation, origin, data, description = '') => {
    sendLogEntry('warning', operation, origin, data, description);
};

/**
 * Log an error record to the server
 * @param operation - identifiable name of logging event - e.g. "payment failed"
 * @param origin - unique application name
 * @param data - (optional) any string or JSON data to be logged
 * @param description - (optional) a short text description
 */
export const error = (operation, origin, data, description = '') => {
    sendLogEntry('error', operation, origin, data, description);
};
