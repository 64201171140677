import React from 'react';
import PropTypes from 'prop-types';

import { Panel, PanelContent, ExpandableContainer } from '@ecster/components';

export const FaqPanel = ({ header, faq, stretch }) => {
    return (
        <div className="common-faq-panel">
            <Panel>
                <PanelContent centered narrow>
                    <h2 className="h3">{header}</h2>
                </PanelContent>
                <PanelContent narrow>
                    {faq.map(item => {
                        return (
                            <ExpandableContainer
                                className="faq-expandable"
                                key={item.q}
                                header={item.q}
                                stretch={stretch}
                            >
                                {typeof item.a === 'string' ? <p>{item.a}</p> : item.a.map(s => <p key={s}>{s}</p>)}
                            </ExpandableContainer>
                        );
                    })}
                </PanelContent>
            </Panel>
        </div>
    );
};

FaqPanel.propTypes = {
    // header text
    header: PropTypes.string.isRequired,

    // an array of objects [ {q: '...', a: '...'}, {q: '...', a: ['...', '...', ...]}
    //    q - questions: a string
    //    a - answers: a string or an array of strings
    faq: PropTypes.object.isRequired,
    stretch: PropTypes.bool,
};

FaqPanel.defaultProps = {
    stretch: false,
};

export default FaqPanel;
