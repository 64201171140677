import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';

export const BgNumber = ({ bgNumber }) => (
    <LabelValue
        label={i18n('invoice.invoice-values.bg-number')}
        value={
            <CopyToClipboardButton
                id="copyBgNumber"
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-bg-number')}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={bgNumber}
            >
                {bgNumber}
            </CopyToClipboardButton>
        }
    />
);
BgNumber.propTypes = { bgNumber: PropTypes.string.isRequired };
