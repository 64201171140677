// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { renderTableChildNodes } from './renderTableChildNodes';
import './TableRow.scss';

interface TableRowProps {
    /** CSS id */
    id?: string;

    /** Child elements: [TableRowColumn](#tablerowcolumn), [TableHeaderColumn](#tableheadercolumn) */
    children: ReactNode;
    onClick?: Function;
    onMouseEnter?: Function;
    onMouseLeave?: Function;

    /** For simple native mouse over tooltip */
    title?: string;

    /** Additional css classes */
    className?: string;

    /** @deprecated use className and css instead */
    style?: CSSProperties;
}

const TableRow = ({ children, onClick, onMouseEnter, onMouseLeave, title, className, style, id }: TableRowProps) => (
    <tr
        id={id}
        className={`table-row ${className} ${typeof onClick === 'function' && 'table-row-clickable'}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style}
        title={title}
    >
        {renderTableChildNodes(children)}
    </tr>
);

TableRow.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Child elements: [TableRowColumn](#tablerowcolumn), [TableHeaderColumn](#tableheadercolumn) */
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,

    /** For simple native mouse over tooltip */
    title: PropTypes.string,

    /** Additional css classes */
    className: PropTypes.string,

    /** @deprecated use className and css instead */
    style: PropTypes.shape(),
};

TableRow.defaultProps = {
    id: '',
    onClick: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    className: '',
    style: {},
    title: '',
};

export default TableRow;
