import React from 'react';
import classNames from 'classnames';

import { Link, IconButton } from '../Clickable';

import './FileList.scss';

interface FileListProps {
    /**
     * A key/value object with File objects.
     * - key - filename
     * - value - file object (or any kind of object, File object when using with FileSelector)
     */
    files: { [key: string]: any };

    /**
     * An additional action when a file object is deleted from the list,
     * such as calling a deleteFileFromDatabase function
     */
    onDeleteFile?: Function;

    /**
     * When clicking on the file this function is triggered.
     * Like when you wish to do a GET request to fetch and open it.
     */
    onClick?: Function;

    /**
     * Use a trashcan icon for delete button
     */
    iconTrash?: boolean;

    /**
     * Use an X icon for delete button
     */
    iconX?: boolean;

    /**
     * Transparent BG, black, text, green icon
     */
    green?: boolean;
    /**
     * Transparent BG, black text, purple icon
     */
    purple?: boolean;

    /**
     * Transparent BG, white text, white icon
     */
    white?: boolean;
}

export class FileList extends React.Component<FileListProps> {
    onDeleteFile = file => {
        const { onDeleteFile} = this.props;

        if (typeof onDeleteFile === 'function') onDeleteFile(file.fileName);
    };

    render() {
        const { files, iconTrash, iconX, green, purple, white, onClick } = this.props;

        const classes = classNames({
            'ec-file-list': true,
            // colors
            'ui-green': green && !(purple || white),
            'ui-purple': purple,
            'ui-white': white,
        });

        const deleteIcon = iconX ? 'icon-x' : iconTrash ? 'icon-trash' : '';
        return (
            <div className={classes}>
                <ul>
                    {files &&
                        Object.entries(files).map(([, file]) => {
                            return (
                                <li key={file.fileName}>
                                    <Link
                                        white={white}
                                        purple={purple}
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            onClick(file);
                                        }}
                                    >
                                        {file.fileName}
                                    </Link>
                                    <IconButton
                                        className="delete-icon"
                                        onClick={() => this.onDeleteFile(file)}
                                        icon={deleteIcon}
                                        type="button"
                                    />
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
FileList.defaultProps = {
    onDeleteFile: () => {},
    onClick: null,
    iconTrash: true,
    iconX: false,
    green: true,
    purple: false,
    white: false,
};
