import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { robotFaceIcon } from '@ecster/icons/animated';

import AuthenticatedSubPageTemplate from './templates/AuthenticatedSubPageTemplate';
import PublicPageTemplate from './templates/PublicPageTemplate';

const Wrapper = ({ isLoggedIn, header, children, className }) => {
    return isLoggedIn ? (
        <AuthenticatedSubPageTemplate header={header} className={`${className} logged-in-404`}>
            <div className="content-404">{children}</div>
        </AuthenticatedSubPageTemplate>
    ) : (
        <PublicPageTemplate className={className}>
            <div className="content-404">{children}</div>
        </PublicPageTemplate>
    );
};

Wrapper.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

class PageNotFound extends PureComponent {
    render() {
        const {
            loginStatus: { isLoggedIn },
        } = this.props;

        const text = isLoggedIn ? i18n('common.404.info.logged-in', 'p') : i18n('common.404.info.not-logged-in', 'p');
        const link = isLoggedIn ? '/account/overview' : '/';
        const linkText = isLoggedIn
            ? i18n('common.404.back-link.logged-in', 'p')
            : i18n('common.404.back-link.not-logged-in', 'p');

        return (
            <Wrapper isLoggedIn={isLoggedIn} header=" 404 " className="common-page-not-found">
                <div className="page-not-found-ctr">
                    <div className="fof-content">
                        <img src={robotFaceIcon} alt="" id="robot-icon" />
                        <h1>{i18n('common.404.header')}</h1>
                        {text}
                        <p>
                            <Link to={link} id="page-not-found-back-link">
                                {linkText}
                            </Link>
                        </p>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

PageNotFound.propTypes = {
    loginStatus: PropTypes.object.isRequired,
};

const mapStateToProps = ({ authentication }) => ({
    loginStatus: authentication.loginStatus,
});

export default connect(mapStateToProps)(PageNotFound);
