import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { removeAdditionalHeader, X_ECSTER_SESSION_KEY_NAME } from '@ecster/net';
import {
    deleteSession as deleteSessionAction,
    dismissDeleteSessionError,
    resetLoginState as resetLoginStateAction,
} from './redux/actions';
import { isSE } from '../../common/country';
import { cache } from '../../common/cacheHandler';
import { deleteOriginCookie } from '../../common/OriginCookie';
import { clearConfig } from '../../common/MyPagesConfig';
import { deleteSessionCookie } from '../../common/sessionCookie';

const LogoutPage = () => {
    const dispatch = useDispatch();

    const { isCustomer: isCustomerStringValue } = useParams();
    const isCustomer = !(isCustomerStringValue === 'false'); // string value arrives on the URL, defaults to true

    const resetLoginState = useCallback(() => dispatch(resetLoginStateAction()), [dispatch]);
    const deleteSession = useCallback(() => dispatch(deleteSessionAction()), [dispatch]);
    const clearState = useCallback(
        () => dispatch({ type: 'CLEAR_STATE', isCustomer, justLoggedOut: true }),
        [dispatch, isCustomer]
    );

    const [deleteSessionFinished, setDeleteSessionFinished] = useState(false);

    const {
        loginStatus: { isLoggedIn },
        deleteSessionIsDone,
        deleteSessionError,
    } = useSelector(state => {
        return state.authentication;
    }, shallowEqual);

    const {
        EcsterConfig: { locale },
        location: { href },
    } = window;

    const localDevEnv = href.match(/^https?:\/\/login\.localhost/) || href.match(/^https?:\/\/localhost\.login/);

    useEffect(() => {
        deleteSession();
        cache.clear();

        deleteOriginCookie();
        deleteSessionCookie();
        removeAdditionalHeader(X_ECSTER_SESSION_KEY_NAME);

        return () => {
            dispatch(dismissDeleteSessionError());
        };
    }, [deleteSession, dispatch]);

    useEffect(() => {
        // wait for deleteSession rest call to finish (failure or success) before clearing state and rendering redirects
        if (!deleteSessionFinished && deleteSessionIsDone) {
            resetLoginState(); // TODO: is this reset included in clearState? // joli44 2021-03

            clearConfig();
            clearState(isCustomer);
            setDeleteSessionFinished(true);
        }
    }, [deleteSessionIsDone, deleteSessionFinished, dispatch, clearState, isCustomer, resetLoginState]);

    // Browser back after logging out
    if (!deleteSessionFinished && !isLoggedIn) {
        return <Redirect to="/" />;
    }

    if ((deleteSessionFinished && !isLoggedIn) || deleteSessionError) {
        if (!isCustomer) {
            return <Redirect to="/authentication/no-account" />;
        }

        if (localDevEnv) {
            return <Redirect to="/" />;
        }

        if (isSE()) {
            window.location.href = 'https://www.ecster.se';
        } else {
            const fiFIUrl = 'https://www.ecster.fi';
            const svFIUrl = 'https://www.ecster.fi/sv/';
            window.location.href = locale === 'fi-FI' ? fiFIUrl : svFIUrl;
        }
    }

    return <div className="authentication-logout-page" data-testid="logout-page" />;
};

export default LogoutPage;
