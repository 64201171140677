/**
 * Create, read and remove cookies
 *
 * Copied from @ecster/net (will be deprecated there soon)
 * Originally borrowed from http://stackoverflow.com/questions/1458724/how-to-set-unset-cookie-with-jquery#answer-1460174 (and modified)
 */

/**
 * Is value numeric or not?
 * @param n The value to check
 */
const isNumeric = (n: any) => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

/**
 * Set (create) a cookie
 * @param name Cookie name
 * @param value Cookie value
 * @param expires Cookie expiration time. Numeric value in days or object with any of the properties days, hours, minutes
 * @param sameSite for test purposes only, Strict (default) or None
 */
export const setCookie = (name: string, value: string, expires: any, sameSite = 'Strict') => {
    let expiresValue;

    if (expires) {
        const date = new Date();

        if (isNumeric(expires)) {
            date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
        } else if (isNumeric(expires.days)) {
            date.setTime(date.getTime() + expires.days * 24 * 60 * 60 * 1000);
        } else if (isNumeric(expires.hours)) {
            date.setTime(date.getTime() + expires.hours * 60 * 60 * 1000);
        } else if (isNumeric(expires.minutes)) {
            date.setTime(date.getTime() + expires.minutes * 60 * 1000);
        } else {
            date.setTime(date.getTime() + 12 * 60 * 60 * 1000); // 12 hours default
        }

        // @ts-ignore - yes toGMTString exists!
        expiresValue = `; expires=${date.toGMTString()}`;
    } else {
        expiresValue = '';
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite#strict
    // For local development you might want to set SameSite to None - to send cookies along to other domains (like for testing with NETS)
    document.cookie = `${name}=${JSON.stringify(value)}${expiresValue}; path=/; SameSite=${sameSite}`;
};

/**
 * Get value of a named cookie
 * @param name Cookie name
 */
export const getCookie = (name: string) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            const value = c.substring(nameEQ.length, c.length);
            return JSON.parse(value);
        }
    }
    return null;
};

/**
 * Remove a named cookie
 * @param name Cookie name
 */
export const removeCookie = (name: string) => {
    setCookie(name, '', -1);
};

/**
 * Check if cookies are enabled by creating and removing a cookie
 */
export const enabled = () => {
    const name = 'ecster-test-cookie-123123';
    const value = 'test';
    try {
        setCookie(name, value, { minutes: 1 });
        if (getCookie(name) === value) {
            removeCookie(name);
            return true;
        }
    } catch (e) {} // eslint-disable-line
    return false;
};
