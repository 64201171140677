import React from 'react';
import classNames from 'classnames';

import './TextArea.scss';

interface TextAreaProps {
    id?: string;
    label?: string;
    name?: string;
    value: string;
    onChange?: Function;
    onBlur?: Function;
    placeholder?: string;

    /**
     * Make TextArea disabled. A disabled TA can not be focused.
     */
    disabled?: boolean;
    className?: string;

    /**
     * Validation: the validation message to show if field does not validate
     */
    validationMessage?: string;

    /**
     * Validation: min length for a text field
     */
    minLength?: number;

    /**
     * Validation: max length for a text field
     */
    maxLength?: number;

    /**
     * Make TextArea read only. A read only TA can be focused.
     */
    readOnly?: boolean;

    /**
     * Incorrect, old spelling of property. Make TextArea read only. A read only TA can be focused.
     * @deprecated use readOnly instead
     */
    readonly?: boolean;

    /**
     * Validation: a value is required
     */
    required?: boolean;
    cols?: number;
    rows?: number;
}

class TextArea extends React.Component<TextAreaProps> {
    state = {
        hasError: false,
    };

    textareaRef = React.createRef<HTMLTextAreaElement>();

    id = `ec-textarea-${Math.floor(Math.random() * 1000000)}`;

    onChange = e => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(e);
        }
    };

    onBlur = e => {
        const { onBlur } = this.props;
        this.doValidation();
        if (onBlur) {
            onBlur(e);
        }
    };

    doValidation = () => {
        let hasError = !this.textareaRef.current.checkValidity();

        // Make sure the value isn't just spaces
        hasError = hasError || this.textareaRef.current.value.trim() === '';

        this.setState({ hasError });
        return !hasError;
    };

    render() {
        const {
            label,
            disabled,
            id,
            validationMessage,
            maxLength,
            value,
            readonly, // old spelling for bw compatibility
            readOnly,
            // remove from ...rest
            onBlur,
            onChange,
            ...rest
        } = this.props;
        const { hasError } = this.state;
        const classes = classNames({
            'ec-textarea': true,
            'has-error': hasError,
            'is-disabled': disabled,
            'is-readonly': readonly || readOnly,
        });

        const labelClasses = classNames({
            'ec-textarea-label': true,
            'no-label': !label,
        });
        const optionalValidationMessage = hasError && <div className="ec-textarea-error">{validationMessage}</div>;

        const used = value ? value.length : 0;

        return (
            <div className={classes}>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label htmlFor={id || this.id} className={labelClasses}>
                    {label}
                </label>
                <textarea
                    ref={this.textareaRef}
                    id={id || this.id}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    maxLength={maxLength}
                    disabled={disabled}
                    readOnly={readonly || readOnly}
                    {...rest}
                />
                {maxLength && <div className="ec-textarea-count">{`${used} / ${maxLength}`}</div>}
                <div className="ec-textarea-error-ctr">{optionalValidationMessage}</div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
TextArea.defaultProps = {
    id: null,
    label: null,
    name: null,
    onBlur: null,
    placeholder: null,
    disabled: false,
    className: '',
    validationMessage: 'Kontrollera värdet',
    minLength: null,
    maxLength: null,
    readonly: false, // old spelling for bw compatibility
    readOnly: false,
    required: false,
    cols: 50,
    rows: 5,
};

export default TextArea;
