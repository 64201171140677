/**
 * Return a new array with only unique items per predicate
 * @param arr (Array) - Array to be parsed
 * @param predicate (function|string) - Unique key in array
 * @returns [] - New unique array
 */

const uniqueBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

    return [
        ...arr
            .reduce((map, item) => {
                const key = item === null || item === undefined ? item : cb(item);

                // eslint-disable-next-line no-unused-expressions
                map.has(key) || map.set(key, item);

                return map;
            }, new Map())
            .values(),
    ];
};

export default uniqueBy;
