import React, {
    ReactNode,
    useImperativeHandle,
    forwardRef,
    useRef,
    useState,
    InputHTMLAttributes,
    useEffect,
    useCallback,
} from 'react';
import classNames from 'classnames';
import './Input.scss';
import { validateEmail } from '@ecster/validation';

const isNumType = type => type === 'number' || type === 'tel';

const hasComparisonErrors = (
    { orNull, eq, lt, gt, le, ge, ne },
    value: any,
    type: InputHTMLAttributes<HTMLInputElement>['type']
): boolean => {
    const nullValueOk = orNull && (eq || lt || gt || le || ge || ne) && !value;

    // diff tests must compare with correct type
    const theValue = isNumType(type) ? parseFloat(value) : value;
    const theLtValue = isNumType(type) ? parseFloat(lt) : lt;
    const theLeValue = isNumType(type) ? parseFloat(le) : le;
    const theGtValue = isNumType(type) ? parseFloat(gt) : gt;
    const theGeValue = isNumType(type) ? parseFloat(ge) : ge;
    const theEqValue = isNumType(type) ? parseFloat(eq) : eq;

    const hasComparisonError =
        (eq !== undefined && !(theValue === theEqValue)) ||
        (ne !== undefined && value === ne) ||
        (lt !== undefined && !(theValue < theLtValue)) ||
        (gt !== undefined && !(theValue > theGtValue)) ||
        (le !== undefined && !(theValue <= theLeValue)) ||
        (ge !== undefined && !(theValue >= theGeValue));

    return hasComparisonError && !nullValueOk;
};

export interface InputProps
    extends Pick<
        InputHTMLAttributes<HTMLInputElement>,
        | 'id'
        | 'onChange'
        | 'onFocus'
        | 'onBlur'
        | 'onKeyUp'
        | 'placeholder'
        | 'name'
        | 'type'
        | 'value'
        | 'style'
        | 'className'
        | 'autoComplete'
        | 'inputMode'
        | 'step'
        | 'min'
        | 'max'
        | 'pattern'
        | 'required'
        | 'lang'
        | 'maxLength'
        | 'minLength'
        | 'tabIndex'
    > {
    /**
     * The text shown to screen readers
     * Should be used especially when the label from isn't available
     */
    ariaLabel?: string;

    /**
     * Optional child elements added after the generated input element
     */
    children?: ReactNode;

    /**
     * Configure auto completion of field. "off" to turn off.
     *
     * Possible values: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-autocomplete
     * @deprecated
     */
    autocomplete?: string;

    /**
     * Input fields label
     */
    label?: ReactNode;

    /**
     * Show label as placeholder, label "floats" to upper left corner
     * on focus or when field has a value.
     */
    floatLabel?: boolean;

    /**
     * Show label inside field (field becomes alignRight)
     */
    labelInside?: boolean;

    /**
     * Disable field (true) or enable field (false or undefined)
     */
    disabled?: boolean;

    /**
     * A URL to an icon.
     *
     * Icon is placed to the right inside the field
     *
     * Only one of "iconClass" and "iconUrl" can be specified
     */
    iconUrl?: string;

    /**
     * A CSS class for an icon font.
     *
     * Icon (<i/> element) is placed to the right inside the field.
     *
     * The input component has no knowledge about icon fonts or icon css classes.
     * Icon font must be loaded by the application.
     *
     * Only one of "iconClass" and "iconUrl" can be specified
     */
    iconClass?: string;

    /**
     * Right align text in field.
     */
    alignRight?: boolean;

    /**
     * Make field smaller (height)
     */
    small?: boolean;

    /**
     * Make field larger (height)
     */
    large?: boolean;

    // validation

    /**
     * Readonly removes input visually but not the value
     */
    readonly?: boolean;

    /**
     * Validation: the validation message to show if field does not validate
     */
    validationMessage?: string;

    /**
     * Validation: the validation message to show if field does not validate when required and empty
     */
    validationMessageEmpty?: string;

    value?: string | number;

    /**
     * Validation: function called when validation is done.
     * property "name" must be specified when "onValidation" is used
     */
    onValidation?: (name: string, isValid: boolean) => void;

    /**
     * Validation: a validation function for special validation
     *
     * E.g. validateMobilePhone
     */
    validator?: (value: string | number) => boolean;

    /**
     * Validation: validate field on each key up event instead of on blur event
     */
    validateOnKeyUp?: boolean;

    /**
     * Validation: validate field on focus blur.
     *
     * Use validateOnBlur=false to postpone validation to forma validation
     */
    validateOnBlur?: boolean;

    /**
     * Validation: a value that field value must be equal to
     *
     * Example usage: verify two password fields
     */
    eq?: string | number;

    /**
     * Validation: a value that field value must be less than
     *
     * Example usage: first field for a date interval
     *
     * Note: string comparison is used unless type=number
     */
    lt?: string | number;

    /**
     * Validation: a value that field value must be less than
     *
     * Example usage: second field for a date interval
     *
     * Note: string comparison is used unless type=number
     */
    gt?: string | number;

    /**
     * Validation: a value that field value must be less or equal than
     *
     * Note: string comparison is used unless type=number
     */
    le?: string | number;

    /**
     * Validation: a value that field value must be greater or equal than
     *
     * Note: string comparison is used unless type=number
     */
    ge?: string | number;

    /**
     * Validation: a value that field value can not be equal to
     */
    ne?: string | number;

    /**
     * Validation: use in combination with eq, lt, gt, le, ge or ne
     *
     * Disables eq, lt, gt, le, ge or ne validation when field is empty
     */
    orNull?: boolean;

    /**
     * Function for formatting value presentation when field is not in focus
     * @param value
     */
    displayFormat?: (value: string | number) => string;

    /**
     * Field value in bold font
     */
    bold?: boolean;

    /**
     * Localization - a valid ISO language code, sv, en etc
     */
    lang?: string;

    /**
     * Input mode for field. Note: only supported by some browsers.
     * Example usage: 'numeric' for numeric keyboard on Android
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
     * @deprecated
     */
    inputmode?: InputHTMLAttributes<HTMLInputElement>['inputMode'];
}

const useStableCallback = <T extends (...args: any) => any>(
    cb: T | null | undefined
): null | undefined | ((...args: Parameters<T>) => ReturnType<T>) => {
    const ref = useRef<T>(cb);
    ref.current = cb;
    const stableCb = useCallback((...args) => {
        return ref.current(...args);
    }, []);

    return ref.current == null ? ref.current : stableCb;
};

export type InputRef = {
    doValidation: (dontValidateEmptyValue?: boolean) => boolean;
    getInputEl: () => HTMLInputElement;
    isDirty: () => boolean;
};

const Input = forwardRef<InputRef, InputProps>(
    (
        {
            onChange,
            ariaLabel,
            alignRight,
            autocomplete = 'on',
            autoComplete = autocomplete,
            bold,
            children,
            disabled,
            floatLabel,
            iconClass,
            iconUrl,

            inputmode,
            inputMode = inputmode,
            label,
            labelInside,
            lang = 'sv',
            large,
            max,
            maxLength,
            min,
            minLength,
            pattern,
            readonly,
            required,
            small,
            step,
            style,
            value = '',
            className: extraClasses,
            validationMessage,
            validationMessageEmpty,
            name,
            onValidation,
            validator,
            type = 'text',
            orNull,
            eq,
            lt,
            gt,
            le,
            ge,
            ne,
            onBlur,
            validateOnBlur = true,
            validateOnKeyUp,
            onFocus,
            onKeyUp,
            displayFormat,
            id: providedId,
            placeholder: providedPlaceholder,
            ...props
        },
        ref
    ) => {
        const stableOnValidation = useStableCallback(onValidation);
        const stabeValidator = useStableCallback(validator);
        const [defaultId] = useState(() => `ec-input-${Math.floor(Math.random() * 99999) + 999}`);
        const id = providedId || defaultId;
        const [originalValue] = useState(value);
        const [focused, setFocused] = useState(false);
        const displayValue = typeof displayFormat === 'function' ? (value !== '' ? displayFormat(value) : null) : null;
        const [hasErrors, setHasErrors] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);

        const shouldValidate = Boolean(
            eq ||
                ge ||
                gt ||
                le ||
                lt ||
                max ||
                maxLength ||
                min ||
                minLength ||
                pattern ||
                required ||
                validationMessage
        );

        const doValidation = useCallback(
            (dontValidateEmptyValue?: boolean) => {
                const el = inputRef.current;
                const value = el.value;
                if (dontValidateEmptyValue && !value) return true;

                const isEmail = type === 'email';

                const validatorTestOk = () => {
                    if (stabeValidator) {
                        return stabeValidator(value);
                    }
                    if (isEmail) {
                        return validateEmail(value);
                    }
                    return true;
                };

                const isValid =
                    el.checkValidity() &&
                    !hasComparisonErrors({ orNull, eq, lt, gt, le, ge, ne }, value, type) &&
                    validatorTestOk();

                setHasErrors(!isValid);
                if (stableOnValidation) {
                    stableOnValidation(name, isValid);
                }

                return isValid;
            },
            [name, type, stableOnValidation, orNull, eq, lt, gt, le, ge, ne, stabeValidator]
        );

        useEffect(() => {
            if (!focused && shouldValidate) {
                doValidation(true);
            }
        }, [doValidation, focused, shouldValidate]);

        useImperativeHandle(ref, () => {
            return {
                doValidation,
                getInputEl: () => {
                    return inputRef.current;
                },

                isDirty: () => {
                    return value !== originalValue;
                },
            };
        });

        let optionalIcon: ReactNode = null;
        let optionalValidationMessage: ReactNode = null;

        const placeholder = floatLabel ? undefined : providedPlaceholder;
        // state
        const displayValueVisible = displayValue ? !focused : false;

        if (iconUrl) {
            optionalIcon = (
                <img className={`text-input__icon ${disabled && 'text-input__disabled'}`} src={iconUrl} alt="" />
            );
        }
        if (iconClass) {
            optionalIcon = <i className={`text-input__icon ${iconClass} ${disabled && 'text-input__disabled'}`} />;
        }

        if (shouldValidate) {
            const showValidationMessageEmpty = validationMessageEmpty && required && !value;

            optionalValidationMessage = (
                <div className="text-input__err-msg">
                    {showValidationMessageEmpty ? validationMessageEmpty : validationMessage || 'Obligatoriskt fält'}
                </div>
            );
        }

        return (
            <div
                style={style}
                onClick={() => {
                    inputRef.current?.focus();
                }}
                className={classNames(
                    'ec-text-input',
                    {
                        'is-disabled': disabled,
                        small,
                        large,
                        'float-label': floatLabel,
                        'has-error': hasErrors,
                        'right-align': alignRight || labelInside,
                        'label-inside': labelInside,
                    },
                    extraClasses
                )}
            >
                {label && (
                    <label
                        className={classNames({
                            'is-disabled': disabled,
                            'float-label-in-corner': floatLabel && (focused || value),
                            'float-label-as-placeholder': floatLabel && !focused && !value,
                        })}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                )}
                <div className={type === 'password' ? 'e-pwd-field' : 'text-input__ctr'}>
                    <input
                        ref={inputRef}
                        id={id}
                        className={classNames({
                            'float-label-in-corner': floatLabel && (focused || value),
                            'bold-value': bold,
                        })}
                        aria-label={ariaLabel}
                        autoComplete={autoComplete === 'off' ? 'off' : 'on'}
                        disabled={disabled}
                        inputMode={inputMode}
                        lang={lang}
                        max={max}
                        maxLength={maxLength}
                        min={min}
                        minLength={minLength}
                        name={name}
                        onBlur={e => {
                            setFocused(false);
                            if (validateOnBlur && shouldValidate) {
                                doValidation();
                            }

                            if (onBlur) onBlur(e);
                        }}
                        onChange={e => {
                            const {
                                target: { value, type, maxLength },
                            } = e;
                            if (maxLength && maxLength > 0 && type === 'number' && value.length > maxLength) {
                                // You can't use maxlength with a number field, only maxvalue.
                                return false;
                            }
                            if (onChange) {
                                onChange(e);
                            }

                            return true;
                        }}
                        onFocus={e => {
                            setFocused(true);
                            if (onFocus) onFocus(e);
                        }}
                        onKeyUp={e => {
                            if (validateOnKeyUp && shouldValidate) {
                                doValidation();
                            }

                            if (onKeyUp) onKeyUp(e);
                        }}
                        pattern={pattern}
                        placeholder={placeholder}
                        readOnly={readonly}
                        required={required}
                        step={step}
                        type={type}
                        value={value}
                        {...props}
                    />
                    <div
                        className={classNames('display-value', {
                            visible: displayValueVisible,
                            'right-align': alignRight || labelInside,
                            'bold-value': bold,
                        })}
                    >
                        {displayValue}
                    </div>
                    {children}
                    {optionalIcon}
                    {hasErrors && optionalValidationMessage}
                </div>
            </div>
        );
    }
);

export default Input;
