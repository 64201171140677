/**
 * Check redux async action's standard state variables for action SUCCESS
 *
 * * Pending state moves from true to false
 * * Error state is undefined => action succeeded
 *
 * The props objects must map the Pending and Error state properties.
 *
 * E.g:
 *
 * if actionName === getAccounts
 *
 * then the props parameters must contain the getAccountsPending and getAccountsError state properties
 *
 * @param {string} actionName
 * @param {object} prevProps
 * @param {object} props
 * @returns {boolean} true if action has finished successfully
 */
export const reduxActionSucceeded = (actionName, prevProps, props) =>
    prevProps[`${actionName}Pending`] && !props[`${actionName}Pending`] && !props[`${actionName}Error`];

/**
 * Check redux async action's standard state variables for action FAILURE
 *
 * * Pending state moves from true to false
 * * Error state has a value => action failed
 *
 * The props objects must map the Pending and Error state properties.
 *
 * E.g:
 *
 * if actionName === getAccounts
 *
 * then the props parameters must contain the getAccountsPending and getAccountsError state properties
 *
 * @param {string} actionName
 * @param {object} prevProps
 * @param {object} props
 * @returns {boolean} true if action has finished with an error
 */
export const reduxActionFailed = (actionName, prevProps, props) =>
    prevProps[`${actionName}Pending`] &&
    !props[`${actionName}Pending`] &&
    props[`${actionName}Error`] !== null &&
    typeof props[`${actionName}Error`] === 'object';

/**
 * Check redux async action's standard state variables if action has finished with or without an error
 *
 * * Pending state moves from true to false
 * * Error state is not checked
 *
 * The props objects must map the Pending property.
 *
 * E.g:
 *
 * if actionName === getAccounts
 *
 * then the props parameters must contain the getAccountsPending state property
 *
 * @param {string} actionName
 * @param {object} prevProps
 * @param {object} props
 * @returns {boolean} true if action has finished successfully or with an error
 */
export const reduxActionFinished = (actionName, prevProps, props) =>
    prevProps[`${actionName}Pending`] && !props[`${actionName}Pending`];
