import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { reportError } from '../../../../common/reportError';

import { formatDate } from '@ecster/util';

import './InvoicePdfLink.scss';

const get = async url => {
    return await fetch(url, {
        headers: {
            accept: 'application/pdf, text/plain, */*',
            'accept-language': 'en-US,en;q=0.9',
            'x-ecster-origin': window.sessionStorage.getItem('origin'),
        },
        referrer: window.location.origin,
        referrerPolicy: 'strict-origin-when-cross-origin',
        body: null,
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    });
};

export const InvoicePdfLink = ({ invoice, isCredit }) => {
    const [objectUrl, setObjectUrl] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsError(false);
                const res = await get(invoice.url);
                if (!res.ok) {
                    throw Error('invoice pdf request failed');
                }
                const buf = await res.arrayBuffer();
                const blob = new Blob([buf], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                setObjectUrl(url);
            } catch (e) {
                reportError(JSON.stringify(e), 'InvoicePdfLinkError');
                setIsError(true);
            }
        };
        fetchData();
    }, [invoice.url]);

    useEffect(() => {
        return () => window.URL.revokeObjectURL(objectUrl);
    }, [objectUrl]);

    return (
        <div className="invoice-pdf-link flex flex-col">
            {objectUrl && (
                <Link
                    href={objectUrl}
                    target="_blank"
                    iconLeft="icon-download"
                    ariaLabel={i18n(`invoice.one-off-invoice.pdf-link.aria-label-${isCredit ? 'credit' : 'debit'}`, {
                        date: formatDate(invoice.date),
                    })}
                >
                    {i18n('invoice.one-off-invoice.pdf-link.get-pdf')}
                </Link>
            )}
            {isError && <span className="invoice-error">{i18n('invoice.one-off-invoice.pdf-link.error')}</span>}

            <span className="invoice-date mt-1x">{formatDate(invoice.date)}</span>
        </div>
    );
};

InvoicePdfLink.propTypes = {
    invoice: PropTypes.object.isRequired,
    isCredit: PropTypes.bool,
};
InvoicePdfLink.defaultProps = {
    isCredit: false,
};
