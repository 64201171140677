import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { i18n } from '@ecster/i18n';
import { Spinner, FadeIn, Message, GeneralErrorMessagePanel, UserMessagePanel } from '@ecster/components';
import { reduxActionSucceeded } from '@ecster/util';

import { happyFaceIcon, disappointedFaceIcon } from '@ecster/icons/H80/green';

import { withRouter } from 'react-router-dom';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import ActivateCardPanel from '../ActivateCardPanel';

import PendingCardInfoMessage from '../PendingCardInfoMessage';
import ShowCardPanel from '../ShowCardPanel';
import ShowExtraCardsPanel from '../ShowExtraCardsPanel';
import BlockCardPanel from '../BlockCardPanel';

import FaqPanel from '../FaqPanel';

import * as customerActions from '../../customer/redux/actions';
import * as accountActions from '../../account/redux/actions';

const initialState = {
    applicationSucceeded: false,
    activationSucceeded: false,
};

const Wrapper = ({ children, pageHeader }) => (
    <AuthenticatedSubPageTemplate className="card-manage-card-page-se" header={pageHeader}>
        {children}
    </AuthenticatedSubPageTemplate>
);

Wrapper.propTypes = {
    children: PropTypes.object.isRequired,
    pageHeader: PropTypes.string.isRequired,
};

export class ManageCardPageSe extends Component {
    state = {
        ...initialState,
        getAccountCardsSucceeded: false,
        getAccountSucceeded: false,
        getAccountTermsSucceeded: false,
    };

    componentDidMount() {
        const { customerId, accountRef, accountActions } = this.props;
        accountActions.getAccount(customerId, accountRef);
        accountActions.getAccountTerms(customerId, accountRef);
        accountActions.getAccountCards(customerId, accountRef);
    }

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('createAccountCard', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ applicationSucceeded: true });
        }
        if (reduxActionSucceeded('updateAccountCard', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ activationSucceeded: true });
        }
        if (reduxActionSucceeded('getAccountCards', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ getAccountCardsSucceeded: true });
        }
        if (reduxActionSucceeded('getAccount', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ getAccountSucceeded: true });
        }
        if (reduxActionSucceeded('getAccountTerms', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ getAccountTermsSucceeded: true });
        }
    }

    componentWillUnmount() {
        const { accountActions } = this.props;
        accountActions.clearAccountBrickIdState();
        accountActions.clearGetAccountCardsState();
        this.clearComponentState();
    }

    clearComponentState = () => {
        const { createAccountCardError, getAccountError, updateAccountCardError, accountActions } = this.props;

        this.setState({ ...initialState });

        if (createAccountCardError) accountActions.dismissCreateAccountCardError();
        if (updateAccountCardError) accountActions.dismissUpdateAccountCardError();
        if (getAccountError) accountActions.dismissGetAccountError();
    };

    createAccountCard = () => {
        const { customerId, accountRef, accountActions } = this.props;
        accountActions.createAccountCard(customerId, accountRef);
    };

    updateAccountCard = (customerId, card, cvc) => {
        const { accountRef, accountActions } = this.props;
        accountActions.updateAccountCard(customerId, accountRef, card, cvc);
    };

    render() {
        const {
            account,
            accountCard,
            extraCards,
            customerId,
            updateAccountCardPending,
            createAccountCardError,
            updateAccountCardError,
            getAccountError,
            accountActions,
            customerActions,
        } = this.props;

        const { getAccountCardsSucceeded, getAccountSucceeded, getAccountTermsSucceeded } = this.state;

        if (getAccountError) {
            return (
                <Wrapper pageHeader={i18n(`card.manage-card.page-header-no-card'}`)}>
                    <GeneralErrorMessagePanel
                        header={i18n('general.error.general.header')}
                        body={i18n('general.error.general.body')}
                        error={getAccountError}
                        dismissError={accountActions.dismissGetAccountError}
                        buttonText={i18n('general.back')}
                        isLink
                    />
                </Wrapper>
            );
        }

        const hasMainCard = !!accountCard && accountCard.status !== 'BLOCKED';

        // actual number of cards returned from backend
        const noOfExtraCards = (extraCards && extraCards.filter(card => card.status !== 'BLOCKED').length) || 0;

        const mainCardIsOrdered = hasMainCard && accountCard.status === 'ORDERED';
        const mainCardIsInactive = hasMainCard && accountCard.status === 'INACTIVE';
        const mainCardIsBlocked = !!accountCard && accountCard.status === 'BLOCKED';
        const mainCardIsInactiveNotActivatable = !!accountCard && accountCard.status === 'INACTIVE_NOT_ACTIVATABLE';
        const mainCardIsTemporarilyBlocked = !!accountCard && accountCard.status === 'TEMPORARILY_BLOCKED';

        const hasCard = !!accountCard;

        const dataReceived =
            getAccountSucceeded && getAccountTermsSucceeded && (getAccountCardsSucceeded || (account && hasCard));

        const { applicationSucceeded, activationSucceeded } = this.state;

        let pageHeader = i18n(`card.manage-card.page-header${!hasCard ? '-no-card' : ''}`);
        if (!hasCard && !account.allowIncreaseLimit) {
            pageHeader = i18n(`card.manage-card.page-header`);
        }

        const isWrongCvc = updateAccountCardError?.code === 4188;

        return (
            <Wrapper pageHeader={pageHeader}>
                {dataReceived ? (
                    <FadeIn show={dataReceived}>
                        <div className="card-manage-card-page-se-container">
                            {((!applicationSucceeded &&
                                !createAccountCardError &&
                                !activationSucceeded &&
                                !updateAccountCardError) ||
                                isWrongCvc) && (
                                <>
                                    {mainCardIsInactiveNotActivatable && (
                                        <Message
                                            red
                                            exclamation
                                            header={i18n('card.inactive-not-activatable.header')}
                                            message={i18n('card.inactive-not-activatable.info')}
                                        />
                                    )}
                                    {mainCardIsTemporarilyBlocked && (
                                        <Message
                                            red
                                            exclamation
                                            header={i18n('card.temporarily-blocked.header')}
                                            message={i18n('card.temporarily-blocked.info')}
                                        />
                                    )}

                                    {mainCardIsOrdered && <PendingCardInfoMessage />}

                                    {!mainCardIsOrdered && (hasMainCard || mainCardIsBlocked) && (
                                        <ShowCardPanel account={account} accountCard={accountCard} />
                                    )}

                                    {hasMainCard && mainCardIsInactive && (
                                        <ActivateCardPanel
                                            card={accountCard}
                                            updateAccountCard={this.updateAccountCard}
                                            updateAccountCardPending={updateAccountCardPending}
                                            account={account}
                                            isWrongCvc={isWrongCvc}
                                            customerId={customerId}
                                            updateCustomerExtraCardHolderContactInfo={
                                                customerActions.updateCustomerExtraCardHolderContactInfo
                                            }
                                        />
                                    )}

                                    {noOfExtraCards > 0 && (
                                        <ShowExtraCardsPanel
                                            cards={extraCards}
                                            updateAccountCard={this.updateAccountCard}
                                            updateCustomerExtraCardHolderContactInfo={
                                                customerActions.updateCustomerExtraCardHolderContactInfo
                                            }
                                            updateAccountCardPending={updateAccountCardPending}
                                            isWrongCvc={isWrongCvc}
                                            customerId={customerId}
                                            mainCardIsTemporarilyBlocked={mainCardIsTemporarilyBlocked}
                                        />
                                    )}
                                </>
                            )}

                            {applicationSucceeded && (
                                <UserMessagePanel
                                    icon={happyFaceIcon}
                                    header={i18n('card.apply-for-card.success.header')}
                                    text={i18n('card.apply-for-card.success.info')}
                                />
                            )}
                            {createAccountCardError && (
                                <UserMessagePanel
                                    icon={disappointedFaceIcon}
                                    header={i18n('card.apply-for-card.failure.header')}
                                    text={i18n('card.apply-for-card.failure.info')}
                                />
                            )}
                            {activationSucceeded && (
                                <UserMessagePanel
                                    icon={happyFaceIcon}
                                    header={i18n('card.activate-card.success.header')}
                                    text={i18n('card.activate-card.success.info')}
                                />
                            )}
                            {updateAccountCardError && !isWrongCvc && (
                                <GeneralErrorMessagePanel
                                    header={i18n('general.error.general.header')}
                                    body={i18n('general.error.general.body')}
                                    buttonText={i18n('general.back')}
                                    isLink
                                />
                            )}
                            <FaqPanel />
                            {!mainCardIsTemporarilyBlocked && <BlockCardPanel />}
                        </div>
                    </FadeIn>
                ) : (
                    <Spinner id="manage-cards-spinner" isCenterPage isVisible />
                )}
            </Wrapper>
        );
    }
}

ManageCardPageSe.propTypes = {
    accountRef: PropTypes.string.isRequired,
    account: PropTypes.object.isRequired,
    accountCard: PropTypes.object,
    accountTerms: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    extraCards: PropTypes.arrayOf(PropTypes.object),

    // all actions
    accountActions: PropTypes.object.isRequired,
    customerActions: PropTypes.object.isRequired,

    // props used in operationSucceeded
    updateAccountCardPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    updateAccountCardError: PropTypes.object,

    // props used in operationSucceeded
    createAccountCardPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    createAccountCardError: PropTypes.object,

    // props used in operationSucceeded
    getAccountCardsPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getAccountCardsError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    // props used in operationSucceeded
    getAccountPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getAccountError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    // props used in operationSucceeded
    getAccountTermsPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getAccountTermsError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

ManageCardPageSe.defaultProps = {
    accountCard: null,
    extraCards: null,

    updateAccountCardError: null,
    createAccountCardError: null,
    getAccountCardsError: null,
    getAccountError: null,
    getAccountTermsError: null,
};

/* istanbul ignore next */
function mapStateToProps({ account, authentication }, { match }) {
    const { accountRef } = match.params;
    return {
        accountRef,
        account: account.account,
        dismissGetAccountError: account.dismissGetAccountError,
        accountTerms: account.accountTerms,
        accountCard:
            account.account && account.accountCards ? account.accountCards[account.account.reference] : undefined,
        extraCards: account.account && account.extraCards ? account.extraCards[account.account.reference] : undefined,
        customerId: authentication.person.id,

        getAccountPending: account.getAccountPending,
        getAccountError: account.getAccountError,

        getAccountTermsPending: account.getAccountTermsPending,
        getAccountTermsError: account.getAccountTermsError,

        getAccountCardsPending: account.getAccountCardsPending,
        getAccountCardsError: account.getAccountCardsError,

        updateCustomerExtraCardHolderContactInfoPending: account.updateCustomerExtraCardHolderContactInfoPending,
        updateAccountCardPending: account.updateAccountCardPending,
        updateAccountCardError: account.updateAccountCardError,
        createAccountCardPending: account.createAccountCardPending,
        createAccountCardError: account.createAccountCardError,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        accountActions: bindActionCreators({ ...accountActions }, dispatch),
        customerActions: bindActionCreators({ ...customerActions }, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageCardPageSe));
