import React from 'react';
import { Message } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import './UpdatedLoginMessage.scss';

export const UpdatedLoginMessage = () => {
    return (
        <div className="updated-login-message">
            <Message icon={false} green header={i18n('authentication.nets.se.updated-login.header')}>
                <p>{i18n('authentication.nets.se.updated-login.text')}</p>
            </Message>
        </div>
    );
};