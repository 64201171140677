import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { Panel, PanelContent } from '@ecster/components';

import { withRouter } from 'react-router-dom';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import { closeAccount, getAccount } from '../redux/actions';
import { TerminateAccountSuccessMessage, TerminateAccountFailureMessage } from './TerminateAccountSuccessPanel';
import { FeedbackForm } from './FeedbackForm';
import { isSE } from '../../../common/country';
import { PaymentDialog } from '../PaymentDialog';
import { isValidSurplusAmount } from '../../../common/util/is-valid-surplus-amount';

export class TerminateAccountPage extends Component {
    state = {
        closeAccountSuccess: false,
        closeAccountFailure: false,
        isPaymentDialogOpen: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        // close account succeeded when pending === true => pending === false

        const {
            account: { used },
        } = this.props;

        // TODO: use operationSucceeded / operationFailed
        if (this.props.closeAccountPending && nextProps.closeAccountPending === false) {
            if (!nextProps.closeAccountError) {
                const { getAccount, accountRef, customerId } = this.props;

                // delete succeeded
                this.setState({ closeAccountSuccess: true });
                if (isSE() && !isValidSurplusAmount(used)) this.setState({ isPaymentDialogOpen: true });
                // refresh account
                getAccount(customerId, accountRef);
            } else {
                // delete failed
                this.setState({ closeAccountFailure: true });
            }
        }
    }

    oncloseAccount = () => {
        const { closeAccount, customerId, accountRef } = this.props;
        closeAccount(customerId, accountRef);
    };

    onPaymentDialogRequestClose = () => {
        this.setState({ isPaymentDialogOpen: false });
    };

    render() {
        const {
            closeAccountPending,
            account,
            account: { status },
            accountRef,
        } = this.props;

        const backToTermsUrl = `/account/${accountRef}/terms`;
        const backToOverviewUrl = '/account/overview';

        const { closeAccountSuccess, closeAccountFailure, isPaymentDialogOpen } = this.state;

        const isAccountTerminated = status === 'TERMINATED';

        const isSuccess = closeAccountSuccess || isAccountTerminated;

        return (
            <AuthenticatedSubPageTemplate
                linkTo={closeAccountSuccess ? backToOverviewUrl : backToTermsUrl}
                header={i18n('account.terminate.terminate-account')}
                className="account-terminate-account-page"
            >
                <Panel stretchInMobile>
                    <PanelContent wide>
                        {isSuccess ? (
                            <TerminateAccountSuccessMessage backUrl={backToOverviewUrl} />
                        ) : closeAccountFailure ? (
                            <TerminateAccountFailureMessage backUrl={backToOverviewUrl} />
                        ) : (
                            <FeedbackForm
                                closeAccountPending={closeAccountPending}
                                backUrl={backToTermsUrl}
                                onCloseAccount={this.oncloseAccount}
                            />
                        )}
                    </PanelContent>
                </Panel>
                {isSE() && (
                    <PaymentDialog
                        account={account}
                        isOpen={isPaymentDialogOpen}
                        onRequestClose={this.onPaymentDialogRequestClose}
                    />
                )}
            </AuthenticatedSubPageTemplate>
        );
    }
}

TerminateAccountPage.propTypes = {
    accountRef: PropTypes.string.isRequired,
    customerId: PropTypes.number.isRequired,
    closeAccount: PropTypes.func.isRequired,
    closeAccountPending: PropTypes.bool,
    closeAccountError: PropTypes.object,
    getAccount: PropTypes.func.isRequired,
};

TerminateAccountPage.defaultProps = {
    closeAccountPending: false,
    closeAccountError: null,
};

/* istanbul ignore next */
function mapStateToProps({ account, authentication }, { match }) {
    const { accountRef } = match.params;
    return {
        accountRef,
        customerId: authentication.person.id,
        closeAccountPending: account.closeAccountPending,
        closeAccountError: account.closeAccountError,
        account: account.account,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        closeAccount: (customerId, accountRef) => dispatch(closeAccount(customerId, accountRef)),
        getAccount: (customerId, accountRef) => dispatch(getAccount(customerId, accountRef)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TerminateAccountPage));
