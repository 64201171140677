// Suomalainen mobile phone number

export const validateMobilePhoneNumberFI = input => {
    if (!input) {
        return true;
    }

    const trimmedValue = input.replace(/[ ()+-]/g, '');

    // must start with 04x, 0457 or 050 (leading '0' is optional)
    return /^0?4[0-9]{8}$|^0?457[0-9]{7}$|^0?50[0-9]{7}$/.test(trimmedValue);
};

export default validateMobilePhoneNumberFI;