import React from 'react';
import PropTypes from 'prop-types';

import {
    moneyBagIcon,
    camperIcon,
    carIcon,
    caravanIcon,
    motorbikeIcon,
    powerboatIcon,
    quadIcon,
    sailboatIcon,
    snowmobileIcon,
    waterjetIcon,
} from '@ecster/icons/H80/green';

export class ContractIcon extends React.Component {
    static propTypes = {
        contract: PropTypes.object,
    };

    static defaultProps = {
        contract: null,
    };

    state = {};

    render() {
        const { contract } = this.props;
        if (!contract) return null;

        let contractIcon;

        switch (contract.productCode) {
            case 'INSTALLMENT_CARAVAN':
                contractIcon = caravanIcon;
                break;
            case 'INSTALLMENT_MC':
                contractIcon = motorbikeIcon;
                break;
            case 'INSTALLMENT_SAILBOAT':
                contractIcon = sailboatIcon;
                break;
            case 'INSTALLMENT_BOAT':
                contractIcon = powerboatIcon;
                break;
            case 'INSTALLMENT_SNOWMOBILE':
                contractIcon = snowmobileIcon;
                break;
            case 'INSTALLMENT_CAR':
                contractIcon = carIcon;
                break;
            case 'INSTALLMENT_ATV':
                contractIcon = quadIcon;
                break;
            case 'INSTALLMENT_RV':
                contractIcon = camperIcon;
                break;
            case 'INSTALLMENT_WATERJET':
                contractIcon = waterjetIcon;
                break;
            default:
                contractIcon = moneyBagIcon;
                break;
        }

        return contractIcon && <img src={contractIcon} alt="" />;
    }
}
