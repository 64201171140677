/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import './UnorderedList.scss';

interface ListItemProps {
    icon: string;
    iconClass: string;
    itemClass: string;
    item: ReactNode;
}

const ListItem = ({ icon, iconClass, itemClass, item }: ListItemProps) => (
    <li>
        {icon && <i className={`icon ${icon} ${iconClass}`} />}
        <span className={`list-value ${itemClass}`}>{item}</span>
    </li>
);

ListItem.propTypes = {
    icon: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired,
    itemClass: PropTypes.string.isRequired,
    item: PropTypes.node.isRequired,
};

interface UnorderedListProps {
    children: ReactNode;
    icon?: string;
    iconClass?: string;
    itemClass?: string;
    className?: string;
}

const UnorderedList = ({ children, icon, iconClass, itemClass, className }: UnorderedListProps) => {
    const classes = `e-unordered-list e-list ${className} ${icon ? 'has-icon' : 'no-icon'}`;

    if (!children) return null;

    return (
        <ul className={classes}>
            {Array.isArray(children) ? (
                children.map((item, index) => (
                    <ListItem
                        icon={icon}
                        iconClass={iconClass}
                        itemClass={itemClass}
                        item={item}
                        key={index}
                    />
                ))
            ) : (
                <ListItem icon={icon} iconClass={iconClass} itemClass={itemClass} item={children} />
            )}
        </ul>
    );
};

export default UnorderedList;

UnorderedList.propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.string, // icon font name: e.g icon-check
    iconClass: PropTypes.string,
    itemClass: PropTypes.string,
    className: PropTypes.string,
};

UnorderedList.defaultProps = {
    icon: undefined,
    iconClass: 'e-purple',
    itemClass: '',
    className: '',
};
