import { LogoutPage, LoginHelpPage, NotACustomerInfoPage } from '.';

const routes = {
    path: 'authentication',
    name: 'Authentication',

    childRoutes: [
        { path: 'logout/:isCustomer?', name: 'Logout page', component: LogoutPage, isPublic: true },
        { path: 'help', name: 'Login help page', component: LoginHelpPage, isPublic: true },
        { path: 'no-account', name: 'Not a customer info page', component: NotACustomerInfoPage, isPublic: true },
    ],
};

export default routes;
