import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Ecster imports
import { i18n } from '@ecster/i18n';

import {
    Panel,
    ResponsivePanel,
    PanelContent,
    LabelValue,
    ResponsiveTableBody as TableBody,
    ResponsiveTableHeader as TableHeader,
    ResponsiveTableHeaderColumn as TableHeaderColumn,
    ResponsiveTable as Table,
    ResponsiveTableRow as TableRow,
    ResponsiveTableRowColumn as TableRowColumn,
    Spinner,
    FadeIn,
} from '@ecster/components';

import { md5, reduxActionSucceeded } from '@ecster/util';

import { withRouter, Redirect } from 'react-router-dom';
import CreditInvoiceSummaryPanel from '../one-off-invoice/CreditInvoiceSummaryPanel';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import { getInvoice } from '../redux/actions';
import { formatAmount } from '../../../common/util/format-amount';

export class OneOffInvoicePageV1 extends React.Component {
    static propTypes = {
        invoice: PropTypes.object,
        customerId: PropTypes.string.isRequired,
        getInvoice: PropTypes.func.isRequired,
        getInvoicePending: PropTypes.bool.isRequired, // eslint-disable-line
        getInvoiceError: PropTypes.object, // eslint-disable-line
        // router: PropTypes.shape({
        //     params: PropTypes.shape({
        //         invoiceId: PropTypes.string.isRequired,
        //     }).isRequired,
        // }).isRequired,
        invoiceId: PropTypes.string.isRequired,
    };

    static defaultProps = {
        invoice: undefined,
        getInvoiceError: null,
    };

    state = {
        dataAvailable: false,
    };

    componentDidMount() {
        const {
            customerId,
            getInvoice,
            invoiceId,
            // router: {
            //     params: { invoiceId },
            // },
        } = this.props;
        getInvoice(customerId, invoiceId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ dataAvailable: reduxActionSucceeded('getInvoice', this.props, nextProps) });
    }

    render() {
        const { dataAvailable } = this.state;

        const { invoice } = this.props;
        const amountOptions = { strip00: false };

        const isCredit = invoice && invoice.type === 'CREDIT';

        const sign = isCredit ? -1 : 1;

        // switch to new GUI if data looks like BSF data
        if (dataAvailable && invoice?.debitInvoice) {
            return <Redirect to={`/customer/v2/invoice/${invoice.invoiceId}`} />;
        }

        const vat6 = invoice && invoice.invoiceSums && invoice.invoiceSums['vat6%'];
        const vat12 = invoice && invoice.invoiceSums && invoice.invoiceSums['vat12%'];
        const vat25 = invoice && invoice.invoiceSums && invoice.invoiceSums['vat25%'];

        return (
            <AuthenticatedSubPageTemplate
                className="invoice-one-off-invoice-page"
                header={i18n(`invoice.one-off-invoice.${invoice && invoice.type.toLowerCase()}.page-header`)}
            >
                {dataAvailable ? (
                    <FadeIn show={dataAvailable}>
                        <Panel paddingTopBottom="xLarge" greenBG sideMarginsInMobile>
                            <PanelContent wide>
                                <ResponsivePanel className="center-text e-green140">
                                    <div>
                                        <h2 className="h1">{invoice.storeName}</h2>
                                    </div>
                                    <div className="large-text">{formatAmount(invoice.invoiceSums.inclVat)}</div>
                                </ResponsivePanel>
                            </PanelContent>
                        </Panel>

                        {isCredit && <CreditInvoiceSummaryPanel invoice={invoice} />}

                        <Panel paddingAll="small" centeredContent stretchInMobile>
                            <h2>{i18n(`invoice.one-off-invoice.transactions-header.${invoice.type}`)}</h2>

                            <PanelContent fullWidth>
                                <Table large>
                                    <TableHeader notOnMobile>
                                        <TableRow>
                                            <TableHeaderColumn>
                                                {i18n('invoice.one-off-invoice.table.description')}
                                            </TableHeaderColumn>
                                            <TableHeaderColumn alignRight>
                                                {i18n('invoice.one-off-invoice.table.number')}
                                            </TableHeaderColumn>
                                            <TableHeaderColumn alignRight>
                                                {i18n('invoice.one-off-invoice.table.price')}
                                            </TableHeaderColumn>
                                            <TableHeaderColumn alignRight>
                                                {i18n('invoice.one-off-invoice.table.amount')}
                                            </TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableHeader onlyOnMobile>
                                        <TableRow>
                                            <TableHeaderColumn>
                                                {i18n('invoice.one-off-invoice.table.description')}
                                            </TableHeaderColumn>
                                            <TableHeaderColumn alignRight>
                                                {i18n('invoice.one-off-invoice.table.amount')}
                                            </TableHeaderColumn>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {invoice.invoiceRows.map((tx, index) => (
                                            <TableRow id={`item-${index + 1}`} key={md5(JSON.stringify(tx))}>
                                                <TableRowColumn>{tx.description}</TableRowColumn>
                                                <TableRowColumn alignRight notOnMobile>
                                                    {tx.quantity}
                                                </TableRowColumn>
                                                <TableRowColumn alignRight notOnMobile>
                                                    {formatAmount(sign * tx.unitPrice, amountOptions)}
                                                </TableRowColumn>
                                                <TableRowColumn alignRight>
                                                    {formatAmount(sign * tx.unitPrice * tx.quantity, amountOptions)}
                                                </TableRowColumn>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </PanelContent>

                            <div className="summary-box">
                                <div className="summary">
                                    {invoice.feeRows &&
                                        invoice.feeRows.map(fee => (
                                            <LabelValue
                                                id={`fee-${fee.feeType.toLowerCase()}`}
                                                key={fee.feeType}
                                                label={i18n(`invoice.one-off-invoice.summary.${fee.feeType}`)}
                                                value={
                                                    (invoice.type === 'CREDIT' ? '-' : '') + formatAmount(fee.amount)
                                                }
                                            />
                                        ))}
                                    <LabelValue
                                        id="sum-excl-tax"
                                        label={i18n('invoice.one-off-invoice.summary.sum-excl-tax')}
                                        value={formatAmount(invoice.invoiceSums.exclVat || 1)}
                                    />
                                    {vat6 && vat6 !== 0 ? (
                                        <LabelValue
                                            id="vat-6"
                                            label={i18n('invoice.one-off-invoice.summary.tax', { tax: 6 })}
                                            value={formatAmount(invoice.invoiceSums['vat6%'])}
                                        />
                                    ) : null}
                                    {vat12 && vat12 !== 0 ? (
                                        <LabelValue
                                            id="vat-12"
                                            label={i18n('invoice.one-off-invoice.summary.tax', { tax: 12 })}
                                            value={formatAmount(invoice.invoiceSums['vat12%'])}
                                        />
                                    ) : null}
                                    {vat25 && vat25 !== 0 ? (
                                        <LabelValue
                                            id="vat-25"
                                            label={i18n('invoice.one-off-invoice.summary.tax', { tax: 25 })}
                                            value={formatAmount(invoice.invoiceSums['vat25%'])}
                                        />
                                    ) : null}
                                    <LabelValue
                                        id="sum-incl-tax"
                                        label={i18n('invoice.one-off-invoice.summary.sum-incl-tax')}
                                        value={formatAmount(invoice.invoiceSums.inclVat)}
                                    />
                                </div>
                            </div>
                        </Panel>
                    </FadeIn>
                ) : (
                    <Spinner id="waiting-for-invoice-spinner" isCenterPage isVisible />
                )}
            </AuthenticatedSubPageTemplate>
        );
    }
}

/* istanbul ignore next */
function mapStateToProps({ invoice, authentication }, { match }) {
    const { invoiceId } = match.params;
    return {
        invoiceId,
        invoice: invoice.invoice,
        customerId: authentication.person.id,
        getInvoicePending: invoice.getInvoicePending,
        getInvoiceError: invoice.getInvoiceError,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getInvoice: (customerId, invoiceId) => {
            dispatch(getInvoice(customerId, invoiceId));
        },
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OneOffInvoicePageV1));
