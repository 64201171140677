// NOTE: don't rename initialState constant, needed when rekit adds actions.
const initialState = {
    contracts: [],
    currentContractInvoice: null,
    currentContractTerms: null,
    getContractsPending: false,
    getContractsError: null,
    getContractInvoicePending: false,
    getContractInvoiceError: null,
    getContractTermsPending: false,
    getContractTermsError: null,
    createRepaymentInquiryPending: false,
    createRepaymentInquiryError: null,
    getRepaymentInquiryPending: false,
    getRepaymentInquiryError: null,
    sendMailPending: false,
    sendMailError: null,
};

export default initialState;
