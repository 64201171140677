import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Message } from '@ecster/components';

export const RemindedMessage = ({ date }) => (
    <div className="invoice-reminded-message" data-testid="reminded-message">
        <Message
            purple
            iconInMobile={false}
            header={i18n('invoice.one-off-invoice.reminded-message.header')}
            message={i18n('invoice.one-off-invoice.reminded-message.text', { date })}
        />
    </div>
);

RemindedMessage.propTypes = {
    // formatted date
    date: PropTypes.string.isRequired,
};

RemindedMessage.defaultProps = {};

export default RemindedMessage;
