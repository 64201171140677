/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './DialogBody.scss';

function preventDefault(e) {
    if (e.preventDefault) {
        e.preventDefault();
    }
    e.returnValue = false;
    return false;
}

interface DialogBodyProps {
    children?: ReactNode;
    centeredContent?: boolean;
}

export default class DialogBody extends React.Component<DialogBodyProps> {
    scrollElementRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.scrollElementRef.current.addEventListener('scroll', this.scrollHandler);
        this.scrollElementRef.current.addEventListener('mousewheel', this.preventScrollPropagation);
        this.scrollHandler(); // Run once on mount
    }

    componentDidUpdate() {
        this.scrollHandler(); // And for every update to the component
    }

    preventScrollPropagation = e => {
        this.scrollElementRef.current.scrollTop -= e.wheelDeltaY;
        return preventDefault(e);
    };

    scrollHandler = () => {
        const { scrollTop, offsetHeight, scrollHeight } = this.scrollElementRef.current;

        // 5px from bottom is considered bottom
        const hasScroll = scrollHeight - offsetHeight > 5;
        const scrolledToBottom = scrollTop + offsetHeight + 5 > scrollHeight;

        if (!hasScroll) return;

        if (hasScroll) {
            // if no scroll don't draw the fade element, it would be invisible but cover bottom of content
            this.scrollElementRef.current.classList.add('has-scroll');
        }

        if (scrolledToBottom) {
            this.scrollElementRef.current.classList.remove('text-fade');
            // at bottom, as if we have no scroll => don't draw the invisible fade element
            this.scrollElementRef.current.classList.remove('has-scroll');
        } else {
            this.scrollElementRef.current.classList.add('text-fade');
        }
    };

    render() {
        const { centeredContent, children } = this.props;
        const contentClasses = classNames({
            'ec-dialog-content': true,
            'centered-content': centeredContent,
        });

        return (
            <div className="ec-dialog-scroll" ref={this.scrollElementRef}>
                <div className="ec-dialog-body">
                    <div className={contentClasses}>{children}</div>
                </div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
DialogBody.defaultProps = {
    children: <div />,
    centeredContent: false,
};
