import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, Tabs, Tab } from '@ecster/components';
import { formatAmount, formatDate, formatDateLongWithYear } from '../../../../common/util/format';

import './PaymentAlternatives.scss';

export const PaymentAlternatives = ({ bill, billProps }) => {
    // cases as described in HXDN-17420

    const partPaymentsOnlyDiffL1L2 =
        billProps.minimumPayment !== billProps.partPayment && bill.hasPartPayments && !bill.hasAccountTransactions;

    const partPaymentsOnlySameL1lL2 =
        billProps.minimumPayment === billProps.partPayment && bill.hasPartPayments && !bill.hasAccountTransactions;

    // always true for B1: billProps.minimumPayment === billProps.partPayment
    const accountTransactionsOnly = !bill.hasPartPayments && bill.hasAccountTransactions;

    const bothTypes = bill.hasPartPayments && bill.hasAccountTransactions;

    const showAlt2 = (partPaymentsOnlyDiffL1L2 || bothTypes) && billProps.minimumPayment !== billProps.partPayment;

    const showAlt3 = billProps.fullPayment > 0;

    if (!showAlt2 && !showAlt3) return null;

    // TODO: remove span "debug" tag below
    return (
        <div className="account-invoice-payment-alternatives">
            <Panel stretchInMobile>
                <PanelContent className="mb-8x">
                    <h2 className="h3 centered">{i18n('invoice.account-invoices.payment-alternatives.header')}</h2>
                    <p>{i18n('invoice.account-invoices.payment-alternatives.info')}</p>
                    <span style={{ display: 'none' }}>
                        {partPaymentsOnlyDiffL1L2 && 'A1'}
                        {partPaymentsOnlySameL1lL2 && 'A2'}
                        {accountTransactionsOnly && 'B1'}
                        {bothTypes && 'C1'}
                        {!partPaymentsOnlyDiffL1L2 &&
                            !partPaymentsOnlySameL1lL2 &&
                            !accountTransactionsOnly &&
                            !bothTypes &&
                            'NONE!'}
                    </span>
                </PanelContent>

                <PanelContent>
                    <Tabs>
                        <Tab
                            id="tab-minimum-payment"
                            label={formatAmount(billProps.minimumPayment, { currencyDisplay: 'symbol' })}
                        >
                            <h3 className="h5">{i18n('invoice.account-invoices.payment-alternatives.alt1.header')}</h3>
                            <p>{i18n('invoice.account-invoices.payment-alternatives.alt1.info')}</p>
                        </Tab>
                        {showAlt2 && (
                            <Tab
                                id="tab-part-payment"
                                label={formatAmount(billProps.partPayment, { currencyDisplay: 'symbol' })}
                            >
                                <h3 className="h5">
                                    {i18n('invoice.account-invoices.payment-alternatives.alt2.header')}
                                </h3>
                                <p>{i18n('invoice.account-invoices.payment-alternatives.alt2.info')}</p>
                            </Tab>
                        )}
                        {showAlt3 && (
                            <Tab
                                id="tab-full-payment"
                                label={formatAmount(billProps.fullPayment, { currencyDisplay: 'symbol' })}
                            >
                                <h3 className="h5">
                                    {i18n('invoice.account-invoices.payment-alternatives.alt3.header', {
                                        invoiceDate: bill && formatDateLongWithYear(bill.invoiceDate),
                                    })}
                                </h3>
                                {i18n('invoice.account-invoices.payment-alternatives.alt3.info', 'p', {
                                    invoiceDate: bill && formatDate(bill.invoiceDate),
                                })}
                            </Tab>
                        )}
                    </Tabs>
                </PanelContent>
            </Panel>
        </div>
    );
};

PaymentAlternatives.propTypes = {
    bill: PropTypes.object.isRequired,
    billProps: PropTypes.object.isRequired,
};
