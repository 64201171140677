import { COMMON_SET_PREVIOUS_PATH, COMMON_CLEAR_SET_PREVIOUS_PATH_STATE } from './constants';

export const setPreviousPath = prevPath => ({
    type: COMMON_SET_PREVIOUS_PATH,
    payload: prevPath,
});

export const clearSetPreviousPathState = () => ({
    type: COMMON_CLEAR_SET_PREVIOUS_PATH_STATE,
});

// don't ES6 this one, rekit gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case COMMON_SET_PREVIOUS_PATH:
            return {
                ...state,
                previousPath: action.payload,
            };

        case COMMON_CLEAR_SET_PREVIOUS_PATH_STATE:
            return {
                ...state,
                previousPath: null,
            };

        default:
            return state;
    }
}
