import React from 'react';
import PropTypes from 'prop-types';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { Button, ButtonGroup, Panel, PanelContent, Spinner } from '@ecster/components';
import { profileIcon } from '@ecster/icons/H40/green';

import { reduxActionSucceeded } from '@ecster/util';
import EditContactInfo from './EditContactInfo';
import ShowContactInfo from './ShowContactInfo';
import { isFI } from '../../../common/country';
import CustomerSettingsPanel from './FI/CustomerSettingsPanel';
import { validateMobilePhoneNumberIncludingOldFormatsFI } from '@ecster/validation';
// import phoneValidatorSE from '@ecster/components/lib/Input/validators/mobilePhoneNumberSE';
// import emailValidator from '@ecster/components/lib/Input/validators/email';
import { validateMobilePhoneNumberSE as phoneValidatorSE, validateEmail as emailValidator } from '@ecster/validation';

export default class ContactInfoPanel extends React.Component {
    static propTypes = {
        person: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        hasAccounts: PropTypes.bool.isRequired,
        updateCustomerContactInfo: PropTypes.func.isRequired,
        updateCustomerContactInfoPending: PropTypes.bool.isRequired,
        updateCustomerContactInfoError: PropTypes.object,
        getCustomer: PropTypes.func.isRequired,
        getCustomerPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
        getCustomerError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    };

    static defaultProps = { updateCustomerContactInfoError: null, getCustomerError: null };

    state = {
        editMode: false,
        refreshingData: false,
    };

    componentDidMount() {
        const {
            customer: { contactInformation: contact },
        } = this.props;

        if (isFI()) {
            const {
                customer: {
                    contactInformation: {
                        marketing: { email: hasEmail, sms: hasSMS },
                    },
                },
            } = this.props;
            this.setState({
                hasEmail,
                hasSMS,
            });
        }

        const validateMobile =
            window.EcsterConfig.applicationCountry === 'FI'
                ? validateMobilePhoneNumberIncludingOldFormatsFI
                : phoneValidatorSE;

        const phoneIsValid =
            contact.phoneNumber && contact.phoneNumber.countryCallingCode && validateMobile(contact.phoneNumber.number);

        const emailIsValid = contact.email && emailValidator(contact.email);

        if (!phoneIsValid || !emailIsValid) {
            this.enterEditMode();
        }
    }

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('getCustomer', prevProps, this.props)) {
            if (isFI()) {
                const {
                    customer: {
                        contactInformation: {
                            marketing: { email: hasEmail, sms: hasSMS },
                        },
                    },
                } = this.props;
                this.setState({ hasEmail, hasSMS });
            }
            this.setState({ refreshingData: false });
        }
    }

    onButtonClick = () => {
        this.setState({ editMode: true });
    };

    enterEditMode = () => {
        this.setState({ editMode: true });
    };

    leaveEditMode = refreshData => {
        this.setState({ editMode: false, refreshingData: !!refreshData });
        const { getCustomer, customer, hasAccounts } = this.props;
        if (refreshData) {
            getCustomer(customer.id, hasAccounts);
        }
    };

    render() {
        const {
            customer,
            hasAccounts,
            person,
            person: { firstName, lastName, name },
            updateCustomerContactInfo,
            updateCustomerContactInfoError,
            updateCustomerContactInfoPending,
        } = this.props;
        const { editMode, refreshingData, hasEmail, hasSMS } = this.state;

        return (
            <Panel className="customer-contact-info-panel">
                <PanelContent>
                    <img src={profileIcon} aria-hidden="true" alt="" />
                    <h2 className="h3 centered">{firstName && lastName ? `${firstName} ${lastName}` : name}</h2>
                    <p className="mb-6x">{i18n('customer.profile.show-profile.info')}</p>
                </PanelContent>

                {refreshingData ? (
                    <>
                        <Spinner id="refresh-contact-info-spinner" isCenterPage isVisible />
                    </>
                ) : editMode ? (
                    <PanelContent narrow>
                        <EditContactInfo
                            hasSMS={hasSMS}
                            hasEmail={hasEmail}
                            leaveEditMode={this.leaveEditMode}
                            person={person}
                            customer={customer}
                            hasAccounts={hasAccounts}
                            updateCustomerContactInfo={updateCustomerContactInfo}
                            updateCustomerContactInfoError={updateCustomerContactInfoError}
                            updateCustomerContactInfoPending={updateCustomerContactInfoPending}
                        />
                    </PanelContent>
                ) : (
                    <PanelContent narrow>
                        <ShowContactInfo customer={customer} />
                        {isFI() && <CustomerSettingsPanel hasSMS={hasSMS} hasEmail={hasEmail} />}
                        <ButtonGroup alignCenter>
                            <Button round outline onClick={this.onButtonClick}>
                                {i18n('customer.profile.show-profile.edit-profile')}
                            </Button>
                        </ButtonGroup>
                    </PanelContent>
                )}
            </Panel>
        );
    }
}
