// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TableHeaderColumn.scss';

interface TableHeaderColumnProps {
    /** CSS id */
    id?: string;

    /** Child elements */
    children?: ReactNode;

    /** @deprecated use alignRight instead */
    rightAlign?: boolean;
    alignRight?: boolean;
    alignCenter?: boolean;
    noBorder?: boolean;
    borderTop?: boolean;

    /** In most cases, use className and css instead */
    style?: CSSProperties;

    /** Additional css classes */
    className?: string;
    colSpan?: number;
}
/** Table cell component for TableRows in TableHeader */
const TableHeaderColumn = ({
    children,
    rightAlign,
    alignRight,
    alignCenter,
    noBorder,
    borderTop,
    className,
    style,
    colSpan,
    id,
}: TableHeaderColumnProps) => {
    const classes = classNames({
        'table-header-column': true,
        'table-header-column--border': !noBorder,
        'table-header-column--right-align': alignRight || rightAlign,
        'table-header-column--center-align': alignCenter,
        'table-header-column--border-top': borderTop,
        [className]: className,
    });
    return (
        <th id={id} className={classes} style={style} colSpan={colSpan}>
            {children}
        </th>
    );
};

TableHeaderColumn.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Child elements */
    children: PropTypes.node,

    /** @deprecated use alignRight instead */
    rightAlign: PropTypes.bool,
    alignRight: PropTypes.bool,
    alignCenter: PropTypes.bool,

    noBorder: PropTypes.bool,
    borderTop: PropTypes.bool,

    /** In most cases, use className and css instead */
    style: PropTypes.object,

    /** Additional css classes */
    className: PropTypes.string,
    colSpan: PropTypes.number,
};

TableHeaderColumn.defaultProps = {
    id: '',
    rightAlign: false, // @deprecated
    alignRight: false,
    alignCenter: false,
    children: '',
    noBorder: false,
    borderTop: false,
    style: {},
    className: '',
    colSpan: null,
};

export default TableHeaderColumn;
