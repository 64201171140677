import { get } from '@ecster/net/lib/v2/Ajax';
import {
    CONTRACT_GET_CONTRACT_INVOICE_BEGIN,
    CONTRACT_GET_CONTRACT_INVOICE_SUCCESS,
    CONTRACT_GET_CONTRACT_INVOICE_FAILURE,
    CONTRACT_GET_CONTRACT_INVOICE_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_CONTRACT_INVOICE_STATE = 'CLEAR_GET_CONTRACT_INVOICE_STATE';

const GET_CONTRACT_INVOICE_URL = (customerId, contractNumber) =>
    `/rest/ess/customers/v1/${customerId}/contracts/${contractNumber}/invoice`;

export const getContractInvoice = (customerId, contractNumber) => async dispatch => {
    dispatch({
        type: CONTRACT_GET_CONTRACT_INVOICE_BEGIN,
    });

    try {
        const res = await get(GET_CONTRACT_INVOICE_URL(customerId, contractNumber));

        dispatch({
            type: CONTRACT_GET_CONTRACT_INVOICE_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getContractInvoice');

        dispatch({
            type: CONTRACT_GET_CONTRACT_INVOICE_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetContractInvoiceError = () => ({ type: CONTRACT_GET_CONTRACT_INVOICE_DISMISS_ERROR });

export const clearGetContractInvoiceState = () => ({ type: CLEAR_GET_CONTRACT_INVOICE_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CONTRACT_GET_CONTRACT_INVOICE_BEGIN:
            return {
                ...state,
                getContractInvoicePending: true,
                getContractInvoiceError: null,
                getContractInvoiceIsDone: false,
                getContractInvoiceIsError: false,
            };

        case CONTRACT_GET_CONTRACT_INVOICE_SUCCESS:
            return {
                ...state,
                currentContractInvoice: action.data,
                getContractInvoicePending: false,
                getContractInvoiceError: null,
                getContractInvoiceIsDone: true,
            };

        case CLEAR_GET_CONTRACT_INVOICE_STATE:
            return {
                ...state,
                currentContractInvoice: null,
                getContractInvoiceIsDone: false,
                getContractInvoiceIsError: false,
            };

        case CONTRACT_GET_CONTRACT_INVOICE_FAILURE:
            return {
                ...state,
                getContractInvoicePending: false,
                getContractInvoiceError: action.data.error,
                getContractInvoiceIsError: true,
            };

        case CONTRACT_GET_CONTRACT_INVOICE_DISMISS_ERROR:
            return {
                ...state,
                getContractInvoiceError: null,
                getContractInvoiceIsError: false,
            };

        default:
            return state;
    }
}
