import uniqueBy from './unique-by';

/**
 * Return an array of unique errors - meaning one error per request key
 * @param errors (undefined || Array) - Existing errors, if any
 * @param key (String) - The key by each what each object member property is measured by for uniqueness.
 * @param action (Object) - A Redux object containing the action.data.
 * @returns [] - Returns a new array.
 */
const returnUniqueErrors = ({ errors, key, action }) => {
    if (Array.isArray(errors) && errors.length > 0) {
        return uniqueBy([...errors, action.data], key);
    }

    return [action.data];
};

export default returnUniqueErrors;
