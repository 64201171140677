import moment from 'moment';
import { i18n } from '@ecster/i18n';
import { isSE, isFI } from '../country';

const { EcsterConfig: conf } = window;

// YYYY-MM-DD / DD.MM.YYYY etc
export const formatDate = date => {
    moment.locale(window.EcsterConfig.locale);
    return isFI()
        ? moment(date).format('DD.MM.YYYY')
        : isSE()
          ? moment(date).format('YYYY-MM-DD')
          : moment(date).format('L');
};

// 4 jan
// 4. tammi
export const formatDateShort = date => {
    const theDate = new Date(date);
    const dot = conf.locale === 'fi-FI' ? '.' : '';
    return `${theDate.getDate()}${dot} ${i18n(`general.months.short.${theDate.getMonth()}`)}`;
};

// 4 jan 2020
// 4. tammi 2020
export const formatDateShortWithYear = date => {
    const theDate = new Date(date);
    const dot = conf.locale === 'fi-FI' ? '.' : '';
    return `${theDate.getDate()}${dot} ${i18n(`general.months.short.${theDate.getMonth()}`)} ${theDate.getFullYear()}`;
};

// 10 februari
// 10. helmikuuta
export const formatDateLong = date => {
    const theDate = new Date(date);
    const fiFi = conf.locale === 'fi-FI';
    const dot = fiFi ? '.' : '';
    const suffix = fiFi ? 'ta' : '';
    return `${theDate.getDate()}${dot} ${i18n(`general.months.long.${theDate.getMonth()}`)}${suffix}`;
};

// 10 februari 2020
// 10. helmikuuta 2020
export const formatDateLongWithYear = date => {
    const theDate = new Date(date);
    const fiFi = conf.locale === 'fi-FI';
    const dot = fiFi ? '.' : '';
    const suffix = fiFi ? 'ta' : '';
    return `${theDate.getDate()}${dot} ${i18n(
        `general.months.long.${theDate.getMonth()}`
    )}${suffix} ${theDate.getFullYear()}`;
};

// januari
// tammikuu
export const formatDateMonth = date => {
    const theDate = new Date(date);
    return i18n(`general.months.long.${theDate.getMonth()}`);
};

// apr
// huhti
export const formatDateMonthShort = date => {
    const theDate = new Date(date);
    return i18n(`general.months.short.${theDate.getMonth()}`);
};

/**
 * @deprecated don't use
 */
export const formatDateCustom = (date, format) => {
    moment.locale(window.EcsterConfig.locale);
    return moment(date).format(format);
};
