// @ts-expect-error
window.ecKeyValueStorage = window.ecKeyValueStorage || {};

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const storageAvailable = (type: 'localStorage' | 'sessionStorage') => {
    const storage = window[type];
    try {
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException && // eslint-disable-line no-undef
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
};

const fallbackStorage = {
    // @ts-expect-error
    getItem: key => window.ecKeyValueStorage[key],
    // @ts-expect-error
    setItem: (key, value) => (window.ecKeyValueStorage[key] = value),
    // @ts-expect-error
    removeItem: key => delete window.ecKeyValueStorage[key],
};

const storage = () => (storageAvailable('sessionStorage') ? window.sessionStorage : fallbackStorage);

const setItem = (key: string, value: string) => {
    storage().setItem(key, value);
};

const getItem = (key: string) => storage().getItem(key);

const removeItem = (key: string) => {
    storage().removeItem(key);
};

export const keyValueStorage = {
    setItem,
    getItem,
    removeItem,
};
