import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, ResponsivePanel, RadioGroup, Radio, LabelValue, SliderPanel } from '@ecster/components';
import { formatAmount } from '../../../common/util/format-amount';
import { formatNumber } from '../../../common/util/format-number';

const FOLLOW = 'follow';
const DONT_FOLLOW = 'dont-follow';

const { EcsterConfig: conf } = window;

const IS_TEST = conf && conf.env && !conf.env.match(/^prod/);

const roundAmount = amount =>
    Number.isNaN(amount) || amount === undefined ? 'No value' : formatAmount(amount, { round: true, strip00: true });

export default class CalculatePanel extends React.Component {
    state = {
        payAmount: 0,
        displayAmount: '0',
        followPlan: FOLLOW,
        nextMonthAmount: 0,
        debugCss: 'hide',
    };

    static propTypes = {
        accountTerms: PropTypes.object.isRequired,
        lab1: PropTypes.number.isRequired,
        lab2: PropTypes.number.isRequired,
        lab3: PropTypes.number.isRequired,
        hasPartPayments: PropTypes.bool.isRequired,
        hasAccountTransactions: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        const { lab1, lab2, lab3, hasPartPayments: hasPP, hasAccountTransactions: hasAT } = this.props;
        const ppOnly = hasPP && !hasAT;
        const atOnly = !hasPP && hasAT;

        this.setState({
            payAmount: ppOnly || atOnly ? lab1 : lab2,
            followPlan: ppOnly ? DONT_FOLLOW : FOLLOW,
            displayAmount: ppOnly || atOnly ? roundAmount(lab1) : roundAmount(lab2),
            nextMonthAmount: ppOnly || lab2 === lab3 ? lab2 - lab1 : lab3 - lab2,
        });
    }

    onRadioChange = ({ target }) => {
        const { lab1, lab2, lab3 } = this.props;
        const newAmount = target.value === FOLLOW ? lab2 : lab1;

        this.setState({
            followPlan: target.value,
            payAmount: newAmount,
            displayAmount: roundAmount(newAmount),
            nextMonthAmount: lab3 - newAmount,
        });
    };

    onSliderChange = value => {
        const { lab3 } = this.props;
        this.setState({ payAmount: value, displayAmount: roundAmount(value), nextMonthAmount: lab3 - value });
    };

    toggleDebugInfo = event => {
        const { debugCss } = this.state;
        if (IS_TEST && event.getModifierState('Shift') && event.getModifierState('Alt')) {
            this.setState({ debugCss: debugCss === 'show' ? 'hide' : 'show' });
        }
    };

    render() {
        const { payAmount, displayAmount, followPlan, nextMonthAmount, debugCss } = this.state;
        const { lab1, lab2, lab3, hasPartPayments: hasPP, hasAccountTransactions: hasAT, accountTerms } = this.props;

        // 2A/B/C = use case names in requirements
        const is2A = hasPP && hasAT; // hxdn-12192
        const is2B = hasPP && !hasAT; // hxdn-12208
        const is2C = !hasPP && hasAT; // hxdn-12209
        const is2D = lab1 === lab2 && lab2 !== lab3;

        const amountMin = is2C ? lab1 : followPlan === FOLLOW ? lab2 : lab1;
        const amountMax = is2C ? lab3 : followPlan === FOLLOW ? lab3 : is2D ? lab3 : lab2 - 100;

        const amountMinFormatted = roundAmount(amountMin);
        const amountMaxFormatted = roundAmount(amountMax);

        const labelLeft = debugCss === 'hide' ? '' : is2C ? '[ L1 ]' : followPlan === FOLLOW ? '[ L2 ]' : '[ L1 ]';
        const labelRight = debugCss === 'hide' ? '' : is2C ? '[ L3 ]' : followPlan === FOLLOW ? '[ L3 ]' : '[ L2 ]';

        // step is 1 kr for small min/max diffs, 10 kr for bigger diffs
        const step = amountMax - amountMin < 10000 ? 100 : 1000;

        const monthlyInterest = formatNumber(accountTerms && accountTerms.interestRate / 12);
        const costPer1000 =
            accountTerms &&
            formatAmount((1000 * accountTerms.interestRate) / 12, {
                strip00: true,
                roundUp: true,
            });

        return (
            <Panel
                paddingTopBottom="xLarge"
                stretchInMobile
                className="invoice-calculate-panel"
                onClick={this.toggleDebugInfo}
            >
                <PanelContent wide className="centered-content mb-8x">
                    <h2 className="h3 centered-content">{i18n('invoice.account-invoice.calculate-panel.header')}</h2>

                    <div className={`debug-info ${debugCss}`} aria-hidden>
                        <ResponsivePanel>
                            <div>
                                <LabelValue label={`LAB1 (${(lab1 / 100).toFixed(2)})`} value={roundAmount(lab1)} />
                                <LabelValue label={`LAB2 (${(lab2 / 100).toFixed(2)})`} value={roundAmount(lab2)} />
                                <LabelValue label={`LAB3 (${(lab3 / 100).toFixed(2)})`} value={roundAmount(lab3)} />
                            </div>
                            <div>
                                <LabelValue
                                    label="Follow plan"
                                    className={followPlan === FOLLOW ? 'green' : 'red'}
                                    value={followPlan === FOLLOW ? 'yes' : 'no'}
                                />
                                <LabelValue
                                    label="Has PP"
                                    className={hasPP ? 'green' : 'red'}
                                    value={hasPP ? 'yes' : 'no'}
                                />
                                <LabelValue
                                    label="Has AT"
                                    className={hasAT ? 'green' : 'red'}
                                    value={hasAT ? 'yes' : 'no'}
                                />
                            </div>
                        </ResponsivePanel>

                        {is2A && <p className="blue-circle">2A</p>}
                        {is2B && <p className="blue-circle">2B</p>}
                        {is2C && <p className="blue-circle">2C</p>}
                        {is2D && (
                            <p className="yellow">
                                <strong>Lab1 = Lab2</strong>
                            </p>
                        )}

                        <hr style={{ margin: '40px 0' }} />
                    </div>

                    <p>{i18n('invoice.account-invoice.calculate-panel.info', { minAmount: roundAmount(lab1) })}</p>
                    <SliderPanel
                        className="amount-slider"
                        header={i18n('invoice.account-invoice.calculate-panel.slider-header')}
                        min={amountMin}
                        max={amountMax}
                        value={payAmount}
                        displayedValue={displayAmount}
                        onChange={this.onSliderChange}
                        step={step}
                        labelLeft={`${labelLeft} ${amountMinFormatted}`}
                        labelRight={`${labelRight} ${amountMaxFormatted}`}
                        hideControls={(is2B && !is2D && followPlan === FOLLOW) || amountMin === amountMax}
                    />
                    {(is2A || is2B) && !is2D && (
                        <RadioGroup onChange={this.onRadioChange} selectedValue={followPlan}>
                            <ResponsivePanel>
                                <div>
                                    <Radio
                                        id="follow-plan-radio"
                                        value={FOLLOW}
                                        label={i18n('invoice.account-invoice.calculate-panel.radio1-label')}
                                    />
                                </div>
                                <div>
                                    <Radio
                                        id="dont-follow-plan-radio"
                                        value={DONT_FOLLOW}
                                        label={i18n('invoice.account-invoice.calculate-panel.radio2-label')}
                                    />
                                </div>
                            </ResponsivePanel>
                        </RadioGroup>
                    )}
                </PanelContent>
                <PanelContent wide className="plan-summary">
                    {(is2C || is2D) && (
                        <p className="mb-6x">{i18n('invoice.account-invoice.calculate-panel.no-plan.info')}</p>
                    )}
                    {!is2C && !is2D && followPlan === FOLLOW && (
                        <>
                            <h3 className="h5">{i18n('invoice.account-invoice.calculate-panel.follow-plan.header')}</h3>
                            <p className="mb-6x">{i18n('invoice.account-invoice.calculate-panel.follow-plan.info')}</p>
                        </>
                    )}
                    {!is2C && !is2D && followPlan === DONT_FOLLOW && (
                        <>
                            <h3 className="h5">
                                {i18n('invoice.account-invoice.calculate-panel.dont-follow-plan.header')}
                            </h3>
                            <p className="mb-6x">
                                {i18n('invoice.account-invoice.calculate-panel.dont-follow-plan.info')}
                            </p>
                        </>
                    )}
                </PanelContent>
                <PanelContent className="interest-info">
                    <h4 className="centered-content">
                        {i18n('invoice.account-invoice.calculate-panel.next-month.header')}
                    </h4>
                    <strong>{i18n('invoice.account-invoice.calculate-panel.next-month.transfer-header')}</strong>
                    <LabelValue
                        className="amount-next-month"
                        label={i18n('invoice.account-invoice.calculate-panel.next-month.transfer-info')}
                        value={roundAmount(nextMonthAmount)}
                    />
                    <strong>{i18n('invoice.account-invoice.calculate-panel.next-month.interest-header')}</strong>
                    {nextMonthAmount > 0 ? (
                        <p>
                            {i18n(
                                `invoice.account-invoice.calculate-panel.next-month.interest-info.${
                                    is2C ? '2c' : followPlan
                                }`,
                                { interest: monthlyInterest, amount: costPer1000 }
                            )}
                        </p>
                    ) : (
                        <p>{i18n('invoice.account-invoice.calculate-panel.next-month.interest-info.no-interest')}</p>
                    )}
                </PanelContent>
            </Panel>
        );
    }
}

CalculatePanel.propTypes = {};
CalculatePanel.defaultProps = {};
