import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { i18n } from '@ecster/i18n';
import {
    Button,
    ButtonGroup,
    ExpandableContainer,
    Form,
    Icon,
    Input,
    Message,
    PhoneNumberInput,
} from '@ecster/components';

import { GDPRInfo } from '@ecster/components/app';

import { paperPlaneIcon } from '@ecster/icons/H40/green';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';
import { validateMobilePhoneNumberIncludingOldFormatsFI, validateEmail } from '@ecster/validation';
import { ExpandHeader } from '../ExpandHeader';

import {
    updateCustomerContactInfo,
    dismissUpdateCustomerContactInfoError,
    clearUpdateCustomerContactInfoState,
} from '../redux/actions';

import './ChangeContactDetails.scss';

const EMPTY_PHONE = { countryCallingCode: '+358', number: '' };
export class ChangeContactDetails extends React.Component {
    emailField = React.createRef();

    phoneNumberField = React.createRef();

    formRef = React.createRef();

    state = {
        email: '',
        phoneNumber: EMPTY_PHONE,
        operationFailed: false,
        operationSucceeded: false,
        bothMissing: false,
    };

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('updateCustomerContactInfo', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ operationSucceeded: true, operationFailed: false, email: '', phoneNumber: EMPTY_PHONE });
        }
        if (reduxActionFailed('updateCustomerContactInfo', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ operationFailed: true });
        }
    }

    componentWillUnmount() {
        const {
            dismissUpdateCustomerContactInfoError,
            updateCustomerContactInfoError,
            clearUpdateCustomerContactInfoState,
        } = this.props;
        if (updateCustomerContactInfoError) dismissUpdateCustomerContactInfoError();

        clearUpdateCustomerContactInfoState();
    }

    onChangeEmail = ({ target }) => {
        this.setState({ email: target.value, bothMissing: false });
    };

    onChangePhoneNumber = value => {
        this.setState({ phoneNumber: value, bothMissing: false });
    };

    onClick = () => {
        const { updateCustomerContactInfo, customerId } = this.props;
        const { email, phoneNumber } = this.state;

        const data = {};

        const bothMissing = !email && (!phoneNumber || !phoneNumber.number);

        if (bothMissing) {
            this.setState({ bothMissing });
            return;
        }

        // only one of the two is required, check which to add to avoid sending empty strings
        if (email) data.email = email;

        if (phoneNumber && phoneNumber.number) data.phoneNumber = phoneNumber;

        if (this.formRef.current.validate()) {
            updateCustomerContactInfo(customerId, data);
        }
    };

    onCollapse = () => {
        // don't show messages if customer expands send message section again
        this.setState({ operationFailed: false, operationSucceeded: false });
    };

    render() {
        const { email, phoneNumber, operationFailed, operationSucceeded, bothMissing } = this.state;
        const { language, applicationCountry: country } = window.EcsterConfig;

        return (
            <ExpandableContainer
                id="change-contact-details-expandable-panel"
                className="change-contact-details-panel"
                header={
                    <ExpandHeader
                        icon={<Icon icon="icon-user" green medium />}
                        text={i18n('customer.contact-us.profile.header')}
                    />
                }
                stretch
                onCollapse={this.onCollapse}
            >
                <div className="expanded-content">
                    {operationFailed && (
                        <div className="mb-4x">
                            <Message
                                exclamation
                                purple
                                header={i18n('customer.contact-us.profile.feedback.failure.header')}
                            />
                        </div>
                    )}
                    {operationSucceeded && (
                        <div className="mb-4x">
                            <Message
                                iconUrl={paperPlaneIcon}
                                green
                                header={i18n('customer.contact-us.profile.feedback.success.header')}
                                message={i18n('customer.contact-us.profile.feedback.success.text')}
                            />
                        </div>
                    )}
                    <Form ref={this.formRef} validateRefs={[this.emailField, this.phoneNumberField]}>
                        <Input
                            id="change-contact-details-email-field"
                            value={email}
                            label={i18n('customer.contact-us.profile.labels.email-field')}
                            validator={validateEmail}
                            validationMessage={i18n('general.validation.email')}
                            onChange={this.onChangeEmail}
                            ref={this.emailField}
                        />

                        <PhoneNumberInput
                            ref={this.phoneNumberField}
                            value={phoneNumber}
                            label={i18n('customer.contact-us.profile.labels.phone-field')}
                            onChange={this.onChangePhoneNumber}
                            countryCodeIsSelectable={false}
                            numberValidator={validateMobilePhoneNumberIncludingOldFormatsFI}
                            validationMessagePhone={i18n('general.validation.phone')}
                            validationMessageCountryCode={i18n('general.validation.country-code')}
                        />

                        {bothMissing && (
                            <p className="both-missing-warning">
                                {i18n('customer.contact-us.profile.validation.both-missing')}
                            </p>
                        )}

                        <ButtonGroup alignRight>
                            <Button id="change-contact-details-button" onClick={this.onClick} round>
                                {i18n('customer.contact-us.profile.button')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                    <GDPRInfo onlineNewContactDetails language={language} country={country} />
                </div>
            </ExpandableContainer>
        );
    }
}

ChangeContactDetails.propTypes = {
    customerId: PropTypes.number.isRequired,
    updateCustomerContactInfo: PropTypes.func.isRequired,
    dismissUpdateCustomerContactInfoError: PropTypes.func.isRequired,
    /* eslint-disable-next-line */
    updateCustomerContactInfoPending: PropTypes.bool.isRequired, // used by rekitActionSucceeded
    /* eslint-disable-next-line */
    updateCustomerContactInfoError: PropTypes.object, // used by rekitActionSucceeded
    /* eslint-disable-next-line */
    clearUpdateCustomerContactInfoState: PropTypes.func.isRequired,
};

ChangeContactDetails.defaultProps = {
    updateCustomerContactInfoError: null,
};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ customer, authentication }) {
    return {
        customerId: authentication.person.id,
        updateCustomerContactInfoPending: customer.updateCustomerContactInfoPending,
        updateCustomerContactInfoError: customer.updateCustomerContactInfoError,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        updateCustomerContactInfo: (customerId, data) => dispatch(updateCustomerContactInfo(customerId, true, data)),
        dismissUpdateCustomerContactInfoError: () => dispatch(dismissUpdateCustomerContactInfoError()),
        clearUpdateCustomerContactInfoState: () => dispatch(clearUpdateCustomerContactInfoState()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeContactDetails);
