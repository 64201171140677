const getQueryParams = () => {
    const params = window.location.search.substring(1).split('&');
    const result: Record<string, string> = {};

    for (let i = 0; i < params.length; i++) {
        var key = params[i].split('=')[0],
            val = params[i].split('=')[1];

        result[key] = val;
    }
    return result;
};

const api = {
    getQueryParams,
};

export default api;
