export const GET_CUSTOMER_URL = (customerId, updateFromCreditSystem) =>
    `/rest/ess/customers/v1/${customerId}?update=${updateFromCreditSystem}`; // update=true => get data from ACSX + update NETX

/** @deprecated */
export const GET_CUSTOMER_PROPERTIES_URL = (customerId, property) =>
    `/rest/ess/customers/v1/${customerId}/properties?property=${property}`;

export const GET_CUSTOMER_PROPERTY_URL = (customerId, property) =>
    `/rest/ess/customers/v1/${customerId}/properties?property=${property}`;

export const GET_CUSTOMER_EXTRA_CARD_HOLDERS_URL = customerId =>
    `/rest/ess/customers/v1/${customerId}/extracardholders`;
export const UPDATE_CUSTOMER_CONTACT_INFO_URL = customerId => `/rest/ess/customers/v1/${customerId}/contact`;
export const UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_URL = customerId =>
    `/rest/ess/customers/v1/${customerId}/extracardholders`;
