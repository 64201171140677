/* eslint-disable max-len,no-undef */
import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import DialogHeader from './DialogHeader';
import DialogFooter from './DialogFooter';

import './Dialog.scss';

interface DialogProps {
    className?: string;

    /**
     * Custom dialog css
     */
    dialogCss?: CSSProperties;

    /**
     *  Set to true to show dialog, false hides it
     */
    open: boolean;

    /**
     * What to do when close X or overlay is clicked
     */
    onRequestClose?: Function;

    /**
     * Dialog content
     */
    children?: ReactNode;

    /**
     * Corner rounding - just a little.
     *
     * Default if no rounding prop is true.
     */
    rounded1x?: boolean;

    /**
     * Corner rounding - a little bit more
     */
    rounded2x?: boolean;

    /**
     * Corner rounding - some more
     */
    rounded3x?: boolean;

    /**
     * Corner rounding - even more
     */
    rounded4x?: boolean;

    /**
     * Corner rounding - more than even more
     */
    rounded5x?: boolean;

    /**
     * Corner rounding - a lot
     */
    rounded6x?: boolean;

    /**
     * Set a dialog max width - any valid css string. 500px, 50rem, 60% etc
     */
    maxWidth?: string;

    /**
     * Stretch dialog to max width and height of screen in mobile devices.
     *
     * Corner rounding is removed when this one is set to true
     */
    fullscreenMobile?: boolean;

    /**
     * Set to true to override default behaviour that Dialog sticks to bottom in mobile devices
     */
    centerVerticalMobile?: boolean;

    /**
     * A lighter mask behind the dialog
     */
    lightOverlay?: boolean;

    /**
     * Adds a fade effect to DialogBody when scroll appears.
     *
     * Set to false to turn it off when DialogBody contains interactive elements
     * like buttons and links
     */
    fadeEffect?: boolean;
}

export default class Dialog extends React.Component<DialogProps> {
    state = {
        hasHeader: false,
        hasFooter: false,
    };

    componentDidMount() {
        const { children } = this.props;
        if (Array.isArray(children)) {
            children.map(child => {
                if (child && child.type === DialogFooter) {
                    this.setState({ hasFooter: true });
                }
                if (child && child.type === DialogHeader) {
                    this.setState({ hasHeader: true });
                }
                return '';
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { open } = nextProps;
        const { body } = document;
        if (open) {
            body.addEventListener('keyup', this.onEscapeKey);
        }
    }

    onOverlayClick = () => {
        this.requestClose();
    };

    onEscapeKey = e => {
        if (e.which === 27) {
            this.requestClose();
        }
    };

    wrappedOnRequestClose = () => {
        const { onRequestClose } = this.props;
        onRequestClose();
        window.scrollBy(0, 0); // just in case we're on an ios 12 mobile and a keyboard has been opened
    };

    requestClose = () => {
        const { body } = document;
        body.removeEventListener('keyup', this.onEscapeKey);
        this.wrappedOnRequestClose();
    };

    childrenWithRequestClose = () => {
        const { children } = this.props;
        return React.Children.map(children, child =>
            child
                ? React.cloneElement(child as any, {
                      onRequestClose: this.wrappedOnRequestClose,
                  })
                : null
        );
    };

    render() {
        const {
            open,
            rounded1x,
            rounded2x,
            rounded3x,
            rounded4x,
            rounded5x,
            rounded6x,
            maxWidth,
            className,
            dialogCss,
            fullscreenMobile,
            centerVerticalMobile,
            lightOverlay,
            fadeEffect,
        } = this.props;

        const { hasHeader, hasFooter } = this.state;

        const modalClasses = classNames({
            modal: true,
            'ec-dialog-is-active': open,
        });

        const overlayClasses = classNames({
            'ec-dialog-overlay': true,
            'ec-dialog-overlay--light': lightOverlay,
        });

        const dialogStyle = {
            ...dialogCss,
            maxWidth,
        };

        const dialogClasses = classNames({
            'ec-dialog': true,
            'fullscreen-mobile': fullscreenMobile,
            'stick-to-bottom-mobile': !centerVerticalMobile,
            'has-header': hasHeader,
            'has-footer': hasFooter,
            'has-fade': fadeEffect,
            rounded1x,
            rounded2x,
            rounded3x,
            rounded4x: rounded4x || (!rounded1x && !rounded2x && !rounded3x && !rounded4x && !rounded5x && !rounded6x),
            rounded5x,
            rounded6x,
            [className]: className,
        });

        return (
            <div className={modalClasses}>
                <div className={overlayClasses} onClick={this.onOverlayClick}>
                    <div className={dialogClasses} style={dialogStyle} onClick={e => e.stopPropagation()}>
                        {this.childrenWithRequestClose()}
                    </div>
                </div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
Dialog.defaultProps = {
    className: '',
    dialogCss: {},
    onRequestClose: () => {},
    children: <span />,
    rounded1x: false,
    rounded2x: false,
    rounded3x: false,
    rounded4x: false,
    rounded5x: false,
    rounded6x: false,
    maxWidth: undefined,
    fullscreenMobile: false,
    centerVerticalMobile: false,
    lightOverlay: false,
    fadeEffect: true,
};
