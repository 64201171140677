/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

import './OrderedList.scss';

interface ListItemProps {
    indexClass: string;
    itemClass: string;
    item: ReactNode;
    index: number;
}

const ListItem = ({ indexClass, itemClass, item, index }: ListItemProps) => (
    <li>
        <span className={`list-index ${indexClass}`}>{`${index + 1}.`}</span>
        <span className={`list-value ${itemClass}`}>{item}</span>
    </li>
);

ListItem.propTypes = {
    indexClass: PropTypes.string.isRequired,
    itemClass: PropTypes.string.isRequired,
    item: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
};

interface OrderedListProps {
    children: ReactNode;
    indexClass?: string;
    itemClass?: string;
    className?: string;
}

const OrderedList = ({ children, indexClass, itemClass, className }: OrderedListProps) => {
    const classes = `e-ordered-list e-list ${className}`;

    if (!children) return null;

    return (
        <ol className={classes}>
            {Array.isArray(children) ? (
                children &&
                children.map((item, index) => (
                    <ListItem indexClass={indexClass} itemClass={itemClass} item={item} key={index} index={index} />
                ))
            ) : (
                <ListItem indexClass={indexClass} itemClass={itemClass} item={children} index={0} />
            )}
        </ol>
    );
};

export default OrderedList;

OrderedList.propTypes = {
    children: PropTypes.node.isRequired,
    indexClass: PropTypes.string,
    itemClass: PropTypes.string,
    className: PropTypes.string,
};

OrderedList.defaultProps = {
    indexClass: '',
    className: '',
    itemClass: '',
};
