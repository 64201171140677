import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import {
    Badge,
    LabelValue,
    Link,
    Message,
    Mobile,
    Panel,
    PanelContent,
    ResponsivePanel,
    TabletOrDesktop,
    UnorderedList,
} from '@ecster/components';

import { fileIcon, handWithMoneyIcon, infoIcon } from '@ecster/icons/H15/green';

import { formatDate, formatDateShort } from '../../common/util/format-date';
import { formatAmount } from '../../common/util/format-amount';
import { ArrowLink } from '../common/arrow-link/ArrowLink';
import { ContractIcon, OverviewPanelHeader } from '../common';

import { getContractHeader } from './util';
import { NO_ID } from './constants';

const TheHeader = ({ icon, badge, hasInvoice }) => (
    <strong className="header">
        {hasInvoice ? (
            <>
                <span>{i18n('contract.overview.invoice.header1')}</span>
                <span>
                    {badge}
                    {icon && <i className={icon} />}
                </span>
            </>
        ) : (
            i18n('invoice.invoice-overview.no-invoice.header')
        )}
    </strong>
);
TheHeader.propTypes = { icon: PropTypes.string, badge: PropTypes.node, hasInvoice: PropTypes.bool };

TheHeader.defaultProps = { icon: null, badge: null, hasInvoice: false };

const TheData = ({ hasInvoice, contract: { invoicedAmount, dueDate } }) => {
    const amount = formatAmount(invoicedAmount, { strip00: true, decimals: 2 });
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {hasInvoice ? (
                <>
                    <LabelValue label={i18n('contract.overview.invoice.amount')} value={amount} />
                    <LabelValue label={i18n('contract.overview.invoice.due-date')} value={formatDate(dueDate)} />
                </>
            ) : (
                <div>{i18n('invoice.invoice-overview.no-invoice.info')}</div>
            )}
        </>
    );
};

TheData.propTypes = {
    contract: PropTypes.shape({
        invoicedAmount: PropTypes.number.isRequired,
        dueDate: PropTypes.string.isRequired,
    }),
    hasInvoice: PropTypes.bool,
};

TheData.defaultProps = {
    hasInvoice: false,
    contract: {},
};

export default class ContractOverviewPanel extends Component {
    static propTypes = {
        contract: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    state = {};

    render() {
        const {
            contract,
            contract: {
                approvedLimit,
                dueDate,
                contractNumber,
                id,
                status,
                paymentStatus,
                currentDebt,
                outstandingPaymentContract,
                latestPaymentAmount,
                latestPaymentDate,
                repaymentInquiryAllowed,
            },
        } = this.props;

        const hasInvoice = typeof dueDate !== 'undefined'; // Assuming that if we don't have a dueDate we shouldn't have an invoice

        const contractInvoiceUrl = `/contract/${contractNumber}/invoice`;

        const contractTermsUrl = `/contract/${contractNumber}/terms`;

        // id is undefined in some rare cases. Don't show "undefined" in URL. Value is only used for backend logging.
        const contractRepaymentUrl = `/contract/${contractNumber}/${id || NO_ID}/repayment/`;

        const contractIsActive =
            status !== 'AIP' &&
            (status === 'ACTIVE' ||
                // TODO: remove later. Status generated by test servlet only?
                status === 'ERROR');

        const arrowLinkInvoice = (
            <ArrowLink
                text={i18n('contract.overview.links.invoice')}
                icon={fileIcon}
                to={contractInvoiceUrl}
                id="contract-invoice-link"
            />
        );

        const arrowLinkInfo = (
            <ArrowLink
                text={i18n('contract.overview.links.info')}
                icon={infoIcon}
                to={contractTermsUrl}
                id="contract-terms-link"
            />
        );

        const arrowLinkRepayment = (
            <ArrowLink
                text={i18n('contract.overview.links.repayment')}
                icon={handWithMoneyIcon}
                to={contractRepaymentUrl}
                id="contract-repayment-link"
            />
        );

        const overDueMessage = (
            <Message
                purple
                header={i18n('contract.overview.over-due.header')}
                message={i18n('contract.overview.over-due.info')}
            />
        );

        const paid = paymentStatus === 'PAID';

        const badge = (
            <Badge dark purple={!paid} iconLeft={paid ? 'icon-check' : null}>
                {i18n(`contract.payment-status.${paymentStatus}`)}
            </Badge>
        );

        return (
            <Panel paddingAll="small" className="contract-overview-panel">
                <PanelContent fullWidth>
                    {status === 'AIP' ? (
                        <OverviewPanelHeader
                            image={<ContractIcon contract={{ productCode: 'none' }} />}
                            isContract
                            header={getContractHeader(contract)}
                            amount1={approvedLimit}
                            amount1Label={i18n('contract.overview.aip.amount-label')}
                        />
                    ) : (
                        <OverviewPanelHeader
                            image={<ContractIcon contract={contract} />}
                            header={getContractHeader(contract)}
                            amount1={contractIsActive ? currentDebt : null}
                            isContract
                            amount1Label={contractIsActive ? i18n('contract.overview.amount-label') : null}
                        />
                    )}

                    {contractIsActive && (
                        <>
                            {outstandingPaymentContract > 0 && overDueMessage}

                            <ResponsivePanel className="pt-4x plr-2x" verticalLines>
                                <div>
                                    <Mobile>
                                        {hasInvoice ? (
                                            <Link
                                                className="link-no-style"
                                                to={contractInvoiceUrl}
                                                underline={false}
                                                id="contract-more-info-mobile"
                                            >
                                                <TheHeader icon="icon-chevron-right" badge={badge} hasInvoice />
                                                <TheData hasInvoice contract={contract} />
                                            </Link>
                                        ) : (
                                            <>
                                                <TheHeader hasInvoice={false} />
                                                <TheData hasInvoice={false} />
                                            </>
                                        )}
                                    </Mobile>
                                    <TabletOrDesktop>
                                        <TheHeader badge={badge} hasInvoice={hasInvoice} />
                                        <TheData hasInvoice={hasInvoice} contract={contract} />
                                        {hasInvoice && (
                                            <div className="right">
                                                <Link
                                                    className=""
                                                    purple
                                                    iconRight="icon-chevron-right"
                                                    iconColorClass="e-black"
                                                    to={contractInvoiceUrl}
                                                    underline={false}
                                                    id="contract-more-info-tablet-or-desktop"
                                                >
                                                    {i18n('contract.overview.invoice.detail-link')}
                                                </Link>
                                            </div>
                                        )}
                                    </TabletOrDesktop>
                                    {hasInvoice && latestPaymentAmount > 0 ? (
                                        <>
                                            <hr />
                                            <div>
                                                <strong className="header">
                                                    {i18n('contract.overview.invoice.header2')}
                                                </strong>
                                                <LabelValue
                                                    label={i18n('contract.overview.invoice.payment-bg')}
                                                    value={formatAmount(latestPaymentAmount, {
                                                        strip00: true,
                                                        decimals: 2,
                                                    })}
                                                />
                                                <p className="e-gray120 small-text">
                                                    {formatDateShort(latestPaymentDate)}
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="pb-3x" />
                                    )}
                                </div>
                                {repaymentInquiryAllowed ? (
                                    <div className="arrow-links-panel">
                                        {arrowLinkInvoice}
                                        {arrowLinkInfo}
                                        {arrowLinkRepayment}
                                    </div>
                                ) : (
                                    <div className="arrow-links-panel">
                                        {arrowLinkInvoice}
                                        {arrowLinkInfo}
                                    </div>
                                )}
                            </ResponsivePanel>
                        </>
                    )}

                    {status === 'AIP' && (
                        <div className="text-content mtb-6x">
                            <p>{i18n('contract.overview.aip.info')}</p>
                            <UnorderedList icon="icon-check" iconClass="e-purple">
                                {i18n('contract.overview.aip.bullets', 'span')}
                            </UnorderedList>
                        </div>
                    )}

                    {status === 'COLLECTION' && (
                        <>
                            <Message purple header={i18n('contract.overview.collection.header')}>
                                {i18n('contract.overview.collection.info', { danger: true })}
                            </Message>
                            <div className="contract-links-ctr">
                                <div className="arrow-links-panel">{arrowLinkInfo}</div>
                            </div>
                        </>
                    )}

                    {status === 'CONTACT_US' && (
                        <>
                            <Message purple header={i18n('contract.overview.contact-us.header')}>
                                {i18n('contract.overview.contact-us.info', { danger: true })}
                            </Message>
                            <div className="contract-links-ctr">
                                <div className="arrow-links-panel">{arrowLinkInfo}</div>
                            </div>
                        </>
                    )}

                    {status === 'FINISHED' && (
                        <>
                            <Message
                                blue
                                header={i18n('contract.overview.finished.header')}
                                message={i18n('contract.overview.finished.info')}
                            />
                            <div className="contract-links-ctr">
                                <div className="arrow-links-panel">{arrowLinkInfo}</div>
                            </div>
                        </>
                    )}
                </PanelContent>
            </Panel>
        );
    }
}
