import classNames from 'classnames';

export const getWidthClasses = props => {
    const { xNarrow, narrow, wide, xWide, xxWide, fullWidth, fullWidthPadded } = props;
    return classNames({
        'width-x-narrow': xNarrow,
        'width-narrow': narrow,
        'width-wide': wide,
        'width-x-wide': xWide,
        'width-xx-wide': xxWide,
        'width-full': fullWidth,
        'width-full-padded': fullWidthPadded,
    });
};
