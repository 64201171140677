/**
 * Parse an array of objects and return the object that matches provided object with specified property.
 * @param arr (Array) - Array to search
 * @param key (String) - Property in the object to look for
 * @param obj (Object) - Object that holds the prop to match
 * @param id (String) - The prop id that holds the key to match for
 * @returns {null}
 */
export const getObjectWithKeyFromArray = (arr, key, obj, id) =>
    Array.isArray(arr) && arr.length > 0
        ? arr.find(item => {
              // eslint-disable-next-line no-prototype-builtins
              return item[key] === (obj && obj[id] ? obj[id] : null);
          })
        : null;
