import React from 'react';
import { ButtonGroup, LinkButton, Panel, PanelContent } from '@ecster/components';
import { i18n } from '@ecster/i18n';

type Props = {
    backUrl: string;
};

export const TerminateAccountFailureMessage = ({ backUrl }: Props) => (
    <>
        <h2 className="h3">{i18n('account.terminate.failure.header')}</h2>
        <p>{i18n('account.terminate.failure.message', { danger: true })}</p>
        <ButtonGroup alignCenter spaceBelow={false}>
            <LinkButton transparent to={backUrl} id="terminate-account-failure-back">
                {i18n('account.terminate.failure.back')}
            </LinkButton>
        </ButtonGroup>
    </>
);
export const TerminateAccountSuccessMessage = ({ backUrl }: Props) => (
    <>
        <h2 className="h3">{i18n('account.terminate.success.header')}</h2>
        <p className="centered">{i18n('account.terminate.success.message')}</p>
        <ButtonGroup alignColumn spaceBelow={false}>
            <LinkButton transparent to={backUrl} id="terminate-account-success-back">
                {i18n('account.terminate.success.back')}
            </LinkButton>
        </ButtonGroup>
    </>
);

export const TerminateAccountSuccessPanel = ({ backUrl }: Props) => {
    return (
        <Panel stretchInMobile className="account-terminate-account">
            <PanelContent wide>
                <TerminateAccountSuccessMessage backUrl={backUrl} />
            </PanelContent>
        </Panel>
    );
};
