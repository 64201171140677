import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { Spinner, UserMessagePanel } from '@ecster/components';
import { walletIcon } from '@ecster/icons/H80/green';

import { formatAmount } from '@ecster/util';
import InvoicePanelV2 from './InvoicePanelV2';
import AuthenticatedPageTemplate from '../../common/templates/AuthenticatedPageTemplate';
import { getInvoices, clearGetInvoicesState } from '../redux/actions';
import { getContracts } from '../../contract/redux/actions';
import { getAccounts, getAccountBillOverview } from '../../account/redux/actions';
import { mapOneOffInvoices, mapAccountInvoices, mapContractInvoices, separate } from './mapInvoicesv2';

export const InvoiceOverviewPageV2 = () => {
    const dispatch = useDispatch();

    // account redux state
    const {
        accounts,
        accountBillOverview: bills,
        getAccountsIsDone,
    } = useSelector(state => state.account, shallowEqual);

    // invoice redux state
    const { invoices, getInvoicesIsDone } = useSelector(state => state.invoice, shallowEqual);

    // contract redux state
    const { contracts, getContractsIsDone } = useSelector(state => state.contract, shallowEqual);

    // authentication redux state
    const {
        person: { id: customerId },
    } = useSelector(state => state.authentication, shallowEqual);

    // didMount
    useEffect(() => {
        dispatch(getInvoices(customerId));
        dispatch(getContracts(customerId));
        dispatch(getAccounts(customerId));
    }, [customerId, dispatch]);

    // didUpdate
    useEffect(() => {
        if (getAccountsIsDone) {
            accounts.forEach(account => {
                dispatch(getAccountBillOverview(customerId, account.reference));
            });
        }
    }, [accounts, customerId, dispatch, getAccountsIsDone]);

    // willUnmount
    useEffect(
        () => () => {
            dispatch(clearGetInvoicesState());
        },
        [dispatch]
    );

    // one off invoices
    const oneOffInvoices = getInvoicesIsDone ? mapOneOffInvoices(invoices, customerId) : [];

    // account invoices
    const accountInvoices = mapAccountInvoices(bills, accounts, customerId);

    // contract invoices
    const contractInvoices = getContractsIsDone ? mapContractInvoices(contracts) : [];

    // all invoices
    const allInvoices = [...oneOffInvoices, ...accountInvoices, ...contractInvoices];

    // separate all invoices into unpaid and completed
    const { unpaidInvoices, completedInvoices } = separate(allInvoices);

    const totalUnpaid = formatAmount(
        unpaidInvoices.reduce((acc, curr) => acc + curr.amount, 0),
        { strip00: true }
    );
    return (
        <AuthenticatedPageTemplate
            className="invoice-invoice-page"
            header={i18n('invoice.invoice-overview.page-header')}
        >
            {allInvoices.length > 0 ? (
                <>
                    {unpaidInvoices.length > 0 && (
                        <InvoicePanelV2
                            invoices={unpaidInvoices}
                            headerLeft={i18n('invoice.invoice-overview.pay')}
                            headerRight={i18n('invoice.invoice-overview.total', { total: totalUnpaid })}
                        />
                    )}
                    {completedInvoices.length > 0 && (
                        <InvoicePanelV2
                            invoices={completedInvoices}
                            headerLeft={i18n('invoice.invoice-overview.completed')}
                        />
                    )}
                </>
            ) : getInvoicesIsDone && getContractsIsDone && getAccountsIsDone ? (
                <UserMessagePanel
                    header={i18n('invoice.invoice-overview.no-invoice.header')}
                    icon={walletIcon}
                    body={<p>{i18n('invoice.invoice-overview.no-invoice.info')}</p>}
                />
            ) : (
                <Spinner id="invoice-overview-spinner" isCenter isVisible />
            )}
        </AuthenticatedPageTemplate>
    );
};

export default InvoiceOverviewPageV2;
