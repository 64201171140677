import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@ecster/i18n';
import { formatAmount } from '@ecster/util';
import { Panel, PanelContent, ResponsivePanel, LabelValue, Link, TabletOrDesktop, Icon } from '@ecster/components';

import moment from 'moment';
import ErrorBoundary from '../../../common/ErrorBoundary';
import OcrScan from '../../invoice/ocr-scan/OcrScan';
import { formatDateMonth } from '../../../common/util/format-date';
import { getBillProperties } from '../util';
import { DueAmount, DueDate, OcrNumber, BgNumber } from '../../invoice/invoice-values';

const AmountPaid = ({ amount, paidMore }) => (
    <LabelValue
        label={paidMore ? i18n('account.next-payment.paid-more') : i18n('account.next-payment.amount-paid')}
        value={formatAmount(amount, { decimals: 2, strip00: true })}
    />
);
AmountPaid.propTypes = { amount: PropTypes.number.isRequired, paidMore: PropTypes.bool.isRequired };

export default class InvoiceInfoPanel extends React.Component {
    static propTypes = {
        bill: PropTypes.object.isRequired,
        lab3: PropTypes.number.isRequired,
        accountRef: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    render() {
        const { bill, lab3, accountRef } = this.props;
        const billProps = getBillProperties(bill);

        const { amountPaidByCustomer, ocrNumber } = bill;

        const month = formatDateMonth(moment(billProps.dueDate));
        const bgNumber = i18n('general.bgNumber.account');

        return (
            <Panel paddingTopBottom="xLarge" sideMarginsInMobile className="invoice-invoice-info-panel">
                <PanelContent wide>
                    <div className="mb-6x">
                        <h2 className="h3 centered-content">{`${i18n('account.next-payment.header')} ${month}`}</h2>

                        <p className="centered"> {billProps.badge}</p>
                    </div>

                    <ResponsivePanel verticalLines>
                        <>
                            <DueAmount amount={lab3} />

                            {billProps.hasPaidAll || billProps.paidLessLab3 ? (
                                <AmountPaid amount={amountPaidByCustomer} paidMore={billProps.paidMoreLab3} />
                            ) : (
                                <DueDate date={billProps.dueDate} />
                            )}
                        </>
                        <>
                            {billProps.paidLessLab3 && <DueDate date={billProps.dueDate} />}
                            <BgNumber bgNumber={bgNumber} />
                            <OcrNumber ocrNumber={ocrNumber} />
                        </>
                    </ResponsivePanel>

                    <div className="invoice-additional-info">
                        {billProps.paidBetweenLab1Lab2 && (
                            <div>
                                {i18n('invoice.account-invoice.invoice-info.paid.between-lab1-lab2', {
                                    amount: formatAmount(billProps.lab3 - amountPaidByCustomer, {
                                        decimals: 2,
                                        strip00: true,
                                    }),
                                })}
                            </div>
                        )}
                        {billProps.paidBetweenLab2Lab3 && (
                            <div>
                                {i18n('invoice.account-invoice.invoice-info.paid.between-lab2-lab3', {
                                    amount: formatAmount(billProps.lab3 - amountPaidByCustomer, {
                                        decimals: 2,
                                        strip00: true,
                                    }),
                                })}
                            </div>
                        )}
                        {billProps.paidLab3 && <div>{i18n('invoice.account-invoice.invoice-info.paid.paid-lab3')}</div>}
                        {billProps.paidMoreLab3 && (
                            <div>
                                {i18n('invoice.account-invoice.invoice-info.paid.paid-more-lab3', {
                                    amount: formatAmount(amountPaidByCustomer - billProps.lab3, {
                                        decimals: 2,
                                        strip00: false,
                                    }),
                                })}
                            </div>
                        )}
                    </div>

                    <ResponsivePanel className="invoice-actions">
                        <div className="invoice-spec">
                            <Link
                                to={`/account/${accountRef}/invoice/specification`}
                                ariaLabel={i18n('invoice.account-invoice.invoice-info.invoice-spec-aria-label')}
                                underline={false}
                            >
                                <Icon icon="icon-file" green invert />
                            </Link>
                            <p>
                                <Link
                                    to={`/account/${accountRef}/invoice/specification`}
                                    ariaLabel={i18n('invoice.account-invoice.invoice-info.invoice-spec-aria-label')}
                                    iconRight="icon-chevron-right"
                                    id="account-invoice-spec-link"
                                    underline={false}
                                >
                                    {i18n('invoice.account-invoice.invoice-info.invoice-spec')}
                                </Link>
                            </p>
                        </div>
                        <TabletOrDesktop>
                            <div className="invoice-ocr-scan">
                                <ErrorBoundary name="InvoiceInfoPanel OcrScan">
                                    <OcrScan
                                        text={i18n('invoice.ocr-scan.text')}
                                        ocrNumber={ocrNumber}
                                        bgNumber={bgNumber}
                                    />
                                </ErrorBoundary>
                            </div>
                        </TabletOrDesktop>
                    </ResponsivePanel>
                </PanelContent>
            </Panel>
        );
    }
}
