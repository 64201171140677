import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { connect } from 'react-redux';

import './ExportTransactionsToExcel.scss';

export class ExportTransactionsToExcel extends Component {
    static propTypes = {
        accountRef: PropTypes.string,
        sessionKey: PropTypes.string,
    };

    static defaultProps = {
        accountRef: '',
        sessionKey: '',
    };

    render() {
        const { EcsterConfig: conf } = window;
        const yyyymmdd = 'YYYY-MM-DD';
        const { accountRef, sessionKey } = this.props;

        const startDate = moment().subtract(25, 'months').startOf('month').format(yyyymmdd);
        const endDate = moment().format(yyyymmdd);

        const url = `${conf.baseURL}/export/customerTransactions?session=${sessionKey}&refCode=${accountRef}&from=${startDate}&to=${endDate}&lang=${window.EcsterConfig.language}`;

        return (
            <div className="export-transactions-to-excel">
                <Link href={url} iconLeft="icon-download" underline={false} download>
                    {i18n('general.exportToExcel')}
                </Link>
            </div>
        );
    }
}

/* istanbul ignore next */
function mapStateToProps({ account, authentication }) {
    return {
        accountRef: account.account.reference,
        sessionKey: authentication.loginStatus.sessionKey,
    };
}

export default connect(mapStateToProps)(ExportTransactionsToExcel);
