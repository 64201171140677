import React, { Component } from 'react';
import classNames from 'classnames';
import { validateMobilePhoneNumberSE } from '@ecster/validation';
import Input, { InputProps, InputRef } from './Input';
import './PhoneNumberInput.scss';
import { CountryCodeSelect } from './CountryCodeSelect';

interface PhoneNumberInputProps {
    ariaLabel?: string;
    ariaLabelCountryCode?: string;
    onChange: Function;
    className?: string;
    value?: {
        countryCallingCode: string;
        number: string;
    };
    countryCodeIsSelectable?: boolean;
    label?: string;
    gaPrefix?: string;
    numberValidator?: InputProps['validator'];
    validationMessagePhone?: string;
    validationMessageCountryCode?: string;
    validateOnBlur?: boolean;
    language?: string;
    required?: boolean;
    name?: string;
}

export class PhoneNumberInput extends Component<PhoneNumberInputProps> {
    countryCodeRef = React.createRef<CountryCodeSelect>();

    phoneRef = React.createRef<InputRef>();

    onChangeCountryCode = (newCountryCode) => {
        const { onChange, value: currentValue } = this.props;

        const newValue = { ...currentValue, countryCallingCode: newCountryCode };
        onChange(newValue);
    };

    onChangeNumber = ({ target }) => {
        const { onChange, value: currentValue } = this.props;
        const { value: newNumber } = target;

        const newValue = { ...currentValue, number: newNumber };
        onChange(newValue);
    };

    isDirty = () => {
        return this.phoneRef.current.isDirty();
    };

    doValidation() {
        const { countryCodeIsSelectable } = this.props;
        const phoneInput = this.phoneRef.current;

        const countryCodeSelect = this.countryCodeRef.current;

        const phoneInputValid = phoneInput.doValidation();

        const countryCodeSelectValid = !countryCodeIsSelectable || countryCodeSelect.doValidation();

        return phoneInputValid && countryCodeSelectValid;
    }

    render() {
        const {
            ariaLabel,
            ariaLabelCountryCode,
            className,
            label,
            value,
            countryCodeIsSelectable,
            gaPrefix,
            numberValidator,
            validationMessagePhone,
            validationMessageCountryCode,
            validateOnBlur,
            language,
            required,
            name,
            ...rest
        } = this.props;
        // const { value } = this.state;

        const classes = classNames({
            'fields-container': true,
            [className]: className,
        });

        return (
            <div className="ec-phone-number-input">
                <label htmlFor="phone-number-input-country-code" className="hidden">
                    {language === 'sv' ? 'Landskod' : language === 'fi' ? 'Maakoodi' : 'Country code'}
                </label>
                {label && (
                    <label htmlFor="phone-number-input" className="ec-phone-number-input-label">
                        {label}
                    </label>
                )}
                <div className={classes}>
                    <CountryCodeSelect
                        id="phone-number-input-country-code"
                        selectRef={this.countryCodeRef}
                        ariaLabelCountryCode={ariaLabelCountryCode}
                        value={value.countryCallingCode}
                        onChange={this.onChangeCountryCode}
                        displayOnly={!countryCodeIsSelectable}
                        language={language}
                        validationMessage={validationMessageCountryCode}
                        required={required}
                        name={`${name}CountryCode`}
                    />
                    <Input
                        {...rest}
                        ariaLabel={ariaLabel}
                        id="phone-number-input"
                        className="phone-number-field"
                        value={value.number}
                        onChange={this.onChangeNumber}
                        ref={this.phoneRef}
                        validator={numberValidator}
                        validationMessage={validationMessagePhone}
                        required={required}
                        type="tel"
                        validateOnBlur={validateOnBlur}
                        name={`${name}Number`}
                    />
                </div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
PhoneNumberInput.defaultProps = {
    ariaLabel: '',
    ariaLabelCountryCode: '',
    className: '',
    value: {},
    countryCodeIsSelectable: true,
    label: '',
    gaPrefix: undefined,
    numberValidator: validateMobilePhoneNumberSE,
    validationMessagePhone: 'Kontrollera numret och försök igen',
    validationMessageCountryCode: 'Välj en landskod',
    validateOnBlur: true,
    language: 'sv',
    required: false,
    name: 'phoneNumberInput',
};

