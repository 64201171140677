// https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge#answer-34749873
const isObject = (item: unknown): item is Record<string, unknown> => {
    return !!item && typeof item === 'object' && !Array.isArray(item);
};

export const deepMerge = <T extends object>(object1: T, object2: T): T => {
    const output = { ...object1 };
    if (isObject(object1) && isObject(object2)) {
        Object.keys(object2).forEach(key => {
            if (isObject(object2[key])) {
                if (!(key in object1)) Object.assign(output, { [key]: object2[key] });
                // @ts-expect-error
                else output[key] = deepMerge(object1[key], object2[key]);
            } else {
                Object.assign(output, { [key]: object2[key] });
            }
        });
    }
    return output;
};
