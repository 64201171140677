import React from 'react';
import dayjs from 'dayjs';
import { i18n } from '@ecster/i18n';
import { Badge } from '@ecster/components';
import { NOT_PAID, PAID, PARTIALLY_PAID } from '../../paymentStatus';
import { ACTIVE, DEPRECIATED, DUE_NOT_REMINDED, FULLY_CREDITED, REMINDED, TRANSFERRED } from '../../invoiceStatus';
import { formatAmount } from '../../../../common/util/format-amount';

export const getInvoiceHeaderDetails = invoice => {
    const isFullyCredited = invoice.status === FULLY_CREDITED;
    const isTransferred = invoice.status === TRANSFERRED;

    const isPaid =
        invoice.paymentStatus === PAID &&
        !isFullyCredited &&
        !isTransferred &&
        (invoice.status === ACTIVE ||
            invoice.status === DUE_NOT_REMINDED ||
            invoice.status === REMINDED ||
            invoice.status === DEPRECIATED);

    const isUnpaid = invoice.paymentStatus === NOT_PAID && !isFullyCredited && !isTransferred;
    const isPartiallyPaid = invoice.paymentStatus === PARTIALLY_PAID && !isFullyCredited && !isTransferred;

    const hasNewInvoiceAmount = invoice.isAmountUpdated;

    const description = hasNewInvoiceAmount
        ? i18n('invoice.one-off-invoice.invoice-header.new-invoice-amount')
        : i18n('invoice.one-off-invoice.invoice-header.invoice-amount');

    const labelValues = [];

    const today = dayjs().startOf('day');
    const diffDueDate = dayjs(invoice.dueDate).diff(today, 'days');
    const diffDueDateIsZero = diffDueDate === 0;
    const dueDateDaysLeftText = diffDueDateIsZero
        ? i18n('invoice.one-off-invoice.invoice-header.today')
        : i18n('invoice.one-off-invoice.invoice-header.days', { count: diffDueDate });

    const diffOriginalDueDate = Math.abs(dayjs(invoice.originalDueDate).diff(today, 'days'));
    const diffOriginalDueDateIsZero = diffOriginalDueDate === 0;
    const originalDueDateDaysLeftText = diffOriginalDueDateIsZero
        ? i18n('invoice.one-off-invoice.invoice-header.today')
        : i18n('invoice.one-off-invoice.invoice-header.days', { count: diffOriginalDueDate });

    const data = {
        description,
        hasNewInvoiceAmount,
        labelValues,
    };

    if (isPaid) {
        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.status'),
            value: <Badge dark>{i18n('invoice.one-off-invoice.invoice-header.badge.paid')}</Badge>,
        });

        return data;
    }

    if (isUnpaid) {
        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.status'),
            value: (
                <Badge dark purple>
                    {i18n('invoice.one-off-invoice.invoice-header.badge.not-paid')}
                </Badge>
            ),
        });

        if (invoice.status === ACTIVE) {
            data.labelValues.push({
                label: i18n('invoice.one-off-invoice.invoice-header.labels.is-active'),
                value: dueDateDaysLeftText,
            });
        } else {
            data.labelValues.push({
                label: i18n('invoice.one-off-invoice.invoice-header.labels.is-due'),
                value: originalDueDateDaysLeftText,
            });
        }

        return data;
    }

    if (isTransferred) {
        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.transferred-amount'),
            value: formatAmount(invoice.amountToPay),
        });
        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.amount-paid'),
            value: formatAmount(invoice.amountPaidByCustomer),
        });
        return data;
    }

    if (isPartiallyPaid) {
        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.status'),
            value: (
                <Badge dark blue>
                    {i18n('invoice.one-off-invoice.invoice-header.badge.partially-paid')}
                </Badge>
            ),
        });

        if (invoice.status === ACTIVE) {
            data.labelValues.push({
                label: i18n('invoice.one-off-invoice.invoice-header.labels.is-active'),
                value: dueDateDaysLeftText,
            });
        } else {
            data.labelValues.push({
                label: i18n('invoice.one-off-invoice.invoice-header.labels.is-due'),
                value: originalDueDateDaysLeftText,
            });
        }

        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.amount-paid'),
            value: formatAmount(invoice.amountPaidByCustomer),
        });
        data.labelValues.push({
            label: i18n('invoice.one-off-invoice.invoice-header.labels.amount-to-pay'),
            value: formatAmount(invoice.amountToPay),
        });

        return data;
    }

    return data;
};
