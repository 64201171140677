import {
    AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR,
    AUTHENTICATION_GET_SESSION_DISMISS_ERROR,
    AUTHENTICATION_RESET_LOGIN_STATE,
} from './constants';
import { loginStatus, loginProgress } from './initialState';

export const resetLoginState = () => async dispatch => {
    dispatch({
        type: AUTHENTICATION_GET_SESSION_DISMISS_ERROR,
    });
    dispatch({
        type: AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR,
    });

    dispatch({
        type: AUTHENTICATION_RESET_LOGIN_STATE,
    });
};

export function reducer(state, action) {
    const nextState = {
        ...state,
        loginStatus,
        loginProgress,
    };
    switch (action.type) {
        case AUTHENTICATION_RESET_LOGIN_STATE:
            return nextState;

        default:
            return state;
    }
}
