import React from 'react';
import { i18n } from '@ecster/i18n';
import { ButtonGroup, Button, Panel, PanelContent, LabelValue } from '@ecster/components';
import { formatAmount } from '../../../common/util/format-amount';
import { TerminateAccountSuccessPanel } from '../terminate-account/TerminateAccountSuccessPanel';
import { AccountDetail } from '../types/Account';
import { isValidSurplusAmount } from '../../../common/util/is-valid-surplus-amount';

const debtInfoLabelValue = (i18nKey: string, amount: number) => {
    const debtInfo = isValidSurplusAmount(-amount)
        ? i18n(`account.account-information.terminate.debt-comment.${i18nKey}`)
        : null;
    return (
        <>
            <LabelValue
                className="text-center"
                label={i18n(`account.account-information.terminate.labels.${i18nKey}`)}
                value={formatAmount(amount, { strip00: true })}
            />
            {debtInfo && <p>{debtInfo}</p>}
        </>
    );
};
const ignorableDebt = 100; // 1 kr // TODO: FI?

const getDebtInfo = (used: number) => {
    // debt that can be ignored
    if (used > 0 && used <= ignorableDebt) {
        return debtInfoLabelValue('ignore-debt', used);
    }

    // debt that must be paid
    if (used > 0 && used > ignorableDebt) {
        return debtInfoLabelValue('debt', used);
    }

    // refundable amount
    if (used < 0) {
        return debtInfoLabelValue('refundable', Math.abs(used));
    }
    // no debt, no refundable amount
    return debtInfoLabelValue('no-debt', used);
};

type Props = {
    accountRef: string;
    account: AccountDetail;
};

const CloseAccountTab = (props: Props) => {
    const {
        accountRef,
        account: { accountHolder, used, allowAccountClose, status },
    } = props;

    const noOfAccountHolders = (accountHolder && accountHolder.length) || 1;

    const url = `/account/${accountRef}/terminate`;
    const backUrl = '/account/overview';

    const isAccountTerminated = status === 'TERMINATED';

    if (isAccountTerminated) return <TerminateAccountSuccessPanel backUrl={backUrl} />;

    return (
        <div className="account-close-account-tab">
            <Panel className="account-terminate-intro" key="account-terminate-panel" stretchInMobile>
                <h3>{i18n('account.account-information.terminate.header')}</h3>
                {noOfAccountHolders === 2 ? (
                    <PanelContent>
                        {i18n(`account.account-information.terminate.info-text-2-account-holders`, 'p', {
                            danger: true,
                        })}
                    </PanelContent>
                ) : (
                    <>
                        <PanelContent>
                            {i18n('account.account-information.terminate.info-text', 'p', {
                                danger: true,
                            })}
                        </PanelContent>
                        <PanelContent centered>
                            <div className="account-amount-info mtb-4x">{getDebtInfo(used)}</div>
                        </PanelContent>
                        <PanelContent>
                            <ButtonGroup alignCenter spaceBelow={false}>
                                <Button
                                    disabled={!allowAccountClose}
                                    outline
                                    round
                                    onClick={() => (window.location.hash = url)}
                                    id="start-terminate-account"
                                >
                                    {i18n('account.account-information.terminate.header')}
                                </Button>
                            </ButtonGroup>
                        </PanelContent>
                    </>
                )}
            </Panel>
        </div>
    );
};

CloseAccountTab.defaultProps = {
    accountRef: null,
    account: null,
};

export default CloseAccountTab;
