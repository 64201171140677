import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Button, ButtonGroup, LabelValue, Panel, PanelContent } from '@ecster/components';
import './Transactions.scss';

import { formatAmount, formatDate, formatDateLong } from '../../../../common/util/format';

const showTransactions = () => {
    // const url = `${window.location.hash}/specification`;
    // window.location.hash = url;
    window.location.hash += '/specification';
};

const formatAmount00 = amount => formatAmount(amount, { strip00: false });

export const Transactions = ({ bill }) => {
    const {
        balanceBroughtForward,
        balanceCarriedForward,
        invoiceDate,
        invoicePeriod: { to, from },
        totalInterestAndCharges,
        totalPayments,
        totalTransaction,
    } = bill;
    return (
        <div className="account-invoice-transactions">
            <Panel>
                <PanelContent>
                    <h2 className="h3 centered">
                        <span>{i18n('invoice.account-invoices.invoice-period')}</span>
                        <br />
                        <span>{` ${formatDateLong(from)} -`}</span>
                        <span>{` ${formatDateLong(to)}`}</span>
                    </h2>
                    <div>
                        {balanceBroughtForward < 0 ? (
                            <LabelValue
                                label={i18n('invoice.account-invoices.transactions.credited-balance-brought-forward')}
                                value={formatAmount00(-1 * balanceBroughtForward)}
                            />
                        ) : (
                            <LabelValue
                                label={i18n('invoice.account-invoices.transactions.balance-brought-forward')}
                                value={formatAmount00(balanceBroughtForward)}
                            />
                        )}
                        <LabelValue
                            label={i18n('invoice.account-invoices.transactions.total-payments')}
                            value={formatAmount00(totalPayments)}
                        />
                        <LabelValue
                            label={i18n('invoice.account-invoices.transactions.total-transaction')}
                            value={formatAmount00(totalTransaction)}
                        />
                        <LabelValue
                            label={i18n('invoice.account-invoices.transactions.total-interest-and-charges')}
                            value={formatAmount00(totalInterestAndCharges)}
                        />
                        {balanceCarriedForward < 0 ? (
                            <LabelValue
                                label={i18n('invoice.account-invoices.transactions.credited-balance-carried-forward')}
                                value={formatAmount00(-1 * balanceCarriedForward)}
                            />
                        ) : (
                            <LabelValue
                                label={i18n('invoice.account-invoices.transactions.balance-carried-forward', {
                                    date: formatDate(invoiceDate),
                                })}
                                value={formatAmount(balanceCarriedForward)}
                            />
                        )}
                    </div>
                    <ButtonGroup alignCenter spaceBelow={false}>
                        <Button id="see-all-tx" outline round onClick={showTransactions}>
                            {i18n('invoice.account-invoices.transactions.see-all')}
                        </Button>
                    </ButtonGroup>
                </PanelContent>
            </Panel>
        </div>
    );
};

Transactions.propTypes = {
    bill: PropTypes.object.isRequired,
};
