import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { formatAmount } from '@ecster/util';

export const DueAmount = ({ amount }) => (
    <LabelValue
        label={i18n('invoice.invoice-values.due-amount')}
        value={
            <CopyToClipboardButton
                id="copyDueAmount"
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-amount')}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={`${amount / 100}`}
            >
                {formatAmount(amount, { strip00: true, decimals: 2 })}
            </CopyToClipboardButton>
        }
    />
);
DueAmount.propTypes = { amount: PropTypes.number.isRequired };
