import { get } from '@ecster/net/v2';

import {
    CUSTOMER_GET_CUSTOMER_PROPERTY_BEGIN,
    CUSTOMER_GET_CUSTOMER_PROPERTY_SUCCESS,
    CUSTOMER_GET_CUSTOMER_PROPERTY_FAILURE,
    CUSTOMER_GET_CUSTOMER_PROPERTY_DISMISS_ERROR,
} from './constants';
import { GET_CUSTOMER_PROPERTY_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_CUSTOMER_PROPERTY_STATE = 'CLEAR_GET_CUSTOMER_PROPERTY_STATE';

export const getCustomerProperty = (customerId, property) => async dispatch => {
    dispatch({
        type: CUSTOMER_GET_CUSTOMER_PROPERTY_BEGIN,
        property,
    });

    try {
        const key = GET_CUSTOMER_PROPERTY_URL(customerId, property);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: CUSTOMER_GET_CUSTOMER_PROPERTY_SUCCESS,
            property,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getCustomerProperty');

        dispatch({
            type: CUSTOMER_GET_CUSTOMER_PROPERTY_FAILURE,
            property,
            data: { error: err },
        });
    }
};

export const dismissGetCustomerPropertyError = property => ({
    type: CUSTOMER_GET_CUSTOMER_PROPERTY_DISMISS_ERROR,
    property,
});

export const clearGetCustomerPropertyState = property => ({ type: CLEAR_GET_CUSTOMER_PROPERTY_STATE, property });

const toCamelCase = prop =>
    prop && `_${prop}`.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('_', ''));

/** get the name of the rekit error state property */
export const getRekitErrorPropertyName = property => `getCustomerProperty${toCamelCase(property)}Error`;

/** get the name of the rekit pending state property */
export const getRekitPendingPropertyName = property => `getCustomerProperty${toCamelCase(property)}Pending`;

export function reducer(state, action) {
    const pendingKey = getRekitPendingPropertyName(action.property);
    const errorKey = getRekitErrorPropertyName(action.property);

    switch (action.type) {
        case CUSTOMER_GET_CUSTOMER_PROPERTY_BEGIN:
            return {
                ...state,
                [pendingKey]: true,
                [errorKey]: null,
                getCustomerPropertyIsDone: false,
                getCustomerPropertyIsError: false,
            };

        case CUSTOMER_GET_CUSTOMER_PROPERTY_SUCCESS:
            return {
                ...state,
                [action.property]: action.data.properties[0].value,
                [pendingKey]: false,
                [errorKey]: null,
                getCustomerPropertyIsDone: true,
            };

        case CUSTOMER_GET_CUSTOMER_PROPERTY_FAILURE:
            return {
                ...state,
                [pendingKey]: false,
                [errorKey]: action.data.error,
                getCustomerPropertyIsError: true,
            };

        case CUSTOMER_GET_CUSTOMER_PROPERTY_DISMISS_ERROR:
            return {
                ...state,
                [errorKey]: null,
                getCustomerPropertyIsError: false,
            };

        case CLEAR_GET_CUSTOMER_PROPERTY_STATE:
            return {
                ...state,
                [action.property]: null,
                [pendingKey]: false,
                [errorKey]: null,
                getCustomerPropertyIsDone: false,
                getCustomerPropertyIsError: false,
            };
        default:
            return state;
    }
}
