import React, { useEffect, useState } from 'react';
import { get } from '@ecster/net/v2';
import NotificationPanel from './NotificationPanel';
import { reportError } from './reportError';
import { NETXResponse } from '../types/NETX';

type Notification = {
    id: number;
    title: string;
    message: string;
    validFrom: string;
    validTo?: string;
    forcePopup: boolean;
    locale: string;
};

const GlobalNotificationsPanel = ({ onlyForcePopup = false }) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [locale, setLocale] = useState<string>(window.EcsterConfig.locale);
    const [intervalId, setIntervalId] = useState<any>();

    const getNotifications = async () => {
        try {
            const notifications = await get<NETXResponse<Notification[]>>(
                `/rest/ess/customers/v1/messages?locale=${window.EcsterConfig.locale}`
            );
            setNotifications(notifications.response);
        } catch (e) {
            reportError(e, 'GlobalNotificationsPanel');
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            if (window.EcsterConfig.locale !== locale) {
                setLocale(window.EcsterConfig.locale);
                getNotifications();
            }
        }, 300);

        setIntervalId(id);
    }, [locale]);

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    const filteredNotifications = notifications.filter(el => (onlyForcePopup ? el.forcePopup === true : true));

    return notifications.length > 0 && <NotificationPanel notifications={filteredNotifications} />;
};

export default GlobalNotificationsPanel;
