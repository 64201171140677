import { formatNumberNew } from './number';
import { getOptionsWithCurrency } from './get-options';

/**
 * Format amount with currency correctly based on locale
 *
 * @param amount {number} IMPORTANT: expected in "cents", will be divided by 100 before formatting
 * @param options {object} an options object (accepts a locale string, bw compatible use)
 * @param currencyIfLocale currency if bw compatibility use
 * @param optionsIfLocale options object if bw compatible use
 * @param global {object} window is default, pass only when testing
 */
export const formatAmount = (amount, options, currencyIfLocale, optionsIfLocale, global = window) => {
    // note: 3rd param is for formatNumber bw compatibility
    //    must send undef so global can be passed in 4th param for jasmine tests to work :-/

    if (typeof options === 'string') {
        const opt = getOptionsWithCurrency(
            { ...optionsIfLocale, currency: currencyIfLocale, locale: options, style: 'currency' },
            global
        );
        return formatNumberNew(amount / 100, opt, undefined, global);
    }

    if (!options && (currencyIfLocale || optionsIfLocale)) {
        const opt = getOptionsWithCurrency(
            { ...optionsIfLocale, currency: currencyIfLocale, style: 'currency' },
            global
        );
        return formatNumberNew(amount / 100, opt, undefined, global);
    }

    return formatNumberNew(
        amount / 100,
        getOptionsWithCurrency({ ...options, style: 'currency' }, global),
        undefined,
        global
    );
};
