import { forwardRef, useImperativeHandle } from 'react';
import { EventsType, IIdleTimer, IIdleTimerProps, useIdleTimer } from 'react-idle-timer';

type IdleProps = {
    /**
     * Events to bind.
     */
    events?: EventsType[];
} & Pick<
    IIdleTimerProps,
    | 'onIdle'
    | 'onAction'
    | 'onActive'
    | 'timeout'
    | 'debounce'
    | 'throttle'
    | 'eventsThrottle'
    | 'element'
    | 'startOnMount'
    | 'startManually'
    | 'stopOnIdle'
    | 'crossTab'
>;

const Idle = forwardRef<{ idleTimer: IIdleTimer }, IdleProps>(
    (
        {
            onIdle,
            onAction,
            onActive,
            events,
            timeout,
            debounce,
            throttle,
            eventsThrottle,
            element,
            startOnMount,
            startManually,
            stopOnIdle,

            crossTab,
        },
        ref
    ) => {
        const timer = useIdleTimer({
            onIdle,
            onAction,
            onActive,
            events,
            timeout,
            debounce,
            throttle,
            eventsThrottle,
            element,
            startOnMount,
            startManually,
            stopOnIdle,
            crossTab,
        });

        useImperativeHandle(ref, () => ({ idleTimer: timer }), [timer]);
        return null;
    }
);

export default Idle;
