import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

interface CheckboxProps {
    children?: ReactNode;
    id?: string;
    name?: string;
    onChange: Function;
    checked: boolean;
    className?: string;
    required?: boolean;
    validationMessage?: string;
}

class Checkbox extends React.Component<CheckboxProps> {
    state = {
        hasErrors: false,
    };

    checkboxEl = React.createRef();

    checkboxRef = React.createRef<HTMLInputElement>();

    checkboxTextRef = React.createRef<HTMLDivElement>();

    onBlur = () => {
        this.doValidation();
    };

    onChange = e => {
        const { onChange } = this.props;

        this.doValidation();
        onChange(e);
    };

    doValidation() {
        const isValid = this.checkboxRef.current.checkValidity();
        this.setState({ hasErrors: !isValid });
        return isValid;
    }

    render() {
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        const { children, className, required, checked, validationMessage, ...rest } = this.props;

        const { hasErrors } = this.state;

        const classes = classNames({
            'ec-checkbox': true,
            'has-error': hasErrors,
            [className]: className,
        });

        const classesStyle = classNames({
            'ec-checkbox__style': true,
            'no-text': !children,
        });

        return (
            <div className={classes}>
                <label className="ec-checkbox__label">
                    <input
                        {...rest}
                        onChange={this.onChange}
                        type="checkbox"
                        required={required}
                        checked={checked}
                        ref={this.checkboxRef}
                    />
                    <span className={classesStyle}>
                        <i className="icon-check" />
                    </span>
                    <span className="ec-checkbox__label" ref={this.checkboxTextRef}>
                        {children}
                    </span>
                </label>
                <div className="ec-checkbox-error">{validationMessage}</div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
Checkbox.defaultProps = {
    children: null,
    id: '',
    name: '',
    className: '',
    // validation
    required: false,
    validationMessage: 'Du måste kryssa i denna',
};

export default Checkbox;
