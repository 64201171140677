import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Pwd from '@ecster/util/lib/password-strength';
import Input, { InputProps, InputRef } from './Input';

import './PasswordField.scss';

interface PasswordFieldPropsBase extends InputProps {
    showIndicator?: boolean;
    showTextIndicator?: boolean;
    minLevel?: number;
}

type PasswordFieldProps = Omit<PasswordFieldPropsBase, 'type' | 'min' | 'max' | 'pattern'>;

const PasswordField = forwardRef<InputRef, PasswordFieldProps>(
    ({ showIndicator, showTextIndicator, minLevel = 0, ...props }, ref) => {
        const pwdStrength = Pwd.strength(props.value);

        return (
            <Input
                ref={ref}
                {...props}
                type="password"
                validator={(value) => {
                    if (Pwd.strength(value).level > minLevel) {
                        return props.validator ? props.validator(value) : true;
                    }
                    return false;
                }}
            >
                <span>
                    {showIndicator && (
                        <div
                            className={classNames({
                                'e-pwd-field__meter': true,
                                [pwdStrength.name]: true,
                            })}
                        />
                    )}

                    {showTextIndicator && pwdStrength.level !== 0 && (
                        <div className={classNames('e-pwd-field__indicator-text', pwdStrength.name)}>
                            {
                                {
                                    1: 'mycket svagt',
                                    2: 'svagt',
                                    3: 'medel',
                                    4: 'starkt',
                                    5: 'mycket starkt',
                                }[pwdStrength.level]
                            }
                        </div>
                    )}
                </span>
            </Input>
        );
    }
);

export default PasswordField;
