import { useEffect, useState } from 'react';
import { measure, subscribe, unSubscribe } from './screen';

export type Media = {
    onMobile: boolean;
    onTablet: boolean;
    onDesktop: boolean;
};

export const useMediaQueries = (): Media => {
    const [media, setMedia] = useState<Media>(() => {
        const measurement = measure();
        return {
            onMobile: measurement.isMobile,
            onTablet: measurement.isTablet,
            onDesktop: measurement.isDesktop,
        };
    });

    useEffect(() => {
        const onResize = screenSize => {
            setMedia({
                onMobile: screenSize.isMobile,
                onTablet: screenSize.isTablet,
                onDesktop: screenSize.isDesktop,
            });
        };

        const screenSize = measure();
        onResize(screenSize);

        subscribe(onResize);

        return () => {
            unSubscribe(onResize);
        };
    }, []);

    return media;
};
