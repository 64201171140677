/* eslint-disable react/no-danger */
import React from 'react';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent } from '@ecster/components';

export default class BlockCardPanel extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    state = {};

    render() {
        return (
            <Panel paddingTopBottom="xLarge" centeredContent stretchInMobile yellowBG className="card-block-card-panel">
                <PanelContent>
                    <h1 className="h3">{i18n('card.block-card.header')}</h1>
                    <p>
                        <span dangerouslySetInnerHTML={{ __html: i18n('card.block-card.info') }} />
                    </p>
                </PanelContent>
            </Panel>
        );
    }
}
