import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Message } from '@ecster/components';
import { formatAmount } from '../../../../common/util/format';
import './PaymentMessage.scss';

export const PaymentMessage = ({ bill, billProps }) => {
    let header;
    let text;

    const amount = formatAmount(bill.amountPaidByCustomer);

    if (billProps.paidBetweenLab1Lab2 && bill.hasPartPayments) {
        header = i18n('invoice.account-invoices.payment-message.between-lab1-lab2.header', { amount });
        text = i18n('invoice.account-invoices.payment-message.between-lab1-lab2.text');
    }
    if (billProps.paidMoreLab2 && bill.hasPartPayments) {
        header = i18n('invoice.account-invoices.payment-message.more-than-lab2.header', { amount });
        text = i18n('invoice.account-invoices.payment-message.more-than-lab2.text', {
            amount: formatAmount(bill.amountPaidByCustomer - billProps.partPayment),
        });
    }

    if (!header || !text) return null;

    return <Message blue header={header} message={text} className="account-invoice-payment-message" />;
};

PaymentMessage.propTypes = {
    bill: PropTypes.object.isRequired,
    billProps: PropTypes.object.isRequired,
};
