// @ts-nocheck
// Created by jobo15 2018-05-03

/**
 * Check if media queries matches particular devices.
 * Assumes that anything IE/Edge is a Desktop device
 * Assumes that anything that can hover is a desktop device (or, in case of Firefox that touch isn't enabled)
 * (Firefox will soon support hover:hover)
 *
 * - This also works for Desktops with touch screens (Except for FireFox on touchscreen Desktops)
 *
 * NOTE! This does NOT update if the screen size changes and that's by design.
 * Which means that if you're testing using an emulator you will need to reload as you change device.
 * This will not be an issue on real devices...
 *
 * @returns {{isDesktop: boolean, isTablet: boolean, isMobile: boolean, isAndroid: boolean, isIOS: boolean}}
 */

const detectDevice = () => {
    const mobileMaxHeight = 896;
    const mobileMaxWidth = 980;

    const tabletLargeMaxWidth = 1366;
    const tabletLargeMinWidth = 824;

    const tabletMaxWidth = 1024;
    const tabletMinWidth = 600;
    const ext = 'px';

    const isMobile = window.matchMedia(
        `all and (hover: none) and (max-device-width: ${mobileMaxWidth +
            ext}) and (max-device-height: ${mobileMaxHeight + ext}) and (orientation: portrait),
        all and (any-hover: none) and (max-device-width: ${mobileMaxWidth +
            ext}) and (max-device-height: ${mobileMaxHeight + ext}) and (orientation: portrait),
            all and (any-pointer: coarse) and (max-device-width: ${mobileMaxWidth +
                ext}) and (max-device-height: ${mobileMaxHeight + ext}) and (orientation: portrait),
        all and (-moz-touch-enabled: 1) and (max-device-width: ${mobileMaxWidth +
            ext}) and (max-device-height: ${mobileMaxHeight}) and (orientation: portrait),
        all and (hover: none) and (max-device-width: ${mobileMaxWidth + ext}) and (orientation: landscape),
        all and  (any-hover: none) and (max-device-width: ${mobileMaxWidth + ext}) and (orientation: landscape),
            all and (any-pointer: coarse) and (max-device-width: ${mobileMaxWidth + ext}) and (orientation: landscape),
        all and (-moz-touch-enabled: 1) and (max-device-width: ${mobileMaxWidth + ext}) and (orientation: landscape)`
    ).matches;

    const isTablet = window.matchMedia(
        `all and (hover: none) and (min-device-width: ${tabletLargeMinWidth +
            ext}) and (max-device-width: ${tabletLargeMaxWidth + ext}) and (orientation: landscape),
   all and (-moz-touch-enabled: 1) and (min-device-width: ${tabletLargeMinWidth +
       ext}) and (max-device-width: ${tabletLargeMaxWidth + ext}) and (orientation: landscape),
   all and (hover: none) and (min-device-width: ${tabletMinWidth + ext}) and (max-device-width: ${tabletMaxWidth +
            ext}) and (orientation: portrait),
   all and (-moz-touch-enabled: 1) and (min-device-width: ${tabletMinWidth +
       ext}) and (max-device-width: ${tabletMaxWidth + ext}) and (orientation: portrait)`
    ).matches;

    const isDesktop =
        window.matchMedia(
            `all and (hover: hover),
   not all and (-moz-touch-enabled: 1),
   (-ms-high-contrast: active),
   (-ms-high-contrast: none)`
        ).matches &&
        !isMobile &&
        !isTablet;

    // detect OS
    const isAndroid = /android/i.test(window.navigator.userAgent);

    const isIOS =
        (/iPad|iPhone|iPod/i.test(window.navigator.userAgent) ||
            (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)) &&
        !window.MSStream;

    return {
        isDesktop,
        isTablet,
        isMobile,
        isAndroid,
        isIOS,
    };
};

export default detectDevice;
