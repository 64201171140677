import React, { ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';
import './AutoScrollContainer.scss';

interface AutoScrollContainerProps {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;

    /**
     * "height" must be an integer
     */
    height: number;

    /**
     * "maxHeight" must be an integer
     */
    maxHeight: number;
    minHeight?: number;
}

/**
 * At least one of "height" or "maxHeight" must be specified
 */
class AutoScrollContainer extends React.Component<AutoScrollContainerProps> {
    render() {
        const { style, height, maxHeight, minHeight, className, children } = this.props;

        const styleProps = {
            ...style,
            height,
            maxHeight,
            minHeight,
        };

        return (
            <div
                className={classNames({
                    'ec-auto-scroll-ctr': true,
                    [className]: className,
                })}
                style={styleProps}
            >
                {children}
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
AutoScrollContainer.defaultProps = {
    style: {},
    className: undefined,
    height: undefined,
    maxHeight: undefined,
    minHeight: undefined,
};

export default AutoScrollContainer;
