import React, { MouseEventHandler, ReactNode } from 'react';
import Snackbar from './Snackbar';
import ModalMessage from './ModalMessage';
import FullscreenDialog from './FullscreenDialog';

import './MessagePanel.scss';

interface MessagePanelProps {
    // snackbar
    showSnackbar: boolean;
    snackbarMessage: string;
    snackbarError: boolean;

    // modal message
    showModalMessage: boolean;
    modalMessage: string;
    modalMessageType: 'ERROR' | 'INFO' | 'WARNING' | 'CONFIRM';
    modalMessageHeader?: string;
    hideModalMessage: Function;
    modalMessageSubmit?: Function;

    // fullscreen dialog
    showFullscreenDialog: boolean;
    hideFullscreenDialog: MouseEventHandler<HTMLButtonElement>;
    fullscreenDialogBody?: ReactNode;
    closeButtonLabel?: string;
}

class MessagePanel extends React.Component<MessagePanelProps> {
    render() {
        const {
            showSnackbar,
            snackbarMessage,
            snackbarError,
            showModalMessage,
            modalMessageHeader,
            modalMessage,
            modalMessageType,
            hideModalMessage,
            modalMessageSubmit,
            showFullscreenDialog,
            hideFullscreenDialog,
            fullscreenDialogBody,
            closeButtonLabel,
        } = this.props;

        const buttonCloselabel =
            closeButtonLabel !== ''
                ? closeButtonLabel
                : window.EcsterConfig && window.EcsterConfig.applicationCountry === 'FI'
                ? 'Sulje ohjeikkuna'
                : 'Stäng hjälpfönstret';

        return (
            <div className="ec-message-panel message-panel">
                <div className="message-panel__middle">
                    <Snackbar
                        show={showSnackbar}
                        message={snackbarMessage}
                        error={snackbarError}
                    />
                </div>
                <div className="message-panel__overlay">
                    <ModalMessage
                        show={showModalMessage}
                        header={modalMessageHeader}
                        message={modalMessage}
                        type={modalMessageType}
                        hideModalMessage={hideModalMessage}
                        onSubmit={modalMessageSubmit}
                    />
                    <FullscreenDialog
                        show={showFullscreenDialog}
                        onClose={hideFullscreenDialog}
                        body={fullscreenDialogBody}
                        closeButtonLabel={buttonCloselabel}
                    />
                </div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
MessagePanel.defaultProps = {
    modalMessageHeader: null,
    fullscreenBody: null,
    closeButtonLabel: '',
    snackbarError: false,
};

export default MessagePanel;
