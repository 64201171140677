// prop validation was added, but...
// TODO: split into separate files
// @ts-nocheck
/* eslint-disable react/prop-types,import/prefer-default-export,no-else-return */
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Table from './Table';
import TableHeader from './TableHeader';
import TableHeaderColumn from './TableHeaderColumn';
import TableRow from './TableRow';
import TableBody from './TableBody';
import TableRowColumn from './TableRowColumn';

import { Mobile, MobileOrTablet, TabletOrDesktop } from '../MediaQuery/MediaQuery';
import './ResponsiveTable.scss';

const conditionalRender = (column, notOnMobile, onlyOnMobile) => {
    if (notOnMobile) {
        return <TabletOrDesktop>{column}</TabletOrDesktop>;
    } else if (onlyOnMobile) {
        return <Mobile>{column}</Mobile>;
    }
    return column;
};

interface ResponsiveTableProps {
    /**
     * Child elements: ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableFooter
     */
    children: ReactNode;

    /** dom id */
    id?: string;

    /** extra css classes */
    className?: string;
}

/**
 * ResponsiveTable
 */
export const ResponsiveTable = ({ children, className, ...rest }: ResponsiveTableProps) => {
    const classes = classNames({
        'ec-responsive-table': true,
        [className]: className,
    });

    return (
        <div className={classes}>
            <Table {...rest} className="responsive-table__table">
                {children}
            </Table>
        </div>
    );
};

ResponsiveTable.propTypes = {
    /**
     * Child elements: ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableFooter
     */
    children: PropTypes.node.isRequired,

    /** dom id */
    id: PropTypes.string,

    /** extra css classes */
    className: PropTypes.string,
};

ResponsiveTable.defaultProps = { id: '', className: '' };

interface ResponsiveTableRowColumnProps {
    /** dom id */
    id?: string;

    /** extra css classes */
    className?: string;

    /** cell content */
    children: ReactNode;

    /** hide on mobile */
    notOnMobile?: boolean;

    /** show on mobile, hide on desktop */
    onlyOnMobile?: boolean;
    label?: string;

    /**
     * Display block in cell content container
     * @deprecated use classes and css instead
     */
    block?: boolean;
}

/**
 * ResponsiveTableRowColumn
 */
export const ResponsiveTableRowColumn = ({
    children,
    notOnMobile,
    onlyOnMobile,
    label,
    block,
    ...rest
}: ResponsiveTableRowColumnProps) => {
    const blockStyle = block ? { display: 'block' } : {};
    const column = (
        <TableRowColumn {...rest} style={{ verticalAlign: 'top', ...rest.style, ...blockStyle }}>
            {label && (
                <MobileOrTablet>
                    <div style={{ color: '#777', fontSize: '0.9em' }}>{label}</div>
                </MobileOrTablet>
            )}
            {children}
        </TableRowColumn>
    );

    if (notOnMobile) {
        return <TabletOrDesktop>{column}</TabletOrDesktop>;
    } else if (onlyOnMobile) {
        return <Mobile>{column}</Mobile>;
    }
    return column;
};

ResponsiveTableRowColumn.propTypes = {
    /** dom id */
    id: PropTypes.string,

    /** extra css classes */
    className: PropTypes.string,

    /** cell content */
    children: PropTypes.node.isRequired,

    /** hide on mobile */
    notOnMobile: PropTypes.bool,

    /** show on mobile, hide on desktop */
    onlyOnMobile: PropTypes.bool,

    label: PropTypes.string,

    /**
     * Display block in cell content container
     * @deprecated use classes and css instead
     */
    block: PropTypes.bool,
};

ResponsiveTableRowColumn.defaultProps = {
    id: '',
    className: '',
    block: false,
    notOnMobile: false,
    onlyOnMobile: false,
};

interface ResponsiveTableHeaderColumnProps {
    /** dom id */
    id?: string;

    /** extra css classes */
    className?: string;

    /** cell content */
    children: ReactNode;

    /** hide on mobile */
    notOnMobile?: boolean;

    /** show on mobile, hide on desktop */
    onlyOnMobile?: boolean;
    label?: string;
}
/**
 * ResponsiveTableHeaderColumn
 */
export const ResponsiveTableHeaderColumn = ({
    children,
    notOnMobile,
    onlyOnMobile,
    ...rest
}: ResponsiveTableHeaderColumnProps) => {
    const column = <TableHeaderColumn {...rest}>{children}</TableHeaderColumn>;

    return conditionalRender(column, notOnMobile, onlyOnMobile);
};

ResponsiveTableHeaderColumn.propTypes = {
    /** dom id */
    id: PropTypes.string,

    /** extra css classes */
    className: PropTypes.string,

    /** cell content */
    children: PropTypes.node.isRequired,

    /** hide on mobile */
    notOnMobile: PropTypes.bool,

    /** show on mobile, hide on desktop */
    onlyOnMobile: PropTypes.bool,

    label: PropTypes.string,
};

ResponsiveTableHeaderColumn.defaultProps = {
    id: '',
    className: '',
    notOnMobile: false,
    onlyOnMobile: false,
};

interface ResponsiveTableHeaderProps {
    /** CSS id */
    id?: string;

    /** Additional css classes */
    className?: string;

    /** Child elements: [TableRow](#tablerow) */
    children: ReactNode;

    /** White bg and gray text */
    white?: boolean;

    /** hide on mobile */
    notOnMobile?: boolean;

    /** show on mobile, hide on desktop */
    onlyOnMobile?: boolean;
}

/**
 * ResponsiveTableHeader
 */
export const ResponsiveTableHeader = ({ children, notOnMobile, onlyOnMobile, ...rest }: ResponsiveTableHeaderProps) => {
    const column = <TableHeader {...rest}>{children}</TableHeader>;

    return conditionalRender(column, notOnMobile, onlyOnMobile);
};

ResponsiveTableHeader.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Additional css classes */
    className: PropTypes.string,

    /** Child elements: [TableRow](#tablerow) */
    children: PropTypes.node.isRequired,

    /** White bg and gray text */
    white: PropTypes.bool,

    /** hide on mobile */
    notOnMobile: PropTypes.bool,

    /** show on mobile, hide on desktop */
    onlyOnMobile: PropTypes.bool,
};

ResponsiveTableHeader.defaultProps = {
    id: '',
    className: '',
    white: false,
    notOnMobile: false,
    onlyOnMobile: false,
};

interface ResponsiveTableRowProps {
    /** CSS id */
    id?: string;

    /** Additional css classes */
    className?: string;

    /** Child elements: [TableRowColumn](#tablerowcolumn), [TableHeaderColumn](#tableheadercolumn) */
    children: ReactNode;
    onClick?: Function;
    onMouseEnter?: Function;
    onMouseLeave?: Function;

    /** hide on mobile */
    notOnMobile?: boolean;

    /** show on mobile, hide on desktop */
    onlyOnMobile?: boolean;
}

/**
 * ResponsiveTableRow
 */
export const ResponsiveTableRow = ({ children, notOnMobile, onlyOnMobile, ...rest }: ResponsiveTableRowProps) => {
    const column = <TableRow {...rest}>{children}</TableRow>;

    return conditionalRender(column, notOnMobile, onlyOnMobile);
};

ResponsiveTableRow.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Additional css classes */
    className: PropTypes.string,

    /** Child elements: [TableRowColumn](#tablerowcolumn), [TableHeaderColumn](#tableheadercolumn) */
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,

    /** hide on mobile */
    notOnMobile: PropTypes.bool,

    /** show on mobile, hide on desktop */
    onlyOnMobile: PropTypes.bool,
};

ResponsiveTableRow.defaultProps = {
    id: '',
    onClick: undefined,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
    className: '',
    notOnMobile: false,
    onlyOnMobile: false,
};

const GroupHeader = title => (
    <ResponsiveTableRow>
        <ResponsiveTableHeaderColumn style={{ background: '#f8f6f4', padding: '2px' }} colSpan={100}>
            {title}
        </ResponsiveTableHeaderColumn>
    </ResponsiveTableRow>
);

interface ResponsiveTableBodyProps {
    /** CSS id */
    id?: string;

    /** Child elements: [TableRow](#tablerow) */
    children: ReactNode;

    /** Additional css classes */
    className?: string;

    /** hide on mobile */
    notOnMobile?: boolean;

    /** show on mobile, hide on desktop */
    onlyOnMobile?: boolean;
}

/**
 * ResponsiveTableBody
 */
export const ResponsiveTableBody = ({ children, notOnMobile, onlyOnMobile, ...rest }: ResponsiveTableBodyProps) => {
    const column = <TableBody {...rest}>{children}</TableBody>;

    return conditionalRender(column, notOnMobile, onlyOnMobile);
};

ResponsiveTableBody.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Child elements: [TableRow](#tablerow) */
    children: PropTypes.node.isRequired,

    /** Additional css classes */
    className: PropTypes.string,

    /** hide on mobile */
    notOnMobile: PropTypes.bool,

    /** show on mobile, hide on desktop */
    onlyOnMobile: PropTypes.bool,
};

ResponsiveTableBody.defaultProps = {
    id: '',
    className: '',
    notOnMobile: false,
    onlyOnMobile: false,
};

interface GroupedResponsiveTableBodyProps {
    groupBy: Function;
    children: ReactNode;
}

/**
 * GroupedResponsiveTableBody - group rows by some groupBy criteria
 */
export const GroupedResponsiveTableBody = ({ groupBy, ...rest }: GroupedResponsiveTableBodyProps) => {
    const childrenArray = React.Children.toArray(rest.children);
    const groupedArray = [];
    if (groupBy.length !== 0) {
        childrenArray.forEach((child, index) => {
            if (index === 0 || groupBy[index].key !== groupBy[index - 1].key) {
                groupedArray.push(
                    <MobileOrTablet key={groupBy[index].key}>{GroupHeader(groupBy[index].value)}</MobileOrTablet>
                );
            }
            groupedArray.push(child);
        });
    }
    return (
        <ResponsiveTableBody {...rest}>{groupedArray.length === 0 ? childrenArray : groupedArray}</ResponsiveTableBody>
    );
};

GroupedResponsiveTableBody.propTypes = {
    groupBy: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
};
