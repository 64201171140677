import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { UserMessagePanel, Spinner } from '@ecster/components';
import { walletIcon } from '@ecster/icons/H80/green';

import InvoicePanelV1 from './InvoicePanelV1';
import AuthenticatedPageTemplate from '../../common/templates/AuthenticatedPageTemplate';
import { getInvoices, clearGetInvoicesState } from '../redux/actions';
import { getContracts } from '../../contract/redux/actions';
import { mapOneOffInvoices, mapAccountInvoices, mapContractInvoices } from './mapInvoicesV1';
import { Redirect } from 'react-router-dom';

export const InvoiceOverviewPage = () => {
    const dispatch = useDispatch();

    // account redux state
    const { accounts, accountBills: bills } = useSelector(state => state.account, shallowEqual);

    // invoice redux state
    const { invoices = [], getInvoicesIsDone } = useSelector(state => state.invoice, shallowEqual);

    // contract redux state
    const { contracts, getContractsIsDone } = useSelector(state => state.contract, shallowEqual);

    // authentication redux state
    const {
        person: { id: customerId },
    } = useSelector(state => state.authentication, shallowEqual);

    // didMount
    useEffect(() => {
        dispatch(getInvoices(customerId));
        dispatch(getContracts(customerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // willUnmount
    useEffect(() => {
        return () => {
            dispatch(clearGetInvoicesState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isInvoiceV2Api = invoices.some(inv => inv.hasOwnProperty('paymentStatus'));

    if (getInvoicesIsDone && (isInvoiceV2Api || invoices.length === 0)) {
        return <Redirect to="/customer/v2/invoices" />;
    }

    // one off invoices
    const oneOffInvoices = getInvoicesIsDone ? mapOneOffInvoices(invoices, customerId) : [];

    // account invoices
    const accountInvoices = mapAccountInvoices(bills, accounts, customerId);

    // contract invoices
    const contractInvoices = getContractsIsDone ? mapContractInvoices(contracts) : [];

    // all invoices
    const allInvoices = [...oneOffInvoices, ...accountInvoices, ...contractInvoices];

    return (
        <AuthenticatedPageTemplate
            className="invoice-invoice-page"
            header={i18n('invoice.invoice-overview.page-header')}
        >
            {getInvoicesIsDone && getContractsIsDone ? (
                allInvoices.length > 0 ? (
                    <InvoicePanelV1 invoices={allInvoices} />
                ) : (
                    <UserMessagePanel
                        header={i18n('invoice.invoice-overview.no-invoice.header')}
                        icon={walletIcon}
                        body={<p>{i18n('invoice.invoice-overview.no-invoice.info')}</p>}
                    />
                )
            ) : (
                <Spinner id="invoice-overview-spinner" isCenter isVisible />
            )}
        </AuthenticatedPageTemplate>
    );
};

export default InvoiceOverviewPage;
