import React from 'react';
import { Link, matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RRProvider } from '@ecster/context';

const ReactRouterProvider = ({ location, children }) => {
    return (
        <RRProvider Link={Link} matchPath={matchPath} location={location}>
            {children}
        </RRProvider>
    );
};

ReactRouterProvider.propTypes = {
    location: PropTypes.shape({}).isRequired,
    children: PropTypes.node.isRequired,
};

export default withRouter(ReactRouterProvider);
