/* eslint-disable react/prop-types */
import React, { ComponentProps } from 'react';
import Responsive from 'react-responsive';
import { tabletStart } from './breakpoints';
import '../scss/MediaQuery.scss';

export const TabletOrDesktop = ({ children = null, ...rest }: ComponentProps<typeof Responsive>) => (
    <Responsive minWidth={tabletStart} {...rest}>
        {children}
    </Responsive>
);

