// Max allowed amount to be automatically dispersed
const MAX_PAYOUT_LIMIT = 2000000;

/**
 * Makes sure the sum isn't larger than the current limit
 * for it to be automatically dispersed.
 * @param amount {number} a POSITIVE surplus amount to compare with a max payout limit
 * @returns boolean
 */
export const isValidSurplusAmount = amount => {
    return amount > 0 && amount <= MAX_PAYOUT_LIMIT;
};
