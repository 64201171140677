import { Badge } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import React from 'react';

import { isSE, isFI } from '../../common/country';

export const emailIsMissing = contactInfo => !contactInfo || !contactInfo.email;

export const phoneIsMissing = contactInfo =>
    !contactInfo ||
    !contactInfo.phoneNumber ||
    !contactInfo.phoneNumber.countryCallingCode ||
    !contactInfo.phoneNumber.number;

export const emailIsComplete = contactInfo => !emailIsMissing(contactInfo);

export const phoneIsComplete = contactInfo => !phoneIsMissing(contactInfo);

const getBillProps = ({ payment, amountPaidByCustomer = 0 }, { hasBill, lab1, lab2, lab3 }) => {
    let data = {};

    const paidAmount = amountPaidByCustomer;

    const dataTemplate = {
        amountPaidByCustomer,
        hasBill,
        dueDate: hasBill ? payment.dueDate : '',
        hasNotPaid: paidAmount === 0 && lab1 !== 0 && lab3 > 0,
        hasPaidTooLittle: lab1 > 0 && (!paidAmount || paidAmount < lab1),
        canPaySomeAmount: (!paidAmount || paidAmount === 0) && lab1 === 0 && lab3 > 0,

        lab1,
        lab2,
        lab3,
        minimumPayment: lab1, // alt name for lab1
        partPayment: lab2, // alt name for lab2
        fullPayment: lab3, // alt name for lab3

        paidLab1: paidAmount > 0 && paidAmount === lab1,
        paidLab2: paidAmount > 0 && paidAmount === lab2,
        paidLab3: paidAmount > 0 && paidAmount === lab3,

        paidLessLab1: paidAmount > 0 && paidAmount < lab1,
        paidLessLab2: paidAmount > 0 && paidAmount < lab2,
        paidLessLab3: paidAmount > 0 && paidAmount < lab3,
        paidBetweenLab1Lab3: paidAmount > 0 && lab1 <= paidAmount && paidAmount < lab3,
        paidBetweenLab1Lab2: paidAmount > 0 && lab1 <= paidAmount && paidAmount < lab2,
        paidBetweenLab2Lab3: paidAmount > 0 && lab2 <= paidAmount && paidAmount < lab3,
        paidMoreLab2: paidAmount > 0 && lab2 < paidAmount,
        paidMoreLab3: paidAmount > 0 && lab3 < paidAmount,
        allLabsEqual: payment && lab1 === lab2 && lab2 === lab3,
    };

    if (isSE()) {
        const hasPaidSomeAmount = lab1 > 0 && lab1 <= paidAmount && paidAmount < lab3;

        data = {
            ...dataTemplate,
            hasPaidAll: paidAmount > 0 && lab3 <= paidAmount,
            isZeroInvoice: payment && lab1 === lab2 && lab2 === lab3 && lab3 === 0,
        };

        // data.paidLessLab2 = data.paidLessLab1 || data.paidLab1 || data.paidBetweenLab1Lab2;
        // data.paidLessLab3 = data.paidLessLab2 || data.paidBetweenLab2Lab3;

        if (data.hasNotPaid || data.paidLessLab1) {
            data.badge = (
                <Badge dark purple>
                    {i18n('invoice.general.unpaid')}
                </Badge>
            );
            data.paymentStatus = 'NOT_PAID';
        } else if (hasPaidSomeAmount || data.paidBetweenLab2Lab3) {
            data.badge = (
                <Badge dark iconLeft="icon-check">
                    {i18n('invoice.general.partly-paid')}
                </Badge>
            );
            data.paymentStatus = 'PARTIALLY_PAID';
        } else if (data.hasPaidAll && !data.isZeroInvoice) {
            data.badge = (
                <Badge dark iconLeft="icon-check">
                    {i18n('invoice.general.paid')}
                </Badge>
            );
            data.paymentStatus = 'PAID';
        } else {
            data.badge = null;
            data.paymentStatus = undefined;
        }
    }
    if (isFI()) {
        data = {
            ...dataTemplate,
            hasPaidAll: paidAmount > 0 && lab2 <= paidAmount,
            isZeroInvoice: (payment && lab1 === lab2 && lab2 === 0) || !payment,
        };

        if (paidAmount < lab1) {
            data.badge = (
                <Badge dark purple>
                    {i18n('invoice.general.unpaid')}
                </Badge>
            );
            data.paymentStatus = 'NOT_PAID';
        } else if (data.lab1 <= paidAmount && !data.isZeroInvoice) {
            data.badge = (
                <Badge dark iconLeft="icon-check">
                    {i18n('invoice.general.paid')}
                </Badge>
            );
            data.paymentStatus = 'PAID';
        } else {
            data.badge = null;
            data.paymentStatus = undefined;
        }
    }

    return data;
};

export const getBillProperties = bill => {
    if (!bill) return {};

    const { payment, ocrNumber } = bill;

    const hasBill = ocrNumber && payment;

    const lab1 = hasBill && payment.options.find(o => o.type === 'MINIMUMPAYMENT').amount;
    const lab2 = hasBill && payment.options.find(o => o.type === 'PARTPAYMENT').amount;
    const lab3 = hasBill && payment.options.find(o => o.type === 'FULLPAYMENT').amount;

    return getBillProps(bill, { hasBill, lab1, lab2, lab3 });
};
