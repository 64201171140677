import React from 'react';
import classNames from 'classnames';
import { clearingNumberValidator } from './validators';
import Input, { InputRef } from './Input';

import './ClearingNumberInput.scss';

const nordea = 'Nordea';
const shb = 'Handelsbanken';
const seb = 'SEB';
const swedbank = 'Swedbank';
const sparbanken = 'Sparbanken';

const betweenIncluding = (x, min, max) => x >= min && x <= max;

const clearingBetween = (x, min, max) => {
    // in all comments below: "between" means "between or including"

    const factor = min >= 10000 ? 10 : 1;

    // one digit value between first digit of min and max?
    // 5, 5000, 6000 => 5 between 5 and 6? yes
    if (x < 10) {
        const min1 = Math.floor(min / (1000 * factor));
        const max1 = Math.floor(max / (1000 * factor));
        return betweenIncluding(x, min1, max1);
    }
    // two digit value between first two digits of min and max?
    // 15, 1100, 1400 => 15 between 11 and 14? no
    if (x < 100) {
        const min2 = Math.floor(min / (100 * factor));
        const max2 = Math.floor(max / (100 * factor));
        return betweenIncluding(x, min2, max2);
    }
    // three digit value between first three digits of min and max?
    // 650, 6400, 6999 => 650 between 640 and 699? yes
    if (x < 1000) {
        const min3 = Math.floor(min / (10 * factor));
        const max3 = Math.floor(max / (10 * factor));
        return betweenIncluding(x, min3, max3);
    }
    // four digit value between first four digits of min and max?
    // 6501, 6400, 6999 => 650 between 640 and 699? yes
    if (x < 10000) {
        const min3 = Math.floor(min / factor);
        const max3 = Math.floor(max / factor);
        return betweenIncluding(x, min3, max3);
    }
    // sparbanken, five digit clearing numbers
    if (x >= 80000) {
        return betweenIncluding(x, min, max);
    }
    return false;
};

const autoSelectBank = (clearingNumber) => {
    if (Number.isNaN(Number.parseInt(clearingNumber, 10))) return '';

    if (clearingBetween(clearingNumber, 1100, 1199)) return nordea;
    if (clearingBetween(clearingNumber, 1401, 2099)) return nordea;
    if (clearingBetween(clearingNumber, 3000, 3399)) return nordea;
    if (clearingBetween(clearingNumber, 3410, 4999)) return nordea;
    if (clearingBetween(clearingNumber, 5000, 5999)) return seb;
    if (clearingBetween(clearingNumber, 6000, 6999)) return shb;
    if (clearingBetween(clearingNumber, 7000, 7999)) return swedbank;
    if (clearingBetween(clearingNumber, 8000, 8999)) return sparbanken;
    if (clearingBetween(clearingNumber, 80000, 89999)) return sparbanken;
    if (clearingBetween(clearingNumber, 9120, 9124)) return seb;
    if (clearingBetween(clearingNumber, 9130, 9149)) return seb;

    return '';
};

const random = () => Math.floor(Math.random() * 1000000);

interface ClearingNumberInputProps {
    value: {
        clearingNumber: string;
        bank: string;
    };
    onChange?: Function;
    label?: string;
    labelBank?: string;
    placeholderBank?: string;
    validationMessage?: string;
    validationMessageBank?: string;
    row?: boolean;
    column?: boolean;
    required?: boolean;
}

class ClearingNumberInput extends React.Component<ClearingNumberInputProps> {
    state = {
        clearingNumber: '',
        bankIsAutoSelected: true,
    };

    clearingNumberRef = React.createRef<InputRef>();

    bankRef = React.createRef<InputRef>();

    clearingCssId = `clearing-number-${random()}`;

    bankCssId = `bank-${random()}`;

    componentDidMount() {
        const {
            value: { clearingNumber },
            onChange,
        } = this.props;

        if (clearingNumber) {
            const bank = autoSelectBank(clearingNumber);
            if (bank) {
                onChange({ clearingNumber, bank });
                this.setState({ clearingNumber, bankIsAutoSelected: true });
            } else {
                this.setState({ clearingNumber, bankIsAutoSelected: false });
            }
        }
    }

    onChangeClearingNumber = ({ target }) => {
        const { onChange } = this.props;

        const clearingNumber = target.value;
        const autoSelectedBank = autoSelectBank(clearingNumber);

        if (autoSelectedBank) {
            onChange({ clearingNumber, bank: autoSelectedBank });
            this.setState({ clearingNumber, bankIsAutoSelected: true });
        } else {
            onChange({ clearingNumber, bank: '' });
            this.setState({ clearingNumber, bankIsAutoSelected: false });
        }
    };

    onChangeBank = ({ target }) => {
        const { onChange } = this.props;
        const { clearingNumber } = this.state;
        onChange({ clearingNumber, bank: target.value });
    };

    validateClearingNumber = (num) => {
        const { required } = this.props;
        if (!required && !num) return true;
        return clearingNumberValidator(num);
    };

    doValidation = () => {
        const {
            value: { clearingNumber },
        } = this.props;
        const { bankIsAutoSelected } = this.state;
        return (
            this.clearingNumberRef.current.doValidation() &&
            this.validateClearingNumber(clearingNumber) &&
            (bankIsAutoSelected || (!bankIsAutoSelected && this.bankRef.current.doValidation()))
        );
    };

    render() {
        const { bankIsAutoSelected } = this.state;
        const {
            label,
            labelBank,
            placeholderBank,
            validationMessage,
            validationMessageBank,
            value: { clearingNumber, bank },
            required,
            row,
            column,
            ...rest
        } = this.props;

        const classes = classNames({
            'ec-clearing-number-input': true,
            'column-layout': column && !row,
            'row-layout': row,
            'show-bank-input': !bankIsAutoSelected && clearingNumber,
            'show-bank-label': bankIsAutoSelected && clearingNumber,
            'has-labels': !!label && !!labelBank,
        });

        return (
            <div className={classes}>
                {!label && (
                    <label htmlFor={this.clearingCssId} className="hidden">
                        Clearingnummer
                    </label>
                )}
                <Input
                    {...rest}
                    id={this.clearingCssId}
                    type="tel"
                    value={clearingNumber}
                    name="clearingNumber"
                    label={label}
                    onChange={this.onChangeClearingNumber}
                    autoComplete="off"
                    maxLength={5}
                    minLength={4}
                    validator={this.validateClearingNumber}
                    validationMessage={validationMessage}
                    className="clearing-no-input"
                    ref={this.clearingNumberRef}
                    required={required}
                />
                {bankIsAutoSelected && clearingNumber && <div className="bank-label">{bank}</div>}
                {!bankIsAutoSelected && clearingNumber && (
                    <>
                        {!labelBank && (
                            <label htmlFor={this.bankCssId} className="hidden">
                                Bank
                            </label>
                        )}
                        <Input
                            id={this.bankCssId}
                            value={bank}
                            name="bank"
                            label={labelBank}
                            placeholder={placeholderBank}
                            required
                            onChange={this.onChangeBank}
                            autoComplete="off"
                            validationMessage={validationMessageBank}
                            className="bank-name-input"
                            ref={this.bankRef}
                        />
                    </>
                )}
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
ClearingNumberInput.defaultProps = {
    label: '',
    labelBank: '',
    placeholderBank: '',
    validationMessage: 'Ange ett korrekt clearingnummer',
    validationMessageBank: 'Ange namnet på din bank',
    onChange: () => {},
    required: false,
    row: false,
    column: true,
};

export default ClearingNumberInput;

