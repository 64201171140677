import React from 'react';

export const SvgIconUpdateVersion = () => (
    <div className="svg-icon--update-version">
        <svg xmlns="http://www.w3.org/2000/svg" width="109" height="117">
            <defs>
                <filter id="a" width="151.1%" height="151.1%" x="-25.6%" y="-25.6%" filterUnits="objectBoundingBox">
                    <feOffset dx="4" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4" />
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <path
                    id="b"
                    d="M23 0h44c8 0 10.9.8 13.8 2.4a16.4 16.4 0 0 1 6.8 6.8c1.6 3 2.4 5.9 2.4 13.9v43.8c0 8-.8 11-2.4 13.9a16.4 16.4 0 0 1-6.8 6.8c-3 1.6-5.9 2.4-13.9 2.4H23.1c-8 0-11-.8-13.9-2.4a16.4 16.4 0 0 1-6.8-6.8C.8 77.8 0 74.9 0 66.9V23.1c0-8 .8-11 2.4-13.9a16.4 16.4 0 0 1 6.8-6.8C12.2.8 15.1 0 23.1 0z"
                />
                <linearGradient id="d" x1="124.3%" x2="-15.7%" y1="9%" y2="99.7%">
                    <stop offset="0%" stopColor="#59B189" />
                    <stop offset="68.7%" stopColor="#AEEFAA" />
                    <stop offset="100%" stopColor="#FBFFC1" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g filter="url(#a)" transform="translate(4 15)">
                    <mask id="c" fill="#fff">
                        <use xlinkHref="#b" />
                    </mask>
                    <use fill="#D8D8D8" xlinkHref="#b" />
                    <g mask="url(#c)">
                        <path fill="url(#d)" d="M0 8h153v137H0z" transform="rotate(90 88.5 46.5)" />
                        <path fill="#F0EDE8" d="M135 103H-18V-34h153z" />
                        <g fillRule="nonzero" transform="translate(9 9)">
                            <circle cx="36.8" cy="36.8" r="36.8" fill="#59B189" />
                            <path
                                fill="#FFF"
                                d="M26 46.6c.6 1.2 1.5 2.3 2.7 3.2a14 14 0 0 0 4.3 2.3 16.8 16.8 0 0 0 15-2.3 3.6 3.6 0 1 1 4.5 5.6 21 21 0 0 1-6.4 3.5 24 24 0 0 1-7.7 1.2 24 24 0 0 1-7.7-1.2 21 21 0 0 1-6.5-3.5c-1.9-1.6-3.4-3.4-4.5-5.4a14.6 14.6 0 0 1 0-13.8l.2-.3-.2-.3a14.6 14.6 0 0 1 0-13.8c1-2 2.6-3.9 4.5-5.4a21 21 0 0 1 6.5-3.5 24 24 0 0 1 7.7-1.3c2.6 0 5.2.4 7.7 1.3 2.4.8 4.5 2 6.4 3.5a3.6 3.6 0 1 1-4.5 5.5 14 14 0 0 0-4.2-2.3 16.9 16.9 0 0 0-5.4-.8c-2 0-3.7.2-5.4.8a14 14 0 0 0-4.3 2.3c-1.2 1-2 2-2.7 3.2a7.6 7.6 0 0 0-.8 5 21.4 21.4 0 0 1 5.5-2.8 24 24 0 0 1 7.7-1.3c2.6 0 5.2.5 7.7 1.3a21 21 0 0 1 6.7 3.8 6.2 6.2 0 0 1 2.4 4.8 6.2 6.2 0 0 1-3.3 5.5 21.2 21.2 0 0 1-5.8 3 24 24 0 0 1-7.7 1.3 24 24 0 0 1-7.7-1.3 21.4 21.4 0 0 1-5.5-2.7 7.5 7.5 0 0 0 .8 5z"
                            />
                            <path
                                fill="#63C29D"
                                d="M29.6 36.5a14.2 14.2 0 0 0 3.6 1.8 16.8 16.8 0 0 0 10.8 0 14.2 14.2 0 0 0 3.7-1.8 14.2 14.2 0 0 0-3.7-1.8 16.9 16.9 0 0 0-5.4-.9c-1.9 0-3.7.3-5.4.9a14.2 14.2 0 0 0-3.6 1.8z"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(65)">
                    <rect width="44" height="28" fill="#CD3264" rx="14" />
                    <path
                        fill="#FFF"
                        fillRule="nonzero"
                        d="M21.2 9.1V20h-2L14 14v6h-2.8V9.1h2.1l5.3 6.1v-6h2.6zm12.4 0l-4.3 7.6V20h-2.7v-3.3l-4.3-7.6h3L28 14 30.6 9h3z"
                    />
                </g>
            </g>
        </svg>
    </div>
);
