const data = new Map();

export const cache = {
    init: () => {
        console.info('Cache initialized...');
    },
    get: async key =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(data.get(key));
            }, 0);
        }),
    set: (key, val) => {
        data.set(key, val);
    },
    delete: key => {
        data.delete(key);
    },
    clear: () => {
        data.clear();
    },
};
