import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogHeader, DialogBody, InteractiveElement, Icon } from '@ecster/components';
import { i18n } from '@ecster/i18n';

export default class OcrScan extends React.Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        ocrNumber: PropTypes.string,
        bgNumber: PropTypes.string.isRequired,
        amount: PropTypes.number,
    };

    static defaultProps = {
        amount: null,
        ocrNumber: 'unknown',
    };

    state = {
        showOcrScan: false,
    };

    checksum = (amount, ocrNumber) => {
        if (amount !== 0 && !amount) return '';
        return ocrNumber.slice(-1);
    };

    formatAmount = amount => {
        if (amount !== 0 && !amount) return '';

        const intPart = Math.floor(amount / 100);
        const decPart = `${amount}`.slice(-2);
        return `${intPart} ${decPart}`;
    };

    toggleShowOcrScan = () => {
        const { showOcrScan } = this.state;
        this.setState({ showOcrScan: !showOcrScan });
    };

    formatBg = bg => bg.replace(/[^0-9]/g, '');

    nbsp = value => value.replace(/./g, '\u00a0');

    render() {
        const { text, ocrNumber, bgNumber, amount } = this.props;
        const { showOcrScan } = this.state;

        return (
            <div className="invoice-ocr-scan">
                <InteractiveElement
                    className="open-ocr-scan-button"
                    onClick={this.toggleShowOcrScan}
                    ariaLabel={i18n('invoice.ocr-scan.text-aria-label')}
                >
                    <Icon icon="icon-camera" green invert />
                    <span>
                        {text} <i className="icon-chevron-right" />
                    </span>
                </InteractiveElement>

                {showOcrScan && (
                    <Dialog open onRequestClose={this.toggleShowOcrScan}>
                        <DialogHeader ariaLabelClose={i18n('general.close')}>{text}</DialogHeader>
                        <DialogBody centeredContent>
                            <p className="ocr-scan-info">
                                <Icon icon="icon-camera" green />
                                {i18n('invoice.ocr-scan.info')}
                            </p>
                            <div className="ocr-scan ocr-b">
                                <div className="ocr-scan-start">H{this.nbsp('...')}#</div>
                                <div className="ocr-scan-ocr-no">{ocrNumber.replace(/ /g, '')} #</div>
                                <div className="ocr-scan-amount">
                                    {this.formatAmount(amount)}
                                    {this.nbsp('...')}
                                    {this.checksum(amount, ocrNumber)}
                                    {' >'}
                                </div>
                                <div className="ocr-scan-bg-no">
                                    {this.formatBg(bgNumber)}#{amount ? '41' : '42'}#
                                </div>
                            </div>
                        </DialogBody>
                    </Dialog>
                )}
            </div>
        );
    }
}

// doc: https://www.bankgirot.se/globalassets/dokument/anvandarmanualer/bankgiroinbetalningar_anvandarmanual_sv.pdf
