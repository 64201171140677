import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { Spinner, FadeIn, GeneralErrorMessagePanel } from '@ecster/components';
import { Redirect } from 'react-router-dom';
import AuthenticatedPageTemplate from '../common/templates/AuthenticatedPageTemplate';

import {
    getCustomer,
    dismissGetCustomerError,
    updateCustomerContactInfo,
    dismissUpdateCustomerContactInfoError,
    clearUpdateCustomerContactInfoState,
} from './redux/actions';

import ChangeLanguageContext from './profile-page/FI/ChangeLanguageContext';

import ContactInfoPanel from './profile-page/ContactInfoPanel';
import { changeLanguage } from '../common/changeLanguage';

class ProfilePage extends Component {
    componentDidMount() {
        const { customerId, getCustomer, hasAccounts } = this.props;

        getCustomer(customerId, hasAccounts);
    }

    componentWillUnmount() {
        const { clearUpdateCustomerContactInfoState } = this.props;

        clearUpdateCustomerContactInfoState();
    }

    render() {
        const {
            customer,
            dismissGetCustomerError,
            dismissUpdateCustomerContactInfoError,
            hasAccounts,
            person,
            updateCustomerContactInfo,
            updateCustomerContactInfoError,
            updateCustomerContactInfoPending,
            getCustomer,
            getCustomerError,
            getCustomerPending,
        } = this.props;

        if (!hasAccounts) {
            return <Redirect to="/account/overview" />;
        }

        if (getCustomerError || updateCustomerContactInfoError) {
            return (
                <AuthenticatedPageTemplate header={i18n('customer.profile.header')}>
                    <GeneralErrorMessagePanel
                        header={i18n('general.error.general.header')}
                        body={i18n('general.error.general.body')}
                        error={getCustomerError}
                        dismissError={dismissGetCustomerError || dismissUpdateCustomerContactInfoError}
                        buttonText={i18n('general.back')}
                        isLink
                    />
                </AuthenticatedPageTemplate>
            );
        }

        const dataAvailable = customer && customer.name && customer.contactInformation;

        return (
            <AuthenticatedPageTemplate header={i18n('customer.profile.header')}>
                <div className="customer-profile-page">
                    {dataAvailable ? (
                        <FadeIn show={!!dataAvailable}>
                            <ChangeLanguageContext.Provider
                                value={async locale => {
                                    await changeLanguage(locale);
                                    this.forceUpdate();
                                }}
                            >
                                <ContactInfoPanel
                                    customer={customer}
                                    person={person}
                                    hasAccounts={hasAccounts}
                                    updateCustomerContactInfo={updateCustomerContactInfo}
                                    updateCustomerContactInfoError={updateCustomerContactInfoError}
                                    updateCustomerContactInfoPending={updateCustomerContactInfoPending}
                                    getCustomer={getCustomer}
                                    getCustomerError={getCustomerError}
                                    getCustomerPending={getCustomerPending}
                                />
                            </ChangeLanguageContext.Provider>
                        </FadeIn>
                    ) : (
                        <Spinner id="profile-spinner" isVisible isCenterPage />
                    )}
                </div>
            </AuthenticatedPageTemplate>
        );
    }
}

ProfilePage.propTypes = {
    person: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,
    getCustomer: PropTypes.func.isRequired,
    getCustomerError: PropTypes.shape({
        action: PropTypes.string.isRequired,
        message: PropTypes.object.isRequired,
    }),
    getCustomerPending: PropTypes.bool.isRequired,

    updateCustomerContactInfoError: PropTypes.shape({
        action: PropTypes.string.isRequired,
        message: PropTypes.object.isRequired,
    }),
    updateCustomerContactInfoPending: PropTypes.bool.isRequired,
    dismissGetCustomerError: PropTypes.func.isRequired, // eslint-disable-line
    dismissUpdateCustomerContactInfoError: PropTypes.func.isRequired, // eslint-disable-line
    clearUpdateCustomerContactInfoState: PropTypes.func.isRequired, // eslint-disable-line
    updateCustomerContactInfo: PropTypes.func.isRequired,
    hasAccounts: PropTypes.bool.isRequired,
};
ProfilePage.defaultProps = {
    getCustomerError: null,
    updateCustomerContactInfoError: null,
};

/* istanbul ignore next */
function mapStateToProps({ authentication, account, customer }) {
    return {
        person: authentication.person,
        customer: customer.customer,
        customerId: authentication.person.id,
        getCustomerError: customer.getCustomerError,
        getCustomerPending: customer.getCustomerPending,
        updateCustomerContactInfoError: customer.updateCustomerContactInfoError,
        updateCustomerContactInfoPending: customer.updateCustomerContactInfoPending,
        hasAccounts: !account.hasZeroAccounts,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getCustomer: (customerId, customerHasAccounts) => dispatch(getCustomer(customerId, customerHasAccounts)),
        dismissGetCustomerError: () => dispatch(dismissGetCustomerError()),
        dismissUpdateCustomerContactInfoError: () => dispatch(dismissUpdateCustomerContactInfoError()),
        clearUpdateCustomerContactInfoState: () => dispatch(clearUpdateCustomerContactInfoState()),
        updateCustomerContactInfo: (customerId, customerHasAccounts, data) =>
            dispatch(updateCustomerContactInfo(customerId, customerHasAccounts, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
