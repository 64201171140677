import React, { ComponentProps } from 'react';
import Responsive from 'react-responsive';
import { mobileEnd } from './breakpoints';
import '../scss/MediaQuery.scss';

export const Mobile = ({ children = null, ...rest }: ComponentProps<typeof Responsive>) => (
    <Responsive maxWidth={mobileEnd} {...rest}>
        {children}
    </Responsive>
);

