/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../Input';
import { useKey } from '../hooks/use-key';
import { useMultiSelect } from '../hooks/use-multi-select';
import { KEY } from '../lib/constants';
import { debounce } from '../lib/debounce';
import { filterOptions } from '../lib/simple-match-utils';
import SelectItem from './select-item';
import SelectList from './select-list';

enum FocusType {
    SEARCH = 0,
    NONE = -1,
}

const SelectPanel = ({ setExpanded }) => {
    const {
        t,
        onChange,
        options,
        value,
        filterOptions: customFilterOptions,
        ItemRenderer,
        disabled,
        disableSearch,
        hasSelectAll,
        ClearIcon,
        debounceDuration,
    } = useMultiSelect();

    const listRef = useRef<any>();
    const searchInputRef = useRef<any>();
    const [searchText, setSearchText] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchTextForFilter, setSearchTextForFilter] = useState('');
    const [focusIndex, setFocusIndex] = useState(0);
    const debouncedSearch = useCallback(
        debounce(query => setSearchTextForFilter(query), debounceDuration),
        []
    );

    const skipIndex = useMemo(() => {
        let start = 0;

        if (!disableSearch) start += 1; // if search is enabled then +1 to skipIndex
        if (hasSelectAll) start += 1; // if select-all is enabled then +1 to skipIndex

        return start;
    }, [disableSearch, hasSelectAll]);

    const selectAllValues = checked => {
        const filteredValues = filteredOptions.filter(o => !o.disabled).map(o => o.value);

        if (checked) {
            const selectedValues = value.map(o => o.value);
            const finalSelectedValues = [...selectedValues, ...filteredValues];

            return (customFilterOptions ? filteredOptions : options).filter(o => finalSelectedValues.includes(o.value));
        }

        return value.filter(o => !filteredValues.includes(o.value));
    };

    const selectAllChanged = (checked: boolean) => {
        const newOptions = selectAllValues(checked);
        onChange(newOptions);
    };

    const handleSearchChange = e => {
        debouncedSearch(e.target.value);
        setSearchText(e.target.value);
        setFocusIndex(FocusType.SEARCH);
    };

    const handleItemClicked = (index: number) => setFocusIndex(index);

    // Arrow Key Navigation
    const handleKeyDown = e => {
        switch (e.code) {
            case KEY.ARROW_UP:
                updateFocus(-1);
                break;
            case KEY.ARROW_DOWN:
                updateFocus(1);
                break;
            default:
                return;
        }
        e.stopPropagation();
        e.preventDefault();
    };

    useKey([KEY.ARROW_DOWN, KEY.ARROW_UP], handleKeyDown, {
        target: listRef,
    });

    const handleSearchFocus = () => {
        setFocusIndex(FocusType.SEARCH);
    };

    const getFilteredOptions = async () =>
        customFilterOptions
            ? customFilterOptions(options, searchTextForFilter)
            : filterOptions(options, searchTextForFilter);

    const updateFocus = (offset: number) => {
        let newFocus = focusIndex + offset;
        newFocus = Math.max(0, newFocus);
        newFocus = Math.min(newFocus, options.length + Math.max(skipIndex - 1, 0));
        setFocusIndex(newFocus);
    };

    useEffect(() => {
        listRef?.current?.querySelector(`[tabIndex='${focusIndex}']`)?.focus();
    }, [focusIndex]);

    const [isAllOptionSelected, hasSelectableOptions] = useMemo(() => {
        const filteredOptionsList = filteredOptions.filter(o => !o.disabled);
        return [
            filteredOptionsList.every(o => value.findIndex(v => v.value === o.value) !== -1),
            filteredOptionsList.length !== 0,
        ];
        // eslint-disable-next-line
    }, [filteredOptions, value]);

    useEffect(() => {
        getFilteredOptions().then(setFilteredOptions);
    }, [searchTextForFilter, options]);

    /* const creationRef: any = useRef();
    useKey([KEY.ENTER], handleOnCreateOption, { target: creationRef }); */

    return (
        <div className="select-panel" role="listbox" ref={listRef}>
            {!disableSearch && (
                <div className="search">
                    <SelectItem
                        name="all"
                        tabIndex={skipIndex === 1 ? 0 : 1}
                        checked={hasSelectableOptions && isAllOptionSelected}
                        onSelectionChanged={selectAllChanged}
                        onClick={() => handleItemClicked(1)}
                        itemRenderer={ItemRenderer}
                        noLabel
                        disabled={disabled}
                    />
                    <Input
                        placeholder={t('filter')}
                        type="text"
                        small
                        aria-describedby={t('search')}
                        onChange={handleSearchChange}
                        onFocus={handleSearchFocus}
                        value={searchText}
                        ref={searchInputRef}
                        tabIndex={0}
                        className="search-field"
                        iconClass="icon-search e-green"
                    />
                    <button
                        type="button"
                        className="search-clear-button"
                        onClick={() => setExpanded(false)}
                        aria-label={t('clearSearch')}
                    >
                        {ClearIcon || <i className="icon-cross" />}
                    </button>
                </div>
            )}

            <ul className="options">
                {filteredOptions.length ? (
                    <SelectList
                        skipIndex={skipIndex}
                        options={filteredOptions}
                        onClick={(_e, index) => handleItemClicked(index)}
                    />
                ) : (
                    <li className="no-options">{t('noOptions')}</li>
                )}
            </ul>
            <div className="arrow" />
        </div>
    );
};

export default SelectPanel;

SelectPanel.propTypes = {
    setExpanded: PropTypes.func.isRequired,
}
