import React from 'react';
import PropTypes from 'prop-types';

export default class SimpleSummaryPanel extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        amount: PropTypes.string.isRequired,
    };

    static defaultProps = {
        label: '',
    };

    state = {};

    render() {
        const { label, amount } = this.props;

        return (
            <section className="account-simple-summary-panel">
                <span>{label}</span>
                <span className="amount">{amount}</span>
            </section>
        );
    }
}
