import React, { ReactNode } from 'react';
import classNames from 'classnames';
/* eslint-disable react/default-props-match-prop-types */
// eslint-disable-next-line import/no-unresolved
import dayjs from 'dayjs';
import _ from 'lodash';
import { post, get, put } from '@ecster/net/v2';
import { bankIdColorIcon, bankIdWhiteIcon, bankIdLightIcon } from '@ecster/icons/bankid';
import Button from '../Clickable/Button';
import LinkButton from '../Clickable/LinkButton';
import ButtonGroup from '../Clickable/ButtonGroup';
import Input, { InputRef } from '../Input/Input';
import Spinner from '../Spinner/Spinner';
import { detectDevice } from '../DeviceDetect/DeviceTypes';
// @ts-ignore
import { persNrValidator as validateSsn} from '../Input/validators';
import './BankIdAuthentication.scss';
import Dialog, { DialogBody } from '../Dialog';
import Panel from '../Panel';

const pollAgainStatus = ['STARTED', 'OUTSTANDING_TRANSACTION', 'NO_CLIENT', 'USER_SIGN'];
const stopPollStatus = [
    'COMPLETE',
    'EXPIRED_TRANSACTION',
    'CERTIFICATE_ERROR',
    'USER_CANCEL',
    'CANCELLED',
    'START_FAILED',
    'TECHNICAL_ERROR',
];
const MAX_POLL_ERRORS = 3;

export const status = () => pollAgainStatus.concat(stopPollStatus);

// login methods, values used in i18n keys
const MBID_THIS = 'mbid-this-device';
const MBID_OTHER = 'mbid-other-device';
const BID_THIS = 'bid-this-device';

const STATUS_INIT = 'init';
const STATUS_POLLING = 'poll';
const STATUS_SUCCESS = 'success';
const STATUS_FAILURE = 'failure';

let i18n;

const defaultMethods = {
    desktop: { thisDevice: false, otherDevice: true },
    tablet: { thisDevice: false, otherDevice: true },
    mobile: { thisDevice: true, otherDevice: false },
};

interface BankIdAuthenticationState {
    ssn: string;
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
    error: any;
    status: string;
    showStartBankIdLink: boolean;
    tooYoung: boolean;
    defaultSelectedMethod: string | undefined;
    selectedMethod: string | undefined;
    isOtherDevice: boolean | undefined;
    texts: Array<string>;
}

const defaultState = {
    ssn: '',
    isDesktop: detectDevice().isDesktop,
    isMobile: detectDevice().isMobile,
    isTablet: detectDevice().isTablet,
    error: null,
    status: STATUS_INIT,
    showStartBankIdLink: false,
    tooYoung: false, // true if minAge set and user is too young
    defaultSelectedMethod: undefined,
    selectedMethod: undefined,
    isOtherDevice: undefined,
};

interface BankIdAuthenticationProps {
    /**
     * Always show SSN input for apps so Apple and Google can review
     */
    isReviewMode?: boolean;

    /**
     * callback when bankid login process starts
     */
    onStart: Function;

    /**
     * Called before submit is executed.
     *
     * Starts BankID login if function returns true
     *
     * Does not start BankID login if function returns false
     */
    onBeforeSubmit?: Function;

    /**
     * callback for bankid login success
     */
    onSuccess: Function;

    /**
     * callback for bankid login failure
     */
    onFailure: Function;

    /**
     * Listen to changes to bankid polling state
     */
    onStateChange?: Function;

    /**
     * callback when user opens bankid app
     */
    onOpenApp?: Function;

    /**
     * callback when ssn input field changes
     */
    onChangeSSN?: Function;

    /**
     * callback when BankId login has timed out
     */
    onTimeout?: Function;

    /**
     * Called when user confirms bankid login failure (only if showUserFeedback is true)
     */
    onUserConfirmedFailure?: Function;

    /**
     * callback when BankId login is cancelled
     */
    onCancelLogin?: Function;

    /**
     * callback when a create session succeeds. The rest result is passed in to the function.
     */
    onCreateSessionSuccess?: Function;

    /**
     * callback when an elevate session succeeds. The rest result is passed in to the function.
     */
    onElevateSessionSuccess?: Function;

    /**
     * URL to go to when user cancels login, if empty, login will be cancelled but GUI won't change
     */
    cancelReturnUrl?: string;

    /**
     * Stack input field and button on top of each other
     */
    stack?: boolean;

    /**
     * Elevate an existing session to a "strong session" with BankID authentication
     */
    elevateSession?: boolean;

    /**
     * The button text. A default value based on language is used if not specified
     */
    buttonText?: ReactNode;

    /**
     * The button text for mobile bankid on other device. A default value based on language is used if not specified
     */
    mobileBankIdOtherDeviceButtonText?: ReactNode;

    /**
     * The link text for BankId on this device. A default value based on language is used if not specified
     */
    bankIdThisDeviceLinkText?: ReactNode;

    /**
     * The link text for Mobile BankId on this device. A default value based on language is used if not specified
     */
    mobileBankIdThisDeviceLinkText?: ReactNode;

    /**
     * The link text for Mobile BankId on other device. A default value based on language is used if not specified
     */
    mobileBankIdOtherDeviceLinkText?: ReactNode;

    /**
     *  Use float label on ssn input field, placeholder will not be used.
     *  Default is false.
     */
    useFloatLabel?: boolean;

    /**
     * Label for ssn field. A default value based on language is used if not specified
     */
    ssnLabel?: string;

    /**
     * placeholder for ssn field. A default value based on language is used if not specified
     */
    ssnPlaceholder?: string;

    /**
     * validation message for ssn field. A default value based on language is used if not specified
     */
    ssnValidationMsg?: ReactNode;

    /**
     * true or 'color' for default color icon, or an img url
     *
     * false for no icon
     *
     * 'white' for white icon
     *
     * 'light' for bankId light color icon
     */
    buttonIcon?: ReactNode | boolean | 'white' | 'light' | 'color';

    /**
     * Extra properties for button, use to override default styling (outline, bankId color, 8px rounded corners etc)
     *
     * See Button props documentation
     */
    buttonProps?: object;

    /**
     * Extra properties for button links, use to override default styling. ex color
     *
     * See Button props documentation
     */
    buttonLinkProps?: object;

    // authentication methods

    methods?: object;

    // optional user feedback

    /**
     * Show user feedback within this component?
     */
    showUserFeedback?: boolean;

    /**
     * The preferred presentation language for
     * - user feedback (if showUserFeedback is set to true)
     * - GUI elements (unless texts are sent in as props)
     */
    language?: 'sv' | 'en';

    /**
     * Message to show when login succeeds and showUserFeedback is true
     *
     * Default text based on language is shown if empty
     */
    loginSuccessMsg?: ReactNode;

    /**
     * Message to show when login fails and showUserFeedback is true
     *
     * Default text based on language is shown if empty
     */
    loginFailureMsg?: ReactNode;

    /**
     * Text for 'go back' link shown when login fails and showUserFeedback is true
     *
     * Default text based on language is shown if empty
     */
    loginFailureLinkText?: ReactNode;

    /**
     * Minimum age for logging in
     */
    minAge?: number;

    /**
     * Validation message if user is younger than minAge. A default value based on language is used if not specified
     */
    minAgeValidationMessage?: ReactNode;

    /**
     * Message to show when bankId login process starts
     *
     * Default text based on language is shown if empty
     */
    bankIdStartMsg?: ReactNode;

    /**
     * Focus input when SSN is empty after clicking on login button
     * Default is false.
     */
    focusInputWhenSsnIsEmpty?: boolean;

    /**
     * Focus input on mount. defaults to false
     */
    autoFocusSsnInputOnMount?: boolean;

    /**
     * Dont validate empty ssn. defaults to false
     */
    dontValidateEmptySsn?: boolean;

    /**
     * Trim ssn-input. defaults to false.
     */
    trimSSN?: boolean;

    /**
     * Optional mapper function that takes the bankid url as string parameter and returns url string
     * ex: can be used to add &redirect=null or &redirect=ecsterlogin://
     */
    urlMapper?: (url: string) => string;

    // colors

    /** green button and links */
    green?: boolean;
    /** blue button and links */
    blue?: boolean;
    /** purple button and links */
    purple?: boolean;
    /** red button and links */
    red?: boolean;
    /** beige button and links */
    beige?: boolean;
    /** gray button and links */
    gray?: boolean;
    /** light gray button and links */
    lightGray?: boolean;
    /** white button and links */
    white?: boolean;
    /** bankId color on button and links */
    bankId?: boolean;

    /**
     * Add an optional extra React Element
     */
    extras?: ReactNode;

    showInModal?: boolean;
    maxWidth?: string;

    getAppInfo?: Function;
    errorInfoDarkMode?: boolean;

    canaryValue?: string | number;
}

interface WrapperProps {
    showLinkBidThisDevice?: boolean;
    showLinkMbidThisDevice?: boolean;
    showLinkMbidOtherDevice?: boolean;
    children: ReactNode;
}

const Wrapper = ({
    showLinkBidThisDevice,
    showLinkMbidThisDevice,
    showLinkMbidOtherDevice,
    children,
}: WrapperProps) => {
    if (showLinkBidThisDevice || showLinkMbidThisDevice || showLinkMbidOtherDevice) {
        return <div className="bankid-other-item">{children}</div>;
    }

    return null;
};

Wrapper.defaultProps = {
    showLinkBidThisDevice: false,
    showLinkMbidThisDevice: false,
    showLinkMbidOtherDevice: false,
};

/**
 * A simple way to add a BankID login form to your application.
 *
 * The default login method is *mobile bank id on other device*
 *
 * Other bank id methods can be configured
 *
 * Default behavior is to not show user feedback within the component,
 * it must be handled in the application based on callback events
 *
 * The component is customizable with properties
 * - enable default user feedback
 * - enable *mobile bank id on this device*
 * - enable *bankid (on file) on this computer*
 * - texts for the input field and login button
 * - presentation language
 * - row or column presentation for input field and button
 * - callback methods for different events
 *   - login success
 *   - login failure
 *   - before submit (e.g. prevent submission based on validation)
 *   - and more...
 */

interface InDialogProps {
    show?: boolean;
    status: string;
    showUserFeedback?: boolean;
    showErrorPanel?: boolean;
    maxWidth?: string;
    children: ReactNode;
}
const InDialog = ({ show, status, showUserFeedback, showErrorPanel, maxWidth, children }: InDialogProps) => {
    if (!((status !== STATUS_INIT || showErrorPanel) && showUserFeedback)) return null;

    return show ? (
        <Dialog maxWidth={maxWidth} open>
            <DialogBody>{children}</DialogBody>
        </Dialog>
    ) : (
        <>{children}</>
    );
};

InDialog.defaultProps = {
    show: false,
    showUserFeedback: false,
    showErrorPanel: false,
    maxWidth: '500px',
};

export class BankIdAuthentication extends React.Component<BankIdAuthenticationProps, BankIdAuthenticationState> {
    state = { ...defaultState, selectedMethod: null, isOtherDevice: false, texts: [] };

    ssnInputRef = React.createRef<InputRef>();

    startURL = null;

    pollTimer = null;

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        i18n = this.i18n;
        const { methods } = this.props;
        const authMethods = _.merge({}, defaultMethods, methods);
        const { isMobile, isTablet, isDesktop } = this.state;

       
        if (isDesktop) {
            if (authMethods.desktop.otherDevice) {
                this.setState({ defaultSelectedMethod: MBID_OTHER, selectedMethod: MBID_OTHER });
            } else if (authMethods.desktop.thisDevice) {
                this.setState({ defaultSelectedMethod: BID_THIS, selectedMethod: BID_THIS });
            }
        }

        if (isTablet) {
            if (authMethods.tablet.otherDevice) {
                this.setState({ defaultSelectedMethod: MBID_OTHER, selectedMethod: MBID_OTHER });
            } else if (authMethods.tablet.thisDevice) {
                this.setState({ defaultSelectedMethod: MBID_THIS, selectedMethod: MBID_THIS });
            }
        }

        if (isMobile) {
            if (authMethods.mobile.thisDevice) {
                this.setState({ defaultSelectedMethod: MBID_THIS, selectedMethod: MBID_THIS });
            } else if (authMethods.mobile.otherDevice) {
                this.setState({ defaultSelectedMethod: MBID_OTHER, selectedMethod: MBID_OTHER });
            }
        }
    }

    componentDidMount() {
        const { autoFocusSsnInputOnMount, language } = this.props;
        // eslint-disable-next-line no-unused-expressions
        if (autoFocusSsnInputOnMount) this.ssnInputRef.current?.getInputEl()?.focus();

         // load selected language
        const theLanguage = ['sv', 'en'].includes(language) ? language : 'sv';
        import (`./bankid-${theLanguage}.json`).then(langFile => {
            this.setState({"texts": langFile.default})
        });
    }

    i18n = (key, data) => {
        const { texts } = this.state;
        let text = texts[key];

        if (data) {
            Object.keys(data).map(key => (text = text.replace(`{{${key}}}`, data[key])));
        }
        return text;
    };

    createSessionAndPoll = async (data: { type: any; ssn: any }) => {
        try {
            const { onOpenApp, elevateSession, urlMapper, canaryValue, onCreateSessionSuccess, onElevateSessionSuccess } = this.props;
            const newData = { ...data };

            // todo: Bug? if not newData.ssn then delete newData.ssn // joli44 2022-06
            if (!newData.ssn || newData.type === 'BANKID') delete newData.ssn;

            let res;
            const queryParams = canaryValue ? `?canaryValue=${canaryValue}` : '';

            if (elevateSession) {
                // Upgrade current Session
                res = await put(`/rest/sessions/v2${queryParams}`, newData);
                if (onElevateSessionSuccess) onElevateSessionSuccess(res);
            } else {
                // Create new Session
                res = await post(`/rest/sessions/v2${queryParams}`, newData);
                if (onCreateSessionSuccess) onCreateSessionSuccess(res);
            }
            const { authentication } = res.response;
            this.startURL = urlMapper ? urlMapper(authentication.eid.startURL) : authentication.eid.startURL;

            await this.startPolling(authentication.eid.pollTime);

            if (newData.type === 'BANKID') onOpenApp(this.startURL);
        } catch (err) {
            console.error('createSession failed: ', err);
            const { onFailure } = this.props;
            this.setState({ error: err }, () => onFailure(err));
        }
    };

    startPolling = async firstPollTime => {
        const { status: internalStatus } = this.state;
        const { onFailure, onSuccess, onStateChange } = this.props;
        let counter = 0;
        let pollErrorCount = 0;
        const maxIterations = 60;
        const poll = async (prevStatus?: string) => {
            try {
                const start = new Date();
                const res = await get(`/rest/sessions/v2?count=${counter + 1}`);
                pollErrorCount = 0; // always reset pollErrorCount after successful poll
                const end = new Date();
                counter += 1;
                const {
                    authentication: { status: bankIdStatus, type, eid },
                    key,
                    person,
                } = (res as any).response;

                const pollTime = (eid && eid.pollTime) || 1500;

                if (bankIdStatus !== prevStatus) onStateChange(bankIdStatus);

                if (internalStatus === STATUS_POLLING) {
                    if (pollAgainStatus.includes(bankIdStatus) && counter < maxIterations) {
                        const elapsedTime = end.getTime() - start.getTime();
                        if (elapsedTime < pollTime) {
                            this.pollTimer = setTimeout(() => poll(bankIdStatus), pollTime - elapsedTime);
                        } else {
                            await poll(bankIdStatus);
                        }
                    } else if (stopPollStatus.includes(bankIdStatus)) {
                        if (bankIdStatus === 'COMPLETE') {
                            this.setState({ status: STATUS_SUCCESS });
                            onSuccess({ key, person, type });
                        } else {
                            this.setState({ status: STATUS_FAILURE });
                            onFailure({ key, bankIdStatus, type });
                        }
                    } else if (counter >= maxIterations) {
                        this.onTimeout();
                    } else {
                        await poll(bankIdStatus);
                    }
                }
            } catch (err) {
                pollErrorCount += 1;
                if (pollErrorCount < MAX_POLL_ERRORS) {
                    counter += 1;
                    this.pollTimer = setTimeout(() => poll('TMP_POLL_ERROR'), 1500);
                } else {
                    this.setState({ error: err }, () => onFailure(err));
                }
            }
        };

        this.pollTimer = setTimeout(() => {
            poll();
        }, firstPollTime);
    };

    onTimeout = () => {
        const { onTimeout } = this.props;

        this.setState({ status: STATUS_FAILURE });

        onTimeout();
    };

    onChangeSsn = e => {
        const { value } = e.target;
        const { onChangeSSN, trimSSN } = this.props;
        this.setState({ ssn: trimSSN ? value.trim() : value, tooYoung: false });
        onChangeSSN(value);
    };

    onStartLogin = () => {
        const { onStart, onBeforeSubmit, focusInputWhenSsnIsEmpty, dontValidateEmptySsn, isReviewMode } = this.props;
        const { ssn, isMobile, selectedMethod } = this.state;
        const isMbidOtherDevice = isReviewMode || selectedMethod === MBID_OTHER;

        if (!ssn && focusInputWhenSsnIsEmpty && isMbidOtherDevice) {
            // eslint-disable-next-line no-unused-expressions
            this.ssnInputRef?.current?.getInputEl()?.focus();
            return;
        }

        if (!ssn && dontValidateEmptySsn && isMbidOtherDevice) return;

        if (!onBeforeSubmit() || (isMbidOtherDevice && !this.ssnInputRef.current.doValidation())) return;

        const type = isMbidOtherDevice ? 'BANKID_MOBILE' : 'BANKID';

        this.setState({ isOtherDevice: isMbidOtherDevice });

        onStart(type, isMobile);
        this.setState({ status: STATUS_POLLING });

        if (!isMbidOtherDevice) {
            setTimeout(() => this.setState({ showStartBankIdLink: true }), 3000);
        }
        this.createSessionAndPoll({
            type,
            ssn,
        });
    };

    onStartBid = () => {
        this.setState({ selectedMethod: BID_THIS }, () => this.onStartLogin());
    };

    onSelectMbidThisDevice = () => {
        this.setState({ selectedMethod: MBID_THIS });
    };

    onSelectMbidOtherDevice = () => {
        this.setState({ selectedMethod: MBID_OTHER });
    };

    onKeyUp = ({ which }) => {
        if (which === 13) {
            if (this.ssnInputRef.current.doValidation()) {
                this.onStartLogin();
            }
        }
    };

    onUserConfirmedFailure = () => {
        const { onUserConfirmedFailure } = this.props;
        const { defaultSelectedMethod } = this.state;
        // don't expose ssn when user clicks back
        this.setState({ ...defaultState, selectedMethod: defaultSelectedMethod });
        onUserConfirmedFailure();
    };

    onCancelLogin = () => {
        const { cancelReturnUrl, onCancelLogin } = this.props;
        const { defaultSelectedMethod } = this.state;
        if (this.pollTimer) clearTimeout(this.pollTimer);
        this.setState({ ...defaultState, selectedMethod: defaultSelectedMethod }, () => console.table(this.state));
        if (cancelReturnUrl) window.location.href = cancelReturnUrl;
        onCancelLogin();
    };

    validateAge = ssn => {
        const { minAge } = this.props;

        if (!ssn) return true;

        let birthDate;

        if (ssn.length === 10 || ssn.length === 11) {
            // comments below assume year now is 2021
            const yyNow = dayjs().format('YY'); // 21

            const thisCentury = dayjs().format('YYYY').substring(0, 2); // 20

            const prevCentury = `${parseInt(thisCentury, 10) - 1}`; // 19

            const yySsn = ssn.substring(0, 2); // xx if user entered xxyyzz-nnnn

            birthDate = `${yySsn <= yyNow ? thisCentury : prevCentury}${ssn.substring(0, 6)}`; // 20xx or 19xx
        } else {
            birthDate = ssn.substring(0, 8);
        }

        return dayjs().diff(dayjs(birthDate), 'years') >= minAge;
    };

    validateSsnAndAge = () => {
        const { minAge, dontValidateEmptySsn } = this.props;
        const { ssn } = this.state;

        if (!ssn && dontValidateEmptySsn) return true;

        const isValid = validateSsn(ssn);

        if (isValid && minAge > 0) {
            const ageIsValid = this.validateAge(ssn);
            if (!ageIsValid) this.setState({ tooYoung: true });
            return ageIsValid;
        }
        return isValid;
    };

    render() {
        const {
            isReviewMode,
            stack,
            buttonText,
            mobileBankIdOtherDeviceButtonText,
            bankIdThisDeviceLinkText,
            mobileBankIdThisDeviceLinkText,
            mobileBankIdOtherDeviceLinkText,
            useFloatLabel,
            ssnLabel,
            ssnPlaceholder,
            ssnValidationMsg,
            minAge,
            minAgeValidationMessage,
            buttonIcon,
            buttonProps,
            buttonLinkProps,
            methods,
            showUserFeedback,
            loginSuccessMsg,
            loginFailureMsg,
            loginFailureLinkText,
            bankIdStartMsg,
            dontValidateEmptySsn,
            showInModal,
            maxWidth,
            getAppInfo,
            errorInfoDarkMode,
            // colors
            green,
            blue,
            purple,
            red,
            beige,
            gray,
            lightGray,
            white,
            bankId,
            extras,
        } = this.props;
        const { ssn, isDesktop, isMobile, isTablet, selectedMethod, error, status, showStartBankIdLink, tooYoung } =
            this.state;

        const authMethods = _.merge({}, defaultMethods, methods);

        const tabletOrDesktop = isTablet || isDesktop;
        const mobileThisDevice = (isMobile || isTablet) && selectedMethod === MBID_THIS;
        const classes = classNames({ 'ec-bank-id-authentication': true, stack: stack || mobileThisDevice });
        const ssnFieldClasses = classNames({
            ssn: true,
            'ec-ssn-input': true,
            stack: stack || mobileThisDevice,
            mobile: isMobile,
        });
        const loginButtonClasses = classNames({
            bankId: true,
            'ec-bankid-button': true,
            stack: stack || mobileThisDevice,
            mobile: isMobile,
        });
        const formClasses = classNames({
            'ec-bankid-form': true,
            mobile: isMobile,
            tabletOrDesktop,
        });

        // user form
        const showSsnInput = isReviewMode || selectedMethod === MBID_OTHER;
        const showLoginButton =
            (isDesktop && authMethods.desktop.otherDevice) ||
            (isTablet && (authMethods.tablet.otherDevice || authMethods.tablet.thisDevice)) ||
            (isMobile && (authMethods.mobile.otherDevice || authMethods.mobile.thisDevice));

        // links
        // Desktop + native BID?
        const showLinkBidThisDevice = authMethods.desktop.thisDevice && isDesktop;

        // Mobile or tablet + MBID this device?
        const showLinkMbidThisDevice =
            ((authMethods.mobile.thisDevice && isMobile) || (authMethods.tablet.thisDevice && isTablet)) &&
            selectedMethod === MBID_OTHER;

        // Mobile or tablet + MBID other device
        const showLinkMbidOtherDevice =
            ((authMethods.mobile.otherDevice && isMobile) || (authMethods.tablet.otherDevice && isTablet)) &&
            selectedMethod === MBID_THIS;

        // prepare for user feedback
        const showStartAppInfo = !error && showUserFeedback;
        const showStartAppLink = !error && showUserFeedback && showStartBankIdLink;
        const showErrorPanel = error && showUserFeedback;

        const appInfo = showErrorPanel && getAppInfo && getAppInfo();

        // set configured or default gui texts
        const theMobileBankIdOtherDeviceButtonText =
            mobileBankIdOtherDeviceButtonText || i18n('button-text-mbid-other-default');
        const theBankIdThisDeviceLinkText = bankIdThisDeviceLinkText || i18n('bid-this-device-link-text');
        const theMobileBankIdThisDeviceLinkText = mobileBankIdThisDeviceLinkText || i18n('mbid-this-device-link-text');
        const theMobileBankIdOtherDeviceLinkText =
            mobileBankIdOtherDeviceLinkText || i18n('mbid-other-device-link-text');
        const theButtonText =
            selectedMethod === MBID_OTHER
                ? theMobileBankIdOtherDeviceButtonText
                : buttonText || i18n('button-text-default');
        const theSsnLabel = ssnLabel || i18n('ssn-label-default');
        const theSsnPlaceholder = ssnPlaceholder || i18n('ssn-placeholder-default');
        const theSsnValidationMsg = tooYoung
            ? minAgeValidationMessage || i18n('ssn-validation-msg-min-age-default', { minAge })
            : ssnValidationMsg || i18n('ssn-validation-msg-default');
        const theLoginSuccessMsg = loginSuccessMsg || i18n('login-success-msg-default');
        const theLoginFailureMsg = loginFailureMsg || i18n('login-failure-msg-default');
        const theLoginFailureLinkText = loginFailureLinkText || i18n('login-failure-link-text-default');
        const theBankIdStartMsg = bankIdStartMsg || i18n(`open-${selectedMethod}-info`);

        const colorProps = {
            green: green && !(bankId || blue || purple || red || beige || gray || lightGray || white),
            blue: blue || undefined,
            purple: purple || undefined,
            red: red || undefined,
            beige: beige || undefined,
            gray: gray || undefined,
            lightGray: lightGray || undefined,
            white: white || undefined,
            bankId: bankId || undefined,
        };

        let theButtonIcon;

        if (buttonIcon === true || buttonIcon === 'white') {
            theButtonIcon = bankIdWhiteIcon;
        } else if (buttonIcon === 'color') {
            theButtonIcon = bankIdColorIcon;
        } else if (buttonIcon === 'light') {
            theButtonIcon = bankIdLightIcon;
        } else if (buttonIcon) {
            theButtonIcon = buttonIcon;
        }

        return (
            <div className={classes}>
                {(status === STATUS_INIT || !showUserFeedback || showInModal) && (
                    <>
                        <div className={formClasses}>
                            {showSsnInput && (
                                <Input
                                    name="ssn"
                                    className={ssnFieldClasses}
                                    label={theSsnLabel}
                                    value={ssn}
                                    onChange={this.onChangeSsn}
                                    validator={this.validateSsnAndAge}
                                    onKeyUp={this.onKeyUp}
                                    placeholder={theSsnPlaceholder}
                                    autocomplete="off"
                                    validationMessage={theSsnValidationMsg}
                                    required={!dontValidateEmptySsn}
                                    type="tel"
                                    floatLabel={useFloatLabel}
                                    ref={this.ssnInputRef}
                                />
                            )}
                            {showLoginButton && (
                                <Button
                                    {...colorProps}
                                    name="bankId"
                                    className={loginButtonClasses}
                                    onClick={this.onStartLogin}
                                    {...buttonProps}
                                >
                                    <span>
                                        {theButtonText}
                                        {typeof theButtonIcon === 'string' ? (
                                            <img src={theButtonIcon} alt="" className="icon-bankid" />
                                        ) : (
                                            theButtonIcon
                                        )}
                                    </span>
                                </Button>
                            )}
                        </div>
                        <Panel
                            key="bankid-other"
                            className="bankid-other"
                            noBorder
                            transparent
                            paddingTopBottom={isMobile ? 'large' : 'xxLarge'}
                        >
                            <Wrapper
                                showLinkBidThisDevice={showLinkBidThisDevice}
                                showLinkMbidOtherDevice={showLinkMbidOtherDevice}
                                showLinkMbidThisDevice={showLinkMbidThisDevice}
                            >
                                <>
                                    {showLinkBidThisDevice && (
                                        <ButtonGroup
                                            alignRight={!stack && !isMobile}
                                            alignCenter={stack || selectedMethod === MBID_THIS}
                                            space={false}
                                        >
                                            <Button
                                                name="bid-this-device"
                                                {...colorProps}
                                                link={authMethods.desktop.otherDevice}
                                                onClick={this.onStartBid}
                                                {...buttonLinkProps}
                                            >
                                                {theBankIdThisDeviceLinkText}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                    {showLinkMbidThisDevice && (
                                        <ButtonGroup
                                            alignRight={!stack && !isMobile}
                                            alignCenter={stack || selectedMethod === MBID_THIS}
                                        >
                                            <Button
                                                name="mbid-this-device"
                                                {...colorProps}
                                                link
                                                onClick={this.onSelectMbidThisDevice}
                                                {...buttonLinkProps}
                                            >
                                                {theMobileBankIdThisDeviceLinkText}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                    {showLinkMbidOtherDevice && (
                                        <ButtonGroup
                                            alignRight={!stack && !isMobile}
                                            alignCenter={stack || selectedMethod === MBID_THIS}
                                        >
                                            <Button
                                                {...colorProps}
                                                name="mbid-other-device"
                                                link
                                                onClick={this.onSelectMbidOtherDevice}
                                                {...buttonLinkProps}
                                            >
                                                {theMobileBankIdOtherDeviceLinkText}
                                            </Button>
                                        </ButtonGroup>
                                    )}
                                </>
                            </Wrapper>
                            {!isMobile && extras && (
                                <div className={classNames({ ...buttonLinkProps, spacer: true })} />
                            )}
                            {extras && <div className="extras">{extras}</div>}
                        </Panel>
                    </>
                )}
                <InDialog
                    show={showInModal}
                    status={status}
                    showUserFeedback={showUserFeedback}
                    showErrorPanel={showErrorPanel}
                    maxWidth={maxWidth}
                >
                    <>
                        {status === STATUS_POLLING && showUserFeedback && (
                            <>
                                {showStartAppInfo && (
                                    <div className="centered mb-4x">
                                        <h4>{i18n(`open-${selectedMethod}-header`)}</h4>
                                        <Spinner id="bankid-authentication-spinner" isCenterX isVisible />
                                        <p>{theBankIdStartMsg}</p>
                                        <Button transparent onClick={this.onCancelLogin}>
                                            {i18n('cancel-login')}
                                        </Button>
                                    </div>
                                )}
                                {showStartAppLink && (
                                    <div className="centered">
                                        <p>{i18n('open-this-device-additional-info')}</p>
                                        <LinkButton href={this.startURL} outline round small>
                                            {i18n('open-this-device-button-text')}
                                        </LinkButton>
                                    </div>
                                )}
                            </>
                        )}

                        {/* Tech Error */}
                        {showErrorPanel && showUserFeedback && (
                            <div className="centered mtb-8x">
                                <i className="e-purple icon-alert-triangle large-icon" />
                                <p>{i18n('login-unknown-err-msg')}</p>
                                <Button className="mb-4x" transparent onClick={this.onUserConfirmedFailure}>
                                    {theLoginFailureLinkText}
                                </Button>
                            </div>
                        )}

                        {/* BankId Success */}
                        {status === STATUS_SUCCESS && showUserFeedback && (
                            <div className="centered mtb-8x">
                                <i className="e-green icon-check-circle large-icon" />
                                <p>{theLoginSuccessMsg}</p>
                            </div>
                        )}

                        {/* BankId Failure or timeout */}
                        {status === STATUS_FAILURE && showUserFeedback && (
                            <div className="centered mtb-8x">
                                <i className="e-red icon-alert-circle large-icon" />
                                <p>{theLoginFailureMsg}</p>
                                <Button transparent onClick={this.onUserConfirmedFailure}>
                                    {theLoginFailureLinkText}
                                </Button>
                            </div>
                        )}
                    </>
                </InDialog>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
BankIdAuthentication.defaultProps = {
    isReviewMode: false,
    showInModal: false,
    maxWidth: '500px',
    stack: false,
    onChangeSSN: () => {},
    onTimeout: () => {},
    onUserConfirmedFailure: () => {},
    onBeforeSubmit: () => true,
    onStateChange: () => {},
    onOpenApp: () => {},
    onCancelLogin: () => {},
    cancelReturnUrl: '',
    elevateSession: false,
    buttonText: '', // looked up in i18n json if empty
    mobileBankIdOtherDeviceButtonText: '', // looked up in i18n json if empty
    bankIdThisDeviceLinkText: '', // looked up in i18n json if empty
    mobileBankIdThisDeviceLinkText: '', // looked up in i18n json if empty
    mobileBankIdOtherDeviceLinkText: '', // looked up in i18n json if empty
    useFloatLabel: false,
    ssnLabel: '',
    ssnPlaceholder: '', // looked up in i18n json if empty
    ssnValidationMsg: '', // looked up in i18n json if empty
    buttonIcon: false,
    buttonProps: {},
    buttonLinkProps: {},

    loginSuccessMsg: '', // looked up in i18n json if empty
    loginFailureMsg: '', // looked up in i18n json if empty
    loginFailureLinkText: '', // looked up in i18n json if empty
    bankIdStartMsg: '', // looked up in i18n json if empty

    // authentication methods
    methods: defaultMethods,

    // other behaviour
    focusInputWhenSsnIsEmpty: false,
    autoFocusSsnInputOnMount: false,
    dontValidateEmptySsn: false,
    trimSSN: false,

    // optional user feedback
    showUserFeedback: false,
    language: 'sv',

    // age
    minAge: 0,
    minAgeValidationMessage: '', // looked up in i18n json if empty

    // mapping functions
    urlMapper: null,

    // colors
    green: true,
    blue: false,
    purple: false,
    red: false,
    beige: false,
    gray: false,
    lightGray: false,
    white: false,
    bankId: false,
};
