// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { renderTableChildNodes } from './renderTableChildNodes';
import THead from './TableHeader';
import TBody from './TableBody';
import Tr from './TableRow';
import Th from './TableHeaderColumn';
import Td from './TableRowColumn';

import './Table.scss';

interface TableProps {
    /** CSS id */
    id?: string;

    /** Additional css classes */
    className?: string;

    /** @deprecated use className and css instead */
    style?: CSSProperties;

    /**
     * Alt #1: Child elements: TableHeader, TableBody, TableFooter
     */
    children?: ReactNode;

    /**
     * Alt #2: pass array of headers and a data set
     */
    headers?: object[];

    /**
     * Alt #2: pass array of headers and a data set
     */
    data?: object[];

    /**
     * Alt #2: a click handler for table row clicks
     * @param {object} row The row data object is sent in the first parameter
     */
    onRowClick?: Function;

    /**
     * Alt #2: props for the table header. E.g white, beige
     */
    theadProps?: object;

    /**
     * Alt #2: Alignment for headers and data set
     *
     * Array with alignment for columns: E.g ['left', 'right', 'left']
     *
     * Default alignment is 'left'
     *
     * Optional even if headers and data are passed. Array can be shorter than headers array.
     * Empty string means 'left' - third column right aligned ['', '', 'right']
     */
    align?: string[];

    /**
     * Alt #2: Column widths for headers and data set
     *
     * Array with css widths for columns. E.g. ['25%', ]
     *
     * Optional even if headers and data are passed. Array can be shorter than headers array.
     * Empty string means no width is set
     */
    width?: string[];

    /** Add a hover effect to table rows */
    hover?: boolean;

    /** Center content in th's and td's */
    centered?: boolean;
    borderOnLastRow?: boolean;

    /** @deprecated should not be used */
    large?: boolean;

    /**
     * This property is used in combination with Alt #2 (array of headers and a data set)
     *
     * Adds a column to the left with bullets to indicate a timeline
     *
     * @see ([TableRowColumn](/#tablerowcolumn) )
     */
    bullets?: boolean;
}

const Table = ({
    children,
    className,
    style,
    hover,
    centered,
    borderOnLastRow,
    id,
    large,
    // Alt #2 usage
    headers,
    data,
    align,
    width,
    bullets,
    onRowClick,
    theadProps,
}: TableProps) => {
    const noOfRows = data && data.length;
    return (
        <table
            id={id}
            className={classNames({
                'ec-table': true,
                table: true,
                [className]: className,
                'table-rows-hover': hover,
                'table-cells-centered': centered,
                'table-no-border-on-last-row': !borderOnLastRow,
                'table-large': large,
                'table-timeline': bullets,
            })}
            style={style}
        >
            {children ? (
                renderTableChildNodes(children)
            ) : (
                <>
                    <THead {...theadProps}>
                        <Tr>
                            {bullets && <Th noBorder> </Th>}
                            {headers.map((header, index) => (
                                <Th
                                    alignLeft={align.length > index && align[index] === 'left'}
                                    alignRight={align.length > index && align[index] === 'right'}
                                    alignCenter={align.length > index && align[index] === 'center'}
                                    key={header}
                                    style={width.length > index && width[index] ? { width: width[index] } : null}
                                >
                                    {header}
                                </Th>
                            ))}
                        </Tr>
                    </THead>
                    <TBody>
                        {data.map((row, rowIndex) => {
                            let cellCount = 0;
                            return (
                                <Tr
                                    onClick={onRowClick ? () => onRowClick(row) : null}
                                    className={`${row.className || ''} ${
                                        rowIndex === 0 ? 'first-row' : rowIndex === noOfRows - 1 ? 'last-row' : ''
                                    }`}
                                    key={row.reactKey}
                                >
                                    {bullets && <Td bullet className={`on-row-${rowIndex + 1}`} />}
                                    {Object.keys(row).map((cell, index) => {
                                        if (cell === 'className') return null;
                                        if (cell === 'reactKey') return null;
                                        if (cellCount >= headers.length) return null;
                                        // eslint-disable-next-line no-plusplus
                                        cellCount++;
                                        return (
                                            <Td
                                                alignLeft={align.length > index && align[index] === 'left'}
                                                alignRight={align.length > index && align[index] === 'right'}
                                                alignCenter={align.length > index && align[index] === 'center'}
                                                key={row[cell]}
                                                style={
                                                    width.length > index && width[index]
                                                        ? { width: width[index] }
                                                        : null
                                                }
                                            >
                                                {row[cell]}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </TBody>
                </>
            )}
        </table>
    );
};

Table.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Additional css classes */
    className: PropTypes.string,

    /** @deprecated use className and css instead */
    style: PropTypes.shape(),

    /**
     * Alt #1: Child elements: TableHeader, TableBody, TableFooter
     */
    children: PropTypes.node,

    /**
     * Alt #2: pass array of headers and a data set
     */
    headers: PropTypes.arrayOf(PropTypes.object),

    /**
     * Alt #2: pass array of headers and a data set
     */
    data: PropTypes.arrayOf(PropTypes.object),

    /**
     * Alt #2: a click handler for table row clicks
     * @param {object} row The row data object is sent in the first parameter
     */
    onRowClick: PropTypes.func,

    /**
     * Alt #2: props for the table header. E.g white, beige
     */
    theadProps: PropTypes.object,

    /**
     * Alt #2: Alignment for headers and data set
     *
     * Array with alignment for columns: E.g ['left', 'right', 'left']
     *
     * Default alignment is 'left'
     *
     * Optional even if headers and data are passed. Array can be shorter than headers array.
     * Empty string means 'left' - third column right aligned ['', '', 'right']
     */
    align: PropTypes.arrayOf(PropTypes.string),

    /**
     * Alt #2: Column widths for headers and data set
     *
     * Array with css widths for columns. E.g. ['25%', ]
     *
     * Optional even if headers and data are passed. Array can be shorter than headers array.
     * Empty string means no width is set
     */
    width: PropTypes.arrayOf(PropTypes.string),

    /** Add a hover effect to table rows */
    hover: PropTypes.bool,

    /** Center content in th's and td's */
    centered: PropTypes.bool,

    borderOnLastRow: PropTypes.bool,

    /** @deprecated should not be used */
    large: PropTypes.bool,

    /**
     * This property is used in combination with Alt #2 (array of headers and a data set)
     *
     * Adds a column to the left with bullets to indicate a timeline
     *
     * @see ([TableRowColumn](/#tablerowcolumn) )
     */
    bullets: PropTypes.bool,
};

Table.defaultProps = {
    id: '',
    className: '',
    style: {},
    hover: false,
    centered: false,
    borderOnLastRow: false,
    large: false,
    bullets: false,
    // Alt #1
    children: null,
    // Alt #2
    headers: null,
    data: null,
    align: [],
    width: [],
    onRowClick: null,
    theadProps: {},
};

export default Table;
