import React, { Component } from 'react';
import {
    Panel,
    PanelContent,
    ResponsiveTable as Table,
    ResponsiveTableHeader as THead,
    ResponsiveTableHeaderColumn as Th,
    ResponsiveTableBody as TBody,
    ResponsiveTableRow as Tr,
    ResponsiveTableRowColumn as Td,
} from '@ecster/components';
import { i18n } from '@ecster/i18n';
import PropTypes from 'prop-types';

import { getDueDatePresentation } from '../../invoice/v1/mapInvoicesV1';
import { getBillProperties } from '../util';
import { formatAmount } from '../../../common/util/format-amount';
import { formatDateShort, formatDateCustom } from '../../../common/util/format-date';

const Invoices = ({ bills, onClick }) => {
    const billProps = [];

    bills.map(bill => billProps.push(getBillProperties(bill)));

    const invoicePeriod = bill => (
        <>
            {formatDateShort(bill.invoicePeriod.from)}
            {' - '}
            {formatDateShort(bill.invoicePeriod.to)} {formatDateCustom(bill.invoicePeriod.to, 'YYYY')}
        </>
    );

    const invoiceDueDate = bill => <>{getDueDatePresentation(bill.payment.dueDate)}</>;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    const invoiceBadge = (bill, index) => <>{billProps[index].isZeroInvoice ? ' ' : billProps[index].badge}</>;

    const invoiceAmount = (bill, index) => (
        <>
            <span>{formatAmount(billProps[index].partPayment)}</span>
            <span className="icon-chevron-right" />
        </>
    );

    return (
        <Table large className="invoice-list">
            <THead white notOnMobile>
                <Tr>
                    <Th>{i18n('invoice.account-invoices.invoice-period')}</Th>
                    <Th>{i18n('invoice.account-invoices.due-date')}</Th>
                    <Th><span className="screenreader">Status</span></Th>
                    <Th alignRight>{i18n('invoice.account-invoices.amount')}</Th>
                </Tr>
            </THead>
            <TBody>
                {bills.map((bill, index) => (
                    <Tr
                        key={`${bill.payment.dueDate}-${bill.totalTransaction}`}
                        onClick={onClick}
                        className="invoice-row"
                    >
                        <Td notOnMobile>{invoicePeriod(bill)}</Td>
                        <Td notOnMobile>{invoiceDueDate(bill)}</Td>
                        <Td notOnMobile>{invoiceBadge(bill, index)}</Td>
                        <Td notOnMobile alignRight>
                            {invoiceAmount(bill, index)}
                        </Td>
                        <Td onlyOnMobile>
                            <div className="mb-1x">{invoicePeriod(bill)}</div>
                            <span className="weak small-text">{invoiceDueDate(bill)}</span>
                        </Td>
                        <Td onlyOnMobile alignRight>
                            <div className="mb-1x">{invoiceAmount(bill, index)}</div>
                            {invoiceBadge(bill, index)}
                        </Td>
                    </Tr>
                ))}
            </TBody>
        </Table>
    );
};

Invoices.propTypes = {
    bills: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired,
};

export class AccountInvoicesPanel extends Component {
    onClickInvoice = () => {
        const { accountRef } = this.props;
        window.location.hash = `/account/${accountRef}/invoice`;
    };

    render() {
        const { bills } = this.props;

        const currentBill = bills.slice(0, 1);
        const restOfBills = bills.length > 1 ? bills.slice(1) : null;

        return (
            <div className="account-invoices-panel">
                <h2 className="h4 ml-3x">{i18n('invoice.account-invoices.current-bill')}</h2>
                <Panel paddingAll="small" stretchInMobile>
                    <PanelContent fullWidth>
                        <Invoices bills={currentBill} onClick={this.onClickInvoice} />
                    </PanelContent>
                </Panel>
                {restOfBills && (
                    <>
                        <h2 className="h4">{i18n('invoice.account-invoices.older-bills')}</h2>
                        <Panel paddingAll="small" stretchInMobile>
                            <PanelContent fullWidth>
                                <Invoices bills={restOfBills} onClick={this.onClickInvoice} />
                            </PanelContent>
                        </Panel>
                    </>
                )}
            </div>
        );
    }
}

AccountInvoicesPanel.propTypes = {
    accountRef: PropTypes.string.isRequired,
    bills: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AccountInvoicesPanel.defaultProps = {};
