import React from 'react';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { LabelValue } from '@ecster/components';
// @ts-ignore
import { formatZip } from '@ecster/util';
import { Customer } from '../types/Customer';

type Props = {
    customer: Customer;
};

const toLower = (str: string) => (str ? str.toLowerCase() : '');

const ShowContactInfo = ({ customer }: Props) => {
    const { locale } = window.EcsterConfig;

    return (
        <div className="customer-show-contact-info mtb-6x">
            <LabelValue
                label={i18n('general.address.address')}
                value={
                    <>
                        <div className="capitalize">{toLower(customer.address)}</div>
                        <div>
                            {formatZip(customer.zip, locale)}{' '}
                            <span className="capitalize">{toLower(customer.city)}</span>
                        </div>
                    </>
                }
            />
            <hr />
            <LabelValue
                label={i18n('general.address.mobile')}
                value={`${customer.contactInformation.phoneNumber.countryCallingCode} ${customer.contactInformation.phoneNumber.number}`}
            />
            <hr />
            <LabelValue label={i18n('general.address.email')} value={customer.contactInformation.email} />
        </div>
    );
};

export default ShowContactInfo;
