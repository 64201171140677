import { getCookie, removeCookie, setCookie } from '@ecster/cookies';
import { v4 as uuidv4 } from 'uuid';

export const ORIGIN_COOKIE = 'ecster-origin-id';

/**
 * Create OriginCookie.
 * Creates cookie with name 'ecster-origin-id' and value is a generated random originId string
 * @returns {string} originId
 */
export const createOriginCookie = () => {
    const originId = uuidv4().replace(/-/g, '');

    setCookie(ORIGIN_COOKIE, originId, 1);

    return originId;
};

/**
 * Returns value of OriginCookie
 * @returns {string|null} value
 */
export const getOriginCookie = () => {
    return getCookie(ORIGIN_COOKIE);
};

/**
 * Delete OriginCookie
 */
export const deleteOriginCookie = () => {
    removeCookie(ORIGIN_COOKIE);
};
