import { post } from '@ecster/net/v2';

import {
    AUTHENTICATION_CREATE_SESSION_BEGIN,
    AUTHENTICATION_CREATE_SESSION_SUCCESS,
    AUTHENTICATION_CREATE_SESSION_FAILURE,
    AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR,
} from './constants';
import { CREATE_SESSION_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { setAdditionalHeaders, X_ECSTER_SESSION_KEY_NAME } from '@ecster/net';
import { setCookie } from '@ecster/cookies';

export const createSession = (data, canaryValue) => async dispatch => {
    dispatch({
        type: AUTHENTICATION_CREATE_SESSION_BEGIN,
    });

    try {
        const res = await post(CREATE_SESSION_URL + (canaryValue ? `?canaryValue=${canaryValue}` : ''), data);

        setAdditionalHeaders({ [X_ECSTER_SESSION_KEY_NAME]: res.response.key });
        setCookie(X_ECSTER_SESSION_KEY_NAME, res.response.key, 1);

        dispatch({
            type: AUTHENTICATION_CREATE_SESSION_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'createSession');

        dispatch({
            type: AUTHENTICATION_CREATE_SESSION_FAILURE,
            data: {
                error: {
                    message: err,
                    action: AUTHENTICATION_CREATE_SESSION_FAILURE,
                },
            },
        });
    }
};

export const dismissCreateSessionError = () => ({ type: AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR });

export function reducer(state, action) {
    switch (action.type) {
        case AUTHENTICATION_CREATE_SESSION_BEGIN:
            return {
                ...state,
                createSessionPending: true,
                createSessionError: null,
                createSessionIsDone: false,
                createSessionIsError: false,
            };

        case AUTHENTICATION_CREATE_SESSION_SUCCESS:
            return {
                ...state,
                loginStatus: {
                    sessionKey: action.data.key,
                    isLoggedIn: false,
                },
                loginProgress: {
                    status: action.data.authentication.status,
                    pollTime: action.data.authentication.eid.pollTime,
                    startURL: action.data.authentication.eid.startURL,
                },

                createSessionPending: false,
                createSessionError: null,
                createSessionIsDone: true,
            };

        case AUTHENTICATION_CREATE_SESSION_FAILURE:
            return {
                ...state,
                createSessionPending: false,
                createSessionError: action.data.error,
                createSessionIsError: true,
            };

        case AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR:
            return {
                ...state,
                createSessionError: null,
                createSessionIsError: false,
            };

        default:
            return state;
    }
}
