/* This is the Root component mainly initializes Redux and React Router. */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistor } from './common/configStore';
import PageNotFound from './features/common/PageNotFound';
import createRoutes from './createRoutes';
import ScrollToTop from './common/util/ScrollToTop';
import IdleDetectionDialog from './common/IdleDetectionDialog';
import HistoryListener from './common/HistoryListener';
import ReactRouterProvider from './common/ReactRouterProvider';
import ErrorBoundary from './common/ErrorBoundary';

// const PAGE_VIEW_BLACKLIST = [
//     '/authentication/logout/.*',
//     '/account/overview', // explicit setPageView call in OverviewPage
// ];

class Root extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        routeConfig: PropTypes.array.isRequired,
    };

    render() {
        const { routeConfig, store } = this.props;

        const routes = createRoutes(routeConfig);

        return (
            <ErrorBoundary name="Root">
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <HashRouter>
                            <ReactRouterProvider>
                                <HistoryListener />
                                <ScrollToTop />
                                <Switch>
                                    {routes.publicRoutes}
                                    {routes.privateRoutes}
                                    <Route>
                                        <PageNotFound />
                                    </Route>
                                </Switch>
                                <IdleDetectionDialog />
                            </ReactRouterProvider>
                        </HashRouter>
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        );
    }
}

export default Root;
