import dayjs from 'dayjs';
import { DEBT_COLLECTION } from './invoiceStatus';

export const byDueDate = (a, b) => {
    if (dayjs(a.dueDate).diff(dayjs(b.dueDate)) > 0) {
        return 1;
    }
    if (dayjs(a.dueDate).diff(dayjs(b.dueDate)) < 0) {
        return -1;
    }
    return 0;
};

export const byDebtCollection = (a, b) => {
    if (a.invoiceStatus === DEBT_COLLECTION && b.invoiceStatus !== DEBT_COLLECTION) {
        return -1;
    }
    if (a.invoiceStatus !== DEBT_COLLECTION && b.invoiceStatus === DEBT_COLLECTION) {
        return 1;
    }
    return 0;
};

export const byCompletedSortKey = (a, b) => {
    if (dayjs(a.completedSortKey).diff(dayjs(b.completedSortKey)) > 0) {
        return -1;
    }
    if (dayjs(a.completedSortKey).diff(dayjs(b.completedSortKey)) < 0) {
        return 1;
    }
    return 0;
};

export const byContractInvoice = (a, b) => {
    if (a.contract && !b.contract) {
        return -1;
    }
    if (!a.contract && b.contract) {
        return 1;
    }
    return 0;
};

export const byAccountInvoice = (a, b) => {
    if (a.account && !b.account) {
        return -1;
    }
    if (!a.account && b.account) {
        return 1;
    }
    return 0;
};

export const byOneOffInvoice = (a, b) => {
    if (a.invoice && !b.invoice) {
        return -1;
    }
    if (!a.invoice && b.invoice) {
        return 1;
    }
    return 0;
};
