import React from 'react';
import PropTypes from 'prop-types';
import './InfoItem.scss';

export const InfoItem = ({ label, value, description, hasBreakByWord }) => {
    return (
        <div className="atp-info-item">
            <div className="atp-info-label" title={description}>
                {label}
            </div>
            <div className={`atp-info-value ${hasBreakByWord ? 'word-break' : ''}`}>{value}</div>
            {description && (
                <div className={`atp-info-description ${hasBreakByWord ? 'word-break' : ''}`}>{description}</div>
            )}
        </div>
    );
};

InfoItem.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hasBreakByWord: PropTypes.bool,
};
InfoItem.defaultProps = {
    label: '',
    value: '',
    description: '',
    hasBreakByWord: false,
};
