import { get } from '@ecster/net/v2';

import {
    CUSTOMER_GET_CUSTOMER_BEGIN,
    CUSTOMER_GET_CUSTOMER_SUCCESS,
    CUSTOMER_GET_CUSTOMER_FAILURE,
    CUSTOMER_GET_CUSTOMER_DISMISS_ERROR,
} from './constants';

import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import { GET_CUSTOMER_URL } from './urls';
import { ACCOUNT_GET_ACCOUNT_FAILURE } from '../../account/redux/constants';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_CUSTOMER_STATE = 'CLEAR_GET_CUSTOMER_STATE';

export const getCustomer = (customerId, updateFromCreditSystem) => async dispatch => {
    dispatch({
        type: CUSTOMER_GET_CUSTOMER_BEGIN,
    });

    try {
        const res = await get(GET_CUSTOMER_URL(customerId, updateFromCreditSystem));

        parseServerResponseStatus(res);

        dispatch({
            type: CUSTOMER_GET_CUSTOMER_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getCustomer');

        dispatch({
            type: CUSTOMER_GET_CUSTOMER_FAILURE,
            data: {
                error: {
                    message: err,
                    action: ACCOUNT_GET_ACCOUNT_FAILURE,
                },
            },
        });
    }
};

export const dismissGetCustomerError = () => ({ type: CUSTOMER_GET_CUSTOMER_DISMISS_ERROR });

export const clearGetCustomerState = () => ({ type: CLEAR_GET_CUSTOMER_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case CUSTOMER_GET_CUSTOMER_BEGIN:
            return {
                ...state,
                getCustomerPending: true,
                getCustomerError: null,
                getCustomerIsDone: false,
                getCustomerIsError: false,
            };

        case CUSTOMER_GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.data,
                getCustomerPending: false,
                getCustomerError: null,
                getCustomerIsDone: true,
            };

        case CUSTOMER_GET_CUSTOMER_FAILURE:
            return {
                ...state,
                getCustomerPending: false,
                getCustomerError: action.data.error,
                getCustomerIsError: true,
            };

        case CUSTOMER_GET_CUSTOMER_DISMISS_ERROR:
            return {
                ...state,
                getCustomerError: null,
                getCustomerIsError: false,
            };

        case CLEAR_GET_CUSTOMER_STATE:
            return {
                ...state,
                customer: null,
                getCustomerIsDone: false,
                getCustomerIsError: false,
            };
        default:
            return state;
    }
}
