import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, FullscreenDialog } from '@ecster/components';
import { getText as i18n } from '@ecster/i18n/lib/Translate';

import { SvgIconUpdateVersion } from './images';
import './UpdateVersion.scss';

const updateApp = () => {
    const { applicationCountry: country } = window.EcsterConfig;

    const platform = window?.device?.platform?.toUpperCase();

    const links = {
        SE: {
            IOS: 'itms-apps://itunes.apple.com/se/app/ecster/id1070488388',
            ANDROID: 'https://play.google.com/store/apps/details?id=se.ecster.mobile.android',
        },
        FI: {
            IOS: 'itms-apps://itunes.apple.com/se/app/ecster/id1583575414',
            ANDROID: 'https://play.google.com/store/apps/details?id=fi.ecster.login',
        },
    };

    window.location.href = links[country][platform];
};

const UpdateVersion = ({ status, close }) => (
    <div className="update-version">
        <FullscreenDialog show isCloseButtonVisible={status !== 'MUST_UPDATE'} onClose={() => close(false)}>
            <div className="center">
                <SvgIconUpdateVersion />
                <h1>{i18n(`update.${status}.header`)}</h1>
                {i18n(`update.${status}.body`, 'div')}

                <ButtonGroup alignCenter className="buttons">
                    {status === 'SHOULD_UPDATE' && (
                        <Button outline round className="buttons" onClick={() => close(false)}>
                            {i18n(`update.button.cancel`)}
                        </Button>
                    )}
                    <Button round onClick={updateApp}>
                        {i18n(`update.button.ok`)}
                    </Button>
                </ButtonGroup>

                <div />
            </div>
        </FullscreenDialog>
    </div>
);

UpdateVersion.propTypes = {
    status: PropTypes.string.isRequired,
    close: PropTypes.func,
};

UpdateVersion.defaultProps = {
    close: () => {},
};

export default UpdateVersion;
