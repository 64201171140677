import React from 'react';
import PropTypes from 'prop-types';

import { CopyToClipboardButton, LabelValue, Panel, PanelContent } from '@ecster/components';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import { formatAmount } from '../../../../common/util/format-amount';
import { getInvoiceHeaderDetails } from './getInvoiceHeaderDetails';

export const InvoiceHeader = ({ invoice }) => {
    const { hasNewInvoiceAmount, description, labelValues } = getInvoiceHeaderDetails(invoice);

    const descriptionClasses = classNames({
        description: true,
        'has-new-amount': hasNewInvoiceAmount,
    });

    return (
        <div className="invoice-invoice-header" data-testid="invoice-header">
            <Panel greenBG sideMarginsInMobile>
                <PanelContent narrow>
                    <h2 className="store-name">{invoice.storeName}</h2>
                    <CopyToClipboardButton
                        noIcon
                        value={`${invoice.amount / 100}`}
                        tooltip={i18n('invoice.one-off-invoice.invoice-header.copy-amount')}
                        tooltipSuccess={i18n('invoice.one-off-invoice.invoice-header.copy-success')}
                    >
                        <div className="amount">
                            <h1 className="amount-value light">{formatAmount(invoice.amount, { strip00: false })}</h1>
                        </div>
                    </CopyToClipboardButton>
                    <p className={descriptionClasses}>{description}</p>

                    {labelValues.map(({ label, value }) => (
                        <LabelValue key={label} green label={label} value={value} />
                    ))}
                </PanelContent>
            </Panel>
        </div>
    );
};

InvoiceHeader.propTypes = {
    invoice: PropTypes.object.isRequired,
};

InvoiceHeader.defaultProps = {};

export default InvoiceHeader;
