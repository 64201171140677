import { get } from '@ecster/net/v2';
import {
    INVOICE_GET_INVOICES_BEGIN,
    INVOICE_GET_INVOICES_SUCCESS,
    INVOICE_GET_INVOICES_FAILURE,
    INVOICE_GET_INVOICES_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const GET_INVOICES_URL = customerId => `/rest/ess/customers/v1/${customerId}/invoice`;

const CLEAR_GET_INVOICES_STATE = 'CLEAR_GET_INVOICES_STATE';

export const getInvoices = customerId => async dispatch => {
    dispatch({
        type: INVOICE_GET_INVOICES_BEGIN,
    });

    try {
        const key = GET_INVOICES_URL(customerId);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: INVOICE_GET_INVOICES_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err);

        dispatch({
            type: INVOICE_GET_INVOICES_FAILURE,
            data: {
                error: {
                    message: err,
                    action: INVOICE_GET_INVOICES_FAILURE,
                },
            },
        });
    }
};

export const dismissGetInvoicesError = () => ({ type: INVOICE_GET_INVOICES_DISMISS_ERROR });

export const clearGetInvoicesState = () => ({ type: CLEAR_GET_INVOICES_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case INVOICE_GET_INVOICES_BEGIN:
            return {
                ...state,
                getInvoicesPending: true,
                getInvoicesError: null,
                getInvoicesIsDone: false,
                getInvoicesIsError: false,
            };

        case INVOICE_GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.data,
                getInvoicesPending: false,
                getInvoicesError: null,
                getInvoicesIsDone: true,
            };

        case INVOICE_GET_INVOICES_FAILURE:
            return {
                ...state,
                getInvoicesPending: false,
                getInvoicesError: action.data.error,
                getInvoicesIsError: true,
            };

        case INVOICE_GET_INVOICES_DISMISS_ERROR:
            return {
                ...state,
                getInvoicesError: null,
                getInvoicesIsError: false,
            };

        case CLEAR_GET_INVOICES_STATE:
            return {
                ...state,
                invoices: [],
                getInvoicesIsDone: false,
                getInvoicesIsError: false,
            };

        default:
            return state;
    }
}
