import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { LabelValue, Mobile, Panel, PanelContent, ResponsivePanel, TabletOrDesktop } from '@ecster/components';
import { EcsterCard } from '../common/card/EcsterCard';

const GreenBox = ({ children }) => (
    <Panel paddingAll="large" greenBG sideMarginsInMobile>
        <PanelContent fullWidth>{children}</PanelContent>
    </Panel>
);

GreenBox.propTypes = { children: PropTypes.node.isRequired };

export default class ShowCardPanel extends Component {
    static propTypes = {
        account: PropTypes.shape().isRequired,
        accountCard: PropTypes.shape().isRequired,
    };

    static defaultProps = {};

    shouldComponentUpdate(nextProps) {
        const { account, accountCard } = this.props;

        return nextProps.account !== account && nextProps.accountCard !== accountCard;
    }

    render() {
        const { account, accountCard: card } = this.props;

        const cardName = (
            (account.product && account.product.name) ||
            i18n('card.show-card.default-card-name')
        ).toLocaleLowerCase(); // will be css cap'ed

        const isBlocked = card.status.endsWith('BLOCKED');

        const cardImage = <div className="card-ctr">{card?.brickId && <EcsterCard brickId={card.brickId} />}</div>;

        const cardInfo = (
            <div className="card-info-ctr">
                <h2 className="e-green120 card-name">{cardName}</h2>
                <LabelValue label={i18n('card.general.card-number')} value={card.cardNumber} green />
                <LabelValue label={i18n('card.general.card-holder')} value={card.holder} green />
                {!isBlocked && (
                    <LabelValue
                        label={i18n('card.general.valid-to')}
                        value={`${card.expires.year} / ${card.expires.month}`}
                        green
                    />
                )}
                <LabelValue label={i18n('card.general.status')} value={i18n(`card.show-card.${card.status}`)} green />
            </div>
        );

        return (
            <div className="card-show-card-panel">
                <TabletOrDesktop>
                    <GreenBox>
                        <ResponsivePanel>
                            {cardImage}
                            {cardInfo}
                        </ResponsivePanel>
                    </GreenBox>
                </TabletOrDesktop>
                <Mobile>
                    {cardImage}
                    <GreenBox>{cardInfo}</GreenBox>
                </Mobile>
            </div>
        );
    }
}
