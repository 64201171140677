import axios, { AxiosRequestConfig } from 'axios';
import { keyValueStorage } from './KeyValueStorage';
import { addQueryParams } from './add-query-params';
import { deepMerge } from './deep-merge-objects';
import { getCookie } from '@ecster/cookies';
import { KVS_ORIGIN, KVS_BASE_URL, ORIGIN_HEADER_NAME } from '../constants';

const timeout = 30000;

let errorHandlerHook = (error: unknown) => {};

// for cookie and header
export const X_ECSTER_SESSION_KEY_NAME: string = 'X-ECSTER-SESSION';

// A custom Axios instance, allows for better handling of custom headers and config
const axiosClient = axios.create({
    baseURL: '',
});

const sessionKey = getCookie(X_ECSTER_SESSION_KEY_NAME);

if (sessionKey) {
    axiosClient.defaults.headers = {
        ...axiosClient.defaults.headers,
        [X_ECSTER_SESSION_KEY_NAME]: sessionKey,
    };
}

// TODO 2023-11: use interceptor to create request config instead of the createConfig function
axiosClient.interceptors.request.use(config => {
    // modify/set config or config.headers here if needed
    return config;
});

/**
 * Values may get string representations of null or undefined after session storage
 */
const isUndefined = (value: unknown) => !value || value === 'undefined' || value === 'null';

/**
 * Create an axios config object with url, method, post/put data and additional config
 * - Origin header is set if setOrigin has been called
 * - axios baseURL is set if setBaseUrl has been called
 * TODO 2023-11: use interceptor to create request config instead of the createConfig function
 */
export const createConfig = (config: AxiosRequestConfig = { timeout }): AxiosRequestConfig => {
    const origin = keyValueStorage.getItem(KVS_ORIGIN);
    const baseURL = keyValueStorage.getItem(KVS_BASE_URL);
    const headers: Record<string, string> = {};

    if (!isUndefined(origin)) {
        headers[ORIGIN_HEADER_NAME] = origin;
    }

    const sessionKey = getCookie(X_ECSTER_SESSION_KEY_NAME);
    if (sessionKey) {
        headers[X_ECSTER_SESSION_KEY_NAME] = sessionKey;
    }

    return deepMerge(
        {
            baseURL: isUndefined(baseURL) ? '' : baseURL,
            headers,
            withCredentials: true,
        },
        config
    );
};

/**
 * Make an ajax POST request
 *
 * import { post } from '@ecster/net';
 */
export const post = <T = unknown>(url: string, data: unknown, config: AxiosRequestConfig = { timeout }): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        axiosClient
            .post(url, data, createConfig(config))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const err = error.response?.data || error;
                errorHandlerHook(err);
                reject(err);
            });
    });
};

/**
 * Make an ajax PUT request
 *
 *     import { put } from '@ecster/net';
 */
export const put = <T = unknown>(
    url: string,
    data: Record<string, unknown>,
    config: AxiosRequestConfig = { timeout }
): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        axiosClient
            .put(url, data, createConfig(config))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const err = error.response?.data || error;
                errorHandlerHook(err);
                reject(err);
            });
    });
};

/**
 * Make an ajax GET request
 *
 *     import { get } from '@ecster/net';
 *
 */
export const get = <T = unknown>(
    url: string,
    queryParams: Record<string, string | number | boolean> | null = null,
    config: AxiosRequestConfig = { timeout }
): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        axiosClient
            .get(addQueryParams(url, queryParams), createConfig(config))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const err = error.response?.data || error;
                errorHandlerHook(err);
                reject(err);
            });
    });
};

/**
 * Make an ajax DELETE request
 *
 *     import { del } from '@ecster/net';
 */
export const del = <T = unknown>(url: string, config: AxiosRequestConfig = { timeout }): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
        axiosClient
            .delete(url, createConfig(config))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                const err = error.response?.data || error;
                errorHandlerHook(err);
                reject(err);
            });
    });
};

/**
 * Set an origin value to be used for all subsequent ajax calls.
 * It's set in the X-Ecster-Origin header if a value is present
 *
 *     import { setOrigin } from '@ecster/net';
 *
 * @param {string} origin Origin string
 */
export const setOrigin = (origin: string) => {
    keyValueStorage.setItem(KVS_ORIGIN, origin);
};

/**
 * Add additional headers for all subsequent ajax calls.
 *
 *     import { setAdditionalHeaders } from '@ecster/net';
 *
 * @param {object} headers key value object with header names and header values
 */
export const setAdditionalHeaders = (headers: object) => {
    axiosClient.defaults.headers = {
        ...axiosClient.defaults.headers,
        ...headers,
    };
};

export const removeAdditionalHeader = (headerName: string) => {
    // @ts-ignore
    delete axiosClient.defaults.headers[headerName];
};

/**
 * Set a base URL for all Ajax calls (no trailing slash please)
 *
 *     import { setBaseUrl } from '@ecster/net';
 *
 * @param {string} url Baseurl for all ajax calls to relative URLs
 */
export const setBaseUrl = (url: string) => {
    keyValueStorage.setItem(KVS_BASE_URL, url && url.replace(/\/$/, ''));
};

/**
 * Get the current base URL
 *
 *     import { getBaseUrl } from '@ecster/net';
 *
 * @returns {string}
 */
export const getBaseUrl = (): string | undefined => {
    return keyValueStorage.getItem(KVS_BASE_URL);
};

/**
 * Set an error handler that will be called when Ajax promise is rejected
 *
 *     import { setErrorHandler } from '@ecster/net';
 *
 * @param {function} fn The error handler function
 */
export const setErrorHandlerHook = (fn: (error: unknown) => void) => {
    errorHandlerHook = fn;
};

/**
 * returns the error handler configured
 */
export const getErrorHandlerHook = () => {
    return errorHandlerHook;
};
