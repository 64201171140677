import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_BEGIN,
    ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_SUCCESS,
    ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_FAILURE,
    ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_DISMISS_ERROR,
} from './constants';
import { GET_ACCOUNT_CAMPAIGN_PURCHASES_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_ACCOUNT_CAMPAIGN_PURCHASES_STATE = 'CLEAR_GET_ACCOUNT_CAMPAIGN_PURCHASES_STATE';

export const getAccountCampaignPurchases = accountRef => async (dispatch, getState) => {
    dispatch({
        type: ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_BEGIN,
    });

    try {
        const customerId = getState().authentication.person.id;

        const key = GET_ACCOUNT_CAMPAIGN_PURCHASES_URL(customerId, accountRef);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_SUCCESS,
            data: res.response.campaignPurchases,
        });
    } catch (err) {
        reportError(err, 'getAccountCampaignPurchases');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetAccountCampaignPurchasesError = () => ({
    type: ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_DISMISS_ERROR,
});

export const clearGetAccountCampaignPurchasesState = () => ({ type: CLEAR_GET_ACCOUNT_CAMPAIGN_PURCHASES_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_BEGIN:
            return {
                ...state,
                getAccountCampaignPurchasesPending: true,
                getAccountCampaignPurchasesError: null,
                getAccountCampaignPurchasesIsDone: false,
                getAccountCampaignPurchasesIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_SUCCESS:
            return {
                ...state,
                accountCampaignPurchases: action.data,
                getAccountCampaignPurchasesPending: false,
                getAccountCampaignPurchasesError: null,
                getAccountCampaignPurchasesIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_FAILURE:
            return {
                ...state,
                getAccountCampaignPurchasesPending: false,
                getAccountCampaignPurchasesIsDone: false,
                getAccountCampaignPurchasesError: action.data.error,
                getAccountCampaignPurchasesIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_DISMISS_ERROR:
            return {
                ...state,
                getAccountCampaignPurchasesError: null,
                getAccountCampaignPurchasesIsError: false,
                getAccountCampaignPurchasesIsDone: false,
            };

        case CLEAR_GET_ACCOUNT_CAMPAIGN_PURCHASES_STATE:
            return {
                ...state,
                accountCampaignPurchases: null,
                getAccountCampaignPurchasesIsDone: false,
                getAccountCampaignPurchasesIsError: false,
            };

        default:
            return state;
    }
}
