import { post } from '@ecster/net/v2';

import {
    ACCOUNT_CREATE_ACCOUNT_CARD_BEGIN,
    ACCOUNT_CREATE_ACCOUNT_CARD_SUCCESS,
    ACCOUNT_CREATE_ACCOUNT_CARD_FAILURE,
    ACCOUNT_CREATE_ACCOUNT_CARD_DISMISS_ERROR,
} from './constants';
import { CREATE_ACCOUNT_CARD_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_CREATE_ACCOUNT_CARD_STATE = 'CLEAR_CREATE_ACCOUNT_CARD_STATE';
const CLEAR_CREATE_ACCOUNT_CARD_REDUX_STATE = 'CLEAR_CREATE_ACCOUNT_CARD_REDUX_STATE';

export const createAccountCard = (customerId, accountRef, freeTextMessage) => async dispatch => {
    dispatch({
        type: ACCOUNT_CREATE_ACCOUNT_CARD_BEGIN,
    });

    try {
        await cache.clear();
        const res = await post(CREATE_ACCOUNT_CARD_URL(customerId, accountRef), { freeTextMessage });

        dispatch({
            type: ACCOUNT_CREATE_ACCOUNT_CARD_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'createAccountCard');

        dispatch({
            type: ACCOUNT_CREATE_ACCOUNT_CARD_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissCreateAccountCardError = () => ({ type: ACCOUNT_CREATE_ACCOUNT_CARD_DISMISS_ERROR });

// clear the entire state including data
export const clearCreateAccountCardState = () => ({ type: CLEAR_CREATE_ACCOUNT_CARD_STATE });
// reset all redux state properties, i.e. the Pending, (is)Error and isDone properties
export const clearCreateAccountCardReduxState = () => ({ type: CLEAR_CREATE_ACCOUNT_CARD_REDUX_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_CREATE_ACCOUNT_CARD_BEGIN:
            return {
                ...state,
                createAccountCardPending: true,
                createAccountCardError: null,
                createAccountCardIsDone: false,
                createAccountCardIsError: false,
            };

        case ACCOUNT_CREATE_ACCOUNT_CARD_SUCCESS:
            return {
                ...state,
                createAccountCardPending: false,
                createAccountCardError: null,
                createAccountCardIsDone: true,
            };

        case ACCOUNT_CREATE_ACCOUNT_CARD_FAILURE:
            return {
                ...state,
                createAccountCardPending: false,
                createAccountCardError: action.data.error,
                createAccountCardIsError: true,
            };

        case ACCOUNT_CREATE_ACCOUNT_CARD_DISMISS_ERROR:
            return {
                ...state,
                createAccountCardError: null,
                createAccountCardIsError: false,
            };

        case CLEAR_CREATE_ACCOUNT_CARD_REDUX_STATE:
        case CLEAR_CREATE_ACCOUNT_CARD_STATE:
            return {
                ...state,
                createAccountCardPending: false,
                createAccountCardError: null,
                createAccountCardIsDone: false,
                createAccountCardIsError: false,
            };

        default:
            return state;
    }
}
