import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getBillProperties } from '../util';

import { Header, Transactions, PaymentAlternatives, PaymentMethods } from './account-invoice';

export class AccountInvoicePageContent extends Component {
    static propTypes = {
        bill: PropTypes.object.isRequired,
    };

    render() {
        const { bill } = this.props;
        const billProps = getBillProperties(bill);

        return (
            <>
                <Header bill={bill} billProps={billProps} />
                <Transactions bill={bill} billProps={billProps} />
                {!bill.paymentFreeMonth && !billProps.allLabsEqual && (
                    <PaymentAlternatives bill={bill} billProps={billProps} />
                )}
                <PaymentMethods bill={bill} billProps={billProps} />
            </>
        );
    }
}
