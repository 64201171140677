import { ServerLog as log } from '@ecster/logging';
import Bowser from 'bowser';
import { getOriginCookie } from './OriginCookie';

let person = {};
const UNKNOWN = 'unknown';

const getPerson = () => {
    if (!person.ssn || person.ssn === UNKNOWN) {
        try {
            person = JSON.parse(JSON.parse(window.sessionStorage.getItem('persist:root')).authentication).person;
        } catch (e) {
            // ignore errors
        }
    }

    return person.ssn ? person : { ssn: UNKNOWN };
};

const getLogData = data => {
    const theData = { ...data };
    const person = getPerson();
    const { EcsterConfig: conf } = window;

    theData.ecsterOriginId = getOriginCookie();

    if (person.ssn) {
        theData.ssn = person.ssn;
    }

    try {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const { name, version } = browser.getBrowser();
        theData.browserName = name;
        theData.browserVersion = version;
    } catch (e) {
        theData.MyPagesServerLogError = 'Failed to parse userAgent';
    }

    theData.url = window?.location?.href;

    return theData;
};

const info = (operation, data = {}, description = '') => {
    log.info(`${operation}`, 'my-pages-client', getLogData(data), description);
};

const warning = (operation, data = {}, description = '') =>
    log.warning(`${operation}`, 'my-pages-client', getLogData(data), description);

const error = (operation, data = {}, description = '') =>
    log.error(`${operation}`, 'my-pages-client', getLogData(data), description);

export const ServerLog = { info, warning, error };
