import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_BILL_DETAILS_BEGIN,
    ACCOUNT_GET_ACCOUNT_BILL_DETAILS_SUCCESS,
    ACCOUNT_GET_ACCOUNT_BILL_DETAILS_FAILURE,
    ACCOUNT_GET_ACCOUNT_BILL_DETAILS_DISMISS_ERROR,
    ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
} from './constants';
import { GET_ACCOUNT_BILL_DETAILS_URL } from './urls';
import initialState from './initialState';
import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import returnUniqueErrors from '../../../common/util/return-unique-errors';

import { isSE } from '../../../common/country';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_ACCOUNT_BILL_DETAILS_STATE = 'CLEAR_GET_ACCOUNT_BILL_DETAILS_STATE';

export const getAccountBillDetails = (customerId, accountRef) => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_ACCOUNT_BILL_DETAILS_BEGIN,
    });

    try {
        const { language } = window.EcsterConfig;
        const key = GET_ACCOUNT_BILL_DETAILS_URL(customerId, accountRef, language);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        parseServerResponseStatus(res);
        if (!cached) cache.set(key, res);

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_BILL_DETAILS_SUCCESS,
            data: res.response,
            accountRef,
        });
    } catch (err) {
        reportError(err, 'getAccountBillDetails');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_BILL_DETAILS_FAILURE,
            data: {
                accountRef,
                error: {
                    message: err,
                    action: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
                },
            },
            accountRef,
        });
    }
};

export const dismissGetAccountBillDetailsError = () => ({ type: ACCOUNT_GET_ACCOUNT_BILL_DETAILS_DISMISS_ERROR });

export const clearGetAccountBillDetailsState = () => ({ type: CLEAR_GET_ACCOUNT_BILL_DETAILS_STATE });

const processBillData = data => {
    const result = JSON.parse(JSON.stringify(data));
    if (isSE() && result.accountBillTransactions) {
        const incomingDebt = result.accountBillTransactions.shift();
        const outgoingDebt = result.accountBillTransactions.pop();
        result.balanceBroughtForward = incomingDebt && incomingDebt.amount;
        result.balanceCarriedForward = outgoingDebt && outgoingDebt.amount;
        result.outgoingDebt = outgoingDebt;
    }
    return result;
};

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_BILL_DETAILS_BEGIN:
            return {
                ...state,
                getAccountBillDetailsPending: true,
                getAccountBillDetailsError: null,
                getAccountBillDetailsIsDone: false,
                getAccountBillDetailsIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_BILL_DETAILS_SUCCESS:
            return {
                ...state,
                accountBillDetails: { ...state.accountBillDetails, [action.accountRef]: processBillData(action.data) },
                getAccountBillDetailsPending: false,
                getAccountBillDetailsIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_BILL_DETAILS_FAILURE:
            return {
                ...state,
                accountBillDetails: initialState.accountBillDetails,
                getAccountBillDetailsPending: false,
                getAccountBillDetailsIsDone: false,
                getAccountBillDetailsError: returnUniqueErrors({
                    errors: state.getAccountBillDetailsError,
                    key: 'accountRef',
                    action,
                }),
                getAccountBillDetailsIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_BILL_DETAILS_DISMISS_ERROR:
            return {
                ...state,
                getAccountBillDetailsError: null,
                getAccountBillDetailsIsError: false,
                getAccountBillDetailsIsDone: false,
            };

        case CLEAR_GET_ACCOUNT_BILL_DETAILS_STATE:
            return {
                ...state,
                accountBillDetails: {},
                getAccountBillDetailsIsDone: false,
                getAccountBillDetailsIsError: false,
            };
        default:
            return state;
    }
}
