import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from '@ecster/components';
import './LoginResponsivePanel.scss';
import classNames from 'classnames';

export const LoginResponsivePanel = ({ children, className, fullscreenInMobile, ...rest }) => {
    const classes = classNames({
        'login-responsive-panel': true,
        'fullscreen-in-mobile': fullscreenInMobile,
        [className]: className,
    });

    return (
        <Panel className={classes} {...rest}>
            {children}
        </Panel>
    );
};

LoginResponsivePanel.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    fullscreenInMobile: PropTypes.bool,
};

LoginResponsivePanel.defaultProps = {
    fullscreenInMobile: false,
    className: '',
};
