// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import './Card.scss';

interface CardProps {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
}

/**
 * @deprecated Use [Panel](#panel) instead
 */
const Card = ({ children, style, className }: CardProps) => {
    return (
        <div className={`card ${className}`} style={style}>
            {children}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.shape(),
};

Card.defaultProps = {
    children: [],
    className: '',
    style: {},
};

export default Card;
