export const emailServicesDomains = [
    'yahoo.com',
    'hotmail.com',
    'gmail.com',
    'mail.com',
    'protonmail.com',
    'yandex.com',
    'telia.com',
    'live.se',
    'hotmail.se',
    'outlook.com',
    'yahoo.se',
    'yahoo.com',
    'icloud.com',
    'spray.se',
    'bredband.net',
    'comhem.se',
    'msn.com',
    'live.com',
    'tele2.se',
    'me.com',
    'home.se',
    'giganten.se',
    'swipnet.se',
    'bahnhof.se',
    '3mail.se',
    'glocalnet.net',
    'handelsbanken.se',
    'tvprodukter.com',
    'kth.se',
    'bredband2.com',
    'passagen.se',
    'ownit.nu',
    'ymail.com',
    'mac.com',
    'wp.pl',
    'norscand.com',
    'mail.ru',
    'yahoo.fr',
    'yahoo.co.uk',
    'skenejarn.se',
    'vgregion.se',
    'chalmers.se',
    'volvo.com',
    'svenskakyrkan.se',
    'mrevent.nu',
    'gmail.se',
    'rocketmail.com',
    'ecster.se',
    'hotmail.co.uk',
    'ericsson.com',
    'protonmail.com',
    'saknas.se',
    'eldkraft.se',
    'z-aim.com',
    'mockfjards.se',
    'seb.se',
    'chello.se',
    'volvocars.com',
    'netatonce.net',
    'malmo.se',
    'telia.se',
    'oktv.se',
    'engelska.se',
    'blixtmail.se',
    'peab.se',
    'online.no',
    'mail.se',
    'orebro.se',
    'stockholm.se',
    'live.dk',
    'allt2.se',
    'sverige.nu',
    'bilenochjag.se',
    'ki.se',
    'koping.net',
    'giganten.dk',
    'gmx.de',
    'web.de',
    'aol.com',
    'ncc.se',
    'audiovideo.se',
    'tyfonmail.se',
    'scania.com',
    'vrg.se',
    'skanska.se',
    'email.com',
    'sll.se',
    'gmx.com',
    'tninet.se',
    'vattenfall.com',
    'euronics.se',
    'electroluxhome.com',
    'k-rauta.se',
    'umea.se',
    'compaqnet.se',
    'sweco.se',
    'live.no',
    'hotmail.fr',
    'googlemail.com',
    'eskilstuna.se',
    'ptj.se',
    'aol.se',
    'se.abb.com',
    'sandvik.com',
    'sjobo.nu',
    'yahoo.no',
];

export const protectedKeyCodes = [9, 17, 18, 35, 36, 37, 38, 39, 40, 45];
