import React, { ButtonHTMLAttributes, Component, FocusEventHandler, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import './InteractiveElement.scss';

interface InteractiveElementProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string;
    name?: string;

    /**
     * The text shown to screen readers
     */
    ariaLabel?: string;
    className?: string;
    children?: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onMouseOver?: MouseEventHandler<HTMLButtonElement>;
    onMouseOut?: MouseEventHandler<HTMLButtonElement>;
    onBlur?: FocusEventHandler<HTMLButtonElement>;
    onFocus?: FocusEventHandler<HTMLButtonElement>;
}

class InteractiveElement extends Component<InteractiveElementProps> {
    buttonRef = React.createRef<HTMLButtonElement>();

    onClick = e => {
        const { onClick } = this.props;

        if (typeof onClick === 'function') onClick(e);
    };

    render() {
        const { className, children, id, name, onMouseOver, onMouseOut, onBlur, onFocus, ariaLabel, ...rest } =
            this.props;
        const classes = classNames({
            'ec-interactive-element': true,
            [className]: className,
        });
        return (
            <button
                id={id}
                aria-label={ariaLabel}
                name={name}
                onClick={this.onClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onBlur={onBlur}
                onFocus={onFocus}
                className={classes}
                ref={this.buttonRef}
                {...rest}
            >
                {children}
            </button>
        );
    }
}

// @ts-expect-error class defaultProps
InteractiveElement.defaultProps = {
    id: undefined,
    name: '',
    ariaLabel: '',
    className: '',
    children: <div />,
    onClick: () => {},
    onMouseOver: () => {},
    onMouseOut: () => {},
    onBlur: () => {},
    onFocus: () => {},
};

export default InteractiveElement;
