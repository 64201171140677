// @ts-nocheck
/* eslint-disable no-shadow,no-undef */
import { desktopStart, mobileEnd, tabletEnd, tabletStart } from './breakpoints';

import debounce from '../util/debounce';

const subscriptions = [];

export const measure = () => {
    const width = window.innerWidth;

    return {
        isMobile: width <= mobileEnd,
        isTablet: width >= tabletStart && width <= tabletEnd,
        isDesktop: width >= desktopStart,
    };
};

let lastMeasures = measure();

const notify = () => {
    const measures = measure();
    const shouldNotify =
        lastMeasures.isMobile !== measures.isMobile ||
        lastMeasures.isTablet !== measures.isTablet ||
        lastMeasures.isDesktop !== measures.isDesktop;

    if (shouldNotify) {
        subscriptions.map(subscription => subscription(measures));
    }

    lastMeasures = measures;
};

const onResize = debounce(notify, 50);

window.addEventListener('resize', () => {
    requestAnimationFrame(() => {
        onResize();
    });
});

export const subscribe = callback => {
    subscriptions.push(callback);
};

export const unSubscribe = callback => {
    subscriptions.splice(subscriptions.indexOf(callback), 1);
};
