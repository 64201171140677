const storeValueForNameInState = (e, that, callback = () => {}, forceThisValue) => {
    const { target } = typeof e === 'object' ? e : window.event;
    const value = forceThisValue || (target.type === 'checkbox' ? target.checked : target.value);
    const { name } = target;

    that.setState(
        {
            [name]: value,
        },
        callback
    );
};

export default storeValueForNameInState;
