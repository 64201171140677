/**
 * The key name for storing the Ecster origin string in session storage
 */
export const KVS_ORIGIN = 'origin';

/**
 * The key name for storing the REST base URL in session storage
 */
export const KVS_BASE_URL = 'baseUrl';

/**
 * The header name used when sending the Ecster origin string
 'X-ECSTER-origin'
 */
export const ORIGIN_HEADER_NAME = 'X-ECSTER-origin';
