import React from 'react';
import PropTypes from 'prop-types';

import { Panel, PanelContent } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { handWithMoneyIcon } from '@ecster/icons/H80/green';

export const RepaymentInfoPanel = ({ id }) => {
    return (
        <Panel sideMarginsInMobile id={id}>
            <PanelContent centered>
                <img src={handWithMoneyIcon} alt="" className="mb-4x" />
                <h2 className="h3">{i18n('contract.repayment-info.header')}</h2>
                <p dangerouslySetInnerHTML={{ __html: i18n('contract.repayment-info.info') }} />
            </PanelContent>
        </Panel>
    );
};

RepaymentInfoPanel.propTypes = { id: PropTypes.string.isRequired };
