import React, { ButtonHTMLAttributes, Component, MouseEventHandler } from 'react';
import classNames from 'classnames';
import InteractiveElement from './InteractiveElement';

import './IconButton.scss';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string;
    name?: string;

    /**
     * The text shown to screen readers
     */
    ariaLabel?: string;
    className?: string;
    onClick: MouseEventHandler<HTMLButtonElement>;

    // size

    /**
     * A small icon button.
     *
     * Alt: style with font-size in app
     */
    small?: boolean;

    /**
     * A medium sized icon button.
     *
     * Alt: style with font-size in app
     */
    medium?: boolean;

    /**
     * A large icon button.
     *
     * Alt: style with font-size in app
     */
    large?: boolean;

    /**
     * Ecster icon font classname.
     *
     * E.g. icon-edit
     */
    icon: string;

    // colors
    green?: boolean;
    blue?: boolean;
    purple?: boolean;
    red?: boolean;
    beige?: boolean;
    gray?: boolean;
    white?: boolean;
}

class IconButton extends Component<IconButtonProps> {
    render() {
        const {
            className,
            icon,
            small,
            medium,
            large,
            green,
            blue,
            purple,
            red,
            beige,
            gray,
            white,
            ariaLabel,
            ...rest
        } = this.props;
        const classes = classNames({
            'ec-icon-button': true,
            'ec-small': small,
            'ec-medium': medium,
            'ec-large': large,
            'ec-green': green,
            'ec-blue': blue,
            'ec-purple': purple,
            'ec-red': red,
            'ec-beige': beige,
            'ec-gray': gray,
            'ec-white': white,
            [className]: className,
        });

        const Icon = () => <i className={icon} />;

        return (
            <InteractiveElement className={classes} {...rest} ariaLabel={ariaLabel}>
                <Icon />
            </InteractiveElement>
        );
    }
}

// @ts-expect-error class defaultProps
IconButton.defaultProps = {
    id: undefined,
    name: '',
    ariaLabel: '',
    className: '',

    small: undefined,
    medium: undefined,
    large: undefined,

    green: undefined,
    blue: undefined,
    purple: undefined,
    red: undefined,
    beige: undefined,
    gray: undefined,
    white: undefined,
};

export default IconButton;
