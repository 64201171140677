import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import './ButtonGroup.scss';

interface ButtonGroupProps {
    /**
     * Extra classes to add to button container
     */
    className?: string;

    /**
     * Extra styling to add to button container.
     *
     * Avoid! Use className and css instead
     */
    style?: CSSProperties;

    /**
     * Button alignment: left, right or center
     * @deprecated use alignLeft, alignCenter or alignRight instead
     */
    align?: string;

    /**
     * Align buttons to left in container (default)
     */
    alignLeft?: boolean;

    /**
     * Align buttons centered in container
     */
    alignCenter?: boolean;

    /**
     * Align buttons right in container
     */
    alignRight?: boolean;

    /**
     * Align buttons centered on top of each other
     */
    alignColumn?: boolean;

    /**
     * Add the top and bottom paddings, false removes them.
     *
     * Shorthand for both spaceAbove and spaceBelow
     */
    space?: boolean;

    /**
     * Add the top padding, false removes it.
     */
    spaceAbove?: boolean | string;

    /**
     * Add the bottom padding, false removes it.
     */
    spaceBelow?: boolean;

    children: ReactNode;
}

const ButtonGroup = (props: ButtonGroupProps) => {
    const {
        alignLeft,
        alignCenter,
        alignRight,
        alignColumn,
        align,
        spaceAbove,
        spaceBelow,
        space,
        className,
        children,
        style,
    } = props;

    const noAlign = !(alignLeft || alignRight || alignCenter || alignColumn || align);
    return (
        <div
            style={style}
            className={classNames({
                'ec-button-group': true,
                'align-left': alignLeft || align === 'left' || noAlign,
                'align-right': alignRight || align === 'right',
                'align-center': alignCenter || align === 'center',
                'align-column': alignColumn,
                'space-above': spaceAbove && space,
                'space-below': spaceBelow && space,
                'space-above-large': spaceAbove === 'large' && space,
                [className]: className,
            })}
        >
            {children}
        </div>
    );
};

ButtonGroup.defaultProps = {
    align: undefined,
    alignLeft: false,
    alignCenter: false,
    alignRight: false,
    space: true,
    spaceAbove: true,
    spaceBelow: true,
    className: '',
    style: {},
};

export default ButtonGroup;
