// Swedish mobile phone number

export const validateMobilePhoneNumberSE = input => {
    if (!input) {
        return true;
    }

    const trimmedValue = input.replace(/[ ()-]/g, '');

    // 1. 0703402121 is valid = 10 digits starting with zero
    // 2. 703402121 is valid = 9 digits not starting with zero
    // 3. All other cases are invalid
    // keep old regexp for reference for a while: const regex = /^(\b[0]\d{2})(\d{7}\b)|(\b^(?![0])\d{9}\b)/;

    // alt 1: start of value. zero. non zero. 8 digits. end of value
    // alt 2: start of value. non zero. 8 digits. end of value
    const regex = /^((0[1-9][0-9]{8})|([1-9][0-9]{8}))$/;

    // input must not start with ')', '-' or ' '
    const regex2 = /^[) -]/;

    // input must end with a number
    const regex3 = /[0-9]$/;

    return regex.test(trimmedValue) && !regex2.test(input) && regex3.test(input);
};

export default validateMobilePhoneNumberSE;