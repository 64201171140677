import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { setPreviousPath } from '../features/common/redux/actions';

const HistoryListener = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const persistedPreviousPath = useSelector(state => state.common.previousPath);

    const [recentPaths, setRecentPaths] = useState({ prev: null, curr: persistedPreviousPath || null });

    useEffect(() => {
        // Temporarily disable server log for black month 2022
        // ServerLog.info(`change page to ${history.location.pathname}`, '');
        setRecentPaths(prevState => ({ prev: prevState.curr, curr: history.location.pathname }));
    }, [history.location.pathname]);

    useEffect(() => {
        if (recentPaths.prev) {
            dispatch(setPreviousPath(recentPaths.prev));
        }
    }, [recentPaths, dispatch]);

    return null;
};

export default withRouter(HistoryListener);
