import React from 'react';
import PropTypes from 'prop-types';

import './ExpandHeader.scss';

export const ExpandHeader = ({ icon, text, info }) => (
    <div className="expand-header">
        <div className="expand-header-left">
            <div className="icon-ctr">{icon}</div>
            <div className="text-ctr">
                <div>{text}</div>
                {info && <div className="weak">{info}</div>}
            </div>
        </div>
    </div>
);

ExpandHeader.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    info: PropTypes.string,
};
ExpandHeader.defaultProps = { info: null };
