// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
    customer: { contactInformation: {} },
    customerExtraCardHolders: [],

    SHOW_PRIVATLAN_MENU: false,
    SHOW_LOAN_PROTECTION: false,

    // generated
    getCustomerPending: false,
    getCustomerError: null,
    getCustomerPropertiesPending: false,
    getCustomerPropertiesError: null,
    getCustomerExtraCardHoldersPending: false,
    getCustomerExtraCardHoldersError: null,
    updateCustomerContactInfoPending: false,
    updateCustomerContactInfoError: null,
    updateCustomerExtraCardHolderContactInfoPending: false,
    updateCustomerExtraCardHolderContactInfoError: null,
    createCustomerServiceRequestPending: false,
    createCustomerServiceRequestError: null,
};

export default initialState;
