import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_BEGIN,
    ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_SUCCESS,
    ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_FAILURE,
    ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_DISMISS_ERROR,
} from './constants';

import { GET_ACCOUNT_LIMIT_RAISE_TERMS_URL } from './urls';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_ACCOUNT_LIMIT_RAISE_TERMS_STATE = 'CLEAR_GET_ACCOUNT_LIMIT_RAISE_TERMS_STATE';

export const getAccountLimitRaiseTerms = (country, lang) => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_BEGIN,
    });

    try {
        const res = await get(GET_ACCOUNT_LIMIT_RAISE_TERMS_URL(country, lang));
        dispatch({
            type: ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getAccountLimitRaiseTerms');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetAccountLimitRaiseTermsError = () => ({
    type: ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_DISMISS_ERROR,
});

export const clearGetAccountLimitRaiseTermsState = () => ({ type: CLEAR_GET_ACCOUNT_LIMIT_RAISE_TERMS_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_BEGIN:
            return {
                ...state,
                getAccountLimitRaiseTermsPending: true,
                getAccountLimitRaiseTermsError: null,
                getAccountLimitRaiseTermsIsDone: false,
                getAccountLimitRaiseTermsIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_SUCCESS:
            return {
                ...state,
                accountLimitRaiseTerms: action.data,
                getAccountLimitRaiseTermsPending: false,
                getAccountLimitRaiseTermsError: null,
                getAccountLimitRaiseTermsIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_FAILURE:
            return {
                ...state,
                getAccountLimitRaiseTermsPending: false,
                getAccountLimitRaiseTermsError: action.data.error,
                getAccountLimitRaiseTermsIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_DISMISS_ERROR:
            return {
                ...state,
                getAccountLimitRaiseTermsError: null,
                getAccountLimitRaiseTermsIsError: false,
            };

        case CLEAR_GET_ACCOUNT_LIMIT_RAISE_TERMS_STATE:
            return {
                ...state,
                accountLimitRaiseTerms: null,
                getAccountLimitRaiseTermsIsDone: false,
                getAccountLimitRaiseTermsIsError: false,
            };
        default:
            return state;
    }
}
