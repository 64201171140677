import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from '@ecster/net/v2';
import { Panel } from '@ecster/components';
import LoginPageTemplate from '../features/common/templates/LoginPageTemplate';
import { isFI } from './country';
import { reportError } from './reportError';
import { fetchConfig, setConfig } from './MyPagesConfig';
import { createCanaryCookie } from './canaryValues';
import BackendIsDown from './BackendIsDown';
import UpdateVersion from './UpdateVersion';

import './maxWidthLoginBackplate.scss';
import { GatewayContextProvider } from '../context/gateway-context';

const isItTimeToUpdate = async conf => {
    const devicePlatform = window.device && window.device.platform && window.device.platform.toUpperCase(); // Only returns something when on Cordova

    if (devicePlatform === 'ANDROID' || devicePlatform === 'IOS') {
        const version = conf.version === '%BUILD_VERSION%' ? '4.0.13' : conf.version;
        const { applicationCountry } = window.EcsterConfig;

        try {
            const {
                response: { status },
            } = await get(`/rest/eos/v1/mobile/version/${applicationCountry}/${devicePlatform}/${version}`);
            return status === 'OK' ? false : status;
        } catch (e) {
            reportError(e, 'isItTimeToUpdate');
            return false;
        }
    }

    return false;
};

const checkStatus = async () => {
    try {
        const response = await get(`/rest/eos/v1/externalsystem`);
        const acsStatus = response.response.filter(backend => backend.system === 'ACS')[0];
        const ecrStatus = response.response.filter(backend => backend.system === 'ECR')[0];
        const etapStatus = response.response.filter(backend => backend.system === 'ETAP')[0];

        const acs = acsStatus ? acsStatus.isOnline : true; // assume up if no status is found
        const ecr = ecrStatus ? ecrStatus.isOnline : true; // same!
        const etap = etapStatus ? etapStatus.isOnline : true; // same!

        if (isFI()) {
            return {
                isBackendUp: etap,
            };
        }

        const status = {
            isBackendUp: acs || ecr, // we're up if one service is up
        };
        return status;
    } catch (e) {
        reportError(e, 'checkBackendStatus');
        return {
            isBackendUp: true,
        };
    }
};

class Gateway extends Component {
    state = {
        isBackendUp: true,
        isReadyToUpdate: false,
        backPlateSize: null,
        isLoading: true,
    };

    async componentDidMount() {
        const { config } = this.props;
        createCanaryCookie();

        const myPagesConfig = await fetchConfig();
        setConfig(myPagesConfig); // session storage for later use
        this.setState({ isLoading: false });

        const { isBackendUp } = await checkStatus();

        this.setState({ isBackendUp });
        this.setState({ isReadyToUpdate: await isItTimeToUpdate(config) });
        const size = getComputedStyle(document.documentElement).getPropertyValue('--back-plate-size');
        this.setState({ backPlateSize: size });
        this.setState({ myPagesConfig });
    }

    render() {
        const { App } = this.props;
        const { isBackendUp, isReadyToUpdate, isLoading, backPlateSize, myPagesConfig } = this.state;

        const shutdownStatus = myPagesConfig?.mobileAppShutdownConfig?.status;

        if (shutdownStatus === 'DISABLED') {
            return <GatewayContextProvider value={{ isLoading }}>{App}</GatewayContextProvider>;
        }

        if (isBackendUp === false) {
            return (
                <React.Suspense fallback="">
                    <LoginPageTemplate isNoMessages>
                        <Panel id="home-login-page" maxWidth={backPlateSize} className="login-page-panel">
                            <BackendIsDown />
                        </Panel>
                    </LoginPageTemplate>
                </React.Suspense>
            );
        }

        return isReadyToUpdate === false ? (
            <GatewayContextProvider value={{ isLoading }}>{App}</GatewayContextProvider>
        ) : (
            <React.Suspense fallback="">
                <UpdateVersion
                    status={isReadyToUpdate}
                    close={isReady => this.setState({ isReadyToUpdate: isReady })}
                />
            </React.Suspense>
        );
    }
}

Gateway.propTypes = {
    App: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
};

export default Gateway;
