import React from 'react';
import classNames from 'classnames';

import { FileList } from './FileList';

import './FileSelector.scss';

interface FileSelectorProps {
    /**
     * A key/value object with File objects.
     * - key - filename
     * - value - file object (or any kind of object, File object when using with FileSelector)
     */
    files: { [key: string]: any };

    /**
     * Function is called when list of selected files change
     * (the list changes when files are selected, dropped or removed from list).
     *
     * Set the files property with the new value.
     *
     * The files object can be passed to the @ecster/net/uploadFile function
     *
     * @param {object} files An object with file entries, keys are filenames, values are File objects
     */
    onChange: Function;

    /**
     * CSS Id
     */
    id?: string;

    /**
     * Label for select files button
     */
    label?: string;

    /**
     * Which file types to accept. It only sets the filter in the file explorer. The filter can
     * easily be changed by the user. The accept property does not affect which file types that
     * can dragged and dropped.
     *
     * File type checks must be performed by the backend service receiving the files.
     *
     * More info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
     */
    accept?: string[];

    /**
     * Accept more than one file?
     */
    multiple?: boolean;

    /**
     * Max file size in bytes. 1 Mb is default. Value is per file if multiple is enabled.
     *
     * Note: not yet implemented
     */
    maxSizeBytes?: number;

    /**
     * Align button and file list to the left in container
     */
    alignLeft?: boolean;

    /**
     * Align button and file list centered in container
     */
    alignCenter?: boolean;

    /**
     * Align button and file list to the right in container
     */
    alignRight?: boolean;

    /**
     * Transparent BG, green text, light green overlay on drag
     */
    green?: boolean;

    /**
     * Transparent BG, purple text, light purple overlay on drag
     */
    purple?: boolean;

    /**
     * Transparent BG, white text, transparent black overlay on drag
     */
    white?: boolean;

    /**
     * Rounded corners
     */
    round?: boolean;
}

export class FileSelector extends React.Component<FileSelectorProps> {
    state = {
        fileSelectorId: `ec-file-selector-${Math.round(Math.random() * 100000)}`,
        dragging: false,
    };

    // count drag enter events
    dragEnter = 0;

    dragLeave = 0;

    // <input type=file> changes, selected or dropped files
    onFileInputChange = ({ target }) => {
        const { multiple, files: oldFiles, onChange } = this.props;
        let updatedFiles;
        if (target.files) {
            if (multiple) {
                const newFiles = {};
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < target.files.length; i++) {
                    const file = target.files[i];
                    file.fileName = file.name;

                    newFiles[file.name] = file;
                }
                updatedFiles = { ...oldFiles, ...newFiles };
            } else {
                const file = target.files[0];
                file.fileName = file.name;
                updatedFiles = { [file.name]: file };
            }
            onChange(updatedFiles);
        }
    };

    // <FileList> changes (delete icon clicked)
    onFileListChange = files => {
        const { onChange } = this.props;
        onChange(files);
    };

    onDragEnter = () => {
        this.dragEnter += 1;
        if (this.dragEnter - this.dragLeave > 0) this.setState({ dragging: true });
    };

    onDragLeave = () => {
        this.dragLeave += 1;
        if (this.dragEnter - this.dragLeave === 0) this.setState({ dragging: false });
    };

    onDrop = () => {
        this.dragEnter = 0;
        this.dragLeave = 0;
        this.setState({ dragging: false });
    };

    onDeleteFile = (filename: string) => {
        const { files, onChange } = this.props;
        const newList = { ...files };
        delete newList[filename];
        onChange(newList);
    };

    render() {
        const {
            id,
            files,
            label,
            accept,
            multiple,
            alignLeft,
            alignRight,
            alignCenter,
            green,
            purple,
            white,
            round,
        } = this.props;
        const { fileSelectorId, dragging } = this.state;

        const classes = classNames({
            'ec-file-selector': true,
            'is-dragging': dragging,
            'rounded-corners': round,
            // align elements
            'align-left': alignLeft,
            'align-right': alignRight,
            'align-center': alignCenter && !(alignLeft || alignRight),
            // colors
            'ui-green': green && !(purple || white),
            'ui-purple': purple,
            'ui-white': white,
        });

        const theLabel = label || (multiple ? 'Select files' : 'Select file');

        return (
            <div id={id} className={classes} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <label className="selector-button" htmlFor={fileSelectorId}>
                    <i className="icon-upload" />
                    {theLabel}
                </label>
                <div className="select-info">Click or drag and drop to select files</div>
                <input
                    className="file-input"
                    id={fileSelectorId}
                    type="file"
                    multiple={multiple}
                    accept={accept && accept.join(',')}
                    onChange={this.onFileInputChange}
                    onDrop={this.onDrop}
                />
                <div className="file-list">
                    <FileList
                        white={white}
                        purple={purple}
                        files={files}
                        onDeleteFile={this.onDeleteFile}
                    />
                </div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
FileSelector.defaultProps = {
    id: 'ec-file-selector-input',
    label: '',
    accept: ['.png', '.jpg', '.pdf'],
    multiple: false,
    maxSizeBytes: 1024 * 1024,
    round: false,
    // align elements
    alignLeft: false,
    alignCenter: true,
    alignRight: false,
    // colors
    green: true,
    purple: false,
    white: false,
};
