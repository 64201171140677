import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';

import { formatDate } from '../../../common/util/format-date';

export const DueDate = ({ date }) => (
    <LabelValue
        label={i18n('invoice.general.due-date')}
        value={
            <CopyToClipboardButton
                id="copyDueDate"
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-due-date')}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={formatDate(date)}
            >
                {formatDate(date)}
            </CopyToClipboardButton>
        }
    />
);
DueDate.propTypes = { date: PropTypes.string.isRequired };
