// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { ContractInvoicePage, ContractTermsPage, ContractRepaymentPage, ContractRepaymentTermsPage } from '.';

import { isSE, isFI } from '../../common/country';

const seRoutes = isSE()
    ? [
          { path: ':contractNumber/invoice', name: 'Contract invoice page', component: ContractInvoicePage },
          { path: ':contractNumber/terms', name: 'Contract terms page', component: ContractTermsPage },
          {
              path: ':contractNumber/:id/repayment/:newRequest?',
              name: 'Contract repayment page',
              component: ContractRepaymentPage,
          },
          {
              path: ':contractNumber/:id/repayment-terms',
              name: 'Contract repayment terms page',
              component: ContractRepaymentTermsPage,
          },
      ]
    : [];

const fiRoutes = isFI() ? [] : [];

const routes = {
    path: 'contract',
    name: 'Contract',
    childRoutes: [...seRoutes, ...fiRoutes],
};

export default routes;
