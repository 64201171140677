import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import {
    Panel,
    PanelContent,
    LabelValue,
    ButtonGroup,
    Button,
    LinkButton,
    Spinner,
    Message,
    Dialog,
    DialogBody,
    DialogFooter,
    Input,
    Form,
    CopyToClipboardButton,
} from '@ecster/components';
import { reduxActionSucceeded, reduxActionFailed } from '@ecster/util';
import { happyFaceIcon } from '@ecster/icons/H80/green';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import { ContractIcon } from '../common';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { getRepaymentInquiry, sendMail, clearGetRepaymentInquiryState } from './redux/actions';
import { formatAmount, formatDate, formatAccount } from '../../common/util/format';

export class ContractRepaymentTermsPage extends React.Component {
    static propTypes = {
        // router: PropTypes.shape({
        //     params: PropTypes.shape({
        //         contractNumber: PropTypes.string.isRequired,
        //         id: PropTypes.string.isRequired,
        //     }).isRequired,
        // }).isRequired,
        contractNumber: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,

        customerId: PropTypes.number.isRequired,
        currentRepaymentTerms: PropTypes.object,

        customerEmail: PropTypes.string.isRequired,

        getRepaymentInquiry: PropTypes.func.isRequired,
        clearGetRepaymentInquiryState: PropTypes.func.isRequired,

        getRepaymentInquiryError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

        sendMail: PropTypes.func.isRequired,
        sendMailError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
        sendMailPending: PropTypes.bool,
    };

    static defaultProps = {
        currentRepaymentTerms: null,
        getRepaymentInquiryError: null,

        sendMailError: null,
        sendMailPending: false,
    };

    emailInputRef = React.createRef();

    formRef = React.createRef();

    state = {
        dataAvailable: false,
        isOpenSendMail: false,
        isOpenSuccessMail: false,
        isOpenErrorMail: false,
        generalError: false,
        email: '',
    };

    componentDidMount() {
        const {
            getRepaymentInquiry,
            customerId,
            customerEmail,
            contractNumber,
            // router: {
            //     params: { contractNumber },
            // },
        } = this.props;

        getRepaymentInquiry(customerId, contractNumber);

        this.setState({ email: customerEmail });
    }

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('getRepaymentInquiry', prevProps, this.props)) {
            this.setState({ dataAvailable: true }); // eslint-disable-line react/no-did-update-set-state
        }
        if (reduxActionFailed('getRepaymentInquiry', prevProps, this.props)) {
            this.setState({ generalError: true }); // eslint-disable-line react/no-did-update-set-state
        }
        if (reduxActionSucceeded('sendMail', prevProps, this.props)) {
            this.setState({ isOpenSuccessMail: true, isOpenSendMail: false }); // eslint-disable-line react/no-did-update-set-state
        }

        if (reduxActionFailed('sendMail', prevProps, this.props)) {
            this.setState({ isOpenErrorMail: true, isOpenSendMail: false }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    componentWillUnmount() {
        const { clearGetRepaymentInquiryState } = this.props;
        clearGetRepaymentInquiryState();
    }

    onRequestClose = () => {
        this.setState({
            isOpenSendMail: false,
            isOpenSuccessMail: false,
            isOpenErrorMail: false,
        });
    };

    onClickSendMail = () => {
        this.setState({ isOpenSendMail: true });
    };

    onClickSuccessErrorMail = () => {
        if (this.formRef.current.validate()) {
            const {
                sendMail,
                customerId,
                contractNumber,
                // router: {
                //     params: { contractNumber },
                // },
            } = this.props;
            const { email } = this.state;
            const data = { email };

            sendMail(customerId, contractNumber, data);
        }
    };

    onEmailChanged = ({ target }) => {
        this.setState({ email: target.value });
    };

    render() {
        const {
            currentRepaymentTerms,
            sendMailPending,
            contractNumber,
            id,
            // router: {
            //     params: { contractNumber },
            // },
        } = this.props;
        const { dataAvailable, isOpenSendMail, isOpenSuccessMail, isOpenErrorMail, email, generalError } = this.state;

        const contractRepaymentUrl = `#/contract/${contractNumber}/${id}/repayment/newRequest`;

        const now = dayjs();

        let showAutogiroInfo;
        let showInvoiceInfo;

        // figure out which messages to show after data is retrieved
        if (currentRepaymentTerms) {
            const dueDate = dayjs(currentRepaymentTerms.dueDateLatestInvoice);
            const realPayDate = dayjs(currentRepaymentTerms.date).add(3, 'days');

            // show autogiro info?
            showAutogiroInfo =
                currentRepaymentTerms.autogiroEnabled &&
                now.isBefore(dueDate) &&
                dueDate.isBefore(realPayDate.add(1, 'days'));
        }

        const contractObject = [];

        if (currentRepaymentTerms?.contractObject?.objectType) {
            contractObject.push(i18n(`contract.object-types.${currentRepaymentTerms.contractObject.objectType}`));
        }

        if (currentRepaymentTerms?.contractObject?.description) {
            contractObject.push(currentRepaymentTerms.contractObject.description);
        }

        const dueDate = dataAvailable && formatDate(currentRepaymentTerms.date.substring(0, 10));

        return (
            <AuthenticatedSubPageTemplate
                className="contract-repayment-page"
                header={i18n('contract.repayment-terms.top-header')}
            >
                {dataAvailable ? (
                    <div className="contract-contract-repayment-terms-page">
                        <Panel>
                            <PanelContent centered>
                                <ContractIcon contract={{ productCode: currentRepaymentTerms.productCode }} />
                                <h1> {i18n('contract.repayment-terms.header')} </h1>
                                <p> {i18n('contract.repayment-terms.info-text')}</p>
                            </PanelContent>

                            <div className="repayment-terms">
                                <LabelValue
                                    className="pb-5x"
                                    label={i18n('contract.repayment-terms.amount-to-pay')}
                                    value={
                                        <CopyToClipboardButton
                                            noIcon
                                            tooltip={i18n('invoice.invoice-values.tooltip.copy-amount')}
                                            tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                                            value={`${currentRepaymentTerms.amount / 100}`}
                                        >
                                            {formatAmount(currentRepaymentTerms.amount, { decimals: 2 })}
                                        </CopyToClipboardButton>
                                    }
                                />
                                <LabelValue
                                    className="pb-5x"
                                    label={i18n('contract.repayment-terms.due-date')}
                                    value={
                                        <CopyToClipboardButton
                                            noIcon
                                            tooltip={i18n('invoice.invoice-values.tooltip.copy-due-date')}
                                            tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                                            value={dueDate}
                                        >
                                            {dueDate}
                                        </CopyToClipboardButton>
                                    }
                                />
                                <LabelValue
                                    className="pb-5x"
                                    label={i18n('contract.repayment-terms.bankgiro')}
                                    value={
                                        <CopyToClipboardButton
                                            noIcon
                                            tooltip={i18n('invoice.invoice-values.tooltip.copy-bg-number')}
                                            tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                                            value={i18n('general.bgNumber.invoice')}
                                        >
                                            {i18n('general.bgNumber.invoice')}
                                        </CopyToClipboardButton>
                                    }
                                />
                                <LabelValue
                                    className="pb-5x"
                                    label={i18n('contract.repayment-terms.ocr')}
                                    value={
                                        <CopyToClipboardButton
                                            noIcon
                                            tooltip={i18n('invoice.invoice-values.tooltip.copy-ocr-number')}
                                            tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                                            value={currentRepaymentTerms.ocrNumber}
                                        >
                                            {formatAccount(currentRepaymentTerms.ocrNumber)}
                                        </CopyToClipboardButton>
                                    }
                                />
                                {contractObject.length > 0 && (
                                    <LabelValue
                                        className="pb-5x"
                                        label={i18n('contract.repayment-terms.object')}
                                        value={contractObject.join(' - ')}
                                    />
                                )}
                                {currentRepaymentTerms.contractObject?.identifier && (
                                    <LabelValue
                                        className="pb-5x"
                                        label={i18n('contract.repayment-terms.identifier')}
                                        value={currentRepaymentTerms.contractObject.identifier}
                                    />
                                )}
                            </div>

                            <PanelContent>
                                {showAutogiroInfo && (
                                    <Message
                                        id="autogiro-info"
                                        blue
                                        header={i18n('contract.repayment-terms.messages.autogiro.header')}
                                        message={i18n('contract.repayment-terms.messages.autogiro.text')}
                                    />
                                )}
                            </PanelContent>

                            <Form ref={this.formRef} validateRefs={[this.emailInputRef]}>
                                <Dialog open={isOpenSendMail} onRequestClose={this.onRequestClose}>
                                    <DialogBody>
                                        <h3 className="send-request-header">
                                            {' '}
                                            {i18n('contract.repayment-terms.send-request-button')}{' '}
                                        </h3>
                                        <p> {i18n('general.address.email')} </p>
                                        <Input
                                            type="email"
                                            ref={this.emailInputRef}
                                            required
                                            validationMessage={i18n('general.validation.email')}
                                            value={email}
                                            onChange={this.onEmailChanged}
                                        />
                                    </DialogBody>
                                    <DialogFooter>
                                        <ButtonGroup>
                                            <Button outline round onClick={this.onRequestClose}>
                                                {i18n('contract.repayment-terms.button.cancel-button')}
                                            </Button>
                                            <Button
                                                round
                                                onClick={this.onClickSuccessErrorMail}
                                                ignoreClick={sendMailPending}
                                                spinnerRight={sendMailPending}
                                            >
                                                Skicka
                                            </Button>
                                        </ButtonGroup>
                                    </DialogFooter>
                                </Dialog>
                            </Form>

                            <Dialog open={isOpenSuccessMail} onRequestClose={this.onRequestClose}>
                                <DialogBody centeredContent>
                                    <img src={happyFaceIcon} className="image" alt="" />
                                    <h3> {i18n('contract.repayment-terms.status.success-header')} </h3>
                                    <div className="status-success-text">
                                        {' '}
                                        <p>
                                            {' '}
                                            {i18n('contract.repayment-terms.status.success-body')}
                                            {email}{' '}
                                        </p>{' '}
                                    </div>
                                </DialogBody>
                                <DialogFooter>
                                    <ButtonGroup alignCenter>
                                        <Button className="okButton" outline round onClick={this.onRequestClose}>
                                            {i18n('contract.repayment-terms.button.ok-button')}
                                        </Button>
                                    </ButtonGroup>
                                </DialogFooter>
                            </Dialog>

                            <Dialog open={isOpenErrorMail} onRequestClose={this.onRequestClose}>
                                <DialogBody centeredContent>
                                    <h3> {i18n('contract.repayment-terms.status.error-header')} </h3>
                                    <p> {i18n('contract.repayment-terms.status.error-body')} </p>
                                </DialogBody>
                                <DialogFooter>
                                    <ButtonGroup alignCenter>
                                        <Button className="okButton" outline round onClick={this.onRequestClose}>
                                            {i18n('contract.repayment-terms.button.ok-button')}
                                        </Button>
                                    </ButtonGroup>
                                </DialogFooter>
                            </Dialog>

                            <ButtonGroup alignCenter>
                                <LinkButton round outline small href={contractRepaymentUrl}>
                                    {i18n('contract.repayment-terms.new-request-button')}
                                </LinkButton>
                                <Button iconLeft="icon-mail" round small onClick={this.onClickSendMail}>
                                    {i18n('contract.repayment-terms.send-request-button')}
                                </Button>
                            </ButtonGroup>
                        </Panel>
                    </div>
                ) : generalError ? (
                    <Message
                        purple
                        header={i18n('contract.repayment-terms.errors.get-terms.header')}
                        message={i18n('contract.repayment-terms.errors.get-terms.message')}
                    />
                ) : (
                    <Spinner id="repayment-terms-spinner" isCenterPage isVisible />
                )}
            </AuthenticatedSubPageTemplate>
        );
    }
}

function mapStateToProps({ contract, authentication }, { match }) {
    const { contractNumber, id } = match.params;
    return {
        contractNumber,
        id,
        customerId: authentication.person.id,
        contract: contract.contract,
        customerEmail: authentication.person.email,
        currentRepaymentTerms: contract.currentRepaymentTerms,
        getRepaymentInquiryPending: contract.getRepaymentInquiryPending,
        getRepaymentInquiryError: contract.getRepaymentInquiryError,

        sendMailPending: contract.sendMailPending,
        sendMailError: contract.sendMailError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRepaymentInquiry: (customerId, contractNumber) => dispatch(getRepaymentInquiry(customerId, contractNumber)),
        sendMail: (customerId, contractNumber, data) => dispatch(sendMail(customerId, contractNumber, data)),
        clearGetRepaymentInquiryState: () => dispatch(clearGetRepaymentInquiryState()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractRepaymentTermsPage));
