import React, { Component } from 'react';
import { i18n } from '@ecster/i18n';
import { UserMessagePanel, Message } from '@ecster/components';
import { walletIcon } from '@ecster/icons/H80/green';

import PropTypes from 'prop-types';

import InvoiceInfoPanel from '../components/InvoiceInfoPanel';
import CalculatePanel from '../components/CalculatePanel';
import { getBillProperties } from '../util';

export class AccountInvoicePageContent extends Component {
    static propTypes = {
        account: PropTypes.object.isRequired,
        bill: PropTypes.object.isRequired,
        accountTerms: PropTypes.object.isRequired,
        accountRef: PropTypes.string.isRequired,
    };

    render() {
        const { account, bill, accountRef, accountTerms } = this.props;

        const billProps = getBillProperties(bill);
        const { hasPartPayments, hasAccountTransactions } = bill;
        const accountTxUrl = `#/account/${accountRef}/transactions`;

        return (
            <>
                {!billProps.hasBill && (
                    <UserMessagePanel
                        icon={walletIcon}
                        header={i18n('invoice.account-invoice.no-invoice.header')}
                        body={i18n('invoice.account-invoice.no-invoice.info', 'p', {
                            url: accountTxUrl,
                            danger: true,
                        })}
                    />
                )}
                {(billProps.hasBill || billProps.isZeroInvoice) && (
                    <InvoiceInfoPanel
                        account={account}
                        bill={bill}
                        accountRef={accountRef}
                        lab1={billProps.lab1}
                        lab2={billProps.lab2}
                        lab3={billProps.lab3}
                    />
                )}
                {!billProps.hasPaidAll &&
                    billProps.hasBill &&
                    !billProps.allLabsEqual &&
                    !billProps.paidBetweenLab1Lab3 && (
                        <CalculatePanel
                            account={account}
                            bill={bill}
                            accountTerms={accountTerms}
                            lab1={billProps.lab1}
                            lab2={billProps.lab2}
                            lab3={billProps.lab3}
                            hasAccountTransactions={hasAccountTransactions}
                            hasPartPayments={hasPartPayments}
                        />
                    )}
                {billProps.isZeroInvoice && (
                    <Message
                        blue
                        header={i18n('invoice.account-invoice.zero-invoice.header')}
                        message={i18n('invoice.account-invoice.zero-invoice.text')}
                    />
                )}
            </>
        );
    }
}
