import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { GeneralErrorMessagePanel, Spinner } from '@ecster/components';

import md5 from '@ecster/util/lib/md5';
import { withRouter } from 'react-router-dom';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import {
    dismissGetAccountBillDetailsError,
    dismissGetAccountCampaignPurchasesError,
    getAccountBillDetails,
    getAccountCampaignPurchases,
} from './redux/actions';
import PartPaymentsPanel from './part-payment/components/PartPaymentsPanel';
import { AccountInvoiceSpecTransactions } from './AccountInvoiceSpecTransactions';
import { Header } from './SE/account-invoice';
import { getBillProperties } from './util';
import { isSE } from '../../common/country';

export class AccountInvoiceSpecPage extends Component {
    static propTypes = {
        // router: PropTypes.shape({
        //     params: PropTypes.shape({
        //         accountRef: PropTypes.string.isRequired,
        //     }).isRequired,
        // }).isRequired,
        accountRef: PropTypes.string.isRequired,
        // account: PropTypes.object,
        bills: PropTypes.object,
        campaignPurchases: PropTypes.array,
        customerId: PropTypes.number.isRequired,
        getAccountBillDetails: PropTypes.func.isRequired,
        dismissGetAccountBillDetailsError: PropTypes.func.isRequired,
        getAccountBillDetailsIsDone: PropTypes.bool,
        getAccountCampaignPurchases: PropTypes.func.isRequired,
        getAccountCampaignPurchasesIsDone: PropTypes.bool,
        dismissGetAccountCampaignPurchasesError: PropTypes.func.isRequired,

        // props are used in operationSucceeded
        getAccountBillDetailsError: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
        getAccountCampaignPurchasesError: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
    };

    static defaultProps = {
        bills: {},
        campaignPurchases: undefined,
        getAccountBillDetailsError: undefined,
        getAccountCampaignPurchasesError: undefined,
        getAccountCampaignPurchasesIsDone: !isSE(),
        getAccountBillDetailsIsDone: false,
    };

    componentDidMount() {
        const {
            getAccountBillDetails,
            customerId,
            getAccountCampaignPurchases,
            accountRef,
            // router: {
            //     params: { accountRef },
            // },
        } = this.props;

        getAccountBillDetails(customerId, accountRef);

        if (isSE()) {
            getAccountCampaignPurchases(accountRef);
        }
    }

    render() {
        const {
            bills,
            campaignPurchases,
            accountRef,
            // router: {
            //     params: { accountRef },
            // },
            getAccountCampaignPurchasesError,
            getAccountBillDetailsError,
            dismissGetAccountBillDetailsError,
            dismissGetAccountCampaignPurchasesError,
            getAccountBillDetailsIsDone,
            getAccountCampaignPurchasesIsDone,
        } = this.props;
        const bill = bills[accountRef];

        const billProps = getBillProperties(bill);

        // generate unique keys
        const billTx =
            bill?.accountBillTransactions &&
            bill?.accountBillTransactions.map((tx, idx) => ({ ...tx, key: md5(idx + JSON.stringify(tx)) }));

        if (getAccountCampaignPurchasesError || getAccountBillDetailsError) {
            return (
                <AuthenticatedSubPageTemplate
                    className="invoice-account-invoice-spec-page"
                    header={i18n('invoice.account-invoice-spec.page-header')}
                >
                    <GeneralErrorMessagePanel
                        header={i18n('general.error.general.header')}
                        body={i18n('general.error.general.body')}
                        error={getAccountCampaignPurchasesError || getAccountBillDetailsError}
                        dismissError={dismissGetAccountCampaignPurchasesError || dismissGetAccountBillDetailsError}
                        buttonText={i18n('general.back')}
                        isLink
                    />
                </AuthenticatedSubPageTemplate>
            );
        }
        return (
            <AuthenticatedSubPageTemplate
                className="invoice-account-invoice-spec-page"
                header={i18n('invoice.account-invoice-spec.page-header')}
            >
                {getAccountBillDetailsIsDone && getAccountCampaignPurchasesIsDone ? (
                    <>
                        {isSE() && <Header billProps={billProps} bill={bill} />}

                        <AccountInvoiceSpecTransactions transactions={billTx} />
                        {campaignPurchases.length > 0 && (
                            <PartPaymentsPanel
                                campaignPurchases={campaignPurchases}
                                header={i18n('invoice.account-invoice-spec.this-months-part-payments')}
                            />
                        )}
                    </>
                ) : (
                    <Spinner id="account-invoice-spec-spinner" isCenterPage isVisible />
                )}
            </AuthenticatedSubPageTemplate>
        );
    }
}

/* istanbul ignore next */
function mapStateToProps({ account, authentication }, { match }) {
    const { accountRef } = match.params;
    return {
        accountRef,
        bills: account.accountBillDetails,
        campaignPurchases: account.accountCampaignPurchases,
        customerId: authentication.person.id,
        dismissGetAccountBillDetailsError: account.dismissGetAccountBillDetailsError,
        dismissGetAccountCampaignPurchasesError: account.dismissGetAccountCampaignPurchasesError,
        getAccountBillDetailsError: account.getAccountBillDetailsError,
        getAccountBillDetailsIsDone: account.getAccountBillDetailsIsDone,
        getAccountBillDetailsPending: account.getAccountBillDetailsPending,
        getAccountCampaignPurchasesError: account.getAccountBillDetailsError,
        getAccountCampaignPurchasesIsDone: account.getAccountCampaignPurchasesIsDone,
        getAccountCampaignPurchasesPending: account.getAccountCampaignPurchasesPending,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getAccountBillDetails: (customerId, accountRef) => dispatch(getAccountBillDetails(customerId, accountRef)),
        getAccountCampaignPurchases: accountRef => dispatch(getAccountCampaignPurchases(accountRef)),
        dismissGetAccountCampaignPurchasesError: () => dispatch(dismissGetAccountCampaignPurchasesError()),
        dismissGetAccountBillDetailsError: () => dispatch(dismissGetAccountBillDetailsError()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInvoiceSpecPage));
