import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_BEGIN,
    ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_SUCCESS,
    ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_FAILURE,
    ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_DISMISS_ERROR,
    ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
} from './constants';
import { GET_ACCOUNT_BILL_URL } from './urls';
import initialState from './initialState';
import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import returnUniqueErrors from '../../../common/util/return-unique-errors';

import { isSE } from '../../../common/country';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_ACCOUNT_BILL_OVERVIEW_STATE = 'CLEAR_GET_ACCOUNT_BILL_STATE';

export const getAccountBillOverview = (customerId, accountRef) => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_BEGIN,
    });

    try {
        const { language } = window.EcsterConfig;
        const key = GET_ACCOUNT_BILL_URL(customerId, accountRef, language);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        parseServerResponseStatus(res);
        if (!cached) cache.set(key, res);

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_SUCCESS,
            data: res.response,
            accountRef,
        });
    } catch (err) {
        reportError(err, 'getAccountBillOverview');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_FAILURE,
            data: {
                accountRef,
                error: {
                    message: err,
                    action: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
                },
            },
            accountRef,
        });
    }
};

export const dismissGetAccountBillOverviewError = () => ({ type: ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_DISMISS_ERROR });

export const clearGetAccountBillOverviewState = () => ({ type: CLEAR_GET_ACCOUNT_BILL_OVERVIEW_STATE });

const processBillData = data => {
    const result = JSON.parse(JSON.stringify(data));
    if (isSE() && result.accountBillTransactions) {
        const incomingDebt = result.accountBillTransactions.shift();
        const outgoingDebt = result.accountBillTransactions.pop();
        result.balanceBroughtForward = incomingDebt && incomingDebt.amount;
        result.balanceCarriedForward = outgoingDebt && outgoingDebt.amount;
        result.outgoingDebt = outgoingDebt;
    }
    return result;
};

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_BEGIN:
            return {
                ...state,
                getAccountBillOverviewPending: true,
                getAccountBillOverviewError: null,
                getAccountBillOverviewIsDone: false,
                getAccountBillOverviewIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_SUCCESS:
            return {
                ...state,
                accountBillOverview: {
                    ...state.accountBillOverview,
                    [action.accountRef]: processBillData(action.data),
                },
                getAccountBillOverviewPending: false,
                getAccountBillOverviewIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_FAILURE:
            return {
                ...state,
                accountBillOverview: initialState.accountBillOverview,
                getAccountBillOverviewPending: false,
                getAccountBillOverviewIsDone: false,
                getAccountBillOverviewError: returnUniqueErrors({
                    errors: state.getAccountBillError,
                    key: 'accountRef',
                    action,
                }),
                getAccountBillIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_DISMISS_ERROR:
            return {
                ...state,
                getAccountBillOverviewError: null,
                getAccountBillOverviewIsError: false,
                getAccountBillOverviewIsDone: false,
            };

        case CLEAR_GET_ACCOUNT_BILL_OVERVIEW_STATE:
            return {
                ...state,
                accountBillOverview: {},
                getAccountBillOverviewIsDone: false,
                getAccountBillOverviewIsError: false,
            };
        default:
            return state;
    }
}
