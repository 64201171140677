import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';

export const BicCode = ({ bicCode, label }) => (
    <LabelValue
        label={label || i18n('invoice.invoice-values.bic-code')}
        value={
            <CopyToClipboardButton
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-bic-code')}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={bicCode}
            >
                {bicCode}
            </CopyToClipboardButton>
        }
    />
);
BicCode.propTypes = { bicCode: PropTypes.string.isRequired, label: PropTypes.string };
BicCode.defaultProps = { label: null };
