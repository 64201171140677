export const DELETE_ACCOUNT_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}`;

export const GET_ACCOUNT_LIMIT_RAISE_TERMS_URL = (country, lang) => `/rest/ess/v1/${country}/${lang}/terms/raise`;

export const GET_ACCOUNT_PAYMENT_TERMS_URL = (country, lang, amount) =>
    `/rest/ess/v1/${country}/${lang}/partpayments?amount=${amount}`;

export const GET_ACCOUNTS_URL = customerId => `/rest/ess/customers/v1/${customerId}/accounts`;

export const GET_ACCOUNT_ALLOWED_PART_PAYMENTS_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/allowedpartpayments`;

export const GET_ACCOUNT_BILL_URL = (customerId, accountRef, lang) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/bills?requestType=OVERVIEW&lang=${lang}`; // todo: change to /bill in rest service?

export const GET_ACCOUNT_BILL_DETAILS_URL = (customerId, accountRef, lang) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/bills?requestType=DETAIL&lang=${lang}`;

export const GET_ACCOUNT_CAMPAIGN_PURCHASES_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/campaignpurchases`;

export const GET_ACCOUNT_CARDS_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/cards`;

export const GET_ACCOUNT_TERMS_URL = (customerId, refCode) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${refCode}/terms`;

export const GET_ACCOUNT_TRANSACTIONS_URL = (customerId, accountRef, offset, maxRecords, lang) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/transactions?offset=${offset}&maxRecords=${maxRecords}&lang=${lang}`;

export const GET_ACCOUNT_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}`;

export const GET_LOYALTY_DETAILS_URL = (customerId, accountRef, loyaltyType, loyaltyId) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/loyalties/${loyaltyType}/details${
        loyaltyId ? `?loyaltyId=${loyaltyId}` : ''
    }`;

export const UPDATE_ACCOUNT_CARD_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/cards/${accountRef}`;

export const CREATE_ACCOUNT_CARD_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/cards/${accountRef}/order`;

export const UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_URL = (customerId, accountRef, transactionId) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/transactions/${transactionId}/partpayments`;
