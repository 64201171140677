import React, { ReactNode } from 'react';
import classNames from 'classnames';

// https://github.com/glennflanagan/react-collapsible#readme
import Collapsible from 'react-collapsible';

import './ExpandableContainer.scss';

interface ExpandableContainerProps {
    /**
     * DOM id
     */
    id: string;

    /**
     * Control expand / collapse with outside state
     */
    open?: boolean;

    /**
     * Expand above trigger instead of expanding down
     */
    expandUp?: boolean;

    /**
     * The clickable header that expands or collapses the content
     *
     * A string or jsx markup
     */
    header: ReactNode | string;

    /**
     * The content shown when expanded
     */
    children: ReactNode;

    /**
     * Additional css classes for the wrapping container
     */
    className?: string;

    /**
     * How to organize the header and icon?
     *
     * stretch === true => width = 100%, header to left, icon right
     * stretch === false => icon follows immediately after header, everything centered
     */
    stretch?: boolean;

    /**
     * Align header and icon to left
     */
    left?: boolean;

    /**
     * Align header and icon to right
     */
    right?: boolean;

    /**
     * Show or hide standard chevron icon
     */
    chevron?: boolean;

    /**
     * Callback when expand or collapse has finished.
     * @param {boolean} isOpen first parameter in callback
     */
    onChangeState?: Function;

    /**
     * Callback when expand has finished
     */
    onExpand?: Function;

    /**
     * Callback when collapse has finished
     */
    onCollapse?: Function;

    /**
     * Callback before expand starts
     */
    onBeforeExpand?: () => void;

    /**
     * Callback before collapse starts
     */
    onBeforeCollapse?: () => void;
}

export default class ExpandableContainer extends React.Component<ExpandableContainerProps> {
    state = {
        isExpanded: false,
    };

    componentDidMount() {
        const { open } = this.props;
        this.setState({ isExpanded: open });
    }

    onBeforeOpen = () => {
        const { onChangeState, onBeforeExpand } = this.props;
        if (typeof onChangeState === 'function') onChangeState(true);
        onBeforeExpand();
    };

    onBeforeClose = () => {
        const { onChangeState, onBeforeCollapse } = this.props;
        if (typeof onChangeState === 'function') onChangeState(false);
        onBeforeCollapse();
    };

    onOpen = () => {
        const { onExpand } = this.props;
        onExpand();
    };

    onClose = () => {
        const { onCollapse } = this.props;
        onCollapse();
    };

    toggleExpandState = () => {
        const { isExpanded } = this.state;
        this.setState({ isExpanded: !isExpanded });
    };

    render() {
        const {
            id,
            open,
            expandUp,
            header,
            children,
            stretch,
            className,
            onBeforeExpand,
            onBeforeCollapse,
            left,
            right,
            chevron,
        } = this.props;
        const { isExpanded } = this.state;

        const classes = classNames({
            'ec-expandable-container': true,
            'expc-is-expanded': isExpanded || open,
        });

        const triggerClasses = classNames({
            'expc-trigger': true,
            'expc-is-expanded': isExpanded || open,
            'expc-stretch': stretch,
            'expc-left': left,
            'expc-right': right,
            'expc-string-trigger': typeof header === 'string',
            'expc-composed-trigger': typeof header !== 'string',
            'expc-expand-up': expandUp,
        });

        const contentClasses = classNames({
            'expc-content': true,
            'expc-is-expanded': isExpanded || open,
            'expc-expand-up': expandUp,
        });

        const iconClasses = classNames({
            'icon-chevron-down': true, // icon font
            'expc-is-expanded': isExpanded || open,
        });

        /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
        const trigger = (
            <div tabIndex={0} className={triggerClasses} onClick={this.toggleExpandState}>
                {typeof header === 'string' ? <span className="expc-focus-el">{header}</span> : header}
                {chevron && (
                    <div className="expc-icon-ctr">
                        <i className={iconClasses} />
                    </div>
                )}
            </div>
        );

        return (
            <div className={classes}>
                {!expandUp && trigger}
                <Collapsible
                    id={id}
                    trigger={null}
                    className={className}
                    openedClassName={className}
                    triggerTagName="span"
                    onOpen={this.onOpen}
                    onClose={this.onClose}
                    onOpening={onBeforeExpand}
                    onClosing={onBeforeCollapse}
                    contentHiddenWhenClosed
                    lazyRender
                    open={isExpanded || open}
                >
                    <div className={contentClasses}>{children}</div>
                </Collapsible>
                {expandUp && trigger}
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
ExpandableContainer.defaultProps = {
    open: null,
    expandUp: false,
    className: '',
    stretch: false,
    left: false,
    right: false,
    chevron: true,
    onExpand: () => {},
    onCollapse: () => {},
    onBeforeExpand: () => {},
    onBeforeCollapse: () => {},
    onChangeState: null,
};
