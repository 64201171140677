import React from 'react';

import { ExpandableContainer } from '../Panel';
import { UnorderedList } from '../List';
import { Link } from '../Clickable';

import './GDPRInfo.scss';

const texts = {
    'sv-SE': {
        'expander-text': 'Varför behöver vi dina kontaktuppgifter?',
        'gdpr-link': 'https://www.ecster.se/sv/om-oss/hantering-av-personuppgifter',
        'gdpr-link-text': 'Läs mer om hur vi hanterar personuppgifter',
        'info-items': {
            a: 'För att ingå avtal',
            b: 'För säkrare betalningar med ditt kort',
            c: 'För information och frågor',
            d: 'För köp- och leveransinformation',
            e: 'För kundundersökningar',
            f: 'För erbjudanden från Ecster och våra samarbetspartners',
            g: 'För att förhindra ekonomisk brottslighet',
        },
    },
    en: {
        // note: These were Google translated
        'expander-text': 'Why do we need your contact information?',
        'gdpr-link': 'https://www.ecster.se/sv/om-oss/hantering-av-personuppgifter',
        'gdpr-link-text': 'Read more about how we handle personal data (Swedish)',
        'info-items': {
            a: 'To enter into an agreement',
            b: 'For more secure payments with your card',
            c: 'For information and questions',
            d: 'For purchase and delivery information',
            e: 'For customer surveys',
            f: 'For offers from Ecster and our partners',
            g: 'To prevent economic crime',
        },
    },
    'sv-FI': {
        'expander-text': 'Varför behöver vi dina kontaktuppgifter?',
        'gdpr-link': 'https://www.ecster.fi/sv/hantering-av-personuppgifter',
        'gdpr-link-text': 'Läs mer om hur vi hanterar personuppgifter',
        'info-items': {
            a: 'För att ingå avtal',
            b: 'För säkrare betalningar med ditt kort',
            c: 'För information och frågor',
            d: 'För köp- och leveransinformation',
            e: 'För kundundersökningar',
            f: 'För erbjudanden från Ecster och våra samarbetspartners',
            g: 'För att förhindra ekonomisk brottslighet',
        },
    },
    fi: {
        'expander-text': 'Miksi Ecster kysyy tietojasi?',
        'gdpr-link': 'https://www.ecster.fi/fi/henkilotietojen-kasittely',
        'gdpr-link-text': 'Lue lisää henkilötietojen käsittelystä',
        'info-items': {
            a: 'Sopimuksen tekeminen',
            b: 'Turvallinen korttimaksaminen',
            c: 'Asiakaspalvelu',
            d: 'Tiedot ostoksen ja tilauksen toimittamiseksi',
            e: 'Tutkimukset toimintamme kehittämiseksi',
            f: 'Asiakas- ja markkinointiviestintä Ecsteriltä ja sen yhteistyökumppaneilta',
            g: 'Talousrikollisuuden estäminen',
        },
    },
};

const filterItems = (allItems, showForEmail, emailReasons, showForPhone, phoneReasons) => {
    // join 'abc' and 'bcd' strings into one array with unique values
    const reasons = [...new Set([...emailReasons.split(''), ...phoneReasons.split('')])];
    const items = [];
    reasons.map((reason) => items.push(allItems[reason]));
    return items;
};

interface GDPRInfoProps {
    /**
     * css id
     */
    id?: string;

    /**
     * Additional CSS classes passed on to underlying ExpandableContainer
     */
    className?: string;

    /**
     * The preferred language, lower case ISO code - sv, fi, en etc
     *
     * language and country must combine into a valid locale
     */
    language?: string;

    /**
     * The country, upper case ISO code - SE, FI etc
     *
     * Options may vary depending on country
     *
     * language and country must combine into a valid locale
     */
    country?: string;

    // show for mobile or email or both

    showForEmail?: boolean;
    showForPhone?: boolean;

    /**
     * Alternate text for the clickable header
     */
    expanderText?: string;

    // processess
    /**
     * Show info for an e-commerce account payment
     */
    eCommerceAccountPayment?: boolean;

    /**
     * Show info for for an e-commerce card payment
     */
    eCommerceCardPayment?: boolean;

    /**
     * Show info for an e-commerce invoice payment
     */
    eCommerceInvoicePayment?: boolean;

    /**
     * Show info for an e-commerce swish payment
     */
    eCommerceSwishPayment?: boolean;

    /**
     * Show info for an online card application
     */
    onlineCardApplication?: boolean;

    /**
     * Show info when user edits contact info online
     */
    onlineEditContactDetails?: boolean;

    /**
     * Show info when user applies for a loan pre approval online
     */
    onlineLoanPreApproval?: boolean;

    /**
     * Show info when user or merchant adds contact info online
     */
    onlineNewContactDetails?: boolean;

    /**
     * Show info when user applies for a promissory note loan online
     */
    onlinePromissoryNoteApplication?: boolean;

    /**
     * Show info when user applies for a credit raise online
     */
    onlineRaiseCredit?: boolean;

    /**
     * Show info when user activates extra card online
     */
    onlineExtraCardActivation?: boolean;

    /**
     * Show info when user makes an account payment in a physical store
     */
    storeAccountPayment?: boolean;

    /**
     * Show info when user makes a investment loan application in a physical store
     */
    storeInvestmentLoan?: boolean;
}

export const GDPRInfo = ({
    // process

    id = 'ec-gdpr-info',

    language = 'sv',
    country = 'SE',

    showForEmail = true,
    showForPhone = true,

    expanderText = '', // use translated default text

    eCommerceAccountPayment,
    eCommerceCardPayment,
    eCommerceInvoicePayment,
    eCommerceSwishPayment,

    onlineCardApplication,
    onlineEditContactDetails,
    onlineLoanPreApproval,
    onlineNewContactDetails,
    onlinePromissoryNoteApplication,
    onlineRaiseCredit,
    onlineExtraCardActivation,

    storeAccountPayment,
    storeInvestmentLoan,

    className = '',
}: GDPRInfoProps) => {
    const locale = `${language.toLowerCase()}-${country.toUpperCase()}`;
    // the order to resolve translations.
    // sv-SE -> sv -> en
    const fallback = [locale, language, 'en'];
    const i18n = (key: string) => {
        const bestLocale = fallback.find((locale) => texts[locale]?.[key]);
        if (!bestLocale) return key;
        return texts[bestLocale]?.[key];
    };

    const header = <span className="ec-gdpr-info-header"> {expanderText || i18n('expander-text')}</span>;

    const allItems = i18n('info-items');

    let items;

    if (eCommerceAccountPayment) items = filterItems(allItems, showForEmail, 'bcdefg', showForPhone, 'cdefg');
    if (eCommerceCardPayment) items = filterItems(allItems, showForEmail, 'd', showForPhone, 'd'); // Säljföretaget äger uppgifterna
    if (eCommerceInvoicePayment) items = filterItems(allItems, showForEmail, 'cdef', showForPhone, 'cdef');
    if (eCommerceSwishPayment) items = filterItems(allItems, showForEmail, 'd', showForPhone, 'd');

    if (onlineCardApplication) items = filterItems(allItems, showForEmail, 'bcefg', showForPhone, 'cefg');
    if (onlineEditContactDetails) items = filterItems(allItems, showForEmail, 'bcefg', showForPhone, 'cefg');
    if (onlineLoanPreApproval) items = filterItems(allItems, showForEmail, 'cef', showForPhone, 'cef');
    if (onlineNewContactDetails) items = filterItems(allItems, showForEmail, 'bcefg', showForPhone, 'cefg');
    if (onlinePromissoryNoteApplication) items = filterItems(allItems, showForEmail, 'cefg', showForPhone, 'cefg');
    if (onlineRaiseCredit) items = filterItems(allItems, showForEmail, 'bcefg', showForPhone, 'cefg');
    if (onlineExtraCardActivation) items = filterItems(allItems, showForEmail, 'cg', showForPhone, 'cg');

    if (storeAccountPayment) items = filterItems(allItems, showForEmail, 'acdefg', showForPhone, 'cdefg');
    if (storeInvestmentLoan) items = filterItems(allItems, showForEmail, 'acdefg', showForPhone, 'cdefg');

    if (!items) items = filterItems(allItems, true, 'abcd', true, 'efg');

    return (
        <ExpandableContainer className={`ec-gdpr-info ${className}`} id={id} header={header} left>
            <UnorderedList icon="icon-check" iconClass="ec-gdpr-info-check">
                {items}
            </UnorderedList>
            <Link href={i18n('gdpr-link')} iconRight="icon-external-link" target="_blank">
                {i18n('gdpr-link-text')}
            </Link>
        </ExpandableContainer>
    );
};
