import React from 'react';

import './DialogCloseButton.scss';

interface DialogCloseButtonProps {
    onRequestClose: Function;
    ariaLabel?: string;
}

/**
 * Add inside Dialog if no DialogHeader is used (DH adds this close button automatically)
 *
 * @param ariaLabel
 * @param onRequestClose
 * @returns {*}
 * @constructor
 */
const DialogCloseButton = ({ ariaLabel, onRequestClose }: DialogCloseButtonProps) => (
    <i
        role="button"
        aria-label={ariaLabel}
        tabIndex={0}
        onClick={() => {
            onRequestClose();
        }}
        className="icon-x ec-dialog-close-btn"
    />
);

DialogCloseButton.defaultProps = {
    ariaLabel: '',
};

export default DialogCloseButton;
