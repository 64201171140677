import { get } from '@ecster/net/v2';

import {
    CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_BEGIN,
    CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_SUCCESS,
    CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_FAILURE,
    CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_DISMISS_ERROR,
} from './constants';
import { GET_CUSTOMER_EXTRA_CARD_HOLDERS_URL } from './urls';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_CUSTOMER_EXTRA_CARD_HOLDERS_STATE = 'CLEAR_GET_CUSTOMER_EXTRA_CARD_HOLDERS_STATE';

export const getCustomerExtraCardHolders = customerId => async dispatch => {
    dispatch({
        type: CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_BEGIN,
    });

    try {
        const res = await get(GET_CUSTOMER_EXTRA_CARD_HOLDERS_URL(customerId));
        dispatch({
            type: CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getCustomerExtraCardHolders');

        dispatch({
            type: CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetCustomerExtraCardHoldersError = () => ({
    type: CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_DISMISS_ERROR,
});

export const clearGetCustomerExtraCardHoldersState = () => ({ type: CLEAR_GET_CUSTOMER_EXTRA_CARD_HOLDERS_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_BEGIN:
            return {
                ...state,
                getCustomerExtraCardHoldersPending: true,
                getCustomerExtraCardHoldersError: null,
                getCustomerExtraCardHoldersIsDone: false,
                getCustomerExtraCardHoldersIsError: false,
            };

        case CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_SUCCESS:
            return {
                ...state,
                customerExtraCardHolders: action.data,
                getCustomerExtraCardHoldersPending: false,
                getCustomerExtraCardHoldersError: null,
                getCustomerExtraCardHoldersIsDone: true,
            };

        case CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_FAILURE:
            return {
                ...state,
                getCustomerExtraCardHoldersPending: false,
                getCustomerExtraCardHoldersError: action.data.error,
                getCustomerExtraCardHoldersIsError: true,
            };

        case CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_DISMISS_ERROR:
            return {
                ...state,
                getCustomerExtraCardHoldersError: null,
                getCustomerExtraCardHoldersIsError: false,
            };

        case CLEAR_GET_CUSTOMER_EXTRA_CARD_HOLDERS_STATE:
            return {
                ...state,
                customerExtraCardHolders: [],
                getCustomerExtraCardHoldersIsDone: false,
                getCustomerExtraCardHoldersIsError: false,
            };
        default:
            return state;
    }
}
