import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TabletOrDesktop, Mobile, Tooltip, detectDevice } from '@ecster/components';
import './OverviewPanelHeader.scss';
import { i18n } from '@ecster/i18n';
import { formatAmount } from '../../common/util/format-amount';

const tooltipEvents = detectDevice().isDesktop ? ['click', 'hover'] : 'click';

const Amount = ({ label, amount, second, formatOptions, id, isContract }) => {
    const classes = classNames({
        amount: true,
        flex: true,
        'flex-col': true,
        second,
        'e-green120': true,
    });
    return (
        <div id={id} className={classes}>
            <span className="large-text">{formatAmount(amount, formatOptions)}</span>
            <div>
                <span>{label}</span>
                {isContract && (
                    <Tooltip
                        theme="green"
                        id="debt-tooltip"
                        position={['top left', 'bottom left']}
                        trigger={<i className="icon-info e-green120 ml-2x" />}
                        events={tooltipEvents}
                    >
                        <>{i18n('contract.overview.info', 'div')}</>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

Amount.propTypes = {
    amount: PropTypes.number.isRequired,
    formatOptions: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    isContract: PropTypes.bool,
    label: PropTypes.string.isRequired,
    second: PropTypes.bool,
};

Amount.defaultProps = {
    isContract: false,
    second: false,
};

export const OverviewPanelHeader = ({
    header,
    amount1,
    amount1Label,
    amount1FormatOptions,
    amount2,
    amount2FormatOptions,
    amount2Label,
    image,
    isContract,
}) => {
    return (
        <div className="common-overview-panel-header">
            <TabletOrDesktop>
                <div className="flex justify-between">
                    <div className="flex flex-col">
                        <h2>{header}</h2>
                        <div className="flex justify-start">
                            {amount1 !== null && (
                                <Amount
                                    id="remaining-credit"
                                    label={amount1Label}
                                    amount={amount1}
                                    formatOptions={amount1FormatOptions}
                                    isContract={isContract}
                                />
                            )}
                            {amount2 !== null && (
                                <Amount
                                    id="used-credit"
                                    label={amount2Label}
                                    amount={amount2}
                                    second
                                    formatOptions={amount2FormatOptions}
                                    isContract={isContract}
                                />
                            )}
                        </div>
                    </div>
                    <div className="image-ctr">{image}</div>
                </div>
            </TabletOrDesktop>

            <Mobile>
                <div className="flex flex-col items-center e-green120">
                    <div className="image-ctr">{image}</div>
                    <h2>{header}</h2>
                    {amount1 !== null && (
                        <Amount
                            id="remaining-credit"
                            label={amount1Label}
                            amount={amount1}
                            formatOptions={amount1FormatOptions}
                            isContract={isContract}
                        />
                    )}
                    {amount2 !== null && (
                        <p id="used-credit" className="mt-4x">
                            <span>
                                {amount2Label} <strong>{formatAmount(amount2, amount2FormatOptions)}</strong>
                            </span>
                        </p>
                    )}
                </div>
            </Mobile>
        </div>
    );
};

const validateProps = (props, key) => {
    if (props[key] !== null && props[`${key}label`] === null) {
        return new Error(`You must supply ${key}Label when using ${key}`);
    }
    return null;
};
OverviewPanelHeader.propTypes = {
    amount1: PropTypes.number,
    amount1FormatOptions: PropTypes.object,
    amount1Label: props => validateProps(props, 'amount1'),
    amount2: PropTypes.number,
    amount2FormatOptions: PropTypes.object,
    amount2Label: props => validateProps(props, 'amount2'),
    header: PropTypes.string.isRequired,
    image: PropTypes.node,
    isContract: PropTypes.bool,
};

OverviewPanelHeader.defaultProps = {
    amount1: null,
    amount1Label: null,
    amount1FormatOptions: {},
    amount2: null,
    amount2FormatOptions: {},
    amount2Label: '',
    image: null,
    isContract: false,
};
