import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*
  This is the root component of your app. Here you define the overall layout
  and the container of the react router. The default one is a two columns layout.
  You should adjust it according to the requirement of your app.
*/
class App extends Component {
    static propTypes = {
        children: PropTypes.node,
    };

    static defaultProps = {
        children: 'No content.',
    };

    render() {
        const { children } = this.props;
        return (
            <div className="home-app">
                <div className="page-container">{children}</div>
            </div>
        );
    }
}

export default App;
