import { i18n } from '@ecster/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { formatAmount, formatNumber } from '../../../common/util/format';
import './CurrencyInfo.scss';

export const CurrencyInfo = ({ trans }) => {
    return trans.foreign ? (
        <span className="tx-currency-info">
            {formatAmount(trans.foreign.amount, {
                currency: trans.foreign.currency,
                strip00: true,
                decimals: 2,
            })}
            {' - '}
            {`${i18n('account.transactions.exchange-rate')}: ${formatNumber(trans.foreign.exchangeRate, {
                style: 'decimal',
                decimals: 4,
            })}`}
        </span>
    ) : null;
};
CurrencyInfo.propTypes = { trans: PropTypes.object.isRequired };
