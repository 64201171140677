/**
 * Convert momentjs/dayjs format string to Unicode tokens tech standard #35 (date-fns)
 * @param format {string} input format string, https://day.js.org/docs/en/display/format
 * @returns {string} converted format string, https://date-fns.org/v2.28.0/docs/format
 */
export const formatToUnicodeTokens = format => {
    if (!format) return '';

    return (
        format
            // year
            .replace(/Y/g, 'y')
            // days, replace d's before D's to avoid that fresh D to d
            // replacements are immediately replaced with E's
            // longer d combinations first
            .replace(/dddd/g, 'EEEE')
            .replace(/ddd/g, 'E')
            .replace(/dd/g, 'EEEEE')
            .replace(/D/g, 'd')
            // order is important like d's and D's
            .replace(/a/g, 'aaa')
            .replace(/A/g, 'a')
            // timezone
            .replace(/Z/g, 'z')
            // locale based formats, longer L (or l) combinations first (case ignored)
            .replace(/LLLL/gi, 'PPPPpppp')
            .replace(/LLL/gi, 'PPpp')
            .replace(/LL/gi, 'PPP')
            .replace(/L/gi, 'P')
            .replace(/LT/g, 'p')
            .replace(/LTS/g, 'pp')
    );
};
