import React, { useEffect, useState, useCallback } from 'react';

import {
    Spinner,
    ScrollAwarePanel,
    Message,
    GeneralErrorMessagePanel,
    Panel,
    PanelContent,
    Tabs,
} from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { AccountTransactions } from './transactions/AccountTransactions';
import { Header } from './transactions/Header';
import initialState from './redux/initialState';
import OverdrawnInfo from './components/OverdrawnInfo';
import { getAccountTransactions as getAccountTransactionsRedux } from './redux/getAccountTransactions';
import {
    dismissGetAccountError as dismissGetAccountErrorRedux,
    getAccount as getAccountRedux,
} from './redux/getAccount';
import { getAccountCampaignPurchases as getAccountCampaignPurchasesRedux } from './redux/getAccountCampaignPurchases';
import ExportTransactionsToExcel from '../../common/ExportTransactionsToExcel';
import { TxPageContext } from './AccountTransactionsOverviewPageContext';
import PartPaymentsPanel from './part-payment/components/PartPaymentsPanel';
import { isSE } from '../../common/country';

const defaultFilter = initialState.accountTransactionsFilter;

const AccountTransactionsOverviewPage = () => {
    const { accountRef } = useParams();
    const dispatch = useDispatch();

    const getAccount = useCallback(
        customerId => dispatch(getAccountRedux(customerId, accountRef)),
        [accountRef, dispatch]
    );
    const dismissGetAccountError = () => dispatch(dismissGetAccountErrorRedux());
    const getAccountTransactions = useCallback(
        (customerId, filter, isShortList, concat) =>
            dispatch(getAccountTransactionsRedux(customerId, accountRef, filter, isShortList, concat)),
        [dispatch, accountRef]
    );
    const getAccountCampaignPurchases = useCallback(
        () => dispatch(getAccountCampaignPurchasesRedux(accountRef)),
        [accountRef, dispatch]
    );

    /* @namespace state.account */
    const baseAccount = useSelector(state => state.account, shallowEqual);

    const {
        account = {},
        getAccountError,
        accountTransactions,
        accountTransactionsFilter: filter,
        getAccountTransactionsError,
        receivedAllTransactions = false,
        accountCampaignPurchases,
    } = baseAccount;
    const transactions = accountTransactions ? accountTransactions[accountRef] : [];

    const customerId = useSelector(state => state.authentication.person.id, shallowEqual);

    const [accountNumber, setAccountNumber] = useState(null);
    const [hasFatalError, setHasFatalError] = useState(null);
    const [hasError, setHasError] = useState(null);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        getAccount(customerId);
        if (isSE()) {
            getAccountCampaignPurchases();
        }
    }, [customerId, getAccountCampaignPurchases, getAccount]);

    useEffect(() => {
        if (hasFatalError !== getAccountError) {
            setHasFatalError(getAccountError);
        }
    }, [getAccountError, hasFatalError]);

    useEffect(() => {
        if (hasError !== getAccountTransactionsError) {
            setHasError(getAccountTransactionsError);
        }
    }, [getAccountTransactionsError, hasError]);

    useEffect(() => {
        if (account.product && account.accountNumber !== accountNumber) {
            getAccountTransactions(customerId, defaultFilter, false, false);

            setAccountNumber(account.accountNumber);
        }
    }, [account.product, account.accountNumber, customerId, accountNumber, getAccountTransactions]);

    const onScrollBottom = () => {
        if (receivedAllTransactions) {
            setIsSpinnerVisible(false);
            return;
        }

        setIsSpinnerVisible(true);

        getAccountTransactions(customerId, {
            ...filter,
            offset: filter.offset + filter.maxRecords,
        });
    };

    if (!hasError && !hasFatalError && (!account.product || !transactions)) return null;

    if (hasFatalError) {
        return (
            <AuthenticatedSubPageTemplate
                header={i18n('account.transactions.page-header')}
                className="account-transactions-overview"
            >
                <GeneralErrorMessagePanel
                    header={i18n('general.error.general.header')}
                    body={i18n('general.error.general.body')}
                    error={getAccountError}
                    dismissError={dismissGetAccountError}
                    buttonText={i18n('general.back')}
                    isLink
                />
            </AuthenticatedSubPageTemplate>
        );
    }

    // const partPaymentsDeferred = transactions.filter(trans => trans.detailedPartPaymentStatus === 'ALLOWED_DEFER');

    // overDrawn is only applicable for FI
    const showOverdrawn = account.overDrawn || account.limit - account.used <= -500 * 100; // compare in "öre"

    const switchTab = idx => setActiveIndex(idx);

    const tab1 = (
        <div>
            <Header account={account} />
            {showOverdrawn && (
                <OverdrawnInfo
                    used={account.used}
                    limit={account.limit}
                    accountNumber={account.accountNumber}
                    className="overdrawn-info"
                />
            )}
            {!window.EcsterConfig.isCordova && transactions && transactions.length > 0 && <ExportTransactionsToExcel />}
            <ScrollAwarePanel onScrollBottom={onScrollBottom} moreButtonText={i18n('account.transactions.show-more')}>
                <TxPageContext.Provider value={{ switchTab }}>
                    <AccountTransactions account={account} transactions={transactions} />
                </TxPageContext.Provider>
            </ScrollAwarePanel>
            {hasError && (
                <Message
                    exclamation
                    header={i18n('general.error.general.header')}
                    message={i18n('general.error.general.body')}
                    purple
                    whiteBg
                />
            )}
            <Spinner id="load-transactions-spinner" isCenterX isVisible={isSpinnerVisible} />
            {receivedAllTransactions && (
                <div id={transactions.length === 0 ? 'no-tx-info' : 'no-more-tx-info'} className="tx-info">
                    {transactions.length === 0
                        ? i18n('account.transactions.no-transactions')
                        : i18n('account.transactions.no-more-transactions')}
                </div>
            )}
        </div>
    );

    const tab2 = (
        <div>
            {accountCampaignPurchases.length > 0 ? (
                <PartPaymentsPanel
                    className="pp-panel"
                    campaignPurchases={accountCampaignPurchases}
                    header={i18n('account.transactions.ongoing-payments-tab.ongoing-payments')}
                />
            ) : (
                <Panel>
                    <PanelContent fullWidth centered>
                        <p>{i18n('account.part-payment.no-ongoing')}</p>
                    </PanelContent>
                </Panel>
            )}
        </div>
    );

    return (
        <AuthenticatedSubPageTemplate
            header={i18n('account.transactions.page-header')}
            className="account-transactions-overview"
            linkTo="/account/overview"
        >
            {isSE() && (
                <div className="tab-nav-container">
                    <Tabs
                        activeIndex={activeIndex}
                        labels={[
                            i18n('account.transactions.transactions-tab.transactions'),
                            i18n('account.transactions.ongoing-payments-tab.ongoing-payments'),
                        ]}
                        onTabChange={idx => setActiveIndex(idx)}
                    />
                </div>
            )}

            {activeIndex === 0 && tab1}
            {activeIndex === 1 && tab2}

            {hasError && (
                <Message
                    exclamation
                    header={i18n('general.error.general.header')}
                    message={i18n('general.error.general.body')}
                    purple
                    whiteBg
                />
            )}
        </AuthenticatedSubPageTemplate>
    );
};

export default AccountTransactionsOverviewPage;
