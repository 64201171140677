// for internal use only

const defaultOptions = {
    language: 'sv',
    country: 'SV',
    locale: 'sv-SE',
};

const defaultOptionsWithCurrency = {
    ...defaultOptions,
    currency: 'SEK',
};

const mergeOptions = (options, defaultOptions, global = window) => {
    const { EcsterConfig: conf } = global;

    const globalConf = {};

    if (conf) {
        // must do if here or else globalConf.language === undefined overrides defaultOptions
        if (conf.language || conf.locale) {
            globalConf.language = conf.language || (conf.locale && conf.locale.split('-')[0]);
        }
        if (conf.country || conf.locale) {
            globalConf.country = conf.country || (conf.locale && conf.locale.split('-')[1]);
        }
        if (conf.locale || (conf.language && conf.country)) {
            globalConf.locale = conf.locale || `${conf.language}-${conf.country}`;
        }
        if (conf.currency) {
            globalConf.currency = conf.currency;
        }
        if (defaultOptions.currency && conf.currency) {
            globalConf.currency = conf.currency;
        }
    }

    return { ...defaultOptions, ...globalConf, ...options };
};

/**
 * Merge options to a final options object
 *
 * Default options are
 *    language: 'sv'
 *    country: 'SV'
 *    locale: 'sv-SE'
 *
 * A general override of default options can be done with props in window.EcsterConfig
 *
 * Options passed as an options object parameter overrides both default options and EcsterConfig options
 *
 * @param options {object}
 * @param global {object} default is window, pass only when testing
 * @returns {{country, language, currency, locale}|{}}
 */
export const getOptions = (options, global = window) => {
    const opts = mergeOptions(options, defaultOptions, global);
    delete opts.currency;
    return opts;
};

/**
 * Merge options to a final options object - currency is included in this
 *
 * Default options are
 *    language: 'sv'
 *    country: 'SV'
 *    locale: 'sv-SE'
 *    currency: 'SEK'
 *
 * A general override of default options can be done with props in window.EcsterConfig
 *
 * Options passed as an options object parameter overrides both default options and EcsterConfig options
 *
 * @param options {object}
 * @param global {object} default is window, pass only when testing
 * @returns {{country, language, currency, locale}|{}}
 */
export const getOptionsWithCurrency = (options, global = window) => {
    return mergeOptions(options, defaultOptionsWithCurrency, global);
};
