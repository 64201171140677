import React, { ReactNode } from 'react';
import classNames from 'classnames';

// https://github.com/glennflanagan/react-collapsible#readme
import Collapsible from 'react-collapsible';
import { InteractiveElement } from '../Clickable';
import { getWidthClasses } from './content-width-classes';

import './ExpandUpDownContainer.scss';

const collapsibleDefaultProps = {
    trigger: null,
    contentHiddenWhenClosed: true,
    lazyRender: true,
    transitionTime: 200,
    classParentString: 'eudc-collapsible-outer',
};

interface ExpandUpDownContainerProps {
    /**
     * DOM id
     */
    id: string;

    /**
     * Trigger that expands or collapses first child container
     */
    trigger1: ReactNode | string;

    /**
     * Trigger that expands or collapses second child container
     */
    trigger2?: ReactNode | string;

    /**
     * Trigger that expands or collapses third child container
     */
    trigger3?: ReactNode | string;

    /**
     * Trigger that expands or collapses fourth child container
     */
    trigger4?: ReactNode | string;

    /**
     * Background of the first container
     */
    bg1?: 'white' | 'green' | 'red' | 'purple' | 'blue' | 'yellow' | 'beige' | 'gray';

    /**
     * Background of the second container
     */
    bg2?: 'white' | 'green' | 'red' | 'purple' | 'blue' | 'yellow' | 'beige' | 'gray';

    /**
     * Must be exactly one (1) or two (2) container elements.
     *
     * The first one expands down when first link is clicked
     *
     * The second one expands up when second link is clicked
     */
    children: ReactNode;

    /**
     * Additional content before the two links. A vertical bar will separate content from links
     *
     * A string (white) or JSX of your choice
     */
    contentBefore: ReactNode | string;

    /**
     * Additional content after the two links.
     *
     * A string (white) or JSX of your choice
     */
    contentAfter: ReactNode | string;

    /**
     * Change default behavior where first container expands up and second container expands down
     *
     * If true: First container expands down and second container expands up
     */
    downUp?: boolean;

    /**
     * Stick container to bottom of page
     *
     * Application must add bottom margin to compensate for trigger area height (60px)
     */
    stickToBottom?: boolean;

    /**
     * Additional css classes for the wrapping container
     */
    className?: string;

    /**
     * Callback when expand of first container has finished
     */
    onExpand1?: Function;

    /**
     * Callback when collapse of first container has finished
     */
    onCollapse1?: Function;

    /**
     * Callback before expand of first container starts
     */
    onBeforeExpand1?: () => void;

    /**
     * Callback before collapse of first container starts
     */
    onBeforeCollapse1?: () => void;

    /**
     * Callback when expand of second container has finished
     */
    onExpand2?: Function;

    /**
     * Callback when collapse of second container has finished
     */
    onCollapse2?: Function;

    /**
     * Callback before expand of second container starts
     */
    onBeforeExpand2?: () => void;

    /**
     * Callback before collapse of second container starts
     */
    onBeforeCollapse2?: () => void;

    /**
     * Callback when expand of third container has finished
     */
    onExpand3?: Function;

    /**
     * Callback when collapse of third container has finished
     */
    onCollapse3?: Function;

    /**
     * Callback before expand of third container starts
     */
    onBeforeExpand3?: () => void;

    /**
     * Callback before collapse of third container starts
     */
    onBeforeCollapse3?: () => void;

    /**
     * Callback when expand of fourth container has finished
     */
    onExpand4?: Function;

    /**
     * Callback when collapse of fourth container has finished
     */
    onCollapse4?: Function;

    /**
     * Callback before expand of fourth container starts
     */
    onBeforeExpand4?: () => void;

    /**
     * Callback before collapse of fourth container starts
     */
    onBeforeCollapse4?: () => void;

    /** width 325px (100% in mobile) */
    xNarrow?: boolean;

    /** width 500px (100% in mobile) */
    narrow?: boolean;

    /** width 680px (100% in mobile) */
    wide?: boolean;

    /** width 720px (100% in mobile) */
    xWide?: boolean;

    /** width 1078px (100% in mobile) */
    xxWide?: boolean;

    /** width 100% */
    fullWidth?: boolean;

    /** width 100% with 15px extra space left and right */
    fullWidthPadded?: boolean;

    /** Right align triggers */
    alignTriggersRight?: boolean;
}

export default class ExpandUpDownContainer extends React.Component<ExpandUpDownContainerProps> {
    state = {
        isExpanded1: false,
        isExpanded2: false,
        isExpanded3: false,
        isExpanded4: false,
    };

    onToggleContainer1 = () => {
        const { onExpand1, onCollapse1 } = this.props;
        const { isExpanded1, isExpanded3 } = this.state;
        if (isExpanded3) {
            this.onToggleContainer3();
            setTimeout(() => this.setState({ isExpanded1: !isExpanded1 }), 300);
        } else {
            this.setState({ isExpanded1: !isExpanded1 });
        }
        if (isExpanded1) {
            onCollapse1();
        } else {
            onExpand1();
        }
    };

    onToggleContainer2 = () => {
        const { onExpand2, onCollapse2 } = this.props;
        const { isExpanded2, isExpanded4 } = this.state;
        if (isExpanded4) {
            this.onToggleContainer4();
            setTimeout(() => this.setState({ isExpanded2: !isExpanded2 }), 300);
        } else {
            this.setState({ isExpanded2: !isExpanded2 });
        }
        if (isExpanded2) {
            onCollapse2();
        } else {
            onExpand2();
        }
    };

    onClose2 = () => {
        const { onCollapse2 } = this.props;
        this.setState({ isExpanded2: false });
        onCollapse2();
    };

    onToggleContainer3 = () => {
        const { onExpand3, onCollapse3 } = this.props;
        const { isExpanded1, isExpanded3 } = this.state;
        if (isExpanded1) {
            this.onToggleContainer1();
            setTimeout(() => this.setState({ isExpanded3: !isExpanded3 }), 300);
        } else {
            this.setState({ isExpanded3: !isExpanded3 });
        }
        if (isExpanded3) {
            onCollapse3();
        } else {
            onExpand3();
        }
    };

    onClose3 = () => {
        const { onCollapse3 } = this.props;
        this.setState({ isExpanded3: false });
        onCollapse3();
    };

    onToggleContainer4 = () => {
        const { onExpand4, onCollapse4 } = this.props;
        const { isExpanded2, isExpanded4 } = this.state;

        if (isExpanded2) {
            this.onToggleContainer2();
            setTimeout(() => this.setState({ isExpanded4: !isExpanded4 }), 300);
        } else {
            this.setState({ isExpanded4: !isExpanded4 });
        }
        if (isExpanded4) {
            onCollapse4();
        } else {
            onExpand4();
        }
    };

    onClose4 = () => {
        const { onCollapse4 } = this.props;
        this.setState({ isExpanded4: false });
        onCollapse4();
    };

    render() {
        const {
            id,
            children,
            className,
            trigger1,
            trigger2,
            trigger3,
            trigger4,
            contentBefore,
            contentAfter,
            bg1,
            bg2,
            onBeforeExpand1,
            onBeforeCollapse1,
            onBeforeExpand2,
            onBeforeCollapse2,
            onBeforeExpand3,
            onBeforeCollapse3,
            onBeforeExpand4,
            onBeforeCollapse4,
            downUp,
            stickToBottom,
            alignTriggersRight,
        } = this.props;
        const { isExpanded1, isExpanded2, isExpanded3, isExpanded4 } = this.state;

        let content1;
        let content2;
        let content3;
        let content4;

        if (Array.isArray(children)) {
            if (children.length < 1 || children.length > 4) {
                return (
                    <p className="eudc-oops">
                        Oops... ExpandUpDownContainer accepts exactly 1, 2, 3 or 4 child elements!
                    </p>
                );
            }
            [content1, content2, content3, content4] = children;
        } else {
            content1 = children;
        }

        const widthClasses = getWidthClasses(this.props);

        const classes = classNames({
            'ec-expand-up-down-container': true,
            'eudc-stick-to-bottom': stickToBottom,
            [className]: className,
        });

        const collapsible1Classes = classNames({
            'eudc-container1': true,
            'eudc-is-expanded': isExpanded1,
            'eudc-expands-up': !downUp,
            'eudc-expands-down': downUp,
            [`eudc-bg-${bg1}`]: true,
            [widthClasses]: true,
        });

        const collapsible2Classes = classNames({
            'eudc-container2': true,
            'eudc-is-expanded': isExpanded2,
            'eudc-expands-up': downUp,
            'eudc-expands-down': !downUp,
            [`eudc-bg-${bg2}`]: true,
            [widthClasses]: true,
        });

        const collapsible3Classes = classNames({
            'eudc-container3': true,
            'eudc-is-expanded': isExpanded3,
            'eudc-expands-up': downUp,
            'eudc-expands-down': !downUp,
            [`eudc-bg-${bg1}`]: true,
            [widthClasses]: true,
        });

        const collapsible4Classes = classNames({
            'eudc-container4': true,
            'eudc-is-expanded': isExpanded4,
            'eudc-expands-up': downUp,
            'eudc-expands-down': !downUp,
            [`eudc-bg-${bg2}`]: true,
            [widthClasses]: true,
        });

        const collapsible1InnerClasses = classNames({
            'eudc-collapsible-inner': true,
            [widthClasses]: true,
        });

        const collapsible2InnerClasses = classNames({
            'eudc-collapsible-inner': true,
            [widthClasses]: true,
        });
        const collapsible3InnerClasses = classNames({
            'eudc-collapsible-inner': true,
            [widthClasses]: true,
        });
        const collapsible4InnerClasses = classNames({
            'eudc-collapsible-inner': true,
            [widthClasses]: true,
        });

        const triggersInnerClasses = classNames({
            'eudc-triggers-inner': true,
            [widthClasses]: true,
        });
        const triggersClasses = classNames({
            'eudc-triggers-inner-right': alignTriggersRight,
        });
        const afterContentClasses = classNames({
            'eudc-triggers-inner-right': !alignTriggersRight,
        });

        const firstCollapsible = (
            <Collapsible
                {...collapsibleDefaultProps}
                id={`${id}-first`}
                open={isExpanded1}
                className={collapsible1Classes}
                openedClassName={collapsible1Classes}
                onOpening={onBeforeExpand1}
                onClosing={onBeforeCollapse1}
            >
                <div className={collapsible1InnerClasses}>{content1}</div>
            </Collapsible>
        );

        const secondCollapsible = trigger2 && (
            <Collapsible
                {...collapsibleDefaultProps}
                id={`${id}-second`}
                open={isExpanded2}
                className={collapsible2Classes}
                openedClassName={collapsible2Classes}
                onOpening={onBeforeExpand2}
                onClosing={onBeforeCollapse2}
            >
                <div className={collapsible2InnerClasses}>{content2}</div>
            </Collapsible>
        );

        const thirdCollapsible = trigger3 && (
            <Collapsible
                {...collapsibleDefaultProps}
                id={`${id}-third`}
                open={isExpanded3}
                className={collapsible3Classes}
                openedClassName={collapsible3Classes}
                onOpening={onBeforeExpand3}
                onClosing={onBeforeCollapse3}
            >
                <div className={collapsible3InnerClasses}>{content3}</div>
            </Collapsible>
        );

        const fourthCollapsible = trigger4 && (
            <Collapsible
                {...collapsibleDefaultProps}
                id={`${id}-fourth`}
                open={isExpanded4}
                className={collapsible4Classes}
                openedClassName={collapsible4Classes}
                onOpening={onBeforeExpand4}
                onClosing={onBeforeCollapse4}
            >
                <div className={collapsible4InnerClasses}>{content4}</div>
            </Collapsible>
        );

        return (
            <div className={classes}>
                {downUp ? (
                    <>
                        {secondCollapsible} {fourthCollapsible}
                    </>
                ) : (
                    <>
                        {firstCollapsible} {thirdCollapsible}
                    </>
                )}
                <div className="eudc-triggers-outer">
                    <div className={triggersInnerClasses}>
                        {contentBefore && (
                            <div>
                                <span className="eudc-content-before">
                                    {contentBefore}
                                    {!alignTriggersRight && <span className="eudc-separator"> | </span>}
                                </span>
                            </div>
                        )}

                        <div className={triggersClasses}>
                            <InteractiveElement onClick={this.onToggleContainer1}>{trigger1}</InteractiveElement>
                            {trigger2 && (
                                <>
                                    <span className="eudc-separator"> | </span>
                                    <InteractiveElement onClick={this.onToggleContainer2}>
                                        {trigger2}
                                    </InteractiveElement>
                                </>
                            )}
                            {trigger3 && (
                                <>
                                    <span className="eudc-separator"> | </span>
                                    <InteractiveElement onClick={this.onToggleContainer3}>
                                        {trigger3}
                                    </InteractiveElement>
                                </>
                            )}
                            {trigger4 && (
                                <>
                                    <span className="eudc-separator"> | </span>
                                    <InteractiveElement onClick={this.onToggleContainer4}>
                                        {trigger4}
                                    </InteractiveElement>
                                </>
                            )}
                            {alignTriggersRight && contentAfter && <span className="eudc-separator"> | </span>}
                        </div>

                        {contentAfter && <div className={afterContentClasses}>{contentAfter}</div>}
                    </div>
                </div>
                {downUp ? (
                    <>
                        {firstCollapsible} {thirdCollapsible}
                    </>
                ) : (
                    <>
                        {secondCollapsible} {fourthCollapsible}
                    </>
                )}
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
ExpandUpDownContainer.defaultProps = {
    className: '',
    trigger2: null,
    trigger3: null,
    trigger4: null,
    bg1: 'white',
    bg2: 'white',
    contentBefore: null,
    contentAfter: null,
    downUp: false,
    stickToBottom: false,
    onExpand1: () => {},
    onCollapse1: () => {},
    onBeforeExpand1: () => {},
    onBeforeCollapse1: () => {},
    onExpand2: () => {},
    onCollapse2: () => {},
    onBeforeExpand2: () => {},
    onBeforeCollapse2: () => {},
    onExpand3: () => {},
    onCollapse3: () => {},
    onBeforeExpand3: () => {},
    onBeforeCollapse3: () => {},
    onExpand4: () => {},
    onCollapse4: () => {},
    onBeforeExpand4: () => {},
    onBeforeCollapse4: () => {},

    xNarrow: false,
    narrow: false,
    wide: false,
    xWide: false,
    xxWide: false,
    fullWidth: false,
    fullWidthPadded: false,
    alignTriggersRight: false,
};
