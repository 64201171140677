import { AUTHENTICATION_SET_LOGIN_STATS_DONE } from './constants';

// Remember that login GA stats have been set
// They are set when overview page is loaded and accounts, contracts and invoices have been fetched
// Should happen exactly once per login session

export const setLoginStatsDone = done => ({
    type: AUTHENTICATION_SET_LOGIN_STATS_DONE,
    done,
});

// don't ES6 this one, rekit gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case AUTHENTICATION_SET_LOGIN_STATS_DONE:
            return {
                ...state,
                // add state modifications here
                loginStatsDone: action.done,
            };

        default:
            return state;
    }
}
