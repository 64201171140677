import React from 'react';
import { ExpandableContainer, Icon, LabelValue } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { ExpandHeader } from '../ExpandHeader';

export const CallUs = () => {
    return (
        <ExpandableContainer
            id="call-us-expandable-container"
            header={
                <ExpandHeader
                    icon={<Icon icon="icon-phone" green medium />}
                    text={i18n('customer.contact-us.call-us.header')}
                />
            }
            stretch
        >
            <div className="expanded-content w67">
                <LabelValue
                    shouldWrap
                    label={i18n('customer.contact-us.call-us.phone-service-label')}
                    value={i18n('customer.contact-us.call-us.phone-service-value', {
                        danger: true,
                    })}
                />
                <LabelValue
                    label={i18n('customer.contact-us.call-us.opening-hours-label')}
                    value={i18n('customer.contact-us.call-us.opening-hours-info', { danger: true })}
                />
            </div>
        </ExpandableContainer>
    );
};

CallUs.propTypes = {};

CallUs.defaultProps = {};
