export const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // eslint-disable-next-line prefer-spread
            func.apply(null, args);
        }, wait);
    };
};
