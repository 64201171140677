import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import {
    Panel,
    PanelContent,
    ResponsivePanel,
    TabletOrDesktop,
    Badge,
    Message,
    UserMessagePanel,
    LabelValue,
    ResponsiveTable as Table,
    ResponsiveTableHeader as TableHeader,
    ResponsiveTableBody as TableBody,
    ResponsiveTableRow as TableRow,
    ResponsiveTableHeaderColumn as TableHeaderColumn,
    ResponsiveTableRowColumn as TableRowColumn,
} from '@ecster/components';

import { walletIcon } from '@ecster/icons/H80/green';

import { withRouter } from 'react-router-dom';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { formatAmount } from '../../common/util/format-amount';
import { formatDate } from '../../common/util/format-date';
import { getContractInvoice, dismissGetContractInvoiceError, clearGetContractInvoiceState } from './redux/actions';
import OcrScan from '../invoice/ocr-scan/OcrScan';
import { DueAmount, DueDate, BgNumber, OcrNumber } from '../invoice/invoice-values';
import ErrorBoundary from '../../common/ErrorBoundary';

const Wrapper = ({ children }) => (
    <AuthenticatedSubPageTemplate className="contract-invoice-page" header={i18n('contract.invoice-page.header')}>
        {children}
    </AuthenticatedSubPageTemplate>
);

Wrapper.propTypes = { children: PropTypes.node.isRequired };

export class ContractInvoicePage extends React.Component {
    static propTypes = {
        // router: PropTypes.shape({
        //     params: PropTypes.shape({
        //         contractNumber: PropTypes.string.isRequired,
        //     }).isRequired,
        // }).isRequired,
        contractNumber: PropTypes.string.isRequired,
        contractInvoice: PropTypes.object,
        customerId: PropTypes.number.isRequired,

        getContractInvoice: PropTypes.func.isRequired,
        getContractInvoiceError: PropTypes.object,

        dismissGetContractInvoiceError: PropTypes.func.isRequired,
        clearGetContractInvoiceState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        contractInvoice: null,
        getContractInvoiceError: null,
    };

    state = {};

    componentDidMount() {
        const {
            getContractInvoice,
            customerId,
            contractNumber,
            // router: {
            //     params: { contractNumber },
            // },
        } = this.props;
        getContractInvoice(customerId, contractNumber);
    }

    componentWillUnmount() {
        const { clearGetContractInvoiceState, dismissGetContractInvoiceError } = this.props;
        clearGetContractInvoiceState();
        dismissGetContractInvoiceError();
    }

    render() {
        const { contractInvoice, getContractInvoiceError } = this.props;

        if (getContractInvoiceError) {
            return (
                <Wrapper>
                    <UserMessagePanel
                        icon={walletIcon}
                        header={i18n('invoice.invoice-overview.no-invoice.header')}
                        body={i18n('invoice.invoice-overview.no-invoice.info')}
                    />
                </Wrapper>
            );
        }
        if (!contractInvoice) return null;

        const {
            contractInvoice: {
                invoicedAmount,
                outstandingPaymentContract,
                paymentStatus,
                autogiroEnabled,
                ocrNumber,
                dueDate,
                specification,
                latestPaymentDate,
            },
        } = this.props;

        const totalAmount = invoicedAmount + (outstandingPaymentContract || 0);

        const paid = paymentStatus === 'PAID';

        const outstandingPaymentMessage = outstandingPaymentContract > 0 && (
            <Message purple header={i18n('contract.invoice-page.outstanding-payment-message.header')}>
                <p>{i18n('contract.invoice-page.outstanding-payment-message.info')}</p>
                <div className="outstanding-payment-info">
                    <LabelValue
                        label={
                            <>
                                <div>{i18n('contract.invoice-page.outstanding-payment-message.sub-header')}</div>
                                {latestPaymentDate && (
                                    <div className="small-text">
                                        {i18n('contract.invoice-page.outstanding-payment-message.date-prefix')}{' '}
                                        {formatDate(latestPaymentDate)}
                                    </div>
                                )}
                            </>
                        }
                        value={formatAmount(outstandingPaymentContract, { strip00: true, decimals: 2 })}
                    />
                </div>
                <p>{i18n('contract.invoice-page.outstanding-payment-message.extra-info')}</p>
            </Message>
        );

        const autogiroInfo = autogiroEnabled ? (
            <div className="autogiro-info">
                <h4>{i18n('contract.invoice-page.summary.autogiro.active')} </h4>
                <p>{i18n('contract.invoice-page.summary.autogiro.withdrawal-info')}</p>
            </div>
        ) : (
            <div className="autogiro-info">
                <h4>{i18n('contract.invoice-page.summary.autogiro.not-active')}</h4>
                <p>{i18n('contract.invoice-page.summary.autogiro.activate-info')}</p>
            </div>
        );

        const badge = (
            <Badge action={!paid} iconLeft={paid ? 'icon-check' : null}>
                {i18n(`contract.payment-status.${paymentStatus}`)}
            </Badge>
        );

        const rows = specification.map((row, index) => {
            return { ...row, index };
        });

        return (
            <Wrapper>
                {outstandingPaymentMessage}

                <Panel paddingTopBottom="xLarge" centeredContent stretchInMobile>
                    <PanelContent wide>
                        <h3>{i18n('contract.invoice-page.summary.header')}</h3>
                        {badge}
                        <ResponsivePanel separator className="mt-4x items-center">
                            <div>
                                <DueAmount amount={invoicedAmount} />
                                <DueDate date={dueDate} />
                                <BgNumber bgNumber={i18n('general.bgNumber.invoice')} />
                                {ocrNumber ? (
                                    <OcrNumber
                                        ocrNumber={ocrNumber}
                                        useInfoIcon={false}
                                        label={i18n('invoice.contract-invoice.invoice-info.ocr-label')}
                                    />
                                ) : (
                                    <div className="left">{i18n('contract.invoice-page.summary.ocr-unavailable')}</div>
                                )}
                            </div>
                            {autogiroInfo}
                        </ResponsivePanel>
                        {!autogiroEnabled && (
                            <TabletOrDesktop>
                                <div className="mt-8x flex">
                                    <ErrorBoundary name="ContractInvoicePage OcrScan">
                                        <OcrScan
                                            text={i18n('invoice.ocr-scan.text')}
                                            ocrNumber={ocrNumber}
                                            bgNumber={i18n('general.bgNumber.invoice')}
                                            amount={totalAmount}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </TabletOrDesktop>
                        )}
                    </PanelContent>
                </Panel>

                <Panel paddingTopBottom="xLarge" centeredContent stretchInMobile className="ptb-8x plr-3x">
                    <PanelContent fullWidth>
                        <h3>{i18n('contract.invoice-page.specification.header')}</h3>
                        <Table className="mt-3x" large>
                            <TableHeader notOnMobile>
                                <TableRow>
                                    <TableHeaderColumn>
                                        {i18n('contract.invoice-page.specification.description')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn>
                                        {i18n('contract.invoice-page.specification.period')}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn alignRight>
                                        {i18n('contract.invoice-page.specification.amount')}
                                    </TableHeaderColumn>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {rows.map(row => (
                                    /** @namespace row.contractInvoiceRowType */
                                    /** @namespace row.contractInvoiceRowDescription */
                                    /** @namespace row.periodDescription */
                                    <TableRow key={`${row.contractInvoiceRowType}-${row.index}`}>
                                        <TableRowColumn onlyOnMobile>
                                            <div className="capitalize">
                                                {row.contractInvoiceRowDescription &&
                                                    row.contractInvoiceRowDescription.toLowerCase()}
                                            </div>
                                            {row.periodDescription && (
                                                <div className="weak small-text">{row.periodDescription}</div>
                                            )}
                                        </TableRowColumn>
                                        <TableRowColumn notOnMobile className="capitalize">
                                            {row.contractInvoiceRowDescription &&
                                                row.contractInvoiceRowDescription.toLowerCase()}
                                        </TableRowColumn>
                                        <TableRowColumn notOnMobile>
                                            {row.periodDescription ? row.periodDescription : ''}
                                        </TableRowColumn>
                                        <TableRowColumn alignRight>
                                            {formatAmount(row.amount, { decimals: 2 })}
                                        </TableRowColumn>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </PanelContent>
                </Panel>
            </Wrapper>
        );
    }
}

/* istanbul ignore next */

// eslint-disable-next-line
function mapStateToProps({ contract, authentication }, { match }) {
    const { contractNumber } = match.params;
    return {
        contractNumber,
        customerId: authentication.person.id,
        contractInvoice: contract.currentContractInvoice,
        getContractInvoiceError: contract.getContractInvoiceError,
        getContractInvoicePending: contract.getContractInvoicePending,
    };
}

/* istanbul ignore next */

// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        getContractInvoice: (customerId, contractNumber) => dispatch(getContractInvoice(customerId, contractNumber)),
        dismissGetContractInvoiceError: () => dispatch(dismissGetContractInvoiceError()),
        clearGetContractInvoiceState: () => dispatch(clearGetContractInvoiceState()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractInvoicePage));
