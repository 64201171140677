import React from 'react';
import { ExpandableContainer, Icon, LabelValue, Link } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { ExpandHeader } from '../ExpandHeader';

export const CallUsComplaint = () => {
    return (
        <ExpandableContainer
            id="contact-us-transaction-info"
            header={
                <ExpandHeader
                    icon={<Icon icon="icon-transactions" green medium />}
                    text={i18n('customer.contact-us.call-us-complaint.header')}
                />
            }
            stretch
        >
            <div className="expanded-content">
                <p>{i18n('customer.contact-us.call-us-complaint.info')}</p>
                <p className="mb-4x">
                    <Link
                        green
                        underline
                        href={i18n('customer.contact-us.call-us-complaint.link')}
                        target="_blank"
                        iconRight="icon-external-link"
                    >
                        {i18n('customer.contact-us.call-us-complaint.link-text')}
                    </Link>
                </p>

                <div className="w67">
                    <LabelValue
                        shouldWrap
                        label={i18n('customer.contact-us.call-us.phone-service-label')}
                        value={i18n('customer.contact-us.call-us.phone-service-value', {
                            danger: true,
                        })}
                    />
                </div>
            </div>
        </ExpandableContainer>
    );
};

CallUsComplaint.propTypes = {};

CallUsComplaint.defaultProps = {};
