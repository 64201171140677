// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

const routes = {
    path: 'invoice',
    name: 'Invoice',
    childRoutes: [],
};

export default routes;
