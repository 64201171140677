import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, ResponsivePanel, LabelValue } from '@ecster/components';
import './Header.scss';
import { formatAmount, formatDate, formatDateMonth } from '../../../../common/util/format';

export const Header = ({ bill, billProps }) => {
    // debts
    const ingoingDebt = bill.balanceBroughtForward;
    const { outgoingDebt: { amount, date: dueDate, description = '' } = {} } = bill;

    const hasOutgoingData = description.toLowerCase() === 'utgående skuld';

    const ingoingDebtLabel =
        ingoingDebt < 0 ? 'invoice.account-invoice-spec.owing' : 'invoice.account-invoice-spec.debt';
    const outgoingDebtLabel =
        amount < 0 ? 'invoice.account-invoice-spec.total-owing' : 'invoice.account-invoice-spec.total-debt';

    return (
        <div className="account-invoice-header">
            <Panel paddingTopBottom="large" greenBG id="account-invoice-summary">
                <PanelContent>
                    <ResponsivePanel>
                        <div className="centered">
                            <div className="large-text">{formatAmount(billProps.lab3)}</div>
                            <div className="small-text">
                                {i18n('invoice.account-invoice-spec.to-pay', {
                                    month: formatDateMonth(dueDate),
                                })}
                            </div>
                        </div>
                        <div>
                            <LabelValue
                                green
                                label={i18n(ingoingDebtLabel)}
                                value={formatAmount(ingoingDebt, { isOnlyPositive: true })}
                            />
                            {hasOutgoingData && (
                                <LabelValue
                                    green
                                    label={i18n(outgoingDebtLabel, {
                                        date: formatDate(dueDate),
                                    })}
                                    value={formatAmount(amount, { isOnlyPositive: true })}
                                />
                            )}
                        </div>
                    </ResponsivePanel>
                </PanelContent>
            </Panel>
        </div>
    );
};

Header.propTypes = {
    bill: PropTypes.object.isRequired,
    billProps: PropTypes.object.isRequired,
};
