import React from 'react';

export const SvgIconConstruction = () => (
    <div className="svg-icon svg-icon--construction">
        <svg height="77" viewBox="0 0 79 77" width="79" xmlns="http://www.w3.org/2000/svg">
            <g
                style={{
                    fill: 'none',
                    fillRule: 'evenodd',
                    stroke: '#59b189',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: '2',
                }}
            >
                <path
                    d="m77.311 53.453-15.735-51.428c-.394-1.342-2.282-1.374-2.725-.05l-15.634 47.545 2.623 10.49 17.047-49.83m14.424 43.273c0 3.622 1.474 11.801-6.556 11.801-6.284 0-11.802-4.246-11.802-7.867z"
                    fill="#eff8f3"
                />
                <path d="m40.594 60.01h-39.339l7.868-31.472h23.604l7.867 31.471" fill="#fff" />
                <path d="m3.878 49.519h15.735l5.246 10.49m0-18.359h5.245" />
                <path
                    d="m48.462 65.254h-41.962a5.245 5.245 0 0 0 0 10.49l41.962.001a5.245 5.245 0 0 0 0-10.49z"
                    fill="#eff8f3"
                />
                <path d="m30.104 60.01-2.624-18.36" />
            </g>
        </svg>
    </div>
);
