// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { NetsRedirectPage } from './index';

const routes = {
    path: 'tupas',
    name: 'Tupas',
    childRoutes: [{ isPublic: true, path: 'login', name: 'Tupas Login', component: NetsRedirectPage, isIndex: true }],
};

export default routes;