import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Message } from '@ecster/components';
import './PaymentFreeMonthMessage.scss';

export const PaymentFreeMonthMessage = ({ bill }) => {
    let header;
    let text;

    // note: keeping variables and if statement, other payment free cases may be implemented soon
    const { paymentFreeMonth } = bill;
    if (paymentFreeMonth) {
        header = i18n('invoice.account-invoices.payment-message.amortization-free-month.header');
        text = i18n('invoice.account-invoices.payment-message.amortization-free-month.text');
    } else {
        return null;
    }

    return <Message blue header={header} message={text} className="account-invoice-payment-free-month-message" />;
};

PaymentFreeMonthMessage.propTypes = {
    bill: PropTypes.object.isRequired,
};
