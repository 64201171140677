import React, { Component, ReactNode } from 'react';
import classNames from 'classnames';
import { RRContext } from '@ecster/context';
import { linkOnClick } from './linkOnClick';
import './Link.scss';

interface LinkProps {
    /**
     * CSS id
     */
    id?: string;

    /**
     * CSS classes
     */
    className?: string;

    /**
     * Link child elements
     */
    children: ReactNode;

    /**
     * The text shown to screen readers
     */
    ariaLabel?: string;

    /**
     * This property is for react-router routes. Link returns a <Link to={} ... element
     *
     * E.g. /account/overview
     */
    to?: string;

    /**
     * This property is for other links. Link returns an <a href={} ... element
     *
     * E.g. https://www.ecster.se
     */
    href?: string;

    /**
     * Use in combination with href
     *
     * E.g. target="_blank"
     */
    target?: string;

    /**
     * Called when Link is clicked
     */
    onClick?: Function;

    /**
     * An optional numeric value to use for the onClick GA event.
     */
    gaValue?: number;

    /**
     * Add an icon to the left of link.
     *
     * Values are css names for the ecster-icon font
     */
    iconLeft?: string;

    /**
     * Add an icon to the right of link.
     *
     * Values are css names for the ecster-icon font
     */
    iconRight?: string;

    /**
     * Short name for iconRight
     */
    icon?: string;

    /**
     * An extra css class for icon
     */
    iconColorClass?: string;

    // color tweaks
    /**
     * green is default if no color is specified
     */
    green?: boolean;
    blue?: boolean;
    purple?: boolean;
    red?: boolean;
    yellow?: boolean;

    /** @deprecated */
    beige?: boolean;

    /** @deprecated */
    gray?: boolean;

    /** @deprecated */
    bankId?: boolean;
    white?: boolean;
    black?: boolean;
    phone?: boolean; // phone number link has a specific color

    /**
     * Underline link in default, hover and active states (and focus as always)
     */
    underline?: boolean;

    /**
     * Turn off underline on focus (which is default for all link variations (except phone links).
     *
     * Use for special links where you want to control underline in the app scss
     */
    noUnderlineOnFocus?: boolean;
    strong?: boolean;
    tabIndex?: number;

    /**
     * Signal to supporting browser that the link target should be downloaded.
     */
    download?: boolean;
}

/**
 * Component creates a link
 *
 * Must specify either "href" or "to"
 */
class Link extends Component<LinkProps> {
    linkRef = React.createRef<HTMLAnchorElement>();

    onClick = e => {
        linkOnClick(this, e);
    };

    render() {
        const {
            ariaLabel,
            bankId,
            beige,
            black,
            blue,
            children,
            className,
            download,
            gray,
            href,
            icon,
            iconColorClass,
            iconLeft,
            iconRight,
            id,
            noUnderlineOnFocus,
            phone,
            purple,
            red,
            yellow,
            strong,
            tabIndex,
            target,
            to,
            underline,
            white,
        } = this.props;

        // left icon?
        const theLeftIcon = iconLeft ? (
            <i
                className={classNames({
                    [iconLeft]: iconLeft,
                    [iconColorClass]: iconColorClass,
                })}
            />
        ) : undefined;

        // right icon?
        const theRightIcon = iconRight ? (
            <i
                className={classNames({
                    [iconRight]: iconRight,
                    [iconColorClass]: iconColorClass,
                })}
            />
        ) : undefined;

        // right icon?
        const theIcon = icon ? (
            <i
                className={classNames({
                    [icon]: icon,
                    [iconColorClass]: iconColorClass,
                })}
            />
        ) : undefined;

        const linkProps = {
            'aria-label': ariaLabel,
            target,
            id,
            tabIndex,
            className: classNames({
                'ec-link': true,
                [className]: className,
                'ec-blue': blue || bankId,
                'ec-purple': purple,
                'ec-red': red,
                'ec-yellow': yellow,
                'ec-beige': beige,
                'ec-gray': gray,
                'ec-white': white,
                'ec-black': black,
                'ec-phone': phone,
                'ec-green':
                    !blue && !purple && !red && !beige && !gray && !bankId && !white && !phone && !black && !yellow,
                'ec-link-underline': underline,
                'ec-link-never-underline': noUnderlineOnFocus,
                'ec-strong': strong,
            }),
            rel: null,
        };

        if (target === '_blank') {
            linkProps.rel = 'noopener noreferrer';
        }

        const linkBody = (
            <>
                {theLeftIcon && <>{theLeftIcon} </>}
                <span>{children}</span>
                {theIcon && <> {theIcon}</>}
                {theRightIcon && <> {theRightIcon}</>}
            </>
        );

        const link = ReactRouterLink => {
            if (to && !ReactRouterLink) {
                console.error(`Do not use prop "to" without RRProvider in your application root.js.`);
                return (
                    <a ref={this.linkRef} href={to} onClick={this.onClick} {...linkProps}>
                        {linkBody}
                    </a>
                );
            }

            return to ? (
                <ReactRouterLink ref={this.linkRef} to={to} onClick={this.onClick} {...linkProps}>
                    {linkBody}
                </ReactRouterLink>
            ) : (
                <a ref={this.linkRef} href={href} onClick={this.onClick} {...linkProps} download={download}>
                    {linkBody}
                </a>
            );
        };

        return (
            <RRContext.Consumer>
                {({ Link: ReactRouterLink }) => {
                    return link(ReactRouterLink);
                }}
            </RRContext.Consumer>
        );
    }
}

// @ts-expect-error class defaultProps
Link.defaultProps = {
    ariaLabel: '',
    id: '',
    className: '',
    to: undefined,
    href: undefined,
    target: '_self',
    onClick: () => {},
    gaValue: undefined,

    // icons
    icon: undefined,
    iconLeft: undefined,
    iconRight: undefined,
    iconColorClass: '',

    // colors

    /**
     * Green is the default link color if none is specified
     */
    green: undefined,
    blue: undefined,
    purple: undefined,
    red: undefined,
    yellow: undefined,
    beige: undefined,
    gray: undefined,
    bankId: undefined,
    white: undefined,
    black: undefined,
    phone: undefined,

    underline: false,
    noUnderlineOnFocus: false,
    strong: false,
    tabIndex: 0,
    download: false,
};

export default Link;
