// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './DataColumns.scss';

const gridTemplateColumns = (minWidth = 280) => `repeat(auto-fit, minmax(${minWidth}px, 1fr))`;

interface DataColumnsProps {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;
    minWidth?: number;
}

/** @deprecated use Table or LabelValue instead */
class DataColumns extends React.Component<DataColumnsProps> {
    constructor(props) {
        super(props);

        this.state = {
            numberOfCols: 1,
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);

        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        const containerWidth = this.el.offsetWidth;

        const childWidth = this.props.minWidth;

        this.setState({ numberOfCols: Math.floor(containerWidth / childWidth) });
    }

    render() {
        const { children, style, className, minWidth } = this.props;

        const dataColumnsClassName = classNames({
            'data-columns': true,
            [className]: className,
        });

        const decoratedChildren = React.Children.map(children, (child, index) => {
            const count = index + 1;
            const childrenCount = React.Children.count(children);

            if (count === childrenCount || count % this.state.numberOfCols === 0) {
                return React.cloneElement(child, { minWidth });
            }
            return React.cloneElement(child, { minWidth, borderRight: true });
        });

        return (
            <div
                ref={el => {
                    this.el = el;
                }}
                className={dataColumnsClassName}
                style={{ ...style, gridTemplateColumns: gridTemplateColumns(minWidth) }}
            >
                {decoratedChildren}
            </div>
        );
    }
}

DataColumns.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.shape(),
    className: PropTypes.string,
    minWidth: PropTypes.number,
};

DataColumns.defaultProps = {
    style: {},
    className: '',
    minWidth: 280,
};

export default DataColumns;
