import { Cookie } from '@ecster/net';
import { i18nInit } from '@ecster/i18n';

const currency = {
    FI: 'EUR',
    SE: 'SEK',
};

export const changeLanguage = async locale => {
    const [lang, country] = locale.split('-');

    Cookie.create('locale', locale, 365);

    await i18nInit('static/i18n', lang, country);

    window.EcsterConfig.language = lang;
    window.EcsterConfig.currency = currency[country];
    window.EcsterConfig.locale = locale;

    document.querySelector('html').setAttribute('lang', locale);
};
