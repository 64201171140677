import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';

export const IbanNumber = ({ ibanNumber, label }) => (
    <LabelValue
        label={label || i18n('invoice.invoice-values.iban-number')}
        value={
            <CopyToClipboardButton
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-iban-number')}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={ibanNumber.replace(/ /g, '')}
            >
                {ibanNumber}
            </CopyToClipboardButton>
        }
    />
);
IbanNumber.propTypes = { ibanNumber: PropTypes.string.isRequired, label: PropTypes.string };
IbanNumber.defaultProps = { label: null };
