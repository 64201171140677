/**
 * Returns true if element is in viewport
 * @param el HTML DOM Element
 * @param headerSelectors {array} - CSS query to calculate height of blocks above
 */
const isElementInViewport = (el, headerSelectors = []) => {
    const rect = el.getBoundingClientRect();

    const offsetHeaders = headerSelectors.reduce((sum, headerSelector) => {
        const header = document.querySelector(headerSelector);

        return sum + (header ? header.getBoundingClientRect().height : 0);
    }, 0);

    return (
        rect.bottom > (offsetHeaders || 0) &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight)
    );
};

export default isElementInViewport;
