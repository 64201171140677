import { get } from '@ecster/net/v2'; // or post, put, del
import {
    INVOICE_GET_INVOICE_BEGIN,
    INVOICE_GET_INVOICE_SUCCESS,
    INVOICE_GET_INVOICE_FAILURE,
    INVOICE_GET_INVOICE_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const GET_INVOICE_URL = (customerId, invoiceId) => `/rest/ess/customers/v1/${customerId}/invoice/${invoiceId}`;

const CLEAR_GET_INVOICE_STATE = 'CLEAR_GET_INVOICE_STATE';

export const getInvoice = (customerId, invoiceId) => async dispatch => {
    dispatch({
        type: INVOICE_GET_INVOICE_BEGIN,
    });

    try {
        const key = GET_INVOICE_URL(customerId, invoiceId);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: INVOICE_GET_INVOICE_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err);

        dispatch({
            type: INVOICE_GET_INVOICE_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetInvoiceError = () => ({ type: INVOICE_GET_INVOICE_DISMISS_ERROR });

export const clearGetInvoiceState = () => ({ type: CLEAR_GET_INVOICE_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case INVOICE_GET_INVOICE_BEGIN:
            return {
                ...state,
                getInvoicePending: true,
                getInvoiceError: null,
                getInvoiceIsDone: false,
                getInvoiceIsError: false,
            };

        case INVOICE_GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.data,
                getInvoicePending: false,
                getInvoiceError: null,
                getInvoiceIsDone: true,
            };

        case INVOICE_GET_INVOICE_FAILURE:
            return {
                ...state,
                getInvoicePending: false,
                getInvoiceError: action.data.error,
                getInvoiceIsError: true,
            };

        case INVOICE_GET_INVOICE_DISMISS_ERROR:
            return {
                ...state,
                getInvoiceError: null,
                getInvoiceIsError: false,
            };

        case CLEAR_GET_INVOICE_STATE:
            return {
                ...state,
                invoice: null,
                getInvoiceIsDone: false,
                getInvoiceIsError: false,
            };

        default:
            return state;
    }
}
