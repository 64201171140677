import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Message } from '@ecster/components';
import { formatAccount } from '../../../common/util/format-account';
import { formatAmount } from '../../../common/util/format-amount';

import './OverdrawnInfo.scss';

const OverdrawnInfo = ({ used, limit, accountNumber }) => {
    return (
        <Message
            purple
            className="account-overdrawn-info"
            header={i18n('account.overdrawn.header')}
            message={i18n('account.overdrawn.info', {
                amount: formatAmount(used - limit, {
                    strip00: true,
                    roundUp: true,
                }),
                accountNumber: formatAccount(accountNumber),
            })}
        />
    );
};

OverdrawnInfo.propTypes = {
    used: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    accountNumber: PropTypes.string.isRequired,
};

export default OverdrawnInfo;
