import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Table, TableBody as TBody, Panel, PanelContent } from '@ecster/components';

import './TransactionsPanel.scss';
import md5 from '@ecster/util/lib/md5';
import { formatDateMonth } from '../../../common/util/format';
import { AccountHolderIcon } from './AccountHolderIcon';

import { TransactionsTableRow } from './TransactionsTableRow';

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const getAccountHolderIcon = (trans, account, addTopMargin) => {
    const nameMap = {
        [account.accountHolder[0].name]: 1,
        [account.accountHolder[1].name]: 2,
    };

    const { cardHolderName } = trans;
    const num = nameMap[cardHolderName];

    return <AccountHolderIcon num={num} addTopMargin={addTopMargin} cardHolder={cardHolderName} />;
};

export const TransactionsPanel = ({ transactions, account }) => {
    if (transactions.length === 0) return null;

    const year = moment(transactions[0].date).format('YYYY');
    const month = capitalize(formatDateMonth(transactions[0].date));

    const twoAccountHolders = account.accountHolder && account.accountHolder.length > 1;

    const billTx = transactions
        ? transactions.map((tx, idx) => ({
              ...tx,
              key: md5(idx + JSON.stringify(tx)),
          }))
        : [];

    return (
        <div className="account-transactions-panel">
            <h2>
                <span>{month}</span>
                <span className="year">{year}</span>
            </h2>
            <Panel paddingAll="small" stretchInMobile>
                <PanelContent fullWidth>
                    <Table className="account-tx-table">
                        <TBody>
                            {billTx.map(trans => (
                                <TransactionsTableRow
                                    trans={trans}
                                    key={trans.key}
                                    accountHolderInfo={twoAccountHolders && getAccountHolderIcon(trans, account)}
                                />
                            ))}
                        </TBody>
                    </Table>
                </PanelContent>
            </Panel>
        </div>
    );
};

TransactionsPanel.propTypes = {
    transactions: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
};

TransactionsPanel.defaultProps = {};
