/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { ButtonGroup, LinkButton, Panel, PanelContent } from '@ecster/components';
import { i18n } from '@ecster/i18n';

import { speechBubblesIcon } from '@ecster/icons/H80/green';
import giosgIcon from './SE/giosg-light.svg';

import AuthenticatedPageTemplate from '../common/templates/AuthenticatedPageTemplate';
import ContactUsInfo from './ContactUsInfo';
import { isFI, isSE } from '../../common/country.js';

const ContactUsPage = () => {
    return (
        <AuthenticatedPageTemplate className="customer-contact-us-page" header={i18n('customer.contact-us.header')}>
            <Panel paddingTopBottom="xLarge">
                <PanelContent className="flex flex-col items-center">
                    {isSE() && <img className="my-8" src={giosgIcon} aria-hidden="true" alt="" />}
                    {isFI() && <img className="my-8" src={speechBubblesIcon} aria-hidden="true" alt="" />}

                    <h2 className="h3">{i18n('customer.contact-us.faq.header')}</h2>
                    {i18n('customer.contact-us.faq.info', 'p', { danger: true })}

                    <ButtonGroup alignCenter className="mb-8x">
                        <LinkButton
                            href={i18n('customer.contact-us.faq.url')}
                            target="_blank"
                            iconRight="icon-external-link"
                            round
                        >
                            {i18n('customer.contact-us.faq.button')}
                        </LinkButton>
                    </ButtonGroup>
                </PanelContent>
                {isFI() && (
                    <PanelContent>
                        <h3 className="h5 centered">{i18n('customer.contact-us.sub-header')}</h3>
                        <ContactUsInfo />
                    </PanelContent>
                )}
            </Panel>
        </AuthenticatedPageTemplate>
    );
};

export default ContactUsPage;
