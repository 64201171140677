import moment from 'dayjs';
import { i18n } from '@ecster/i18n';
import { shoppingCartIcon, walletIcon, moneyBagIcon } from '@ecster/icons/H15/green-transparent';

import { formatDate } from '../../../common/util/format-date';
import { getBillProperties } from '../../account/util';
import { getContractHeader } from '../../contract/util';
import { NOT_PAID, PAID, PARTIALLY_PAID } from '../paymentStatus';
import {
    ACTIVE,
    DEBT_COLLECTION,
    DEBT_COLLECTION_COMPLETED,
    DEPRECIATED,
    DUE_NOT_REMINDED,
    FULLY_CREDITED,
    REMINDED,
    TRANSFERRED,
} from '../invoiceStatus';
import {
    byAccountInvoice,
    byCompletedSortKey,
    byContractInvoice,
    byDebtCollection,
    byDueDate,
    byOneOffInvoice,
} from '../sort';

// helper function when separating invoices into unpaid and completed
const isInvoiceUnpaid = invoice => {
    if (invoice.invoice) {
        return (
            (invoice.paymentStatus === NOT_PAID ||
                invoice.paymentStatus === PARTIALLY_PAID ||
                invoice.invoiceStatus === DEBT_COLLECTION) &&
            invoice.invoiceStatus !== DEBT_COLLECTION_COMPLETED &&
            invoice.invoiceStatus !== TRANSFERRED
        );
    }

    if (invoice.accountInvoice) {
        return invoice.paymentStatus === NOT_PAID;
    }

    if (invoice.contract) {
        return invoice.paymentStatus === NOT_PAID;
    }
};

// determine sort key when sorting completed one off invoices
const getOneOffCompletedSortKey = invoice =>
    invoice.status === DEBT_COLLECTION_COMPLETED || invoice.status === TRANSFERRED || invoice.status === FULLY_CREDITED
        ? invoice.statusChangeDate
        : invoice.paymentDate;

const getOneOffInvoiceStatusText = invoice => {
    const { status, paymentStatus, statusChangeDate, paymentDate, originalDueDate, dueDate } = invoice;

    const today = moment().startOf('day');
    const diffOriginalDueDate = Math.abs(moment(originalDueDate).diff(today, 'days'));
    const diffDueDate = Math.abs(moment(dueDate).diff(today, 'days'));

    if (status === ACTIVE && (paymentStatus === NOT_PAID || paymentStatus === PARTIALLY_PAID)) {
        return {
            text: i18n(
                diffDueDate === 0
                    ? 'invoice.invoice-overview.due-date.pay-today'
                    : 'invoice.invoice-overview.oneoff.pay-days-left',
                { count: diffDueDate }
            ),
        };
    }

    if (status === DUE_NOT_REMINDED && (paymentStatus === NOT_PAID || paymentStatus === PARTIALLY_PAID)) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.days-due', {
                count: diffOriginalDueDate,
            }),
            isHighlighted: true,
        };
    }

    if (status === REMINDED && (paymentStatus === NOT_PAID || paymentStatus === PARTIALLY_PAID)) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.days-due-reminder', {
                count: diffOriginalDueDate,
            }),
            isHighlighted: true,
        };
    }

    if (status === DEBT_COLLECTION) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.debt-collection-sent', {
                date: statusChangeDate ? formatDate(statusChangeDate) : '',
            }),
            isHighlighted: true,
        };
    }

    if (status === TRANSFERRED) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.transferred-to-account', {
                date: statusChangeDate ? formatDate(statusChangeDate) : '',
            }),
        };
    }

    if (status === DEBT_COLLECTION_COMPLETED) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.debt-collection-closed', {
                date: statusChangeDate ? formatDate(statusChangeDate) : '',
            }),
        };
    }

    if (status === FULLY_CREDITED) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.fully-credited', {
                date: statusChangeDate ? formatDate(statusChangeDate) : '',
            }),
        };
    }

    if (
        (status === ACTIVE || status === DUE_NOT_REMINDED || status === REMINDED || status === DEPRECIATED) &&
        paymentStatus === PAID
    ) {
        return {
            text: i18n('invoice.invoice-overview.oneoff.paid', { date: paymentDate ? formatDate(paymentDate) : '' }),
        };
    }

    return { text: '' };
};

const getAccountInvoiceStatusText = (bill, billProps) => {
    const {
        payment: { dueDate },
    } = bill;
    const { paymentStatus } = billProps;

    const today = moment().startOf('day');
    const diff = moment(dueDate).diff(today, 'days');
    const diffAbs = Math.abs(diff);

    if (!paymentStatus) {
        return { text: '' };
    }

    if (paymentStatus === PAID) {
        return { text: i18n('invoice.invoice-overview.account.paid') };
    }

    if (paymentStatus === PARTIALLY_PAID) {
        return { text: i18n('invoice.invoice-overview.account.partially-paid') };
    }

    if (diff < 0) {
        return {
            text: i18n('invoice.invoice-overview.account.days-due', {
                count: diffAbs,
            }),
            isHighlighted: true,
        };
    }

    if (diff === 0) {
        return { text: i18n('invoice.invoice-overview.due-date.pay-today') };
    }

    if (diff > 0) {
        return {
            text: i18n('invoice.invoice-overview.account.pay-days-left', {
                count: diff,
            }),
        };
    }

    return { text: '' };
};

const getContractInvoiceStatusText = contract => {
    const { dueDate, paymentStatus } = contract;

    const today = moment().startOf('day');
    const diff = moment(dueDate).diff(today, 'days');
    const diffAbs = Math.abs(diff);

    if (!paymentStatus) {
        return { text: '' };
    }

    if (paymentStatus === PAID) {
        return { text: i18n('invoice.invoice-overview.contract.paid') };
    }

    if (diff < 0) {
        return {
            text: i18n('invoice.invoice-overview.contract.days-due', {
                count: diffAbs,
            }),
            isHighlighted: true,
        };
    }

    if (diff === 0) {
        return { text: i18n('invoice.invoice-overview.due-date.pay-today') };
    }

    if (diff > 0) {
        return {
            text: i18n('invoice.invoice-overview.account.pay-days-left', {
                count: diff,
            }),
        };
    }

    return { text: '' };
};

export const getDueDatePresentation = dueDate => {
    const today = moment().startOf('day');

    const diff = moment(dueDate).diff(today, 'days');
    if (diff < 0) return formatDate(dueDate);
    if (diff === 0) return i18n('invoice.invoice-overview.due-date.today');

    return i18n('invoice.invoice-overview.due-date.days-left', { count: diff });
};

// helper function to separate list of common invoice model into unpaid and completed
export const separate = invoices => {
    const { unpaidInvoices, completedInvoices } = invoices.reduce(
        (acc, curr) => {
            if (isInvoiceUnpaid(curr)) {
                acc.unpaidInvoices.push(curr);
                return acc;
            }
            acc.completedInvoices.push(curr);
            return acc;
        },
        { unpaidInvoices: [], completedInvoices: [] }
    );

    // prettier-ignore
    unpaidInvoices
        .sort(byDueDate)
        .sort(byDebtCollection);

    // prettier-ignore
    completedInvoices
        .sort(byCompletedSortKey)
        .sort(byContractInvoice)
        .sort(byAccountInvoice)
        .sort(byOneOffInvoice);

    return { unpaidInvoices, completedInvoices };
};

// one off invoices
export const mapOneOffInvoices = invoices =>
    invoices &&
    invoices.map(invoice => ({
        invoiceNumber: invoice.invoiceNumber,
        amount: invoice.type === 'CREDIT' ? -1 * invoice.amount : invoice.amount,
        dueDate: invoice.dueDate,
        description: invoice.storeName,
        url: `/customer/v2/invoice/${invoice.invoiceId}`,
        type: invoice.type,
        icon: shoppingCartIcon,
        paymentStatus: invoice.paymentStatus,
        statusText: getOneOffInvoiceStatusText(invoice),
        // specific for oneoff invoice
        invoice: true,
        invoiceStatus: invoice.status,
        completedSortKey: getOneOffCompletedSortKey(invoice),
    }));

// account invoices
export const mapAccountInvoices = (bills, accounts) => {
    const invoices = [];

    if (!bills) return invoices;

    Object.keys(bills).forEach(accountRef => {
        const bill = bills[accountRef];

        if (!bill.payment) return;

        const lab3 = bill.payment.options.find(o => o.type === 'FULLPAYMENT');
        const account = accounts.find(o => o.reference === accountRef);
        const billProps = getBillProperties(bill);

        invoices.push({
            invoiceNumber: bill.ocrNumber, // account no
            amount: lab3.amount,
            dueDate: bill.payment.dueDate,
            description: account?.product?.name,
            url: `/account/${accountRef}/invoice`,
            type: 'MONTHLY',
            icon: walletIcon,
            paymentStatus: billProps.paymentStatus,
            statusText: getAccountInvoiceStatusText(bill, billProps),
            // specific for account invoice
            accountInvoice: true,
            accountRef,
        });
    });

    return invoices;
};

// contract invoices
export const mapContractInvoices = contracts => {
    const invoices = [];

    contracts
        .filter(contract => contract.status !== 'CONTACT_US' && contract.status !== 'COLLECTION')
        .forEach(contract => {
            const { contractNumber, invoicedAmount, dueDate, paymentStatus } = contract;

            if (dueDate) {
                invoices.push({
                    invoiceNumber: contractNumber,
                    amount: invoicedAmount,
                    dueDate,
                    description: getContractHeader(contract),
                    url: `/contract/${contractNumber}/invoice`,
                    type: 'CONTRACT',
                    icon: moneyBagIcon,
                    paymentStatus,
                    statusText: getContractInvoiceStatusText(contract),
                    // specific for contract invoice
                    contract: true,
                });
            }
        });

    return invoices;
};
