import React from 'react';
import { connect } from 'react-redux';
// Ecster imports
import { i18n } from '@ecster/i18n';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import UnderConstruction from '../../common/alpha/UnderConstruction';

export class TransferMoneyPage extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    state = {};

    render() {
        return (
            <AuthenticatedSubPageTemplate
                className="account-transfer-money-page"
                header={i18n('account.transfer-money-page.page-header')}
            >
                <UnderConstruction />
            </AuthenticatedSubPageTemplate>
        );
    }
}

/* istanbul ignore next */

// eslint-disable-next-line
function mapStateToProps(state) {
    return {
        account: state.account,
    };
}

/* istanbul ignore next */

// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferMoneyPage);
