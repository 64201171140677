// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reportError } from './reportError';

class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
        message: PropTypes.string,
        name: PropTypes.string,
    };

    static defaultProps = {
        message: '',
        name: '',
    };

    state = { hasError: false };

    // eslint-disable-next-line no-unused-vars
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const { name } = this.props;
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.error(error);
        console.error(errorInfo);

        const data = {
            error: error?.toString(),
            errorStack: error?.stack,
            componentStack: errorInfo?.componentStack,
        };

        reportError(data, `ErrorBoundary ${name}`);
    }

    render() {
        const { hasError } = this.state;
        const { children, message } = this.props;

        if (hasError) {
            // You can render any custom fallback UI
            return message;
        }

        return children;
    }
}

export default ErrorBoundary;
