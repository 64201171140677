import { COMMON_HIDE_ONBOARDING } from './constants';

export const hideOnboarding = () => ({
    type: COMMON_HIDE_ONBOARDING,
});

// don't ES6 this one, rekit gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case COMMON_HIDE_ONBOARDING:
            return {
                ...state,
                onboarding: {
                    show: false,
                },
            };

        default:
            return state;
    }
}
