import React, { Component, MouseEventHandler, ReactNode } from 'react';
import debounce from 'lodash/debounce';
import { Button, ButtonGroup } from '../Clickable';

interface ScrollAwarePanelProps {
    children: ReactNode;
    onScrollTop?: () => void;
    onScrollBottom?: () => void;
    offset?: number;
    moreButtonText: string;
}

// TODO: extend to allow listening to scroll events in a parent panel, not only entire window
class ScrollAwarePanel extends Component<ScrollAwarePanelProps> {
    state = {
        showMoreButton: false,
    };
    private container: HTMLDivElement;

    UNSAFE_componentWillMount() {
        if (document.scrollingElement) {
            window.addEventListener('scroll', this.onScroll);
        }

        this.setState({ showMoreButton: !document.scrollingElement });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = debounce(e => {
        const { offset, onScrollTop, onScrollBottom } = this.props;

        const el = e.target.scrollingElement;
        const atBottom = el.scrollHeight - el.scrollTop < el.clientHeight + offset;
        const atTop = el.scrollTop < offset;

        if (atTop) {
            onScrollTop();
        }

        if (atBottom) {
            onScrollBottom();
        }

        return true;
    }, 200);

    render() {
        const { children, onScrollBottom, moreButtonText } = this.props;
        const { showMoreButton } = this.state;

        return (
            <div ref={ref => (this.container = ref)} className="scroll-paginate">
                {children}
                {showMoreButton && (
                    <ButtonGroup align="center">
                        <Button round outline xSmall onClick={onScrollBottom} name="scroll-pagination-button">
                            {moreButtonText}
                        </Button>
                    </ButtonGroup>
                )}
            </div>
        );
    }
}

// @ts-expect-error defaultProps
ScrollAwarePanel.defaultProps = {
    onScrollTop: () => {},
    onScrollBottom: () => {},
    offset: 200,
};

export default ScrollAwarePanel;
