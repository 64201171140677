import { get } from '@ecster/net/v2';

import {
    CUSTOMER_GET_CUSTOMER_PROPERTIES_BEGIN,
    CUSTOMER_GET_CUSTOMER_PROPERTIES_SUCCESS,
    CUSTOMER_GET_CUSTOMER_PROPERTIES_FAILURE,
    CUSTOMER_GET_CUSTOMER_PROPERTIES_DISMISS_ERROR,
} from './constants';
import { GET_CUSTOMER_PROPERTIES_URL } from './urls';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_CUSTOMER_PROPERTIES_STATE = 'CLEAR_GET_CUSTOMER_PROPERTIES_STATE';

/**
 * Pending/Error concept in state not working if more than one property is fetched simultaneously
 *
 * @deprecated - use getProperty instead
 */
export const getCustomerProperties = (customerId, property) => async dispatch => {
    dispatch({
        type: CUSTOMER_GET_CUSTOMER_PROPERTIES_BEGIN,
    });

    try {
        const res = await get(GET_CUSTOMER_PROPERTIES_URL(customerId, property));
        dispatch({
            type: CUSTOMER_GET_CUSTOMER_PROPERTIES_SUCCESS,
            property,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getCustomerProperties');

        dispatch({
            type: CUSTOMER_GET_CUSTOMER_PROPERTIES_FAILURE,
            data: { error: err },
        });
    }
};

/** @deprecated - use dismiss action for getProperty instead */
export const dismissGetCustomerPropertiesError = () => ({ type: CUSTOMER_GET_CUSTOMER_PROPERTIES_DISMISS_ERROR });

/** @deprecated - use clear action for getProperty instead */
export const clearGetCustomerPropertiesState = property => ({ type: CLEAR_GET_CUSTOMER_PROPERTIES_STATE, property });

/** @deprecated - use getProperty actions and reducers instead */
export function reducer(state, action) {
    switch (action.type) {
        case CUSTOMER_GET_CUSTOMER_PROPERTIES_BEGIN:
            return {
                ...state,
                getCustomerPropertiesPending: true,
                getCustomerPropertiesError: null,
                getCustomerPropertiesIsDone: false,
                getCustomerPropertiesIsError: false,
            };

        case CUSTOMER_GET_CUSTOMER_PROPERTIES_SUCCESS:
            return {
                ...state,
                [action.property]: action.data.properties[0].value,
                getCustomerPropertiesPending: false,
                getCustomerPropertiesError: null,
                getCustomerPropertiesIsDone: true,
            };

        case CUSTOMER_GET_CUSTOMER_PROPERTIES_FAILURE:
            return {
                ...state,
                getCustomerPropertiesPending: false,
                getCustomerPropertiesError: action.data.error,
                getCustomerPropertiesIsError: true,
            };

        case CUSTOMER_GET_CUSTOMER_PROPERTIES_DISMISS_ERROR:
            return {
                ...state,
                getCustomerPropertiesError: null,
                getCustomerPropertiesIsError: false,
            };

        case CLEAR_GET_CUSTOMER_PROPERTIES_STATE:
            return {
                ...state,
                [action.property]: null,
                getCustomerPropertiesIsDone: false,
                getCustomerPropertiesIsError: false,
            };
        default:
            return state;
    }
}
