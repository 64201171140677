import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// Ecster imports
import { i18n } from '@ecster/i18n';
import * as actions from '../redux/actions';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import UnderConstruction from '../../common/alpha/UnderConstruction';

export class CampaignPurchasesPage extends React.Component {
    state = {};

    render() {
        const message = i18n('campaign-purchases.not-ready');
        return (
            <AuthenticatedSubPageTemplate
                className="account-campaign-purchases-page"
                header={i18n('account.campaign-purchases-page.page-header')}
            >
                <UnderConstruction message={message} />
            </AuthenticatedSubPageTemplate>
        );
    }
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps(state) {
    return {
        account: state.account,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...actions }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPurchasesPage);
