//
// NOTE: 2022-11: moved to @ecster/cookies (will soon be deprecated here)
//
import { setCookie, getCookie, removeCookie, enabled } from '..';

/**
 * Clear (remove) a named cookie or all cookies.
 * @param name Name of cookie to remove
 * @deprecated use @ecster/cookies/removeCookie to remove named cookies
 * Function not copied to @ecster/cookies
 */
const clearCookies = (name: string) => {
    if (name) {
        removeCookie(name);
        return;
    }

    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie;
        removeCookie(name);
    }
};

// keep V1 API for bw compatibility
const api = {
    /**
     * @deprecated use @ecster/cookies/setCookie instead
     */
    create: setCookie,

    /**
     * @deprecated use @ecster/cookies/getCookie instead
     */
    read: getCookie,

    /**
     * @deprecated use @ecster/cookies/removeCookie instead
     */
    erase: removeCookie,

    /**
     * @deprecated use @ecster/cookies/removeCookie instead
     */
    remove: removeCookie,

    /**
     * @deprecated use @ecster/cookies/removeCookie to remove named cookies
     */
    clear: clearCookies,

    /**
     * @deprecated use @ecster/cookies/enabled instead
     */
    enabled,
};

export default api;
