// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './DataColumn.scss';

interface DataColumnProps {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

/** @deprecated use Table or LabelValue instead */
const DataColumn = ({ children, className, style, minWidth, borderRight }: DataColumnProps) => {
    const dataColumnClassName = classNames({
        'data-columns__section': true,
        [className]: className,
        'data-columns__section--bordered': borderRight,
    });
    return (
        <div className={dataColumnClassName} style={{ ...style, msFlex: `1 0 ${minWidth}px` }}>
            {children}
        </div>
    );
};

DataColumn.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape(),
};

DataColumn.defaultProps = {
    className: '',
    style: {},
};

export default DataColumn;
