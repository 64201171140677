// @ts-nocheck
import classNames from 'classnames';

const getClassNames = props => {
    const hasIcon = props.icon || props.iconLeft || props.iconRight;
    const hasIconRight = props.iconRight;
    const hasIconLeft = props.icon || props.iconLeft;

    return classNames({
        'ec-button': true,

        'ec-green':
            props.green ||
            (!props.blue &&
                !props.purple &&
                !props.red &&
                !props.beige &&
                !props.gray &&
                !props.lightGray &&
                !props.white &&
                !props.transparent &&
                !props.link &&
                !props.bankId),

        'ec-blue': props.blue,
        'ec-purple': props.purple,
        'ec-red': props.red,
        'ec-beige': props.beige,
        'ec-gray': props.gray,
        'ec-light-gray': props.lightGray,
        'ec-white': props.white,
        'ec-transparent': props.transparent,
        'ec-link': props.link,
        'ec-bank-id': props.bankId,

        'ec-x-small': props.xSmall,
        'ec-small': props.small,
        'ec-large': props.large,
        'ec-x-large': props.xLarge,

        'ec-outline': props.outline,
        'ec-round': props.round || props.rounded,
        'ec-flat': props.flat,
        'ec-circular': props.circular,
        'ec-square': props.square,
        'ec-stretch': props.stretch,
        'ec-block': props.block,

        'ec-has-icon': hasIcon,
        'ec-icon-left': hasIconLeft,
        'ec-icon-right': hasIconRight,

        [props.className]: props.className,
    });
};

export default getClassNames;
