import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { i18n } from '@ecster/i18n';
import { ExpandableContainer, LabelValue, Message, Panel, PanelContent, Spinner } from '@ecster/components';
import { formatAccount } from '../../common/util/format-account';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import ColumnSummaryPanel from '../common/ColumnSummaryPanel';
import { formatAmount, formatNumber } from '../../common/util/format';
import { clearGetContractInvoiceState, clearGetContractTermsState, getContractTerms } from './redux/actions';

const ContractObject = ({ contractObject }) => {
    if (!contractObject) return null;

    const { objectType, identifier, description } = contractObject;

    return (
        <>
            {objectType && (
                <LabelValue
                    label={i18n('contract.terms-page.terms.object')}
                    value={i18n(`contract.object-types.${objectType}`)}
                />
            )}
            {identifier && <LabelValue label={i18n('contract.terms-page.terms.reg-no')} value={identifier} />}
            {description && <LabelValue label={i18n('contract.terms-page.terms.description')} value={description} />}
        </>
    );
};

ContractObject.propTypes = {
    contractObject: PropTypes.object,
};

ContractObject.defaultProps = {
    contractObject: null,
};

const ContractTermsPage = () => {
    const customerId = useSelector(state => {
        return state.authentication.person.id;
    }, shallowEqual);
    const { currentContractInvoice, contractTerms } = useSelector(state => {
        const { currentContractInvoice, currentContractTerms: contractTerms } = state.contract;
        return { currentContractInvoice, contractTerms };
    }, shallowEqual);

    const { contractNumber } = useParams();

    const dispatch = useDispatch();

    const [hasInvoiceAndOcr, setHasInvoiceAndOcr] = useState(false);
    const [autogiroEnabledForContract, setAutogiroEnabledForContract] = useState(false);

    useEffect(() => {
        dispatch(getContractTerms(customerId, contractNumber));

        // return dispatch(clearGetContractTermsState());
    }, [contractNumber, customerId, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearGetContractTermsState());
            dispatch(clearGetContractInvoiceState());
        };
    }, [dispatch]);

    useEffect(() => {
        const { ocrNumber, status, autogiroEnabled } = currentContractInvoice || {};

        if (ocrNumber && !(status === 'FINISHED' || status === 'COLLECTION')) {
            setHasInvoiceAndOcr(true);
            setAutogiroEnabledForContract(autogiroEnabled);
        }
    }, [currentContractInvoice]);

    if (!contractTerms) {
        return null;
    }

    /** @namespace contractTerms.applicants * */
    const applicants = contractTerms?.applicants;

    const allApplicants =
        applicants &&
        applicants
            .map(applicant => applicant.name.replace(/,/g, ' '))
            .join(', ')
            .toLowerCase(); // css capitalize later

    const allApplicantsLabel =
        (applicants && applicants.length) > 1
            ? i18n('contract.terms-page.terms.all-applicants-n')
            : i18n('contract.terms-page.terms.all-applicants-1');

    const isActiveState = contractTerms && contractTerms.status === 'ACTIVE';
    const isContactUsState = contractTerms && contractTerms.status === 'CONTACT_US';

    /** @namespace contractTerms.sellingCompanyName * */
    const sellingCompanyName =
        contractTerms &&
        contractTerms.sellingCompanyName &&
        // lower case COMPANY AB / KB / HB, then upper case ab / hb / kb in end of string, finally css capitalize
        contractTerms.sellingCompanyName.toLowerCase().replace(/([ahk]b)$/, match => match.toUpperCase());

    const { adminFee, contractObject, currentDebt, interestRate, noOfMonthsLeft, originalDebt } = contractTerms;

    return (
        <AuthenticatedSubPageTemplate className="contract-terms-page" header={i18n('contract.terms-page.header')}>
            {contractTerms ? (
                <>
                    {isActiveState && (
                        <ColumnSummaryPanel
                            label1={i18n('contract.terms-page.summary.debt-at-date')}
                            value1={formatAmount(currentDebt || 0, {
                                strip00: true,
                                decimals: 2,
                            })}
                            label2={i18n('contract.terms-page.summary.time-left')}
                            value2={`${noOfMonthsLeft} ${i18n('contract.terms-page.summary.months')}`}
                        />
                    )}

                    <Panel stretchInMobile>
                        <PanelContent paddingTopBottom="medium">
                            <h1 className="h3 centered">{i18n('contract.terms-page.terms.header')}</h1>
                            <div className="contract-terms">
                                <LabelValue
                                    label={i18n('contract.terms-page.terms.contract-no')}
                                    value={contractNumber}
                                />
                                <LabelValue
                                    label={allApplicantsLabel}
                                    value={<span className="capitalize">{allApplicants}</span>}
                                />
                                {(isActiveState || isContactUsState) && (
                                    <>
                                        <LabelValue
                                            label={i18n('contract.terms-page.terms.yearly-interest')}
                                            value={`${formatNumber(interestRate / 100, {
                                                strip00: true,
                                            })}%`}
                                        />
                                        <LabelValue
                                            label={i18n('contract.terms-page.terms.admin-fee')}
                                            value={formatAmount(adminFee) + i18n('contract.terms-page.terms.per-month')}
                                        />
                                    </>
                                )}
                                {originalDebt && (
                                    <LabelValue
                                        label={i18n('contract.terms-page.terms.initial-debt')}
                                        value={formatAmount(originalDebt)}
                                    />
                                )}
                                <ContractObject contractObject={contractTerms && contractObject} />
                                {sellingCompanyName && (
                                    <LabelValue
                                        label={i18n('contract.terms-page.terms.selling-company')}
                                        value={<span className="capitalize">{sellingCompanyName}</span>}
                                    />
                                )}
                            </div>
                        </PanelContent>
                    </Panel>
                    {hasInvoiceAndOcr ? (
                        <Panel stretchInMobile>
                            <PanelContent paddingTopBottom="medium" centered>
                                <h1 className="h3 centered">{i18n('contract.terms-page.amortization.header')}</h1>
                                <p>{i18n('contract.terms-page.amortization.text-body')}</p>
                                <ExpandableContainer
                                    id="amortization-read-more"
                                    header={i18n('contract.terms-page.amortization.read-more')}
                                >
                                    <p>{i18n('contract.terms-page.amortization.read-more-text')}</p>
                                </ExpandableContainer>
                                <div className="extra-amortization-terms">
                                    <LabelValue
                                        label={i18n('contract.terms-page.amortization.ocr')}
                                        value={formatAccount(currentContractInvoice.ocrNumber)}
                                    />
                                    <LabelValue
                                        label={i18n('contract.terms-page.amortization.bankgiro')}
                                        value={i18n('contract.terms-page.amortization.invoice-number')}
                                    />
                                    <LabelValue
                                        label={i18n('contract.terms-page.amortization.reciever')}
                                        value={i18n('contract.terms-page.amortization.reciever-ecster')}
                                    />
                                </div>
                                <Message
                                    purple
                                    icon={false}
                                    border={false}
                                    beigeBg
                                    header={
                                        autogiroEnabledForContract
                                            ? i18n('contract.terms-page.amortization.active-autogiro.header')
                                            : i18n('contract.terms-page.amortization.non-active-autogiro.header')
                                    }
                                    message={
                                        autogiroEnabledForContract
                                            ? i18n('contract.terms-page.amortization.active-autogiro.text')
                                            : i18n('contract.terms-page.amortization.non-active-autogiro.text')
                                    }
                                />
                            </PanelContent>
                        </Panel>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <Spinner id="contract-terms-spinner" isCenterPage isVisible />
            )}
        </AuthenticatedSubPageTemplate>
    );
};

export default ContractTermsPage;
