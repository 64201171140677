export const CONTRACT_GET_CONTRACTS_BEGIN = 'CONTRACT_GET_CONTRACTS_BEGIN';
export const CONTRACT_GET_CONTRACTS_SUCCESS = 'CONTRACT_GET_CONTRACTS_SUCCESS';
export const CONTRACT_GET_CONTRACTS_FAILURE = 'CONTRACT_GET_CONTRACTS_FAILURE';
export const CONTRACT_GET_CONTRACTS_DISMISS_ERROR = 'CONTRACT_GET_CONTRACTS_DISMISS_ERROR';
export const CONTRACT_GET_CONTRACT_INVOICE_BEGIN = 'CONTRACT_GET_CONTRACT_INVOICE_BEGIN';
export const CONTRACT_GET_CONTRACT_INVOICE_SUCCESS = 'CONTRACT_GET_CONTRACT_INVOICE_SUCCESS';
export const CONTRACT_GET_CONTRACT_INVOICE_FAILURE = 'CONTRACT_GET_CONTRACT_INVOICE_FAILURE';
export const CONTRACT_GET_CONTRACT_INVOICE_DISMISS_ERROR = 'CONTRACT_GET_CONTRACT_INVOICE_DISMISS_ERROR';
export const CONTRACT_GET_CONTRACT_TERMS_BEGIN = 'CONTRACT_GET_CONTRACT_TERMS_BEGIN';
export const CONTRACT_GET_CONTRACT_TERMS_SUCCESS = 'CONTRACT_GET_CONTRACT_TERMS_SUCCESS';
export const CONTRACT_GET_CONTRACT_TERMS_FAILURE = 'CONTRACT_GET_CONTRACT_TERMS_FAILURE';
export const CONTRACT_GET_CONTRACT_TERMS_DISMISS_ERROR = 'CONTRACT_GET_CONTRACT_TERMS_DISMISS_ERROR';
export const CONTRACT_CREATE_REPAYMENT_INQUIRY_BEGIN = 'CONTRACT_CREATE_REPAYMENT_INQUIRY_BEGIN';
export const CONTRACT_CREATE_REPAYMENT_INQUIRY_SUCCESS = 'CONTRACT_CREATE_REPAYMENT_INQUIRY_SUCCESS';
export const CONTRACT_CREATE_REPAYMENT_INQUIRY_FAILURE = 'CONTRACT_CREATE_REPAYMENT_INQUIRY_FAILURE';
export const CONTRACT_CREATE_REPAYMENT_INQUIRY_DISMISS_ERROR = 'CONTRACT_CREATE_REPAYMENT_INQUIRY_DISMISS_ERROR';
export const CONTRACT_GET_REPAYMENT_INQUIRY_BEGIN = 'CONTRACT_GET_REPAYMENT_INQUIRY_BEGIN';
export const CONTRACT_GET_REPAYMENT_INQUIRY_SUCCESS = 'CONTRACT_GET_REPAYMENT_INQUIRY_SUCCESS';
export const CONTRACT_GET_REPAYMENT_INQUIRY_FAILURE = 'CONTRACT_GET_REPAYMENT_INQUIRY_FAILURE';
export const CONTRACT_GET_REPAYMENT_INQUIRY_DISMISS_ERROR = 'CONTRACT_GET_REPAYMENT_INQUIRY_DISMISS_ERROR';
export const CONTRACT_SEND_MAIL_BEGIN = 'CONTRACT_SEND_MAIL_BEGIN';
export const CONTRACT_SEND_MAIL_SUCCESS = 'CONTRACT_SEND_MAIL_SUCCESS';
export const CONTRACT_SEND_MAIL_FAILURE = 'CONTRACT_SEND_MAIL_FAILURE';
export const CONTRACT_SEND_MAIL_DISMISS_ERROR = 'CONTRACT_SEND_MAIL_DISMISS_ERROR';
