import React from 'react';
import { i18n } from '@ecster/i18n';
// import './InactiveCardInfoMessage.scss';
import { Message } from '@ecster/components';

const ActivateCardInfoMessage = () => (
    <Message
        id="activate-card-info-message"
        className="activate-card-info-message"
        blue
        header={i18n('card.activate-card.header')}
        message={i18n('card.activate-card.info')}
    />
);

export default ActivateCardInfoMessage;
