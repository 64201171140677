import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, Message, LabelValue } from '@ecster/components';
import './PaymentMethods.scss';
import { OcrNumber, IbanNumber, BicCode } from '../../../invoice/invoice-values';

export const PaymentMethods = ({ bill }) => {
    return (
        <div className="account-invoice-payment-options">
            <Panel stretchInMobile>
                <PanelContent>
                    <h2 className="h3 centered">{i18n('invoice.account-invoices.payment-methods.header')}</h2>
                    <div>
                        <OcrNumber
                            ocrNumber={bill.ocrNumber}
                            label={i18n('invoice.account-invoices.payment-methods.invoice-ref')}
                            useInfoIcon={false}
                        />

                        <IbanNumber
                            label={i18n('invoice.account-invoices.payment-methods.iban')}
                            ibanNumber={i18n('invoice.account-invoices.payment-methods.iban-no')}
                        />

                        <BicCode bicCode={i18n('invoice.account-invoices.payment-methods.bic-code')} />

                        <LabelValue
                            label={i18n('invoice.account-invoices.payment-methods.payment-receiver.label')}
                            value={<span className="text-[16px]">{i18n('invoice.account-invoices.payment-methods.payment-receiver.name', 'div')}</span>}
                        />
                    </div>
                </PanelContent>
                <PanelContent>
                    <Message
                        purple
                        beigeBg
                        border={false}
                        icon={false}
                        header={i18n('invoice.account-invoices.payment-methods.tip.header')}
                        message={i18n('invoice.account-invoices.payment-methods.tip.info')}
                    />{' '}
                </PanelContent>
            </Panel>
        </div>
    );
};

PaymentMethods.propTypes = {
    bill: PropTypes.object.isRequired,
};
