import React from 'react';
import { i18n } from '@ecster/i18n';
import './PendingCardInfoMessage.scss';
import { Message } from '@ecster/components';

const PendingCardInfoMessage = () => (
    <Message
        id="pending-card-info-message"
        className="pending-card-info-message"
        blue
        header={i18n('card.apply-for-card.pending.header')}
        message={i18n('card.apply-for-card.pending.info')}
    />
);

export default PendingCardInfoMessage;
