import React from 'react';

import { Panel, PanelContent, ExpandableContainer } from '@ecster/components';
import { i18n } from '@ecster/i18n';

export default class FaqPanel extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    render() {
        return (
            <div className="card-faq-panel">
                <Panel paddingTopBottom="xLarge" stretchInMobile>
                    <PanelContent>
                        <h2> {i18n('card.faq.header-faq')} </h2>

                        <ExpandableContainer
                            header={i18n('card.faq.header-section-1')}
                            id="faq-expander1"
                            stretch
                            className="faq-expander"
                        >
                            <p className="question"> {i18n('card.faq.question-1-1')} </p>
                            <p className="answer pb-3x"> {i18n('card.faq.answer-to-question-1-1')} </p>
                            <p className="question"> {i18n('card.faq.question-1-2')} </p>
                            <p className="answer"> {i18n('card.faq.answer-to-question-1-2', { danger: true })} </p>{' '}
                        </ExpandableContainer>

                        <hr className="faq-divider" />

                        <ExpandableContainer
                            header={i18n('card.faq.header-section-2')}
                            id="faq-expander2"
                            stretch
                            className="faq-expander"
                        >
                            <p className="question"> {i18n('card.faq.question-2-1')} </p>
                            <p className="answer pb-3x"> {i18n('card.faq.answer-to-question-2-1')} </p>
                            <p className="question"> {i18n('card.faq.question-2-2')} </p>
                            <p className="answer"> {i18n('card.faq.answer-to-question-2-2', { danger: true })} </p>
                        </ExpandableContainer>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}
