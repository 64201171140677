import React, { Component, MouseEventHandler } from 'react';
import { RRContext } from '@ecster/context';
import getClassNames from './classNames';
import Button, { ButtonPropsBase } from './Button';
import { linkOnClick } from './linkOnClick';
import './Button.scss'; // most styling
import './LinkButton.scss'; // some overrides and tweaks

interface LinkButtonPropsBase extends ButtonPropsBase {
    /**
     * This property is for react-router routes. LinkButton returns a <Link to={} ... element
     *
     * E.g. /account/overview
     */
    to?: string;

    /**
     * This property is for other links. LinkButton returns an <a href={} ... element
     *
     * E.g. https://www.ecster.se
     */
    href?: string;

    /**
     * Use in combination with href
     *
     * E.g. target="_blank"
     */
    target?: string;

    /**
     * Called when LinkButton is clicked
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

type LinkButtonProps = Omit<LinkButtonPropsBase, 'disabled' | 'link'>;

/**
 * Create links with button appearance.
 *
 * Must specify either "href" or "to"
 *
 * To return an html a tag - use
 ```html
 href="https:// ..."
 ```
 *
 * To return a react-router-dom Link - use
 ```html
 to="/some/routing/path ..."
 ```
 */
class LinkButton extends Component<LinkButtonProps> {
    linkRef = React.createRef<HTMLAnchorElement>();

    onClick = e => {
        linkOnClick(this, e);
    };

    render() {
        const { children, iconLeft, iconRight, icon, target, id, name, to, href, style } = this.props;

        const theLeftIcon = iconLeft ? <i className={iconLeft} /> : undefined;
        const theRightIcon = iconRight ? <i className={iconRight} /> : undefined;
        const theIcon = icon ? <i className={icon} /> : undefined;

        const linkProps = {
            target,
            id,
            name,
            className: getClassNames(this.props),
            style,
            rel: null,
        };

        if (target === '_blank') {
            linkProps.rel = 'noopener noreferrer';
        }

        const linkBody = (
            <>
                {theLeftIcon} <span>{children}</span> {theIcon}
                {theRightIcon}
            </>
        );

        const link = ReactRouterLink => {
            if (to && !ReactRouterLink) {
                console.error(`Do not use prop "to" without RRProvider in your application root.js.`);
                return (
                    <a ref={this.linkRef} href={to} onClick={this.onClick} {...linkProps}>
                        {linkBody}
                    </a>
                );
            }

            return to ? (
                <ReactRouterLink ref={this.linkRef} to={to} onClick={this.onClick} {...linkProps}>
                    {linkBody}
                </ReactRouterLink>
            ) : (
                <a ref={this.linkRef} href={href} onClick={this.onClick} {...linkProps}>
                    {linkBody}
                </a>
            );
        };

        return (
            <RRContext.Consumer>
                {({ Link: ReactRouterLink }) => {
                    return link(ReactRouterLink);
                }}
            </RRContext.Consumer>
        );
    }
}

// @ts-expect-error class defaultProps
LinkButton.defaultProps = {
    // @ts-expect-error class defaultProps
    ...Button.defaultProps,
    to: undefined,
    href: undefined,
    target: '_self',
    onClick: () => {},
};

export default LinkButton;
