import React from 'react';

interface MessageDefaultIconProps {
    className: string;
}

export const MessageDefaultIcon = ({ className }: MessageDefaultIconProps) => (
    <div className={`ec-message-icon ${className}`}>
        <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
                <circle className="icon-circle" cx="16" cy="16" r="15" strokeWidth="2" />
                <g className="icon-i">
                    <rect height="12" rx="2" width="4" x="14" y="13" />
                    <circle cx="16" cy="9" r="2" />
                </g>
            </g>
        </svg>
    </div>
);
