import { getCookie, setCookie } from '@ecster/cookies';

export const CANARY_COOKIE = 'ecster-cv';

export const createCanaryCookie = () => {
    let canaryValue = getCookie(CANARY_COOKIE);
    if (!canaryValue) {
        canaryValue = Math.floor(Math.random() * 100) + 1;
        setCookie(CANARY_COOKIE, canaryValue, 365);
    }

    return canaryValue;
};

export const getCanaryValue = () => getCookie(CANARY_COOKIE);

export const setCanaryValue = value => setCookie(CANARY_COOKIE, value);
