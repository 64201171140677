import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Panel, PanelContent } from '@ecster/components';
import { activateCardIcon } from '@ecster/icons/H80/green';
import ActivateCardForm from './ActivateCardForm';

export default class ActivateCardPanel extends React.Component {
    static propTypes = {
        card: PropTypes.shape().isRequired,
        customerId: PropTypes.number.isRequired,
        updateAccountCard: PropTypes.func.isRequired,
        updateAccountCardPending: PropTypes.bool.isRequired,
        isWrongCvc: PropTypes.bool.isRequired,
        mainCardIsTemporarilyBlocked: PropTypes.bool,
        updateCustomerExtraCardHolderContactInfo: PropTypes.func.isRequired,
    };

    static defaultProps = { mainCardIsTemporarilyBlocked: false };

    onSubmitForm = cvc => {
        const { customerId, updateAccountCard, card } = this.props;
        updateAccountCard(customerId, card, cvc);
    };

    render() {
        const {
            isWrongCvc,
            updateAccountCardPending,
            card,
            mainCardIsTemporarilyBlocked,
            updateCustomerExtraCardHolderContactInfo,
            customerId,
        } = this.props;

        const tmpBlock = mainCardIsTemporarilyBlocked || card.status === 'TEMPORARILY_BLOCKED';

        return (
            <Panel paddingTopBottom="xLarge" centeredContent stretchInMobile className="card-activate-card-panel">
                <PanelContent>
                    <img src={activateCardIcon} alt="card icon" />
                    <h1 className="h3">{i18n('card.activate-card.header')}</h1>
                    <p>{i18n('card.activate-card.info')}</p>
                    <ActivateCardForm
                        customerId={customerId}
                        onSubmitForm={this.onSubmitForm}
                        idPrefix="activate-account-card"
                        isWrongCvc={isWrongCvc}
                        card={card}
                        tmpBlock={tmpBlock}
                        updateCustomerExtraCardHolderContactInfo={updateCustomerExtraCardHolderContactInfo}
                        disableButton={updateAccountCardPending}
                    />
                </PanelContent>
            </Panel>
        );
    }
}
