import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EcsterCard.scss';

export const EcsterCard = ({ className, brickId, ...rest }) => {
    const classes = classNames({
        'ecster-card': true,
        [className]: className,
    });

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <img alt="" className={classes} src={`./images/cards/${brickId}.png`} {...rest} />;
};

EcsterCard.propTypes = {
    className: PropTypes.string,
    brickId: PropTypes.string.isRequired,
};

EcsterCard.defaultProps = {
    className: '',
};
