import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { LabelValue, Panel, PanelContent } from '@ecster/components';
import { InvoicePdfLink } from './InvoicePdfLink';

export const CollectionInfoPanel = ({ invoice }) => (
    <div className="invoice-collection-info-panel" data-testid="collection-info">
        <Panel sideMarginsInMobile>
            <PanelContent>
                <h2 className="e-green120">{invoice.storeName}</h2>
                <h3 className="light">{i18n('invoice.one-off-invoice.collection-info.header')}</h3>
                <p>{i18n(`invoice.one-off-invoice.collection-info.text.${invoice.status}`, { danger: true })}</p>
                {i18n('invoice.one-off-invoice.collection-info.text.content', 'p', { danger: true })}
                <hr />
                <LabelValue
                    label={i18n('invoice.one-off-invoice.collection-info.debit-invoice')}
                    value={<InvoicePdfLink invoice={invoice.debitInvoice} />}
                />
                {invoice.creditInvoices?.map(creditInvoice => (
                    <LabelValue
                        key={creditInvoice?.url}
                        label={i18n('invoice.one-off-invoice.collection-info.credit-invoice')}
                        value={<InvoicePdfLink invoice={creditInvoice} isCredit />}
                    />
                ))}
            </PanelContent>
        </Panel>
    </div>
);

CollectionInfoPanel.propTypes = {
    invoice: PropTypes.object.isRequired,
};

CollectionInfoPanel.defaultProps = {};

export default CollectionInfoPanel;
