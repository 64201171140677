import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './DropdownMenu.scss';

export class DropdownMenu extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        requestClose: PropTypes.func,
        children: PropTypes.node, // SubMenuItems
    };

    static defaultProps = {
        children: '',
        requestClose: () => {},
    };

    dropdownMenuContainer = React.createRef();

    arrowRef = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
        document.addEventListener('touchstart', this.handleClick, false);
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
        document.removeEventListener('touchstart', this.handleClick, false);
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = event => {
        const { requestClose } = this.props;
        if (event.key === 'Escape') {
            requestClose();
        }
    };

    handleClick = event => {
        const { requestClose, show } = this.props;
        if (
            show &&
            !event.target.classList.contains('icon-menu') &&
            !this.dropdownMenuContainer.current.contains(event.target)
        ) {
            // We're not clicking on the hamburger menu
            // AND
            // We're not clicking on something inside the SubMenu container - so close it.
            requestClose();
        }
    };

    animateArrow = () => {
        const arrow = this.arrowRef.current;
        const { show } = this.props;
        if (show) {
            arrow.classList.add('show');
        }
    };

    render() {
        const { show, children } = this.props;

        const classes = classNames({
            'dropdown-menu': true,
            show,
        });

        return (
            <div ref={this.dropdownMenuContainer}>
                {show && <div className="arrow" ref={this.arrowRef} />}
                <div role="presentation" className={classes} onTransitionEnd={this.animateArrow}>
                    {children}
                </div>
            </div>
        );
    }
}
