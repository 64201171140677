import React from 'react';
import PropTypes from 'prop-types';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { Panel, Message, PanelContent, CopyToClipboardButton } from '@ecster/components';
import { AccountSummary } from '../components/AccountSummary';
import { formatAccount } from '../../../common/util/format-account';
import { isFI, isSE } from '../../../common/country';
import { InfoItem } from './InfoItem';

export default class AccountTermsTab extends React.Component {
    state = {};

    render() {
        const {
            account: { accountHolder },
            terms: { accountName, accountNumber },
            account,
        } = this.props;

        const accountHolders =
            accountHolder &&
            accountHolder.map(holder => (
                <div key={holder.name} className="account-holder">
                    {holder.name.split(',').reverse().join(' ').trim().toLocaleLowerCase()}
                </div>
            ));

        return (
            <>
                <AccountSummary label="account-summary-panel" account={account} />

                <Panel key="account-terms-panel" className="account-terms-panel" stretchInMobile paddingAll="small">
                    <h2 className="h3">{i18n('account.account-information.terms.account-terms')}</h2>

                    <div>
                        <PanelContent wide>
                            <InfoItem
                                value={accountName}
                                label={i18n('account.account-information.terms.account-name')}
                            />
                            <InfoItem
                                value={accountHolders}
                                label={i18n('account.account-information.terms.account-holder')}
                            />
                            <InfoItem
                                value={
                                    <CopyToClipboardButton
                                        id="copy-account-number-btn"
                                        noIcon
                                        value={accountNumber}
                                        tooltip={i18n('account.account-information.terms.tooltip.account-number')}
                                        tooltipSuccess={i18n('account.account-information.terms.tooltip.copied')}
                                    >
                                        {formatAccount(accountNumber)}
                                    </CopyToClipboardButton>
                                }
                                label={i18n('account.account-information.terms.account-number')}
                            />

                            {isSE() && (
                                <InfoItem
                                    value={
                                        <CopyToClipboardButton
                                            id="copy-bg-number-btn"
                                            noIcon
                                            value={i18n('general.bgNumber.account')}
                                            tooltip={i18n('account.account-information.terms.tooltip.bg-number')}
                                            tooltipSuccess={i18n('account.account-information.terms.tooltip.copied')}
                                        >
                                            {i18n('general.bgNumber.account')}
                                        </CopyToClipboardButton>
                                    }
                                    label={i18n('account.account-information.terms.bg-number')}
                                />
                            )}

                            {isFI() && (
                                <>
                                    <InfoItem
                                        value={i18n('account.account-information.terms.iban-no')}
                                        label={i18n('account.account-information.terms.iban-label')}
                                    />

                                    <InfoItem
                                        value={i18n('account.account-information.terms.bic-code-value')}
                                        label={i18n('account.account-information.terms.bic-code-label')}
                                    />
                                </>
                            )}

                            <InfoItem
                                hasBreakByWord
                                value={i18n('account.account-information.terms.account-receiver-value')}
                                label={i18n('account.account-information.terms.account-receiver-label')}
                            />
                        </PanelContent>
                        <PanelContent className="e-invoice-info-panel">
                            <Message
                                className="mb-3x"
                                icon={false}
                                purple
                                beigeBg
                                header={i18n('account.account-information.terms.e-invoice.header')}
                                message={i18n('account.account-information.terms.e-invoice.information')}
                            />
                        </PanelContent>
                    </div>
                </Panel>
            </>
        );
    }
}

/* istanbul ignore next */
// eslint-disable-next-line

AccountTermsTab.propTypes = {
    // ajax action and its async states
    account: PropTypes.shape(),
    terms: PropTypes.object,
};

AccountTermsTab.defaultProps = {
    account: null,
    terms: null,
};
