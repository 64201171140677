import React from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelContent, Table, TableBody as TBody } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import md5 from '@ecster/util/lib/md5';

import './AccountInvoiceSpecTransactions.scss';
import { TransactionsTableRow } from './transactions/TransactionsTableRow';

const TxTable = ({ transactions, prefix }) => (
    <Table className="account-tx-table">
        <TBody>
            {transactions.map((trans, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <TransactionsTableRow trans={trans} key={idx} id={`${prefix}-tx-${idx + 1}`} />
            ))}
        </TBody>
    </Table>
);

TxTable.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
    prefix: PropTypes.string.isRequired,
};

export const AccountInvoiceSpecTransactions = ({ transactions }) => {
    const billTx = transactions ? transactions.map((tx, idx) => ({ ...tx, key: md5(idx + JSON.stringify(tx)) })) : [];

    // (filter) all tx with cardHolder, and then (map) cardHolder names
    const allCardHolders = billTx && billTx.filter(t => t.cardHolder).map(t => t.cardHolder);
    // filter away duplicates
    const uniqueCardHolders =
        allCardHolders && allCardHolders.filter((t, index) => allCardHolders.indexOf(t) === index);

    let cardHolder1Tx = [];
    let cardHolder2Tx = [];
    let otherTx = billTx;

    if (billTx && uniqueCardHolders && uniqueCardHolders.length === 2) {
        cardHolder1Tx = billTx.filter(tx => tx.cardHolder === uniqueCardHolders[0]);
        cardHolder2Tx = billTx.filter(tx => tx.cardHolder === uniqueCardHolders[1]);
        otherTx = billTx.filter(tx => !tx.cardHolder);
    }

    return (
        <Panel
            paddingLeftRight="small"
            stretchInMobile
            className="account-invoice-transactions-panel"
            id="account-invoice-transactions"
        >
            <PanelContent wide>
                <h2 className="h3"> {i18n('invoice.account-invoice-spec.this-months-transactions')}</h2>
            </PanelContent>
            <PanelContent fullWidth>
                {otherTx && otherTx.length > 0 ? (
                    <TxTable transactions={otherTx} prefix="account-invoice" />
                ) : (
                    <p>{i18n('invoice.account-invoice-spec.no-transactions')}</p>
                )}
                {cardHolder1Tx.length > 0 && (
                    <>
                        <h3>{uniqueCardHolders[0]}</h3>
                        <TxTable transactions={cardHolder1Tx} prefix="account-invoice-user1" />
                    </>
                )}
                {cardHolder2Tx.length > 0 && (
                    <>
                        <h3>{uniqueCardHolders[1]}</h3>
                        <TxTable transactions={cardHolder2Tx} prefix="account-invoice-user2" />
                    </>
                )}
            </PanelContent>
        </Panel>
    );
};

AccountInvoiceSpecTransactions.propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
