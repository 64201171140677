// https://code.tutsplus.com/tutorials/build-a-simple-password-strength-checker--net-7565

const MIN_PWD_LENGTH = 8; // TODO: should be a parameter
const BASE_SCORE = 15;

const calculateScore = pwd => {
    if (!pwd || pwd.length < MIN_PWD_LENGTH) {
        return 0; // no score on empty or too short pwd!
    }

    const charPassword = pwd.split('');
    const num = {
        Excess: 0,
        Upper: 0,
        Numbers: 0,
        Symbols: 0,
    };

    const bonus = {
        Excess: 3,
        Upper: 4,
        Numbers: 5,
        Symbols: 5,
        Combo: 0,
        FlatLower: 0,
        FlatNumber: 0,
    };

    for (let i = 0; i < charPassword.length; i++) {
        if (charPassword[i].match(/[A-Z]/g)) {
            num.Upper++;
        }

        if (charPassword[i].match(/[0-9]/g)) {
            num.Numbers++;
        }

        if (charPassword[i].match(/(.*[,!@#-.$%^§()&*?_~])/)) {
            num.Symbols++;
        }
    }

    num.Excess = charPassword.length - MIN_PWD_LENGTH;

    if (num.Upper && num.Numbers && num.Symbols) {
        bonus.Combo = 25;
    } else if ((num.Upper && num.Numbers) || (num.Upper && num.Symbols) || (num.Numbers && num.Symbols)) {
        bonus.Combo = 15;
    }

    if (pwd.match(/^[\sa-z]+$/)) {
        bonus.FlatLower = -15;
    }

    if (pwd.match(/^[\s0-9]+$/)) {
        bonus.FlatNumber = -35;
    }

    return (
        BASE_SCORE +
        num.Excess * bonus.Excess +
        num.Upper * bonus.Upper +
        num.Numbers * bonus.Numbers +
        num.Symbols * bonus.Symbols +
        bonus.Combo +
        bonus.FlatLower +
        bonus.FlatNumber
    );
};

/**
 * A password strength indicator, returned by {@link passwordStrength}
 *
 * Password strength is really bad - it's an empty password
 *
 * Usage
 *
 *     import { EMPTY_PWD } from '@ecster/util';
 *
 *     console.log(EMPTY_PWD);
 *
 * @type {{level: number, name: string}}
 */
export const EMPTY_PWD = { level: 0, name: 'pwd-empty' };

/**
 * A password strength indicator, returned by {@link passwordStrength}
 *
 * Password is of unacceptable strength
 *
 * Usage
 *
 *     import { UNACCEPTABLE_PWD } from '@ecster/util';
 *
 *     console.log(UNACCEPTABLE_PWD);
 *
 * @type {{level: number, name: string}}
 */
export const UNACCEPTABLE_PWD = { level: 1, name: 'pwd-unacceptable' };

/**
 * A password strength indicator, returned by {@link passwordStrength}
 *
 * Password is weak
 *
 * Usage
 *
 *     import { WEAK_PWD } from '@ecster/util';
 *
 *     console.log(WEAK_PWD);
 *
 * @type {{level: number, name: string}}
 */
export const WEAK_PWD = { level: 2, name: 'pwd-weak' };

/**
 * A password strength indicator, returned by {@link passwordStrength}
 *
 * Password is ok
 *
 * Usage
 *
 *     import { OK_PWD } from '@ecster/util';
 *
 *     console.log(OK_PWD);
 *
 * @type {{level: number, name: string}}
 */
export const OK_PWD = { level: 3, name: 'pwd-ok' };

/**
 * A password strength indicator, returned by {@link passwordStrength}
 *
 * Password is strong
 *
 * Usage
 *
 *     import { STRONG_PWD } from '@ecster/util';
 *
 *     console.log(STRONG_PWD);
 *
 * @type {{level: number, name: string}}
 */
export const STRONG_PWD = { level: 4, name: 'pwd-strong' };

/**
 * A password strength indicator, returned by {@link passwordStrength}
 *
 * Password is very secure
 *
 * Usage
 *
 *     import { SECURE_PWD } from '@ecster/util';
 *
 *     console.log(SECURE_PWD);
 *
 * @type {{level: number, name: string}}
 */
export const SECURE_PWD = { level: 5, name: 'pwd-secure' };

/**
 * Calculate password strength
 *
 * Usage
 *
 *     import { passwordStrength, SECURE_PWD } from '@ecster/util';
 *
 *     if (passwordStrength('sdJfhKfu8&5!fd123x##xx').level === SECURE_PWD.level) {
 *         console.info('Woho! A secure password');
 *     }
 *
 * See {@link EMPTY_PWD}, {@link UNACCEPTABLE_PWD}, {@link WEAK_PWD}, {@link STRONG_PWD} and {@link SECURE_PWD} for the possible return values
 *
 * @param {string} pwd the password to calculate strength for
 * @returns {object}
 */
export const passwordStrength = pwd => {
    // score intervals
    const unacceptable = [undefined, 25];
    const weak = [26, 50];
    const ok = [51, 65];
    const strong = [66, 90];
    const secure = [91, undefined];
    const score = calculateScore(pwd);

    if (!pwd) {
        return EMPTY_PWD;
    }

    if (score <= unacceptable[1]) {
        return UNACCEPTABLE_PWD;
    }
    if (weak[0] <= score && score <= weak[1]) {
        return WEAK_PWD;
    }
    if (ok[0] <= score && score <= ok[1]) {
        return OK_PWD;
    }
    if (strong[0] <= score && score <= strong[1]) {
        return STRONG_PWD;
    }
    if (secure[0] <= score) {
        return SECURE_PWD;
    }
};

// backward compatible export
export default {
    score: calculateScore,
    strength: passwordStrength,
    EMPTY: EMPTY_PWD,
    UNACCEPTABLE: UNACCEPTABLE_PWD,
    WEAK: WEAK_PWD,
    OK: OK_PWD,
    STRONG: STRONG_PWD,
    SECURE: SECURE_PWD,
};
