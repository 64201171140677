import React from 'react';
import Checkbox from '../../Checkbox';

import { Option } from '../lib/interfaces';

interface IDefaultItemRendererProps {
    checked: boolean;
    option: Option;
    noLabel?: boolean;
    name?: string;
    onClick;
}

const DefaultItemRenderer = ({ name, checked, option, onClick, noLabel }: IDefaultItemRendererProps) => (
    <div className="item-renderer">
        <Checkbox className="checkbox" name={name} onChange={onClick} checked={checked} />
        {!noLabel && <span>{option.label}</span>}
    </div>
);

DefaultItemRenderer.defaultProps = {
    noLabel: false,
};

export default DefaultItemRenderer;
