import React, { ComponentProps } from 'react';
import Responsive from 'react-responsive';
import { tabletEnd } from './breakpoints';
import '../scss/MediaQuery.scss';

export const MobileOrTablet = ({ children = null, ...rest }: ComponentProps<typeof Responsive>) => (
    <Responsive maxWidth={tabletEnd} {...rest}>
        {children}
    </Responsive>
);

