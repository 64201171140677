import React from 'react';

import { isFI, isSE } from '../../../common/country';

export const renderThisCountry = that => {
    const { applicationCountry } = window.EcsterConfig;

    if (isSE()) return that.renderSE();

    if (isFI()) return that.renderFI();

    return typeof that.renderConfigError === 'function' ? (
        that.renderConfigError(applicationCountry)
    ) : (
        <p className="e-purple">Unknown country config ({applicationCountry})</p>
    );
};
