/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { ISelectProps } from '../lib/interfaces';

const defaultStrings = {
    allItemsAreSelected: 'All items are selected.',
    noOptions: 'No options',
    filter: 'Filter',
    placeholder: 'Select ...',
    selected: 'selected',
    validationMessage: 'Select a value',
    clearSearch: 'Clear Search', // aria-label
    clearSelected: 'Clear Selected', // aria-label
    search: 'Search', // aria-describedby
};

interface MultiSelectContextProps extends ISelectProps {
    t: (key: string) => string;
    setOptions?;
    isValid: boolean;
    setIsValid: (v: boolean) => void;
}

interface MultiSelectProviderProps {
    props: ISelectProps;
    children;
}

const MultiSelectContext = React.createContext<MultiSelectContextProps>({} as MultiSelectContextProps);

export const MultiSelectProvider = ({ props, children }: MultiSelectProviderProps) => {
    const [options, setOptions] = useState(props.options);
    const [isValid, setIsValid] = useState(true);
    const t = key => props.overrideStrings?.[key] || defaultStrings[key];

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    return (
        <MultiSelectContext.Provider value={{ t, ...props, options, setOptions, isValid, setIsValid }}>
            {children}
        </MultiSelectContext.Provider>
    );
};

export const useMultiSelect = () => React.useContext(MultiSelectContext);
