import React, { Component, ReactNode } from 'react';

const GREEN = '#5eb189'; // note 2021-01: Logo uses original Ecster green, not the new #4e9c79

interface SvgProps {
    id: string;
    viewBox: string;
    children: ReactNode;
}

const Svg = ({ id, viewBox, children }: SvgProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={id} viewBox={viewBox} role="img">
            {children}
        </svg>
    );
};

interface InfoProps {
    id: string;
    title: string;
    desc: string;
}
const Info = ({ id, title, desc }: InfoProps) => {
    return (
        <>
            <title id={`${id}__title`}>{title}</title>
            <desc id={`${id}__desc`}>{desc}</desc>
        </>
    );
};

interface EcsterLogoProps {
    fill: string;
    fillE: string;
}

const EcsterLogo = ({ fill, fillE }: EcsterLogoProps) => (
    <>
        <circle fill={fill} cx="57.39" cy="56.556" r="56.556" />
        <path
            fill={fillE}
            d="M41.116,73.146 C42.068,74.945 43.456,76.582 45.245,78.013 C47.121,79.515 49.329,80.702 51.807,81.54 C54.43,82.429 57.233,82.878 60.13,82.878 C63.027,82.878 65.828,82.429 68.454,81.54 C70.931,80.702 73.14,79.515 75.016,78.013 C77.381,76.12 80.83,76.505 82.722,78.868 C84.612,81.231 84.228,84.68 81.864,86.573 C78.986,88.874 75.657,90.675 71.967,91.923 C68.207,93.196 64.226,93.841 60.13,93.841 C56.037,93.841 52.054,93.196 48.294,91.923 C44.602,90.675 41.274,88.874 38.397,86.573 C35.428,84.196 33.083,81.403 31.425,78.268 C29.654,74.916 28.754,71.342 28.754,67.643 C28.754,63.944 29.654,60.37 31.425,57.018 C31.509,56.862 31.601,56.709 31.687,56.555 C31.601,56.4 31.509,56.249 31.425,56.092 C29.654,52.74 28.754,49.166 28.754,45.466 C28.754,41.767 29.654,38.192 31.425,34.841 C33.082,31.707 35.428,28.913 38.397,26.538 C41.274,24.236 44.603,22.435 48.294,21.186 C52.054,19.914 56.037,19.269 60.13,19.269 C64.226,19.269 68.207,19.915 71.967,21.186 C75.657,22.435 78.986,24.236 81.864,26.538 C84.228,28.429 84.612,31.878 82.722,34.242 C80.827,36.605 77.377,36.988 75.016,35.098 C73.138,33.595 70.93,32.409 68.454,31.57 C65.828,30.682 63.027,30.232 60.13,30.232 C57.234,30.232 54.43,30.682 51.807,31.57 C49.33,32.409 47.123,33.595 45.245,35.098 C43.456,36.529 42.068,38.166 41.116,39.965 C40.188,41.72 39.716,43.571 39.716,45.466 C39.716,46.179 39.785,46.886 39.916,47.584 C42.43,45.819 45.236,44.398 48.294,43.364 C52.054,42.092 56.037,41.447 60.13,41.447 C64.226,41.447 68.207,42.092 71.965,43.364 C75.657,44.613 78.986,46.413 81.864,48.716 C82.054,48.866 82.226,49.029 82.387,49.198 C84.598,50.943 86.027,53.643 86.027,56.681 C86.027,60.354 83.948,63.536 80.905,65.128 C78.253,67.081 75.254,68.636 71.966,69.747 C68.208,71.018 64.227,71.663 60.131,71.663 C56.038,71.663 52.055,71.018 48.295,69.747 C45.237,68.712 42.431,67.292 39.917,65.526 C39.787,66.223 39.717,66.93 39.717,67.643 C39.716,69.539 40.188,71.391 41.116,73.146 Z"
        />
        <path
            fill={fill}
            d="M46.236,56.556 C47.888,57.717 49.749,58.666 51.806,59.364 C54.429,60.253 57.232,60.704 60.129,60.704 C63.026,60.704 65.827,60.253 68.453,59.364 C70.513,58.667 72.371,57.717 74.024,56.556 C72.372,55.396 70.513,54.446 68.453,53.749 C65.827,52.861 63.026,52.41 60.129,52.41 C57.233,52.41 54.433,52.861 51.806,53.749 C49.749,54.445 47.888,55.396 46.236,56.556 Z"
        />
    </>
);

interface EcsterTextProps {
    fill: string;
}

const EcsterText = ({ fill }: EcsterTextProps) => (
    <g fill={fill} transform="translate(132.000000, 11.000000)">
        <path d="M30.325,17.457 C13.62,17.457 0.537,32.076 0.537,50.735 C0.537,66.413 13.642,74.616 26.589,74.616 C34.468,74.616 39.303,73.641 45.066,70.887 L45.625,70.617 L48.831,55.355 L46.469,56.603 C40.594,59.708 35.235,61.154 29.6,61.154 C21.563,61.154 16.296,57.476 16.062,51.748 L51.948,51.748 L52.223,50.871 C53.546,46.665 54.211,42.69 54.211,39.055 C54.21,25.734 45.056,17.457 30.325,17.457 Z M18.081,40.088 C20.659,34.158 25.139,30.801 30.56,30.801 C34.441,30.801 39.057,32.002 39.057,37.73 C39.057,38.543 38.989,39.318 38.843,40.087 L18.081,40.087 L18.081,40.088 Z" />
        <path d="M91.352,17.457 C73.337,17.457 58.682,31.792 58.682,49.411 C58.682,64.251 70.033,74.616 86.295,74.616 C93.724,74.616 100.379,72.749 105.536,69.221 L106.075,68.852 L106.243,54.106 L104.242,55.479 C98.93,59.139 92.852,61.155 87.138,61.155 C79.61,61.155 74.552,56.048 74.552,48.444 C74.552,35.586 84.384,30.92 90.99,30.92 C92.725,30.92 94.263,31.184 95.451,31.684 C94.617,33.144 94.196,34.688 94.196,36.286 C94.196,41.162 98.154,45.127 103.029,45.127 C108.429,45.127 112.35,40.599 112.35,34.36 C112.348,24.881 103.123,17.457 91.352,17.457 Z" />
        <path d="M142.318,17.457 C126.488,17.457 119.274,26.72 119.274,35.325 C119.274,45.799 129.104,49.768 134.359,51.885 L136.348,52.729 C141.017,54.674 145.033,56.356 145.033,59.041 C145.033,61.756 140.583,62.721 136.777,62.721 C133.486,62.721 130.622,62.217 128.547,61.285 C128.263,61.144 128.008,61.002 127.76,60.863 C128.018,60.07 128.161,59.138 128.161,58.437 C128.161,54.095 124.625,50.562 120.284,50.562 C115.579,50.562 112.167,54.228 112.167,59.281 C112.167,62.217 113.502,65.103 116.028,67.633 C120.468,72.072 127.996,74.615 136.662,74.615 C151.688,74.615 160.667,67.89 160.667,56.629 C160.667,47.906 153.541,43.573 145.838,40.197 L144.053,39.423 C139.348,37.384 134.896,35.458 134.896,32.919 C134.896,30.288 138.957,29.357 142.436,29.357 C144.306,29.357 146.356,29.69 147.934,30.258 C148.11,30.317 148.268,30.376 148.425,30.435 C148.141,31.228 148.043,31.812 148.043,32.675 C148.043,37.012 151.708,40.674 156.041,40.674 C160.667,40.674 164.154,36.978 164.154,32.077 C164.154,29.107 162.909,26.225 160.678,23.98 C156.717,19.897 149.855,17.457 142.318,17.457 Z" />
        <path d="M194.738,32.002 L210.744,32.002 L214.135,18.421 L198.129,18.421 L202.53,0.964 L184.878,7.281 L182.063,18.421 L172.165,18.421 L169.077,32.002 L178.782,32.002 L173.508,53.345 C172.82,56.38 172.402,58.673 172.402,60.72 C172.402,69.294 177.822,74.615 186.536,74.615 C193.788,74.615 200.287,71.789 206.413,65.97 L207.208,65.216 L201.532,57.329 L200.533,57.991 C195.378,61.391 193.571,61.995 191.102,61.995 C190.456,61.995 188.503,61.995 188.503,58.436 C188.503,57.657 188.818,55.883 189.31,53.922 L194.738,32.002 Z" />
        <path d="M241.768,17.457 C225.067,17.457 211.981,32.076 211.981,50.735 C211.981,66.413 225.086,74.616 238.034,74.616 C245.915,74.616 250.747,73.641 256.511,70.887 L257.07,70.617 L260.276,55.355 L257.912,56.603 C252.042,59.708 246.68,61.154 241.043,61.154 C233.006,61.154 227.743,57.476 227.506,51.748 L263.392,51.748 L263.666,50.871 C264.989,46.665 265.655,42.69 265.655,39.055 C265.654,25.734 256.5,17.457 241.768,17.457 Z M229.525,40.088 C232.104,34.158 236.582,30.801 242.012,30.801 C245.887,30.801 250.501,32.002 250.501,37.73 C250.501,38.543 250.431,39.318 250.284,40.087 L229.525,40.087 L229.525,40.088 Z" />
        <path d="M312.339,17.457 C306.851,17.457 301.527,21.069 296.472,28.209 L298.607,18.422 L275.496,18.422 L271.948,32.003 L281.602,32.003 L271.125,73.655 L287.2,73.655 L293.452,48.745 C295.766,39.394 300.764,33.307 304.106,31.381 C305.351,34.282 308.498,36.938 312.821,36.938 C318.093,36.938 322.378,32.65 322.378,27.381 C322.377,21.25 317.162,17.457 312.339,17.457 Z" />
    </g>
);

interface LogoProps {
    isNoWrap?: boolean;

    /**
     * Fill color for circle and ecster name
     */
    fill?: string;

    /**
     * Fill color for the "E" inside the circle
     */
    fillE?: string;

    /** Svg title */
    title?: string;

    /** Svg description */
    description?: string;

    /** Logo width */
    width?: string;

    /** Logo height */
    height?: string;

    /** Ecster name or circle logo only? */
    withName?: boolean;

    /** Additional css classes */
    className?: string;

    /** Css id */
    id?: string;
}

class Logo extends Component<LogoProps, {}> {
    public static defaultProps = {
        isNoWrap: false,
        title: 'Ecster Logo',
        description: '',
        fill: GREEN,
        fillE: '#fff',
        width: '100%',
        height: 'auto',
        withName: false,
        className: '',
        id: 'ecster-logo',
    };

    static noName(fill, fillE, title, desc, id) {
        return (
            <Svg id={id} viewBox="0 0 114 114">
                <Info title={title} desc={desc} id={id} />
                <EcsterLogo fill={fill} fillE={fillE} />
            </Svg>
        );
    }

    static withName(fill, fillE, title, desc, id) {
        return (
            <Svg id={id} viewBox="0 0 455 114">
                <Info title={title} desc={desc} id={id} />
                <EcsterLogo fill={fill} fillE={fillE} />
                <EcsterText fill={fill} />
            </Svg>
        );
    }

    isWhite = color => {
        const c = color?.toLowerCase();
        return c === 'white' || c === '#fff' || c === '#ffffff';
    };

    render() {
        const { isNoWrap, title, description, fill, fillE, width, height, withName, className, id } = this.props;

        const style = {
            width,
            height,
            display: 'inline-block',
        };

        // backward compatibility for white logos with fillE default value (#fff)
        const computedFillE = this.isWhite(fill) && this.isWhite(fillE) ? GREEN : fillE;

        const getLogo = () =>
            withName
                ? Logo.withName(fill, computedFillE, title, description, id)
                : Logo.noName(fill, computedFillE, title, description, id);

        const ThisLogo = () => {
            if (isNoWrap) {
                return getLogo();
            }
            return (
                <div className={className} style={style}>
                    {getLogo()}
                </div>
            );
        };

        return <ThisLogo />;
    }
}

export default Logo;
