import React, { Component, ReactNode } from 'react';
import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import detectDevice from '../util/detect-device';
import Dialog, { DialogBody } from '../Dialog';
import { Mobile, TabletOrDesktop } from '../MediaQuery/MediaQuery';
import './Tooltip.scss';

// styling for content box
const contentStyleBase = {
    borderRadius: '6px',
    padding: '15px',
    opacity: 1,
    transition: 'opacity .5s ease',
    fontWeight: 'normal',
    maxWidth: '90%',
};

const contentTheme = {
    light: {},
    green: { color: '#fff', background: '#4e9c79' },
    dark: { color: '#f2f2f2', background: '#313936' },
};

// styling for arrow
const arrowStyleBase = {};

const arrowTheme = {
    light: {
        filter: 'drop-shadow(rgba(0, 0, 0, 0.16) 0px -1px 0px)',
    },
    green: { color: '#4e9c79' },
    dark: { color: '#313936' },
};

interface TooltipProps {
    /**
     * Tooltip content
     */
    children: ReactNode;

    className?: string;

    /**
     * Tooltip trigger - a button, an icon or something
     */
    trigger: JSX.Element;

    /**
     * DOM id
     */
    id: string;

    /**
     * Tooltip style
     */
    theme?: 'dark' | 'light' | 'green' | 'gray';

    /**
     * Tooltip placement: 12 possible combinations of
     *
     * *relative position* to trigger element + *arrow position*
     *
     *    - top + left | center | right
     *
     *    - left + top | center | bottom
     *
     *    - right  + top | center | bottom
     *
     *    - bottom + left | center | right
     *
     * E.g. "top left" or "right center",
     *
     * or an array of preferred positions
     *
     * E.g. ["right top", "right bottom", "left top", "left bottom"]
     *
     * Tooltip is positioned the best possible way if an array of alternatives are specified.
     *
     * __
     */
    position?: PopupProps['position'];

    /**
     * Events triggering the tooltip.
     *
     * E.g: ['click', 'hover'] or 'click' (string if just one)
     */
    events?: PopupProps['on'];

    /**
     * Show arrow or not
     */
    arrow?: boolean;

    /**
     * Disabled or not
     */
    disabled?: boolean;

    /**
     * Milliseconds to wait for open / close on mouse enter / leave
     */
    mouseDelay?: number;

    /**
     * Called when tooltip opens
     */
    onOpen?: Function;

    /**
     * Called when tooltip closes
     */
    onClose?: Function;
    width?: string;
}

class Tooltip extends Component<TooltipProps> {
    static defaultProps = {
        theme: 'light',
        position: ['right center', 'left center', 'top center', 'bottom center'],
        events: detectDevice().isDesktop ? ['click', 'hover'] : 'click',
        arrow: true,
        disabled: false,
        mouseDelay: 120,
        onOpen: () => {},
        onClose: () => {},
        width: 'auto',
    };

    state = {
        openInMobile: false,
    };

    tooltipRef = React.createRef<HTMLSpanElement>();

    onOpen = () => {
        const { onOpen } = this.props;
        if (typeof onOpen === 'function') onOpen();
    };

    onClose = () => {
        const { onClose } = this.props;

        if (typeof onClose === 'function') onClose();
    };

    onOpenMobile = () => {
        this.setState({ openInMobile: true });
    };

    onCloseMobile = () => {
        this.setState({ openInMobile: false });
    };

    onKeyUpMobile = e => {
        if (e.keyCode === 13) {
            this.setState({ openInMobile: true });
        }
    };

    render() {
        const { id, children, events, theme, width, trigger, ...rest } = this.props;
        const { openInMobile } = this.state;
        const contentStyle = { ...contentStyleBase, ...contentTheme[theme], width };
        const arrowStyle = { ...arrowStyleBase, ...arrowTheme[theme] };

        // TODO: themes are not yet implemented for mobile dialog
        return (
            <>
                <Mobile>
                    <span
                        className="ec-tooltip-trigger-mobile"
                        onKeyUp={this.onKeyUpMobile}
                        onClick={this.onOpenMobile}
                        role="button"
                        tabIndex={0}
                    >
                        {trigger}
                    </span>
                    <Dialog
                        maxWidth="90%"
                        open={openInMobile}
                        onRequestClose={this.onCloseMobile}
                        lightOverlay
                        centerVerticalMobile
                        rounded2x
                    >
                        <DialogBody centeredContent>
                            <span className="ec-tooltip-content-mobile">{children}</span>
                        </DialogBody>
                    </Dialog>
                </Mobile>
                <TabletOrDesktop>
                    <span id={id} className="ec-tooltip" ref={this.tooltipRef}>
                        <Popup
                            {...rest}
                            trigger={trigger}
                            on={events}
                            contentStyle={contentStyle}
                            arrowStyle={arrowStyle}
                            onOpen={this.onOpen}
                            onClose={this.onClose}
                        >
                            {children}
                        </Popup>
                    </span>
                </TabletOrDesktop>
            </>
        );
    }
}

export default Tooltip;
