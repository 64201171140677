import React from 'react';
import propTypes from 'prop-types';
import { Panel, PanelContent } from '@ecster/components';
import { i18n } from '@ecster/i18n';

import { diamondIcon } from '@ecster/icons/H80/green';

import './UnderConstruction.scss';

const UnderConstruction = ({ message }) => {
    const thisMessage = message || i18n('common.under-construction.info');

    return (
        <Panel className="under-construction" centeredContent sideMarginsInMobile>
            <PanelContent wide>
                <img alt="" src={diamondIcon} className="mb-6x" />
                <h2 className="e-black mb-3x">{i18n('common.under-construction.header')}</h2>
                <p>{thisMessage}</p>
            </PanelContent>
        </Panel>
    );
};

UnderConstruction.propTypes = {
    message: propTypes.string,
};

UnderConstruction.defaultProps = {
    message: '',
};

export default UnderConstruction;
