// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { renderTableChildNodes } from './renderTableChildNodes';
import './TableFooter.scss';

interface TableFooterProps {
    /** CSS id */
    id?: string;
    children: ReactNode;

    /** Additional css classes */
    className?: string;

    /** Add a hover effect to table rows */
    style?: CSSProperties;
}

const TableFooter = ({ children, className, style, id }: TableFooterProps) => (
    <tfoot id={id} className={`table-footer ${className}`} style={style}>
        {renderTableChildNodes(children)}
    </tfoot>
);

TableFooter.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    children: PropTypes.node.isRequired,

    /** Additional css classes */
    className: PropTypes.string,

    /** Add a hover effect to table rows */
    style: PropTypes.shape(),
};

TableFooter.defaultProps = {
    id: '',
    className: '',
    style: {},
};

export default TableFooter;
