import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';
import { Button, ButtonGroup, ExpandableContainer, Form, Icon, Input, Message, TextArea } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { paperPlaneIcon } from '@ecster/icons/H40/green';
import { ExpandHeader } from '../ExpandHeader';
import { createCustomerServiceRequest } from '../redux/createCustomerServiceRequest';

class SendAMessage extends React.Component {
    contactForm = React.createRef();

    emailField = React.createRef();

    messageField = React.createRef();

    state = {
        email: '',
        message: '',
        operationFailed: false,
        operationSucceeded: false,
    };

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('createCustomerServiceRequest', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ operationSucceeded: true, operationFailed: false, email: '', message: '' });
        }
        if (reduxActionFailed('createCustomerServiceRequest', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ operationFailed: true });
        }
    }

    onChangeEmail = ({ target }) => {
        this.setState({ email: target.value });
    };

    onChangeMessage = ({ target }) => {
        this.setState({ message: target.value });
    };

    onFocusEmail = () => {
        this.setState({ operationFailed: false });
    };

    onSendMessage = () => {
        const { email, message } = this.state;

        if (this.contactForm.current.validate()) {
            const { createCustomerServiceRequest, customerId } = this.props;
            createCustomerServiceRequest(customerId, {
                freeTextMessage: message,
                email,
            });
            this.setState({ operationFailed: false });
        }
    };

    onCollapse = () => {
        // don't show messages if customer expands send message section again
        this.setState({ operationFailed: false, operationSucceeded: false });
    };

    render() {
        const { email, message, operationFailed, operationSucceeded } = this.state;
        return (
            <ExpandableContainer
                id="contact-us-send-a-message"
                header={
                    <ExpandHeader
                        icon={<Icon icon="icon-mail" green medium />}
                        text={i18n('customer.contact-us.send-a-message.header')}
                    />
                }
                stretch
                onCollapse={this.onCollapse}
            >
                <div className="expanded-content">
                    {operationFailed && (
                        <Message
                            exclamation
                            purple
                            header={i18n('customer.contact-us.send-a-message.feedback.failure')}
                        />
                    )}
                    {operationSucceeded && (
                        <div className="mtb-4x">
                            <Message
                                iconUrl={paperPlaneIcon}
                                green
                                header={i18n('customer.contact-us.send-a-message.feedback.success.header')}
                                message={i18n('customer.contact-us.send-a-message.feedback.success.text')}
                            />
                        </div>
                    )}
                    <Form ref={this.contactForm} validateRefs={[this.emailField, this.messageField]}>
                        <Input
                            type="email"
                            value={email}
                            label={i18n('customer.contact-us.send-a-message.labels.email')}
                            validationMessage={i18n('customer.contact-us.send-a-message.validation.email')}
                            onChange={this.onChangeEmail}
                            onFocus={this.onFocusEmail}
                            ref={this.emailField}
                            required
                        />
                        <TextArea
                            label={i18n('customer.contact-us.send-a-message.labels.message')}
                            placeholder={i18n('customer.contact-us.send-a-message.labels.message-placeholder')}
                            validationMessage={i18n('customer.contact-us.send-a-message.validation.message')}
                            onChange={this.onChangeMessage}
                            required
                            ref={this.messageField}
                            value={message}
                            maxLength={1000}
                        />
                        <ButtonGroup alignRight>
                            <Button onClick={this.onSendMessage} round>
                                {i18n('customer.contact-us.send-a-message.button')}
                            </Button>
                        </ButtonGroup>
                    </Form>
                </div>
            </ExpandableContainer>
        );
    }
}

SendAMessage.propTypes = {
    customerId: PropTypes.number.isRequired,
    createCustomerServiceRequest: PropTypes.func.isRequired,
    /* eslint-disable-next-line */
    createCustomerServiceRequestPending: PropTypes.bool.isRequired, // used by opSucceeded/Failed
    /* eslint-disable-next-line */
    createCustomerServiceRequestError: PropTypes.object, // used by opSucceeded/Failed
};

function mapStateToProps({ customer, authentication }) {
    return {
        customerId: authentication.person.id,
        createCustomerServiceRequestPending: customer.createCustomerServiceRequestPending,
        createCustomerServiceRequestError: customer.createCustomerServiceRequestError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomerServiceRequest: (customerId, data) => dispatch(createCustomerServiceRequest(customerId, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendAMessage);
