const parseServerResponseStatus = response => {
    if (response && response.status) {
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
        if (response.status > 399) {
            throw response;
        }
    }
};

export default parseServerResponseStatus;
