// @ts-nocheck
import PropTypes from 'prop-types';
import * as React from 'react';
import './CardHeader.scss';

interface CardHeaderProps {
    primary: string;
    secondary?: string;
}

/**
 * @deprecated use Panel
 */
const CardHeader = ({ primary, secondary }: CardHeaderProps) => {
    return (
        <div className="card-header">
            <h3 className="--primary card-header__primary">{primary}</h3>
            {secondary && (
                <span>
                    <span className="card-header__separator">|</span>
                    <span className="card-header__secondary">{secondary}</span>
                </span>
            )}
        </div>
    );
};

CardHeader.propTypes = {
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
};

CardHeader.defaultProps = {
    secondary: '',
};

export default CardHeader;
