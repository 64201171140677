import { get } from '@ecster/net/v2';
import {
    CONTRACT_GET_CONTRACTS_BEGIN,
    CONTRACT_GET_CONTRACTS_SUCCESS,
    CONTRACT_GET_CONTRACTS_FAILURE,
    CONTRACT_GET_CONTRACTS_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const GET_CONTRACTS_URL = customerId => `/rest/ess/customers/v1/${customerId}/contracts`;

const CLEAR_GET_CONTRACTS_STATE = 'CLEAR_GET_CONTRACTS_STATE';

export const getContracts = customerId => async dispatch => {
    dispatch({
        type: CONTRACT_GET_CONTRACTS_BEGIN,
    });

    try {
        const key = GET_CONTRACTS_URL(customerId);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: CONTRACT_GET_CONTRACTS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getContracts');

        dispatch({
            type: CONTRACT_GET_CONTRACTS_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetContractsError = () => ({ type: CONTRACT_GET_CONTRACTS_DISMISS_ERROR });

export const clearGetContractsState = () => ({ type: CLEAR_GET_CONTRACTS_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CONTRACT_GET_CONTRACTS_BEGIN:
            return {
                ...state,
                getContractsPending: true,
                getContractsError: null,
                getContractsIsDone: false,
                getContractsIsError: false,
            };

        case CONTRACT_GET_CONTRACTS_SUCCESS:
            // const contracts = action.data && action.data.filter(contract => contract.status !== 'ERROR');
            return {
                ...state,
                contracts: action.data,
                hasZeroContracts: !action.data || action.data.length === 0,
                getContractsPending: false,
                getContractsError: null,
                getContractsIsDone: true,
            };

        case CONTRACT_GET_CONTRACTS_FAILURE:
            return {
                ...state,
                getContractsPending: false,
                getContractsError: action.data.error,
                getContractsIsError: true,
            };

        case CONTRACT_GET_CONTRACTS_DISMISS_ERROR:
            return {
                ...state,
                getContractsError: null,
                getContractsIsError: false,
            };

        case CLEAR_GET_CONTRACTS_STATE:
            return {
                ...state,
                contracts: null,
                hasZeroContracts: false,
                getContractsError: null,
                getContractsIsDone: false,
                getContractsIsError: false,
            };

        default:
            return state;
    }
}
