import React from 'react';
import PropTypes from 'prop-types';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { LabelValue, Link, Panel, ResponsivePanel, PanelContent } from '@ecster/components';
import { formatDate } from '../../../common/util/format-date';

export default class CreditInvoiceSummaryPanel extends React.Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
    };

    static defaultProps = {};

    state = {};

    relatedInvoiceUrl = invoiceId => `#/customer/related/invoice/${invoiceId}`;

    render() {
        const { invoice } = this.props;

        return (
            <div className="invoice-credit-invoice-summary-panel">
                <Panel paddingTopBottom="xLarge" stretchInMobile>
                    <PanelContent wide>
                        <h2 className="centered">{i18n('invoice.one-off-invoice.credit.summary-header')}</h2>
                        <ResponsivePanel verticalLines className="mb-3x">
                            <div>
                                <LabelValue
                                    id="invoice-no"
                                    label={i18n('invoice.one-off-invoice.invoice-no')}
                                    value={invoice.invoiceNumber}
                                />
                                <div className="mt-5x">{i18n('invoice.one-off-invoice.credit.info', 'p')}</div>
                            </div>
                            <div>
                                <LabelValue
                                    id="related-invoice-no"
                                    label={i18n('invoice.one-off-invoice.credit.related-invoice')}
                                    value={
                                        <a href={this.relatedInvoiceUrl(invoice.relatedInvoiceId)}>
                                            {invoice.relatedInvoiceNumber}
                                        </a>
                                    }
                                />
                                <LabelValue
                                    id="order-no"
                                    label={i18n('invoice.one-off-invoice.credit.order-number')}
                                    value={invoice.orderNumber}
                                />
                                <LabelValue
                                    id="invoice-date"
                                    label={i18n('invoice.one-off-invoice.invoice-date')}
                                    value={formatDate(invoice.invoiceDate)}
                                />
                            </div>
                        </ResponsivePanel>
                        <p className="centered">
                            <strong>
                                <Link
                                    id="invoice-pdf-link"
                                    href={invoice.invoicePdfUrl}
                                    iconLeft="icon-download"
                                    target="_blank"
                                >
                                    {i18n('invoice.one-off-invoice.credit.invoice-link')}
                                </Link>
                            </strong>{' '}
                            (pdf)
                        </p>
                    </PanelContent>
                </Panel>
            </div>
        );
    }
}
