import React from 'react';

import { Message } from '../Message';
import { Link } from '../Clickable';

import icon from './ie-icon.svg';

import './IEMessage.scss';

const i18n = {
    sv: {
        header: 'Stödet för Internet Explorer har upphört',
        info: 'Vi kan inte garantera att allt fungerar som det ska. Uppdatera till en ny version av',
        browser: 'Microsofts webbläsare',
    },
    no: {
        // Google translate from sv
        header: 'Støtte for Internet Explorer har stoppet',
        info: 'Vi kan ikke garantere at alt vil fungere som det skal. Oppdater til en ny versjon av',
        browser: 'Microsoft nettleser',
    },
    da: {
        // Google translate from sv
        header: 'Internet Explorer-support er stoppet',
        info: 'Vi kan ikke garantere, at alt fungerer, som det skal. Opdater til en ny version af',
        browser: 'Microsoft browser',
    },
    en: {
        // Google translate from sv
        header: 'Support for Internet Explorer has ended',
        info:
            'We can not guarantee that everything will work as expected. We recommend that you upgrade to a new version of',
        browser: "Microsoft's browser",
    },
    fi: {
        header: 'Internet Explorerin tuki on päättynyt',
        info: 'Emme takaa että kaikki toimii moitteettomasti. Päivitä uudempaan',
        browser: 'Microsoftin selaimeen',
    },
};

const edgeLocale = {
    sv: 'sv-se',
    fi: 'fi-fi',
    en: 'en-us',
};

interface IEMessageProps {
    /**
     * Presentation language
     */
    lang?: string;

    /**
     * User agent string. For test only. Never use this property in real applications
     * @ignore
     */
    userAgent?: string;
}

export const IEMessage = ({ lang, userAgent }: IEMessageProps) => {
    const isIE = userAgent.match(/Trident/) || userAgent.match(/MSIE/);

    const theLang = i18n[lang] ? lang : 'en';

    const edgeLink = `https://www.microsoft.com/${edgeLocale[theLang]}/edge`;
    // const chromeLink = `https://www.google.com/intl/${theLang}/chrome`;
    // const firefoxLink = `https://www.mozilla.org/${theLang}/firefox/new/`;

    return isIE ? (
        <Message purple icon={<img src={icon} alt="" />} header={i18n[theLang].header} className="ec-ie-warning">
            <>
                {i18n[theLang].info}{' '}
                <Link purple underline href={edgeLink} target="_blank">
                    {i18n[theLang].browser}
                </Link>
            </>
        </Message>
    ) : null;
};

IEMessage.defaultProps = {
    lang: 'sv',
    userAgent: window.navigator.userAgent,
};

export default IEMessage;
