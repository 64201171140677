// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import './DataRow.scss';

interface DataRowProps {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

/** @deprecated use Table or LabelValue instead */
const DataRow = ({ children, className, style }: DataRowProps) => (
    <div className={`data-columns-row ${className}`} style={style}>
        {children}
    </div>
);

DataRow.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape()
};

DataRow.defaultProps = {
    className: '',
    style: {},
};

export default DataRow;
