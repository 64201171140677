import React from 'react';
import { i18n } from '@ecster/i18n';
import { Button, ButtonGroup, PanelContent } from '@ecster/components';
import LoginPageTemplate from '../common/templates/LoginPageTemplate';
import { LoginResponsivePanel } from './components/LoginResponsivePanel';

export default class NotACustomerInfoPage extends React.Component {
    loginAgain = () => {
        window.location.href = '#/';
    };

    render() {
        return (
            <LoginPageTemplate className="authentication-not-a-customer-info-page" lazy={false}>
                <LoginResponsivePanel>
                    <PanelContent fullWidth>
                        <div className="full-width-content">
                            <div className="plr-6x pt-6x">
                                <h2 className="e-yellow centered">{i18n('authentication.not-a-customer.header')}</h2>
                                {i18n('authentication.not-a-customer.info', 'p')}
                            </div>

                            <ButtonGroup alignCenter>
                                <Button transparent onClick={this.loginAgain} id="not-a-customer-login-again">
                                    <strong>{i18n('authentication.after-logout.login-link')}</strong>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </PanelContent>
                </LoginResponsivePanel>
            </LoginPageTemplate>
        );
    }
}
