import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton, Tooltip, detectDevice } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { formatAccount } from '../../../common/util/format-account';

const tooltipEvents = detectDevice().isDesktop ? ['click', 'hover'] : 'click';

export const OcrNumber = ({ ocrNumber, label, useInfoIcon }) => (
    <LabelValue
        label={
            <>
                {label || i18n('invoice.account-invoice.invoice-info.ocr-account')}
                {useInfoIcon && (
                    <Tooltip
                        id="ocr-tooltip"
                        position={['top center', 'bottom center']}
                        trigger={<i className="icon-info e-green ml-2x" />}
                        events={tooltipEvents}
                    >
                        {i18n('invoice.account-invoice.invoice-info.ocr-info')}
                    </Tooltip>
                )}
            </>
        }
        value={
            <CopyToClipboardButton
                id="copyOcrNumber"
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-ocr-number')}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={ocrNumber.replace(/ /g, '')}
            >
                {formatAccount(ocrNumber)}
            </CopyToClipboardButton>
        }
    />
);
OcrNumber.propTypes = {
    ocrNumber: PropTypes.string.isRequired,
    label: PropTypes.string,
    useInfoIcon: PropTypes.bool,
};

OcrNumber.defaultProps = {
    label: null, // i18n call doesn't work here (!?)
    useInfoIcon: true,
};
