// @ts-nocheck
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Toggle.scss';

interface ToggleProps {
    /** The toggle label */
    children?: ReactNode;

    /** Event handler for the onChange event */
    onChange: Function;

    /**
     * Sets the toggle state. True => on, false => off
     *
     * The alternate property name 'checked' (now removed from property validation)
     * still works, but is not recommended
     */
    toggled: boolean;

    /** Optional css classes */
    className?: string;

    /** The toggle element name */
    name?: string;

    /** CSS id */
    id?: string;

    /** Make toggle 44px height */
    large?: boolean;

    /** Make toggle 26px height */
    small?: boolean;

    /** Disable / enable */
    disabled?: boolean;

    /** Required to check the toogle? */
    required?: boolean;

    /** Validation message if required and not checked */
    validationMessage?: string;
}

export class Toggle extends React.Component<ToggleProps> {
    state = {
        hasError: false,
    };

    onChange = (checked, e) => {
        const { onChange } = this.props;

        this.doValidation();
        onChange(e);
    };

    doValidation() {
        const isValid = this.checkboxRef.current.checkValidity();
        this.setState({ hasError: !isValid });
        return isValid;
    }

    checkboxRef = React.createRef();

    render() {
        const {
            children,
            className,
            toggled,
            checked /* deprecated and removed from propTypes / interface */,
            name,
            large,
            disabled,
            small,
            required,
            validationMessage,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ...rest
        } = this.props;

        const { hasError } = this.state;

        const classes = classNames({
            'ec-toggle': true,
            'has-error': hasError,
            checked: checked || toggled,
            disabled,
            large,
            small,
            [className]: className,
        });

        const iconClasses = classNames({
            'icon-lock': true,
            checked: checked || toggled,
            disabled,
            large,
            small,
        });

        return (
            <div className={classes}>
                {disabled && <i className={iconClasses} />}
                <label className="ec-toggle__label">
                    <input
                        {...rest}
                        className="ec-toggle__checkbox"
                        type="checkbox"
                        name={name}
                        onChange={this.onChange}
                        checked={checked || toggled}
                        disabled={disabled}
                        required={required}
                        ref={this.checkboxRef}
                    />
                    <span className="ec-toggle__style" />
                    <span className="ec-toggle__label-text" ref={this.checkboxTextRef}>
                        {children}
                    </span>
                </label>
                <div className="ec-toggle-error">{validationMessage}</div>
            </div>
        );
    }
}

Toggle.propTypes = {
    children: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func.isRequired,
    toggled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    large: PropTypes.bool,
    small: PropTypes.bool,
    disabled: PropTypes.bool,

    // validation
    required: PropTypes.bool,
    validationMessage: PropTypes.string,
};

Toggle.defaultProps = {
    children: '',
    className: '',
    name: '',
    id: '',
    large: false,
    small: false,
    disabled: false,
    // validation
    required: false,
    validationMessage: 'Du måste kryssa i denna',
};
