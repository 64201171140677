// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { NetsRedirectPage } from './index';

const routes = {
    path: 'nets',
    name: 'Nets',
    childRoutes: [{ isPublic: true, path: 'login', name: 'NETS Login', component: NetsRedirectPage, isIndex: true }],
};

export default routes;