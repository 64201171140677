import React from 'react';
import PropTypes from 'prop-types';
import './BottomNavigation.scss';

function BottomNavigation(props) {
    const { children } = props;
    return <div className="bottom-navigation">{children}</div>;
}

BottomNavigation.propTypes = {
    children: PropTypes.node.isRequired,
};

BottomNavigation.defaultProps = {};

export default BottomNavigation;
