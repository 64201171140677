import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Badge } from '@ecster/components';
import './ArrowLink.scss';

export const ArrowLink = ({ icon, iconClass, text, onClick, to, badge, id }) => {
    const classes = classNames({
        'arrow-link': true,
        'no-underline': true,
    });

    const theBadge = typeof badge === 'string' ? <Badge>{badge}</Badge> : badge;

    let theIcon;

    if (icon) {
        theIcon = <img alt="" src={icon} />;
    } else if (iconClass) {
        theIcon = <i className={`${iconClass} e-green`} />;
    }

    return (
        <Link to={to} onClick={onClick} className={classes} id={id} black noUnderlineOnFocus>
            <div className="icon-ctr">{theIcon}</div>
            <div className="arrow-link__wrapper">
                <div className="arrow-link__text">{text}</div>
                <span>
                    {theBadge}
                    <i className="icon-chevron-right" />
                </span>
            </div>
        </Link>
    );
};

ArrowLink.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    onClick: PropTypes.func,
    badge: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // string value or a node <Badge>...</Badge>
};

ArrowLink.defaultProps = {
    icon: null,
    iconClass: null,
    onClick: () => {},
    badge: null,
};
