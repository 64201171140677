import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Overlay.scss';

class Overlay extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { onClick, show } = this.props;
        const classes = classNames({
            'page-overlay': true,
            show,
        });

        return <div role="presentation" onClick={onClick} className={classes} />;
    }
}

export default Overlay;
