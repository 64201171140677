export const COMMON_GET_TEXT_BEGIN = 'COMMON_GET_TEXT_BEGIN';
export const COMMON_GET_TEXT_SUCCESS = 'COMMON_GET_TEXT_SUCCESS';
export const COMMON_GET_TEXT_FAILURE = 'COMMON_GET_TEXT_FAILURE';
export const COMMON_GET_TEXT_DISMISS_ERROR = 'COMMON_GET_TEXT_DISMISS_ERROR';
export const COMMON_SHOW_SNACKBAR = 'COMMON_SHOW_SNACKBAR';
export const COMMON_HIDE_SNACKBAR = 'COMMON_HIDE_SNACKBAR';
export const COMMON_SHOW_MODAL_MESSAGE = 'COMMON_SHOW_MODAL_MESSAGE';
export const COMMON_HIDE_MODAL_MESSAGE = 'COMMON_HIDE_MODAL_MESSAGE';
export const COMMON_SHOW_FULLSCREEN_DIALOG = 'COMMON_SHOW_FULLSCREEN_DIALOG';
export const COMMON_HIDE_FULLSCREEN_DIALOG = 'COMMON_HIDE_FULLSCREEN_DIALOG';
export const COMMON_SHOW_ONBOARDING = 'COMMON_SHOW_ONBOARDING';
export const COMMON_HIDE_ONBOARDING = 'COMMON_HIDE_ONBOARDING';
export const COMMON_SET_PREVIOUS_PATH = 'COMMON_SET_PREVIOUS_PATH';
export const COMMON_CLEAR_SET_PREVIOUS_PATH_STATE = 'COMMON_CLEAR_SET_PREVIOUS_PATH_STATE';
export const COMMON_SET_REVIEW_MODE = 'COMMON_SET_REVIEW_MODE';
