// @ts-expect-error no type definitions
import { formatAmount as utilFormatAmount } from '@ecster/util';

/**
 * Format an amount with
 * @param amount
 * @param options
 *    currency: undefined | SEK | EUR | etc (options.style='currency' is added automatically if set)
 *    decimals: 0 | 1 | 2 | etc
 *    strip00: true (default) | false
 *    round: true | false (default)
 *    roundUp: true | false (default)
 *    roundDown: true | false (default)
 *    positive: true | false (default)
 *    hasCurrency true (default) | false - show currency or not
 * @returns {*|string}
 */

const currencyMap = new Map();
currencyMap.set('FI', 'EUR');
currencyMap.set('SE', 'SEK');

type FormatAmount = (
    amount: number,
    options?: {
        currency?: string;
        decimals?: number;
        strip00?: boolean;
        round?: boolean;
        roundUp?: boolean;
        roundDown?: boolean;
        positive?: boolean;
        isOnlyPositive?: boolean;
        currencyDisplay?: string;
        hasCurrency?: boolean;
    }
) => string;

export const formatAmount: FormatAmount = (amount, options = { strip00: true, hasCurrency: true }) => {
    // @ts-ignore
    const { language, applicationCountry } = window.EcsterConfig;
    const currency = currencyMap.get(applicationCountry);

    const locale = `${language}-${applicationCountry}`;

    const theCurrency = options.currency || currency;

    const theOptions = { ...options };
    theOptions.positive = options.isOnlyPositive;

    // Use € for Euro unless overridden by option
    if (theCurrency === 'EUR' && !theOptions.currencyDisplay) {
        theOptions.currencyDisplay = 'symbol';
    }

    return utilFormatAmount(amount, locale, theCurrency, theOptions);
};
