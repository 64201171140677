import { LinkButton, Panel } from "@ecster/components";
import React from "react";

export const WarningMsg = ({ header, text, linkText, linkTarget }) => (
  <Panel>
    <i className="icon-alert-circle" />
    <h1 className="h2">{header}</h1>
    {text}
    <LinkButton round to={linkTarget} className="mt-8 mb-4">
      {linkText}
    </LinkButton>
  </Panel>
);
