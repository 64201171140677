import { get } from '@ecster/net/v2';

import {
    AUTHENTICATION_GET_SESSION_BEGIN,
    AUTHENTICATION_GET_SESSION_SUCCESS,
    AUTHENTICATION_GET_SESSION_FAILURE,
    AUTHENTICATION_GET_SESSION_DISMISS_ERROR,
} from './constants';

import { loginStatus as initialLoginStatus, loginProgress as initialLoginProgress } from './initialState';
import { GET_SESSION_URL } from './urls';
import { ACCOUNT_GET_ACCOUNT_FAILURE } from '../../account/redux/constants';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_SESSION_STATE = 'CLEAR_GET_SESSION_STATE';

/**
 *
 * @param bankIdSuccessData (OPTIONAL) { key, person, type } from BankIdAuthentication-components onSuccess callback. no need to do another http call to getSession, set loginStatus directly instead.
 * @param count {number} OPTIONAL add a count query parameter to URL, makes response mocking easier
 */
export const getSession =
    (bankIdSuccessData = null, count = -1) =>
    async dispatch => {
        dispatch({
            type: AUTHENTICATION_GET_SESSION_BEGIN,
        });

        try {
            if (bankIdSuccessData) {
                dispatch({
                    type: AUTHENTICATION_GET_SESSION_SUCCESS,
                    data: {
                        authentication: {
                            status: 'COMPLETE',
                            eid: {},
                        },
                        key: bankIdSuccessData.key,
                        person: bankIdSuccessData.person,
                    },
                });
            } else {
                const res = await get(GET_SESSION_URL + (count > -1 ? `?count=${count}` : ''));

                dispatch({
                    type: AUTHENTICATION_GET_SESSION_SUCCESS,
                    data: res.response,
                });
            }
        } catch (err) {
            reportError(err, 'getSession');

            dispatch({
                type: AUTHENTICATION_GET_SESSION_FAILURE,
                data: {
                    error: {
                        message: err,
                        action: ACCOUNT_GET_ACCOUNT_FAILURE,
                    },
                },
            });
        }
    };

export const dismissGetSessionError = () => ({ type: AUTHENTICATION_GET_SESSION_DISMISS_ERROR });

export const clearGetSessionState = () => ({ type: CLEAR_GET_SESSION_STATE });

export function reducer(state, action) {
    const isLoggedIn =
        action && action.data && action.data.authentication && action.data.authentication.status === 'COMPLETE';

    switch (action.type) {
        case AUTHENTICATION_GET_SESSION_BEGIN:
            return {
                ...state,
                getSessionPending: true,
                getSessionError: null,
                getSessionIsDone: false,
                getSessionIsError: false,
            };

        case AUTHENTICATION_GET_SESSION_SUCCESS:
            return {
                ...state,
                loginStatus: {
                    sessionKey: action.data.key,
                    isLoggedIn,
                },
                loginProgress: {
                    status: action.data.authentication.status,
                    pollTime:
                        isLoggedIn || !action.data.authentication.eid ? 0 : action.data.authentication.eid.pollTime,
                },
                person: action.data.person,
                getSessionPending: false,
                getSessionError: null,
                getSessionIsDone: true,
            };

        case AUTHENTICATION_GET_SESSION_FAILURE:
            return {
                ...state,
                // don't reset state on failure - HXDN-18636
                getSessionPending: false,
                getSessionError: action.data.error,
                getSessionIsError: true,
            };

        case AUTHENTICATION_GET_SESSION_DISMISS_ERROR:
            return {
                ...state,
                getSessionError: null,
                getSessionIsError: false,
            };

        case CLEAR_GET_SESSION_STATE:
            return {
                ...state,
                loginStatus: initialLoginStatus,
                loginProgress: initialLoginProgress,
                getSessionIsDone: false,
                getSessionIsError: false,
            };

        default:
            return state;
    }
}
