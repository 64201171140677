import { put } from '@ecster/net/v2';

import {
    ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_BEGIN,
    ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_SUCCESS,
    ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_FAILURE,
    ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_DISMISS_ERROR,
    CLEAR_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_STATE,
} from './constants';
import { UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

export const updateAccountTransactionPartPayment = (referenceId, transactionId, data) => async (dispatch, getState) => {
    dispatch({
        type: ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_BEGIN,
    });

    try {
        const customerId = getState().authentication.person.id;

        await cache.clear();
        const res = await put(
            UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_URL(customerId, referenceId, transactionId),
            data
        );

        dispatch({
            type: ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'updateAccountTransactionPartPayment');

        dispatch({
            type: ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissUpdateAccountTransactionPartPaymentError = () => ({
    type: ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_DISMISS_ERROR,
});

export const clearUpdateAccountTransactionPartPaymentState = () => ({
    type: CLEAR_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_STATE,
});

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_BEGIN:
            return {
                ...state,
                updateAccountTransactionPartPaymentPending: true,
                updateAccountTransactionPartPaymentError: null,
                updateAccountTransactionPartPaymentIsDone: false,
                updateAccountTransactionPartPaymentIsError: false,
            };

        case ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_SUCCESS:
            return {
                ...state,
                updateAccountTransactionPartPaymentPending: false,
                updateAccountTransactionPartPaymentError: null,
                updateAccountTransactionPartPaymentIsDone: true,
            };

        case ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_FAILURE:
            return {
                ...state,
                updateAccountTransactionPartPaymentPending: false,
                updateAccountTransactionPartPaymentError: action.data.error,
                updateAccountTransactionPartPaymentIsError: true,
            };

        case ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_DISMISS_ERROR:
            return {
                ...state,
                updateAccountTransactionPartPaymentError: null,
                updateAccountTransactionPartPaymentIsError: false,
            };

        case CLEAR_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_STATE:
            return {
                ...state,
                updateAccountTransactionPartPaymentError: null,
                updateAccountTransactionPartPaymentIsError: false,
                updateAccountTransactionPartPaymentIsDone: false,
            };

        default:
            return state;
    }
}
