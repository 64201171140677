import { get } from '@ecster/net/v2';
import {
    CONTRACT_GET_REPAYMENT_INQUIRY_BEGIN,
    CONTRACT_GET_REPAYMENT_INQUIRY_SUCCESS,
    CONTRACT_GET_REPAYMENT_INQUIRY_FAILURE,
    CONTRACT_GET_REPAYMENT_INQUIRY_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_REPAYMENT_INQUIRY_STATE = 'CLEAR_GET_REPAYMENT_INQUIRY_STATE';

const GET_REPAYMENT_INQUIRY_URL = (customerId, contractNumber) =>
    `/rest/ess/customers/v1/${customerId}/contracts/${contractNumber}/repayment`;

export const getRepaymentInquiry = (customerId, contractNumber) => async dispatch => {
    dispatch({
        type: CONTRACT_GET_REPAYMENT_INQUIRY_BEGIN,
    });

    try {
        const res = await get(GET_REPAYMENT_INQUIRY_URL(customerId, contractNumber));

        dispatch({
            type: CONTRACT_GET_REPAYMENT_INQUIRY_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getRepaymentInquiry');
        dispatch({
            type: CONTRACT_GET_REPAYMENT_INQUIRY_FAILURE,
            data: {
                error: {
                    message: err,
                    action: CONTRACT_GET_REPAYMENT_INQUIRY_FAILURE,
                },
            },
        });
    }
};

export const dismissGetRepaymentInquiryError = () => ({ type: CONTRACT_GET_REPAYMENT_INQUIRY_DISMISS_ERROR });

export const clearGetRepaymentInquiryState = () => ({ type: CLEAR_GET_REPAYMENT_INQUIRY_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CONTRACT_GET_REPAYMENT_INQUIRY_BEGIN:
            return {
                ...state,
                getRepaymentInquiryPending: true,
                getRepaymentInquiryError: null,
                getRepaymentInquiryIsDone: false,
                getRepaymentInquiryIsError: false,
            };

        case CONTRACT_GET_REPAYMENT_INQUIRY_SUCCESS:
            return {
                ...state,
                currentRepaymentTerms: action.data,
                getRepaymentInquiryPending: false,
                getRepaymentInquiryError: null,
                getRepaymentInquiryIsDone: true,
            };

        case CLEAR_GET_REPAYMENT_INQUIRY_STATE:
            return {
                ...state,
                currentRepaymentTerms: null,
                getRepaymentInquiryIsDone: false,
                getRepaymentInquiryIsError: false,
            };

        case CONTRACT_GET_REPAYMENT_INQUIRY_FAILURE:
            return {
                ...state,
                getRepaymentInquiryPending: false,
                getRepaymentInquiryError: action.data.error,
                getRepaymentInquiryIsError: true,
            };

        case CONTRACT_GET_REPAYMENT_INQUIRY_DISMISS_ERROR:
            return {
                ...state,
                getRepaymentInquiryError: null,
                getRepaymentInquiryIsError: false,
            };

        default:
            return state;
    }
}
