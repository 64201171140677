// @ts-nocheck
import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Data.scss';

interface DataProps {
    strong?: boolean;
    stronger?: boolean;
    header?: boolean;
    weak?: boolean;
    small?: boolean;
    left?: boolean;
    right?: boolean;
    children?: boolean;
    className?: boolean;
    style?: CSSProperties;
}

/** @deprecated use Table or LabelValue instead */
const Data = ({
    strong,
    stronger,
    header,
    weak,
    small,
    children,
    className,
    style,
    left,
    right,
    ...rest
}: DataProps) => {
    const classes = classNames({
        'data-columns__data': true,
        'data-columns__data--strong': strong,
        'data-columns__data--header': header || stronger,
        'data-columns__data--weak': weak,
        'data-columns__data--small': small,
        'data-columns__data--left': left,
        'data-columns__data--right': right,
        [className]: className,
    });
    if (header || stronger) {
        return (
            /**
             This used to be an H4 - can't use a heading as you don't know which order it will come in when using this component.
             Headings must come in order to comply with WCAG: h1, h2, h3...
             */
            <strong {...rest} className={classes} style={style}>
                {children}
            </strong>
        );
    }
    return (
        <div {...rest} className={classes} style={style}>
            {children}
        </div>
    );
};

Data.propTypes = {
    strong: PropTypes.bool,
    stronger: PropTypes.bool,
    header: PropTypes.bool,
    weak: PropTypes.bool,
    small: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.shape(),
};

Data.defaultProps = {
    strong: false,
    stronger: false,
    header: false,
    weak: false,
    small: false,
    left: false,
    right: false,
    children: '',
    className: '',
    style: {},
};

export default Data;
