import { detectDevice } from '@ecster/components';

/**
 * Add a class representing the type of device to the provided CSS query.
 * It only adds the class to the first hit - so remaining matching elements will remain unaffected.
 * is-mobile
 * is-tablet
 * is-desktop
 * @param query {string | undefined} - CSS query to find ONE element to append the class to.
 * @return {string} - The type of device: MOBILE, TABLET, DESKTOP
 */
const addDeviceClass = (query = 'html') => {
    const htmlRoot = document.querySelector(query);
    const device = detectDevice();
    const { isMobile, isTablet } = device;
    let deviceClass = '';

    if (isMobile) {
        deviceClass = 'mobile';
    } else if (isTablet) {
        deviceClass = 'tablet';
    } else {
        deviceClass = 'desktop';
    }

    htmlRoot.classList.add(`is-${deviceClass}`);

    return deviceClass.toUpperCase();
};

export default addDeviceClass;
