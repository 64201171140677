import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_BEGIN,
    ACCOUNT_GET_ACCOUNT_SUCCESS,
    ACCOUNT_GET_ACCOUNT_FAILURE,
    ACCOUNT_GET_ACCOUNT_DISMISS_ERROR,
    ACCOUNT_CLEAR_BRICKID,
    CLEAR_GET_ACCOUNT_STATE,
    CLEAR_GET_ACCOUNT_REDUX_STATE,
} from './constants';

import { GET_ACCOUNT_URL } from './urls';
import initialState from './initialState';
import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import { reportError } from '../../../common/reportError';

export const getAccount = (customerId, accountRef) => async dispatch => {
    dispatch({ type: ACCOUNT_GET_ACCOUNT_BEGIN });

    try {
        const res = await get(GET_ACCOUNT_URL(customerId, accountRef));
        parseServerResponseStatus(res);

        // note: 2020-06: ETAP does not support account.remaining (may change in the future, then remove this if statement)
        if (res.response && !Number.isInteger(res.response.remaining)) {
            res.response.remaining = res.response.limit - res.response.used;
        }

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getAccount');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_FAILURE,
            data: {
                error: {
                    message: err,
                    action: ACCOUNT_GET_ACCOUNT_FAILURE,
                },
            },
        });
    }
};

export const dismissGetAccountError = () => ({ type: ACCOUNT_GET_ACCOUNT_DISMISS_ERROR });

export const clearGetAccountState = () => ({ type: CLEAR_GET_ACCOUNT_STATE });

export const clearGetAccountReduxState = () => ({ type: CLEAR_GET_ACCOUNT_REDUX_STATE });

export const clearAccountBrickIdState = () => ({ type: ACCOUNT_CLEAR_BRICKID });

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_BEGIN:
            return {
                ...state,
                getAccountPending: true,
                getAccountError: null,
                getAccountIsDone: false,
                getAccountIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.data,
                getAccountPending: false,
                getAccountError: null,
                getAccountIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_FAILURE:
            return {
                ...state,
                account: initialState.account,
                getAccountPending: false,
                getAccountError: action.data.error,
                getAccountIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_DISMISS_ERROR:
            return {
                ...state,
                getAccountError: null,
                getAccountIsError: false,
            };

        case CLEAR_GET_ACCOUNT_STATE:
            return {
                ...state,
                account: null,
                getAccountPending: false,
                getAccountError: null,
                getAccountIsDone: false,
                getAccountIsError: false,
            };

        case CLEAR_GET_ACCOUNT_REDUX_STATE:
            return {
                ...state,
                getAccountPending: false,
                getAccountError: null,
                getAccountIsDone: false,
                getAccountIsError: false,
            };

        case ACCOUNT_CLEAR_BRICKID:
            return {
                ...state,
                account: { ...state.account, brickId: undefined },
                getAccountPending: false,
                getAccountError: null,
            };

        default:
            return state;
    }
}
