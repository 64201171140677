/**
 * Refresh page if it's restored from back-forward cache when navigating using back button in browser
 * https://web.dev/bfcache/#update-stale-or-sensitive-data-after-bfcache-restore
 */

export const initBackForwardCacheBuster = () => {
    window.addEventListener('pageshow', event => {
        if (event.persisted) {
            window.location.reload();
        }
    });
};
