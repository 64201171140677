import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './FlexPanel.scss';

/**
 * @deprecated Use ResponsivePanel instead, just import and change tag name
 * to ResponsivePanel and add the separator property
 *
 * A simple responsive panel.
 *
 * FlexPanel distributes child elements
 *  - flex-direction: row in desktop and tablet,
 *  - flex-direction: column in mobile
 *
 * All children get equal widths in desktop + tablet and 100% widths in mobile
 */
const FlexPanel = props => {
    const { className, children, separator, reverseMobile } = props;

    const classes = classNames({
        'ec-flex-panel': true,
        'reverse-mobile': reverseMobile,
        [className]: className,
    });

    const childClasses = classNames({
        'ec-flex-panel-item': true,
        'reverse-mobile': reverseMobile,
        'separating-line': separator,
        [`num-${children.length}`]: true,
    });

    return (
        <div className={classes}>
            {children.map((child, index) => (
                <div key={index} className={childClasses}>
                    {child}
                </div>
            ))}
        </div>
    );
};

FlexPanel.propTypes = {
    /**
     * Child elements of the Panel.
     *
     * Minimum number: 2, maximum number: 6, recommended: 2-4
     */
    children: PropTypes.node.isRequired,

    /** Any extra classNames to add to the panel container div */
    className: PropTypes.string,

    /**
     * Add a separating line between child elements.
     *
     * Vertical lines in desktop and tablet.
     *
     * Horizontal lines in mobile.
     *
     * No line after last child in row or column.
     */
    separator: PropTypes.bool,

    /**
     * Reverse the order of child elements in mobile.
     *
     * Desktop / tablet

     first | second | third

     * Mobile

     third
     ------
     second
     ------
     first

     * How to reverse order in desktop and tablet?
     *
     * Add elements in reverse order and *reverseMobile* them!
     */
    reverseMobile: PropTypes.bool,
};

FlexPanel.defaultProps = {
    className: undefined,
    separator: true,
    reverseMobile: false,
};

export default FlexPanel;
