import { get } from '@ecster/net';
import PropTypes from 'prop-types';

/**
 * system: The system to be checked - acr, ecr, etap, payon, rapp, swish or uc.
 */
export const checkSystemAvailability = async (system?: string) => {
    try {
        const response = await get(`/rest/eos/v1/externalsystem`);

        if (!system) return (response as any).response; // If no system is provided, return an array with all systems and their status

        // If specific system is provided, return the status (either True or False) of the system
        const status = (response as any).response.filter(backend => backend.system === system)[0];
        return status ? status.isOnline : true; // assume up if no status is found
    } catch (e) {
        console.error(e);
        return true; // Assume system is up as we can't find a status
    }
};

checkSystemAvailability.propTypes = {
    /**
     *  The specified system to be checked - acr, ecr, etap, payon, rapp, swish or uc.
     */
    system: PropTypes.string,
};

checkSystemAvailability.defaultProps = {
    system: ' ',
};
