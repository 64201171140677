/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/**
 * A generic dropdown component.  It takes the children of the component
 * and hosts it in the component.  When the component is selected, it
 * drops-down the contentComponent and applies the contentProps.
 */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useDidUpdateEffect } from '../hooks/use-did-update-effect';
import { useKey } from '../hooks/use-key';
import { useMultiSelect } from '../hooks/use-multi-select';
import { KEY } from '../lib/constants';
import SelectPanel from '../select-panel';
import { DropdownHeader } from './header';

const Dropdown = React.forwardRef((props, ref) => {
    const {
        t,
        onMenuToggle,
        disabled,
        onChange,
        labelledBy,
        value,
        isOpen,
        defaultIsOpen,
        ClearSelectedIcon,
        closeOnChangedValue,
        options,
        required,
        min,
        max,
        isValid,
        setIsValid,
        validationMessage,
    } = useMultiSelect();

    useEffect(() => {
        if (closeOnChangedValue) {
            setExpanded(false);
        }
    }, [value]);

    const [isInternalExpand, setIsInternalExpand] = useState(true);
    const [expanded, setExpanded] = useState(defaultIsOpen);
    const [hasFocus, setHasFocus] = useState(false);

    const wrapper: any = useRef();

    useDidUpdateEffect(() => {
        onMenuToggle && onMenuToggle(expanded);
        if (!expanded) hideDropdown();
    }, [expanded]);

    useEffect(() => {
        if (defaultIsOpen === undefined && typeof isOpen === 'boolean') {
            setIsInternalExpand(false);
            setExpanded(isOpen);
        }
    }, [isOpen]);

    const handleKeyDown = e => {
        // allows space and enter when focused on input/button
        if (['text', 'button'].includes(e.target.type) && [KEY.SPACE, KEY.ENTER].includes(e.code)) {
            return;
        }

        if (isInternalExpand) {
            if (e.code === KEY.ESCAPE) {
                setExpanded(false);
                wrapper?.current?.focus();
            } else {
                setExpanded(true);
            }
        }
        e.preventDefault();
    };

    useKey([KEY.ENTER, KEY.ARROW_DOWN, KEY.SPACE, KEY.ESCAPE], handleKeyDown, {
        target: wrapper,
    });

    const hideDropdown = () => {
        setHasFocus(false);
        setExpanded(false);
        doValidation();
    };

    const handleFocus = () => !hasFocus && setHasFocus(true);

    const handleBlur = e => {
        if (!e.currentTarget.contains(e.relatedTarget) && isInternalExpand) {
            hideDropdown();
        }
    };

    const toggleExpanded = () => {
        isInternalExpand && setExpanded(disabled ? false : !expanded);
    };

    const handleClearSelected = e => {
        e.stopPropagation();
        onChange([]);
        isInternalExpand && setExpanded(false);
    };

    const doValidation = () => {
        let valid = true;

        if (required) {
            valid = !!value.length;
        }
        if (min) {
            if (!!value.length && value.length < min) {
                valid = false;
            }
        }
        if (max) {
            if (!!value.length && value.length > max) {
                valid = false;
            }
        }

        setIsValid(valid);

        return valid;
    };

    useImperativeHandle(ref, () => ({ doValidation }), [value, options]);

    return (
        <div
            tabIndex={0}
            className={`dropdown-container ${!isValid ? 'has-error' : ''}`}
            aria-labelledby={labelledBy}
            aria-expanded={expanded}
            aria-readonly
            aria-disabled={disabled}
            ref={wrapper}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <div className="dropdown-heading" onClick={toggleExpanded} aria-hidden="true">
                <div className="dropdown-heading-value">
                    <DropdownHeader />
                </div>
                {value.length > 0 && ClearSelectedIcon !== null && (
                    <button
                        type="button"
                        className="clear-selected-button"
                        onClick={handleClearSelected}
                        disabled={disabled}
                        aria-label={t('clearSelected')}
                    >
                        {ClearSelectedIcon || <i className="icon-cross" />}
                    </button>
                )}
            </div>
            {expanded && (
                <div className="dropdown-content">
                    <div className="panel-content">
                        <SelectPanel setExpanded={setExpanded} />
                    </div>
                </div>
            )}
            <div className="ec-multi-select-error">{validationMessage || t('validationMessage')}</div>
        </div>
    );
});

export default Dropdown;
