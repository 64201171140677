import React from 'react';

import { useMultiSelect } from '../hooks/use-multi-select';

export const DropdownHeader = () => {
    const { t, value, options, valueRenderer } = useMultiSelect();

    const noneSelected = value.length === 0;
    const allSelected = value.length === options.length;
    const customText = valueRenderer && valueRenderer(value, options);

    const getSelectedText = () => {
        if (value.length <= 2) {
            return value.map(s => s.label).join(', ');
        }
        return `${value.length} ${t('selected')}`;
    };

    return noneSelected ? (
        <div className="dropdown-heading-value-wrapper">
            <span className="gray">{customText || t('placeholder')}</span>
            <i className="icon-chevron-down e-green" />
        </div>
    ) : (
        <span>{customText || (allSelected ? t('allItemsAreSelected') : getSelectedText())}</span>
    );
};
