import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { Button, ButtonGroup, Dialog, DialogBody, DialogFooter, DialogHeader, Idle } from '@ecster/components';
import { keepAliveSession } from '../features/authentication/redux/actions';
import './IdleDetectionDialog.scss';
import { history } from './configStore';

const { EcsterConfig: conf } = window;
const IDLE_TIMEOUT = conf.autoLogoutTimeout || 1000 * 60 * 4; // idle time before modal opens.
const WARNING_TIMEOUT = 1000 * 60; // modal is open, time before automatic logout.
const BACKGROUND_TIMEOUT = IDLE_TIMEOUT + WARNING_TIMEOUT; // total idle time before forcing logout when app is in background.
const LOGOUT_URL = '/authentication/logout';

const Wrapper = ({ children, onIdle, onBackgroundIdle }) => {
    return (
        <div className="IdleHandler">
            {children}
            <Idle timeout={IDLE_TIMEOUT} onIdle={onIdle} debounce={300} />
            <Idle timeout={BACKGROUND_TIMEOUT} onIdle={onBackgroundIdle} debounce={300} />
        </div>
    );
};

Wrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onIdle: PropTypes.func.isRequired,
    onBackgroundIdle: PropTypes.func.isRequired,
};
Wrapper.defaultProps = {
    children: '',
};

const IdleDetectionDialog = () => {
    const dispatch = useDispatch();

    const { isLoggedIn, sessionKey } = useSelector(state => state.authentication.loginStatus, shallowEqual);

    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const warningTimer = useRef();

    useEffect(() => {
        if (isLoggingOut && !isLoggedIn) setIsLoggingOut(false);
    }, [isLoggedIn, isLoggingOut]);

    const clearWarningTimer = () => {
        clearTimeout(warningTimer.current);
        warningTimer.current = undefined;
    };

    useEffect(() => {
        return clearWarningTimer;
    }, []);

    const closeModal = () => {
        setIsOpen(false);
    };

    const onDismiss = () => {
        closeModal();
        clearWarningTimer();

        if (isLoggedIn && sessionKey) {
            dispatch(keepAliveSession(sessionKey));
        }
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const logout = () => {
        closeModal();
        clearWarningTimer();

        history.push(LOGOUT_URL);
    };

    const onIdle = () => {
        if (isLoggedIn && !isOpen && !isLoggingOut) {
            openModal();
            if (!warningTimer.current) {
                warningTimer.current = setTimeout(() => {
                    setIsLoggingOut(true);
                    logout();
                }, WARNING_TIMEOUT);
            }
        }
    };

    /**
     * onBackgroundIdle is a fallback callback when the full idle- and warning time has been reached.
     * Apps in background mode will halt execution and not run setTimeout in the regular "onIdle"-callback.
     * Resulting in not automatically logging out while in background mode in apps, and allowing users to dismiss modal and stay logged in.
     * This method ensures we will log out while app is in background and not let customer have a choice of staying logged in when full time has been reached and app re-opens.
     */
    const onBackgroundIdle = () => {
        if (isLoggedIn && !isLoggingOut) {
            setIsLoggingOut(true);
            logout();
        }
    };

    if (!isOpen) return <Wrapper onIdle={onIdle} onBackgroundIdle={onBackgroundIdle} />;

    return (
        <Wrapper onIdle={onIdle} onBackgroundIdle={onBackgroundIdle}>
            <Dialog maxWidth="532px" open={isOpen} onRequestClose={onDismiss}>
                <DialogHeader ariaLabelClose={i18n('general.close')}>{i18n('general.inactivity.header')}</DialogHeader>
                <DialogBody centeredContent>{i18n('general.inactivity.text', 'p')}</DialogBody>
                <DialogFooter alignCenter>
                    <ButtonGroup alignCenter>
                        <Button outline round onClick={logout}>
                            {i18n('general.inactivity.logout')}
                        </Button>
                        <Button round onClick={onDismiss}>
                            {i18n('general.inactivity.continue')}
                        </Button>
                    </ButtonGroup>
                </DialogFooter>
            </Dialog>
        </Wrapper>
    );
};

export default IdleDetectionDialog;
