import classNames from 'classnames';
import { Link } from '@ecster/components';
import PropTypes from 'prop-types';
import React from 'react';

import './SubMenuItem.scss';

export const SubMenuItem = ({ id, children, linkTo, iconClass, active, tabIndex, badge }) => {
    const icon = iconClass ? <i className={`e-green ${iconClass}`} /> : undefined;

    return (
        <div
            className={classNames({
                'submenu-item': true,
                active,
            })}
        >
            <Link id={id} to={linkTo} tabIndex={tabIndex} noUnderlineOnFocus black>
                <span className="submenu-item__text">{children}</span>
                {icon}
                {badge}
            </Link>
        </div>
    );
};

SubMenuItem.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node, // Link text
    iconClass: PropTypes.string,
    linkTo: PropTypes.string,
    active: PropTypes.bool,
    tabIndex: PropTypes.number.isRequired,
    badge: PropTypes.node,
};

SubMenuItem.defaultProps = {
    id: undefined,
    children: '',
    iconClass: 'icon-chevron-right',
    linkTo: '',
    active: false,
    badge: undefined,
};
