import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { renderThisCountry } from '../common/i18n/renderThisCountry';
import { LoginHelp as LoginHelpSE } from './SE/LoginHelp';
import { LoginHelpFIFI, LoginHelpSVFI } from './FI/LoginHelp';
import LoginHelpFooter from './LoginHelpFooter';
import MessagePanel from '../common/MessagePanel';
import { LoginResponsivePanel } from './components/LoginResponsivePanel';
import { LoginPageTemplate } from '../common';

const { EcsterConfig: conf } = window;

const Page = ({ children }) => (
    <div className="login-help-page">
        <LoginPageTemplate lazy={false}>
            <LoginResponsivePanel fullscreenInMobile maxWidth="700px">
                <div className="header">
                    <IconButton
                        ariaLabel={i18n('general.close')}
                        name="close-button"
                        id="close-button"
                        icon="icon-x"
                        onClick={() => (window.location.href = '#/start')}
                    />
                </div>
                {children}
                <MessagePanel />
            </LoginResponsivePanel>
        </LoginPageTemplate>
    </div>
);

Page.propTypes = { children: PropTypes.node.isRequired };

class LoginHelpPage extends Component {
    renderSE() {
        return (
            <Page>
                <LoginHelpSE footer={<LoginHelpFooter />} />
            </Page>
        );
    }

    renderFI() {
        if (conf.language === 'fi') {
            return (
                <Page>
                    <LoginHelpFIFI footer={<LoginHelpFooter />} />
                </Page>
            );
        }
        if (conf.language === 'sv') {
            return (
                <Page>
                    <LoginHelpSVFI footer={<LoginHelpFooter />} />
                </Page>
            );
        }
        return null;
    }

    render() {
        return renderThisCountry({ renderSE: this.renderSE, renderFI: this.renderFI });
    }
}

export default LoginHelpPage;
