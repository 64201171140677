import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './LabelValue.scss';

interface LabelValueProps {
    /**
     * Extra classes for label value container
     */
    className?: string;

    /**
     * Extra css classes for value
     */
    classNameValue?: string;

    /**
     * Use stacked layout, label above value
     */
    stacked?: boolean;

    /**
     * Left align in stacked layout. Prop has no meaning in default layout
     */
    left?: boolean;

    /**
     * Right align in stacked layout. Prop has no meaning in default layout
     */
    right?: boolean;

    /**
     * CSS Id
     */
    id?: string;

    /**
     * The label
     */
    label: string | ReactNode;

    /**
     * The value
     */
    value: string | number | ReactNode;

    /**
     * @deprecated not used anymore, value is bold by default
     */
    strong?: boolean;

    /**
     * @deprecated not used anymore, value is bold by default
     */
    strongValue?: boolean;

    /**
     * @deprecated not used anymore, label is "weak" (not bold) by default
     */
    weakLabel?: boolean;

    /**
     * Label and value with green color
     */
    green?: boolean;

    /**
     * Don't render this when true
     */
    isNotVisible?: boolean;

    /**
     * Label value can wrap
     *  (Default white-space: nowrap;)
     */
    shouldWrap?: boolean;
}

const LabelValue = ({
    label,
    value,
    className = '',
    id = null,
    classNameValue = '',
    stacked = false,
    left = true,
    right = false,
    green = false,
    isNotVisible = false,
    shouldWrap = false,
}: LabelValueProps) => {
    const classes = classNames({
        'ec-label-value': true,
        'ec-lv-green': green,
        'ec-stacked': stacked,
        'ec-align-left': stacked && left && !right,
        'ec-align-right': stacked && right,
        [className]: className,
    });

    const labelClasses = classNames({
        'ec-lv-label': true,
    });

    const valueClasses = classNames({
        'ec-lv-value': true,
        'ec-should-wrap': shouldWrap,
        [classNameValue]: classNameValue,
    });

    if (isNotVisible) {
        return null;
    }

    return (
        <span id={id} className={classes}>
            <span className={labelClasses}>{label}</span>
            <span className={valueClasses}>{value}</span>
        </span>
    );
};

export default LabelValue;
