import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, Message, Panel, ResponsivePanel } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { NextPaymentPanel } from './NextPaymentPanel';
import { OverviewPanelHeader } from '../../common';
import { getAccountBillOverview } from '../redux/actions';

import './AccountPanel.scss';
import {
    LinkToAccountInvoices,
    LinkToAccountInvoice,
    LinkToAccountTerms,
    LinkToTransactions,
} from '../account-links/Links';
import { isFI, isSE } from '../../../common/country';
import { PaymentDialog } from '../PaymentDialog';
import { isValidSurplusAmount } from '../../../common/util/is-valid-surplus-amount';
import { formatAmount } from '@ecster/util';

class AccountPanelTerminatedAccount extends Component {
    state = {
        isPaymentDialogOpen: false,
    };

    componentDidMount() {
        const { getAccountBill, user, account } = this.props;
        getAccountBill(user.id, account.reference);
    }

    onStartRefund = e => {
        e.preventDefault() && e.stopPropagation();
        this.setState({ isPaymentDialogOpen: true });
    };

    onPaymentDialogRequestClose = () => {
        this.setState({ isPaymentDialogOpen: false });
    };

    render() {
        const {
            className,
            account,
            account: { limit, used, accountNumber, overDrawn },
            bill,
        } = this.props;

        const { isPaymentDialogOpen } = this.state;

        const classes = classNames({
            'account-panel': true,
            'terminated-account-panel': true,
            [className]: className,
        });

        // overDrawn is only applicable for FI
        const isOverdrawn = overDrawn || limit - used <= -500 * 100; // compare in "öre"
        const hasDebt = used > 0;
        const hasSurplusBalance = used < -100; // Överskott under 1kr räknas ej som överskott

        const amountLabel = hasSurplusBalance
            ? i18n('account.terminated-account.label-refundable')
            : i18n('account.terminated-account.label-debt');

        let message;
        let header;

        switch (true) {
            case hasDebt && isOverdrawn:
                header = i18n('account.terminate.overdrawn.header');
                message = i18n('account.terminate.overdrawn.info', 'p', {
                    amount: formatAmount(used - limit, {
                        strip00: true,
                    }),
                    accountNumber,
                });
                break;
            case hasDebt:
                header = i18n('account.terminated-account.message-header');
                message = i18n('account.terminated-account.debt-info', 'p', { danger: true });
                break;
            case hasSurplusBalance:
                header = i18n('account.terminated-account.message-header-refundable');
                message = (
                    <>
                        {i18n('account.terminated-account.refundable-info', 'p', {
                            danger: true,
                            link: 'https://scrive.com',
                        })}
                        {!isValidSurplusAmount(-account.used) &&
                            i18n('account.terminated-account.refundable-info-exceeded-limit', {
                                danger: true,
                            })}
                    </>
                );
                break;
            default:
                header = i18n('account.terminated-account.message-header');
                message = <p>{i18n('account.terminated-account.terminated-info')}</p>;
                break;
        }

        return (
            <Panel paddingTopBottom="small" className={classes}>
                <OverviewPanelHeader
                    header={account.product.name}
                    amount1={Math.abs(account.used)}
                    amount1Label={amountLabel}
                />
                <Message
                    className="mt-3x"
                    blue={!hasDebt && !isOverdrawn}
                    purple={hasDebt || isOverdrawn}
                    header={header}
                >
                    <>
                        {message}
                        {hasSurplusBalance && isSE() && isValidSurplusAmount(-account.used) && (
                            <Link href="#" underline blue onClick={this.onStartRefund}>
                                {i18n('account.terminated-account.refundable-link-text')}
                            </Link>
                        )}
                    </>
                </Message>
                <ResponsivePanel separator className="account-panel-content" reverseInMobile>
                    {hasDebt ? (
                        <NextPaymentPanel account={account} bill={bill} bg={i18n('general.bgNumber.account')} />
                    ) : (
                        <div />
                    )}
                    <div className="arrow-links-panel">
                        <LinkToTransactions accountRef={account.reference} />
                        {hasDebt && isSE() && <LinkToAccountInvoice accountRef={account.reference} />}
                        {hasDebt && isFI() && <LinkToAccountInvoices accountRef={account.reference} />}

                        <LinkToAccountTerms accountRef={account.reference} />
                    </div>
                </ResponsivePanel>
                {isSE() && (
                    <PaymentDialog
                        account={account}
                        isOpen={isPaymentDialogOpen}
                        onRequestClose={this.onPaymentDialogRequestClose}
                    />
                )}
            </Panel>
        );
    }
}

AccountPanelTerminatedAccount.propTypes = {
    className: PropTypes.string,
    account: PropTypes.shape().isRequired,
    bill: PropTypes.shape(),
    getAccountBill: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
};

AccountPanelTerminatedAccount.defaultProps = {
    className: '',
    bill: {},
};

/* istanbul ignore next */
function mapStateToProps({ account }, ownProps) {
    return {
        bill: account.accountBillOverview[ownProps.account.reference],
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getAccountBill: (customerId, reference) => dispatch(getAccountBillOverview(customerId, reference)),
    };
}

export { AccountPanelTerminatedAccount as Component };

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanelTerminatedAccount);
