import React, { ReactNode, ComponentType } from 'react';
import PropTypes from 'prop-types';
import { RRContext } from './RRContext';

interface RRProviderProps {
    /**
     * react-router-dom Link component.
     * ex: Link={Link}
     */
    Link: ComponentType;
    /**
     * react-router-dom location object
     */
    location: object;
    /**
     * react-router-dom matchPath function
     */
    matchPath: Function;
    children: ReactNode;
}

export const RRProvider = ({ Link, location, matchPath, children }: RRProviderProps) => (
    <RRContext.Provider value={{ Link, location, matchPath }}>{children}</RRContext.Provider>
);

RRProvider.propTypes = {
    /**
     * react-router-dom Link component
     */
    Link: PropTypes.elementType.isRequired,
    /**
     * react-router-dom location object
     */
    location: PropTypes.shape({}),
    /**
     * react-router-dom matchPath function
     */
    matchPath: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};
