import React from 'react';
import { useMediaQueries } from './useMediaQueries';
import type { Media } from './useMediaQueries';

export type { Media };

export interface WithMediaProps {
    media: Media;
}

function withMediaQueries<T>(Component: React.ComponentType<T & WithMediaProps>) {
    const ComponentWithMedia = (props: T) => {
        const media = useMediaQueries();
        return <Component media={media} {...props} />;
    };

    return ComponentWithMedia;
}

export default withMediaQueries;

