// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html
import { ManageCardPageSe, ManageCardPageFi } from '.';

import { isSE } from '../../common/country';
import dayjs from 'dayjs';

const seRoutes = isSE() ? [{ path: ':accountRef', name: 'Manage card page se', component: ManageCardPageSe }] : [];

const fiRoutes = [];

if (dayjs().isBefore('2024-05-01 00:00')) {
    fiRoutes.push({ path: ':accountRef', name: 'Manage card page fi', component: ManageCardPageFi });
}

const routes = {
    path: 'card',
    name: 'Card',
    childRoutes: [...seRoutes, ...fiRoutes],
};

export default routes;
