// @ts-nocheck
import React, { Component, CSSProperties } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RadioGroupContext } from './RadioGroupContext';
import './Radio.scss';

interface RadioProps {
    className?: string;
    id?: string;
    label?: string;
    name?: string;
    onClick?: Function;
    value: boolean | string | number;

    /** @deprecated, use class ec-radio and css instead */
    style?: CSSProperties;
}

export class Radio extends Component<RadioProps> {
    static contextType = RadioGroupContext;

    radioButton = React.createRef();

    onClick = e => {
        const { onClick } = this.props;

        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    onChange = (previousValue, event) => {
        const { value } = this.props;

        if (value !== previousValue) {
            const { onChange } = this.context;

            if (typeof onChange === 'function') {
                onChange(value, event);
            }
        }
    };

    render() {
        const { label, name, className, style, value, disabled, ...rest } = this.props;
        const { selectedValue } = this.context;
        const checked = selectedValue !== undefined && value === selectedValue;

        const classes = classNames({
            'ec-radio': true,
            [className]: className,
        });

        return (
            <div className={classes} style={style} ref={this.radioButton}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
                <label>
                    <input
                        {...rest}
                        aria-checked={checked}
                        type="radio"
                        name={name}
                        value={value}
                        checked={checked}
                        onClick={this.onClick}
                        disabled={disabled}
                        onChange={event => this.onChange(selectedValue, event)}
                    />
                    <span className={`ec-radio__circle ${disabled && 'disabled'}`}>
                        <span className="ec-radio__dot" />
                    </span>
                    <span className={`ec-radio__label ${disabled && 'disabled'}`}>{label}</span>
                </label>
            </div>
        );
    }
}

Radio.propTypes = {
    className: PropTypes.string,

    /**
     * An optional label to use for the onClick GA event.
     *
     * If not present - the button's name prop, inner text or id prop is used
     */
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,

    /** @deprecated, use class ec-radio and css instead */
    style: PropTypes.object,
};

Radio.defaultProps = {
    className: '',
    id: '',
    label: '',
    name: '',
    onClick: () => {},
    style: {}, // @deprecated
};
