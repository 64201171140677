import { post } from '@ecster/net/v2';
import {
    CONTRACT_CREATE_REPAYMENT_INQUIRY_BEGIN,
    CONTRACT_CREATE_REPAYMENT_INQUIRY_SUCCESS,
    CONTRACT_CREATE_REPAYMENT_INQUIRY_FAILURE,
    CONTRACT_CREATE_REPAYMENT_INQUIRY_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_CREATE_REPAYMENT_INQUIRY_STATE = 'CLEAR_CREATE_REPAYMENT_INQUIRY_STATE';

const CREATE_REPAYMENT_INQUIRY_URL = (customerId, contractNumber) =>
    `/rest/ess/customers/v1/${customerId}/contracts/${contractNumber}/repayment`;

export const createRepaymentInquiry = (customerId, contractNumber, data) => async dispatch => {
    dispatch({
        type: CONTRACT_CREATE_REPAYMENT_INQUIRY_BEGIN,
    });

    try {
        await cache.clear();
        await post(CREATE_REPAYMENT_INQUIRY_URL(customerId, contractNumber), data);

        dispatch({
            type: CONTRACT_CREATE_REPAYMENT_INQUIRY_SUCCESS,
        });
    } catch (err) {
        reportError(err, 'createRepaymentInquiry');
        dispatch({
            type: CONTRACT_CREATE_REPAYMENT_INQUIRY_FAILURE,
            data: {
                error: {
                    message: err,
                    action: CONTRACT_CREATE_REPAYMENT_INQUIRY_FAILURE,
                },
            },
        });
    }
};

export const dismissCreateRepaymentInquiryError = () => ({ type: CONTRACT_CREATE_REPAYMENT_INQUIRY_DISMISS_ERROR });

export const clearCreateRepaymentInquiryState = () => ({ type: CLEAR_CREATE_REPAYMENT_INQUIRY_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CONTRACT_CREATE_REPAYMENT_INQUIRY_BEGIN:
            return {
                ...state,
                createRepaymentInquiryPending: true,
                createRepaymentInquiryError: null,
                createRepaymentInquiryIsDone: false,
                createRepaymentInquiryIsError: false,
            };

        case CONTRACT_CREATE_REPAYMENT_INQUIRY_SUCCESS:
            return {
                ...state,
                createRepaymentInquiryPending: false,
                createRepaymentInquiryError: null,
                createRepaymentInquiryIsDone: true,
            };

        case CLEAR_CREATE_REPAYMENT_INQUIRY_STATE:
            return {
                ...state,
                createRepaymentInquiryPending: false,
                createRepaymentInquiryError: null,
                createRepaymentInquiryIsDone: false,
                createRepaymentInquiryIsError: false,
            };

        case CONTRACT_CREATE_REPAYMENT_INQUIRY_FAILURE:
            return {
                ...state,
                createRepaymentInquiryPending: false,
                createRepaymentInquiryError: action.data.error,
                createRepaymentInquiryIsError: true,
            };

        case CONTRACT_CREATE_REPAYMENT_INQUIRY_DISMISS_ERROR:
            return {
                ...state,
                createRepaymentInquiryError: null,
                createRepaymentInquiryIsError: false,
            };

        default:
            return state;
    }
}
