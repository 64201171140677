import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Panel,
    PanelContent,
    TableBody as TBody,
    Table,
    TableRow as Tr,
    TableRowColumn as Td,
    Mobile,
    TabletOrDesktop,
} from '@ecster/components';

import { formatAmount } from '../../../common/util/format-amount';

const getIcon = icon => {
    const classes = classNames({
        'invoice-icon-ctr': true,
        'has-icon': icon,
    });
    return <div className={classes}>{typeof icon === 'string' ? <img src={icon} alt="" /> : icon}</div>;
};

export default class InvoicePanelV2 extends React.Component {
    static propTypes = {
        invoices: PropTypes.arrayOf(PropTypes.object),
        header: PropTypes.string,
        headerLeft: PropTypes.string,
        headerRight: PropTypes.string,
    };

    static defaultProps = {
        invoices: [],
        header: undefined,
        headerLeft: '',
        headerRight: '',
    };

    state = {};

    onClickInvoice = invoice => {
        if (invoice.url) {
            window.location.hash = invoice.url;
        }
    };

    render() {
        const { invoices, header, headerLeft, headerRight } = this.props;

        if (!invoices || invoices.length === 0) return null;

        return (
            <Panel paddingAll="small" className="invoice-panel">
                <PanelContent fullWidth>
                    {header && (
                        <div className="invoice-panel-header">
                            <h2 className="h3">{header}</h2>
                        </div>
                    )}
                    {(headerLeft || headerRight) && (
                        <div className="invoice-panel-header-left-right-container">
                            <h4 className="left">{headerLeft}</h4>
                            <span className="right">{headerRight}</span>
                        </div>
                    )}
                    <Mobile>
                        <Table large>
                            <TBody>
                                {invoices.map((invoice, index) => (
                                    <Tr
                                        id={`invoice-${index + 1}`}
                                        key={invoice.invoiceNumber}
                                        onClick={() => this.onClickInvoice(invoice)}
                                    >
                                        <Td>
                                            <div className="flex flex-row justify-start">
                                                {getIcon(invoice.icon)}
                                                <div className="flex flex-col">
                                                    <div>{invoice.description}</div>
                                                    <div
                                                        className={`${
                                                            invoice.statusText.isHighlighted ? 'e-purple' : ''
                                                        }`}
                                                    >
                                                        {invoice.statusText.text}
                                                    </div>
                                                </div>
                                            </div>
                                        </Td>
                                        <Td alignRight>
                                            <div className="flex flex-row justify-end">
                                                {formatAmount(invoice.amount)}
                                                <i className="icon-chevron-right" />
                                            </div>
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    </Mobile>

                    <TabletOrDesktop>
                        <Table>
                            <TBody>
                                {invoices.map((invoice, index) => (
                                    <Tr
                                        id={`invoice-${index + 1}`}
                                        key={invoice.invoiceNumber}
                                        onClick={() => this.onClickInvoice(invoice)}
                                    >
                                        <Td className="invoice-table-row-col">
                                            <div className="invoice-icon-descr">
                                                {getIcon(invoice.icon)} {invoice.description}
                                            </div>
                                        </Td>
                                        <Td
                                            className={`invoice-table-row-col ${
                                                invoice.statusText.isHighlighted ? 'e-purple' : ''
                                            }`}
                                        >
                                            {invoice.statusText.text}
                                        </Td>
                                        <Td className="invoice-table-row-col" alignRight>
                                            {formatAmount(invoice.amount)}
                                            <i className="icon-chevron-right" />
                                        </Td>
                                    </Tr>
                                ))}
                            </TBody>
                        </Table>
                    </TabletOrDesktop>
                </PanelContent>
            </Panel>
        );
    }
}
