import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
// import { routerMiddleware } from 'react-router-redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { createHashHistory } from 'history';
import rootReducer from './rootReducer';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
};

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createHashHistory(),
});

// NOTE: Do not change middlewares declaration pattern since rekit plugins may register middlewares to it.
const middlewares = [thunk, routerMiddleware];

let devToolsExtension = f => f;

/* istanbul ignore if  */
if (
    window.EcsterConfig.env === 'dev' ||
    window.EcsterConfig.env === 'development' ||
    window.EcsterConfig.env === 'test'
) {
    if (window.EcsterConfig.hasReduxLogging !== false) {
        import('redux-logger').then(({ createLogger }) => {
            const logger = createLogger({ collapsed: true });
            middlewares.push(logger);
        });
    }

    // eslint-disable-next-line no-underscore-dangle
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        // eslint-disable-next-line no-underscore-dangle
        devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) || compose;
    }
}

rootReducer.router = routerReducer;

const pReducer = persistReducer(persistConfig, combineReducers(rootReducer));

const store = createStore(pReducer, compose(applyMiddleware(...middlewares), devToolsExtension));

export const history = createReduxHistory(store);

// Don't remove this as this is what actually persists changes to disk.
// We can also utilize this to only render the GUI once the store is available:
// https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
export const persistor = persistStore(store);

export default function configureStore() {
    // Don't to hot reloading here - it's not supported for stores anyway
    return store;
}
