import React, { Component } from 'react';

import { renderThisCountry } from '../common/i18n/renderThisCountry';
import ContactUsInfoFI from './FI/ContactUsInfoFI';

export default class ContactUsInfo extends Component {
    // eslint-disable-next-line react/no-unused-class-component-methods
    renderFI() {
        return this && <ContactUsInfoFI />;
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderSE() {
        return null;
    }

    render() {
        return renderThisCountry(this);
    }
}
