import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Only does ScrollToTop only once when the location changes
 * Has to be within a react router block, like HashRouter.
 */
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;
