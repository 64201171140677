/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { ReactChild, ReactNode } from 'react';
import classNames from 'classnames';

import './ResponsivePanel.scss';
import { useMediaQueries } from '../MediaQuery/useMediaQueries';

interface ResponsivePanelProps {
    /**
     * Child elements of the Panel.
     */
    children: ReactNode;

    /**
     * Any extra classNames to add to the panel container element
     * */
    className?: string;

    /**
     * Number of columns in desktop mode.
     *
     * **Default** value is number of child elements
     */
    desktop?: number;

    /**
     * Number of columns in tablet mode.
     *
     * **Default** value is number of child elements
     */
    tablet?: number;

    /**
     * Number of columns in mobile mode.
     * @default 1
     */
    mobile?: number;

    /**
     * Shorthand for both verticalLines and horizontalLines
     */
    separator?: boolean;

    /**
     * Vertical separating lines when elements line up side by side.
     */
    verticalLines?: boolean;

    /**
     * Horizontal separating lines when elements stack on top of each other.
     */
    horizontalLines?: boolean;

    /**
     * Set green color on separating lines. To be used inside <Panel greenBG ...>
     */
    greenLines?: boolean;

    /**
     * Set purple color on separating lines. To be used inside <Panel purpleBG ...>
     */
    purpleLines?: boolean;

    /**
     * Set blue color on separating lines. To be used inside <Panel blueBG ...>
     */
    blueLines?: boolean;

    /**
     * Set yellow color on separating lines. To be used inside <Panel yellowBG ...>
     */
    yellowLines?: boolean;

    /**
     * Set beige color on separating lines. To be used inside <Panel beigeBG ...>
     */
    beigeLines?: boolean;

    /**
     * Set red color on separating lines. To be used inside <Panel redBG ...>
     */
    redLines?: boolean;

    /**
     * Reverse the order of child elements in mobile.
     *
     * Desktop / tablet

     first | second | third

     * Mobile

     third
     ------
     second
     ------
     first

     * How to reverse order in desktop and tablet?
     *
     * Add elements in reverse order and *reverseInMobile* them!
     */
    reverseInMobile?: boolean;

    /**
     * How to align column items. Need different values for mobile and desktop? Detect in app, send different values in property.
     *
     * Valid values: see https://css-tricks.com/snippets/css/a-guide-to-flexbox/#prop-align-items
     */
    alignItems?: string;
}

const ResponsivePanel = ({
    mobile = 1,
    tablet,
    desktop,
    className,
    children,
    reverseInMobile,
    verticalLines,
    horizontalLines,
    separator,
    alignItems = 'stretch',
    greenLines,
    purpleLines,
    blueLines,
    yellowLines,
    redLines,
    beigeLines,
}: ResponsivePanelProps) => {
    const media = useMediaQueries();
    // filter away empty columns, e.g columns rendered with react conditional
    const columns = (children as ReactChild[]).filter(child => !!child);
    // number of elements per row
    const elementsPerRow =
        (media.onMobile && mobile) || (media.onTablet && tablet) || (media.onDesktop && desktop) || columns.length;

    const classes = classNames(
        {
            'ec-responsive-panel': true,
            'ec-responsive-panel2': true, // backward compatible
            'reverse-in-mobile': reverseInMobile,
            'vertical-lines': verticalLines || separator,
            'horizontal-lines': horizontalLines || separator,
            'green-lines': greenLines,
            'purple-lines': purpleLines,
            'blue-lines': blueLines,
            'yellow-lines': yellowLines,
            'beige-lines': beigeLines,
            'red-lines': redLines,
            [`per-row-${elementsPerRow}`]: true,
        },
        className
    );

    const childClasses = classNames({
        'ec-responsive-panel-item': true,
        'reverse-in-mobile': reverseInMobile,
        [`per-row-${elementsPerRow}`]: true,
    });

    let firstInRow;
    let lastInRow;
    let onFirstRow;
    let onLastRow;

    const style = { alignItems };

    return (
        <div className={classes} style={style}>
            {columns.map((child, index) => {
                firstInRow = (index + 1) % elementsPerRow === 1 ? 'first-in-row' : '';
                lastInRow = (index + 1) % elementsPerRow === 0 ? 'last-in-row' : '';
                onFirstRow = index + 1 <= elementsPerRow ? 'on-first-row' : '';
                onLastRow = columns.length - (index + 1) < elementsPerRow ? 'on-last-row' : '';
                return (
                    <div
                        key={index}
                        className={`${childClasses} ${firstInRow} ${lastInRow} ${onLastRow}  ${onFirstRow}`}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    );
};

export default ResponsivePanel;
