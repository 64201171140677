/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-for */
/**
 * This component represents an individual item in the multi-select drop-down
 */
import React, { useRef } from 'react';

import { useKey } from '../hooks/use-key';
import { KEY } from '../lib/constants';
import { Option } from '../lib/interfaces';
import DefaultItemRenderer from './default-item';

interface ISelectItemProps {
    itemRenderer;
    option?: Option;
    checked?: boolean;
    noLabel?: boolean;
    tabIndex?: number;
    disabled?: boolean;
    name?: string;
    onSelectionChanged: (checked: boolean) => void;
    onClick;
}

const SelectItem = ({
    itemRenderer: ItemRenderer = DefaultItemRenderer,
    option,
    checked,
    tabIndex,
    disabled,
    noLabel,
    onSelectionChanged,
    onClick,
    name,
}: ISelectItemProps) => {
    const itemRef: any = useRef();

    const onOptionCheck = e => {
        toggleChecked();
        e.preventDefault();
    };

    const toggleChecked = () => {
        if (!disabled) {
            onSelectionChanged(!checked);
        }
    };

    const handleClick = e => {
        toggleChecked();
        onClick(e);
    };

    useKey([KEY.ENTER, KEY.SPACE], onOptionCheck, { target: itemRef });

    return (
        <label
            className={`select-item ${checked && 'selected'}`}
            role="option"
            aria-selected={checked}
            tabIndex={tabIndex}
            ref={itemRef}
        >
            <ItemRenderer
                name={name}
                noLabel={noLabel}
                option={option}
                checked={checked}
                onClick={handleClick}
                disabled={disabled}
            />
        </label>
    );
};

SelectItem.defaultProps = {
    checked: false,
    noLabel: false,
    tabIndex: 0,
    disabled: false,
    option: { value: '', label: ''}
};

export default SelectItem;
