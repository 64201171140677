// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { renderTableChildNodes } from './renderTableChildNodes';

import './TableBody.scss';

interface TableBodyProps {
    /** CSS id */
    id?: string;

    /** Child elements: [TableRow](#tablerow) */
    children: ReactNode;

    /** Additional css classes */
    className?: string;

    /** @deprecated use className and css instead */
    style?: CSSProperties;
}

const TableBody = ({ children, className, style, id }: TableBodyProps) => (
    <tbody id={id} className={`table-body ${className}`} style={style}>
        {renderTableChildNodes(children)}
    </tbody>
);

TableBody.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Child elements: [TableRow](#tablerow) */
    children: PropTypes.node.isRequired,

    /** Additional css classes */
    className: PropTypes.string,

    /** @deprecated use className and css instead */
    style: PropTypes.shape(),
};

TableBody.defaultProps = {
    id: '',
    className: '',
    style: {},
};

export default TableBody;
