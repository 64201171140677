// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { renderTableChildNodes } from './renderTableChildNodes';
import './TableHeader.scss';

interface TableHeaderProps {
    /** CSS id */
    id?: string;

    /** Child elements: [TableRow](#tablerow) */
    children: ReactNode;

    /** Additional css classes */
    className?: string;

    /** White bg and gray text */
    white?: boolean;

    /** Beige bg  */
    beige?: boolean;

    /** @deprecated use className and css instead */
    style?: CSSProperties;
}

const TableHeader = ({ children, className, style, id, white, beige }: TableHeaderProps) => (
    <thead
        id={id}
        className={`table-header ${className} ${white && 'table-header-white'} ${beige && 'table-header-beige'}`}
        style={style}
    >
        {renderTableChildNodes(children)}
    </thead>
);

TableHeader.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Child elements: [TableRow](#tablerow) */
    children: PropTypes.node.isRequired,

    /** Additional css classes */
    className: PropTypes.string,

    /** White bg and gray text */
    white: PropTypes.bool,

    /** Beige bg  */
    beige: PropTypes.bool,

    /** @deprecated use className and css instead */
    style: PropTypes.shape(),
};

TableHeader.defaultProps = {
    id: '',
    className: '',
    style: {},
    white: false,
    beige: false,
};

export default TableHeader;
