import React from 'react';
import PropTypes from 'prop-types';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { Button, ButtonGroup, Input, Form, PhoneNumberInput } from '@ecster/components';
import { validateMobilePhoneNumberSE, validateMobilePhoneNumberIncludingOldFormatsFI } from '@ecster/validation';
import { reduxActionSucceeded } from '@ecster/util';

import { isFI } from '../../../common/country';
import CustomerSettingsPanel from './FI/CustomerSettingsPanel';
import { getCustomer } from '../redux/getCustomer';
import storeValueForNameInState from '../../../common/util/store-value-for-name-in-state';
import ChangeLanguageContext from './FI/ChangeLanguageContext';

export default class EditContactInfo extends React.Component {
    static propTypes = {
        customer: PropTypes.object.isRequired,
        hasAccounts: PropTypes.bool.isRequired,
        leaveEditMode: PropTypes.func.isRequired,
        updateCustomerContactInfo: PropTypes.func.isRequired,
        hasEmail: PropTypes.bool,
        hasSMS: PropTypes.bool,

        // used by operationSucceeded
        updateCustomerContactInfoPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
        updateCustomerContactInfoError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    };

    static defaultProps = { updateCustomerContactInfoError: null, hasEmail: false, hasSMS: false };

    static contextType = ChangeLanguageContext;

    state = {
        email: '',
        phoneNumber: {},
    };

    formRef = React.createRef();

    phoneRef = React.createRef();

    emailRef = React.createRef();

    componentDidMount() {
        const {
            customer: { contactInformation },
            hasEmail,
            hasSMS,
        } = this.props;

        const phoneNumber = contactInformation.phoneNumber || {};

        const newState = {
            hasEmail,
            hasSMS,
            email: contactInformation.email,
            phoneNumber: {
                number: phoneNumber.number,
                countryCallingCode: isFI() ? '+358' : phoneNumber.countryCallingCode || '',
            },
        };
        // contactInformation.phoneNumber;

        this.setState(newState, () => {
            // this.formRef.current.validate();
        });

        // autofocus phone number input
        this.phoneRef.current?.phoneRef?.current?.inputFieldRef?.current?.focus();
    }

    componentDidUpdate(prevProps) {
        const { leaveEditMode } = this.props;
        if (reduxActionSucceeded('updateCustomerContactInfo', prevProps, this.props)) {
            leaveEditMode(true); // refresh customer info
        }
    }

    onChange = obj => {
        storeValueForNameInState(obj, this);
    };

    onPhoneChange = value => {
        this.setState({ phoneNumber: value });
    };

    onCancelClick = () => {
        const { leaveEditMode } = this.props;
        // todo: add check if user has edited fields?
        leaveEditMode();
    };

    onSubmitForm = e => {
        e.preventDefault(); // don't post form!
        this.onSave();
    };

    onSave = () => {
        const { hasAccounts, updateCustomerContactInfo, customer } = this.props;
        const { email, phoneNumber, hasEmail, hasSMS } = this.state;

        if (this.formRef.current.validate()) {
            updateCustomerContactInfo(customer.id, hasAccounts, {
                email,
                phoneNumber,
                marketing: {
                    sms: hasSMS,
                    email: hasEmail,
                },
            });
        }
    };

    phoneNumberValidator = () => {
        const {
            phoneNumber: { number, countryCallingCode },
        } = this.state;

        if (countryCallingCode === '+46') return validateMobilePhoneNumberSE(number);

        if (countryCallingCode === '+358') return validateMobilePhoneNumberIncludingOldFormatsFI(number);

        return /^\d{5,15}$/.test(number);
    };

    render() {
        const {
            customer,
            customer: { address, zip, city },
            hasAccounts,
            updateCustomerContactInfo,
            updateCustomerContactInfoPending,
            updateCustomerContactInfoError,
        } = this.props;
        const { email, phoneNumber, hasEmail, hasSMS } = this.state;

        return (
            <div className="customer-edit-contact-info">
                <div className="address">
                    <p>{i18n('general.address.address')}</p>
                    <p>
                        {address}
                        <br />
                        {zip} {city}
                    </p>
                </div>
                <p className="address-info">{i18n('customer.profile.edit-profile.address-info')}</p>

                <Form
                    ref={this.formRef}
                    className="edit-form"
                    validateRefs={[this.phoneRef, this.emailRef]}
                    onSubmit={this.onSubmitForm}
                >
                    <div className="input-inline">
                        <PhoneNumberInput
                            language={window.EcsterConfig.language}
                            hasNoCountryCode
                            ariaLabelCountryCode=""
                            ariaLabel={i18n('edit-contact-info.mobile')}
                            label={i18n('edit-contact-info.mobile')}
                            ref={this.phoneRef}
                            value={phoneNumber}
                            id="phoneNumber"
                            onChange={this.onPhoneChange}
                            countryCodeIsSelectable={!isFI()}
                            numberValidator={this.phoneNumberValidator}
                            validationMessagePhone={i18n('general.validation.phone')}
                            validationMessageCountryCode={i18n('general.validation.country-code')}
                            required
                        />
                    </div>

                    <div className="input-inline">
                        <Input
                            ref={this.emailRef}
                            value={email}
                            id="email"
                            name="email"
                            type="email"
                            label={i18n('general.address.email')}
                            onChange={this.onChange}
                            validationMessage={i18n('general.validation.email')}
                            required
                        />
                    </div>
                    {isFI() && (
                        <CustomerSettingsPanel
                            changeLanguage={this.context}
                            hasSMS={hasSMS}
                            hasEmail={hasEmail}
                            isEditMode
                            onChange={this.onChange}
                            customer={customer}
                            updateCustomerContactInfo={updateCustomerContactInfo}
                            updateCustomerContactInfoError={updateCustomerContactInfoError}
                            updateCustomerContactInfoPending={updateCustomerContactInfoPending}
                            getCustomer={() => getCustomer(customer.id, hasAccounts)}
                        />
                    )}
                    <ButtonGroup alignCenter>
                        <Button transparent onClick={this.onCancelClick}>
                            {i18n('general.cancel')}
                        </Button>
                        <Button
                            round
                            type="submit"
                            onClick={this.onSave}
                            ignoreClick={updateCustomerContactInfoPending}
                            spinnerRight={updateCustomerContactInfoPending}
                        >
                            {i18n('general.save')}
                        </Button>
                    </ButtonGroup>
                </Form>
            </div>
        );
    }
}
