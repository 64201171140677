// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Spinner.scss';

interface SpinnerProps {
    id: string;

    /**
     * Spinner radius (pixels)
     */
    radius?: number;

    /**
     * Stroke width (or thickness)
     */
    strokeWidth?: number;

    /**
     * Center vertically in parent container
     */
    isCenter?: boolean;

    /**
     * Center horizontally in parent
     */
    isCenterX?: boolean;

    /**
     * Center horizontally in window
     */
    isCenterPage?: boolean;

    /**
     * Visible or not?
     */
    isVisible?: boolean;

    /**
     * @deprecated
     */
    isFillParentHeight?: boolean;

    /**
     * Stroke color
     */
    strokeColor?: string;

    /**
     * Stroke background
     */
    strokeBgColor?: string;

    /**
     * Swap stroke and bg colors - use for Spinners on stroke colored backgrounds
     */
    swapColors?: boolean;
}

export const Spinner = (props: SpinnerProps) => {
    const {
        id,
        isVisible,
        isCenter,
        isCenterX,
        isCenterPage,
        isFillParentHeight,
        radius,
        strokeWidth,
        strokeColor,
        strokeBgColor,
        swapColors,
    } = props;

    const myStrokeColor = swapColors ? strokeBgColor : strokeColor;
    const myStrokeBgColor = swapColors ? strokeColor : strokeBgColor;

    // adjust actual radius so everything fits in the viewBox
    const thisRadius = strokeWidth > 11 ? radius - strokeWidth / 2 : radius;
    const containerStyle = isFillParentHeight ? {} : { width: `${radius * 2}px`, height: `${radius * 2}px` };

    return (
        isVisible && (
            <span
                id={`spinner__${id}`}
                className={classNames({
                    spinner: true, // todo: remove when not used
                    'ec-spinner': true,
                    'ec-spinner--center': isCenter,
                    'ec-spinner--center-x': isCenterX,
                    'ec-spinner--center-page': isCenterPage,
                    'ec-spinner--fill-parent-height': isFillParentHeight,
                })}
                style={containerStyle}
            >
                <svg
                    className="spinner__svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid meet"
                    width="100"
                    height="100"
                >
                    <title>Var vänlig vänta - nästa funktion laddas.</title>
                    <defs>
                        {/* Cuts the gradient short, so we get a solid color at the start of the spinner. */}
                        <clipPath id={`spinner__${id}-clip-path`}>
                            <rect x="0" y="50" width="100" height="100" />
                        </clipPath>

                        {/* The gradient in the spinner */}
                        <linearGradient id={`spinner__${id}-gradient`}>
                            <stop offset="0" stopColor={myStrokeColor} />
                            <stop offset="100%" stopColor={myStrokeColor} stopOpacity="0" />
                        </linearGradient>
                    </defs>
                    {/* This is the solid background ring */}
                    <circle
                        cx="50"
                        cy="50"
                        r={thisRadius}
                        stroke={myStrokeBgColor}
                        strokeWidth={strokeWidth}
                        fill="none"
                        opacity="1"
                    />
                    {/* This is the gradient part */}
                    <circle
                        cx="50"
                        cy="50"
                        r={thisRadius}
                        stroke={`url(#spinner__${id}-gradient)`}
                        strokeWidth={strokeWidth}
                        fill="none"
                        clipPath={`url(#spinner__${id}-clip-path)`}
                    />
                </svg>
            </span>
        )
    );
};

Spinner.propTypes = {
    id: PropTypes.string.isRequired,

    /**
     * Spinner radius (pixels)
     */
    radius: PropTypes.number,

    /**
     * Stroke width (or thickness)
     */
    strokeWidth: PropTypes.number,

    /**
     * Center vertically in parent container
     */
    isCenter: PropTypes.bool,

    /**
     * Center horizontally in parent
     */
    isCenterX: PropTypes.bool,

    /**
     * Center horizontally in window
     */
    isCenterPage: PropTypes.bool,

    /**
     * Visible or not?
     */
    isVisible: PropTypes.bool,

    /**
     * @deprecated
     */
    isFillParentHeight: PropTypes.bool,

    /**
     * Stroke color
     */
    strokeColor: PropTypes.string,

    /**
     * Stroke background
     */
    strokeBgColor: PropTypes.string,

    /**
     * Swap stroke and bg colors - use for Spinners on stroke colored backgrounds
     */
    swapColors: PropTypes.bool,
};

Spinner.defaultProps = {
    radius: 32,
    strokeWidth: 8,
    isCenter: false,
    isCenterX: false,
    isCenterPage: false,
    isVisible: false,
    isFillParentHeight: false,
    //    bgColor: undefined,
    strokeColor: '#59b189',
    strokeBgColor: '#eff8f3',
    swapColors: false,
};

export default Spinner;
