import React from 'react';
import PropTypes from 'prop-types';
import { Mobile, TableRow as Tr, TableRowColumn as Td, TabletOrDesktop } from '@ecster/components';
import { formatDateShort } from '../../../common/util/format-date';
import { CurrencyInfo } from './CurrencyInfo';
import { Amount } from './Amount';

import './TransactionsTableRow.scss';

const capitalizeUpperCase = string => {
    return string && string === string.toUpperCase() ? (
        <span className="capitalize">{string.toLowerCase()}</span>
    ) : (
        string
    );
};

export const TransactionsTableRow = ({ trans, id, accountHolderInfo }) => {
    return (
        <Tr id={id} className={`tx-row ${trans.type && trans.type.replace(/_/g, '-').toLowerCase()}`}>
            <TabletOrDesktop>
                <Td className="tx-date-cell">
                    <span className="tx-date">{formatDateShort(trans.date)}</span>
                </Td>
                <Td className="tx-description-cell"> {capitalizeUpperCase(trans.description)}</Td>
                <Td className="tx-amount-cell">
                    <div className="flex flex-col">
                        <CurrencyInfo trans={trans} />
                        <Amount trans={trans} />
                    </div>
                </Td>
                {accountHolderInfo && <Td className="tx-account-holder-cell">{accountHolderInfo}</Td>}
            </TabletOrDesktop>

            <Mobile>
                <Td>
                    <div className="tx-description">{capitalizeUpperCase(trans.description)}</div>
                    <div className="tx-date"> {formatDateShort(trans.date)}</div>
                </Td>
                <Td>
                    <div className="flex flex-col">
                        <Amount trans={trans} />
                        <CurrencyInfo trans={trans} />
                        {accountHolderInfo}
                    </div>
                </Td>
            </Mobile>
        </Tr>
    );
};

TransactionsTableRow.propTypes = {
    trans: PropTypes.object.isRequired,
    id: PropTypes.string,
    accountHolderInfo: PropTypes.node,
};
TransactionsTableRow.defaultProps = {
    id: '',
    accountHolderInfo: null,
};
