import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button,
    ButtonGroup,
    Form,
    IconButton,
    Input,
    Panel,
    PanelContent,
    PhoneNumberInput,
    TabletOrDesktop,
} from '@ecster/components';
import { contactInfoIcon } from '@ecster/icons/H40/purple';
import { i18n } from '@ecster/i18n';
import { validateMobilePhoneNumberIncludingOldFormatsFI, validateMobilePhoneNumberSE } from '@ecster/validation';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';

import { renderThisCountry } from '../../common/i18n/renderThisCountry';
import './ContactInfoAlert.scss';
import { isFI } from '../../../common/country';
import {
    getCustomer,
    updateCustomerContactInfo,
    clearUpdateCustomerContactInfoState,
} from '../../customer/redux/actions';

class ContactInfoAlert extends Component {
    formRef = React.createRef();

    phoneRef = React.createRef();

    emailRef = React.createRef();

    constructor(props) {
        super(props);
        const { contactInformation } = this.props;

        const phoneNumber = contactInformation.phoneNumber || {};

        if (!phoneNumber.countryCallingCode) {
            phoneNumber.countryCallingCode = isFI() ? '+358' : '+46';
        }

        this.state = {
            saveSuccess: false,
            saveFail: false,
            alertClosed: false,
            phoneNumber,
            email: contactInformation.email,
        };
    }

    componentDidUpdate(prevProps) {
        const { getCustomer, customerId, contactInformation } = this.props;
        if (reduxActionSucceeded('updateCustomerContactInfo', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ saveSuccess: true });
            getCustomer(customerId);
        }

        if (reduxActionFailed('updateCustomerContactInfo', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ saveFail: true });
        }

        // getCustomer called from OverviewPage
        if (reduxActionSucceeded('getCustomer', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                phoneNumber: contactInformation.phoneNumber || {},
                email: contactInformation.email,
            });
        }
    }

    componentWillUnmount() {
        const { clearUpdateCustomerContactInfoState } = this.props;

        clearUpdateCustomerContactInfoState();
    }

    onEmailChange = ({ target }) => {
        this.setState({ email: target.value });
    };

    onPhoneChange = value => {
        this.setState({ phoneNumber: value });
    };

    onSave = () => {
        const { customerId, updateCustomerContactInfo } = this.props;
        const { email, phoneNumber } = this.state;

        if (this.formRef.current.validate()) {
            updateCustomerContactInfo(customerId, {
                email,
                phoneNumber,
            });
        }
    };

    onSubmitForm = e => {
        e.preventDefault(); // don't submit html form if user hits enter
        this.onSave();
    };

    onCloseAlert = () => {
        this.setState({ alertClosed: true });
    };

    // prepare for diff in Finland
    // eslint-disable-next-line react/no-unused-class-component-methods
    renderFI() {
        return null;
    }

    phoneNumberValidator = () => {
        const {
            phoneNumber: { number, countryCallingCode },
        } = this.state;

        if (countryCallingCode === '+46') return validateMobilePhoneNumberSE(number);

        if (countryCallingCode === '+358') return validateMobilePhoneNumberIncludingOldFormatsFI(number);

        return /^\d{5,15}$/.test(number);
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderSE() {
        const { saveSuccess, saveFail, phoneNumber, email, alertClosed } = this.state;
        const { showAlert } = this.props;

        if (alertClosed || (!showAlert && !saveSuccess && !saveFail)) return null;

        return (
            <Panel paddingAll="large" className="contact-info-alert">
                {saveSuccess ? (
                    <PanelContent fullWidth className="mb-8x contact-info-saved" centered>
                        <IconButton
                            onClick={this.onCloseAlert}
                            icon="icon-cross"
                            className="close-button"
                            id="close-profile-alert"
                        />
                        <h2>{i18n('customer.profile.reminder.success.header')}</h2>
                        <p className="e-green mtb-6x">
                            <i className="icon-check-circle"> </i>
                            <strong>{i18n('customer.profile.reminder.success.saved')}</strong>
                        </p>
                        <p>
                            {i18n('customer.profile.reminder.success.info', {
                                danger: true,
                                profileLink: `#/customer/profile`,
                            })}
                        </p>
                    </PanelContent>
                ) : (
                    <>
                        <PanelContent fullWidth className="mb-8x">
                            <div className="flex justify-start items-center">
                                <TabletOrDesktop>
                                    <img src={contactInfoIcon} alt="" className="contact-info-icon" />
                                </TabletOrDesktop>
                                <div>
                                    <h2 className="h4">{i18n('customer.profile.reminder.header')}</h2>
                                    <p>{i18n('customer.profile.reminder.info')}</p>
                                </div>
                            </div>
                        </PanelContent>
                        <PanelContent narrow>
                            <Form
                                ref={this.formRef}
                                className="edit-form"
                                validateRefs={[this.phoneRef, this.emailRef]}
                                onSubmit={this.onSubmitForm}
                            >
                                <PhoneNumberInput
                                    ref={this.phoneRef}
                                    value={phoneNumber}
                                    label={i18n('general.address.mobile')}
                                    onChange={this.onPhoneChange}
                                    countryCodeIsSelectable={!isFI()}
                                    numberValidator={this.phoneNumberValidator}
                                    validationMessage={i18n('general.validation.phone')}
                                    validationMessageCountryCode={i18n('general.validation.country-code')}
                                    required
                                />

                                <Input
                                    ref={this.emailRef}
                                    value={email}
                                    type="email"
                                    label={i18n('general.address.email')}
                                    onChange={this.onEmailChange}
                                    validationMessage={i18n('general.validation.email')}
                                    required
                                    className="mb-4x"
                                />

                                {saveFail && (
                                    <p className="e-purple">
                                        <i className="icon-alert-triangle"> </i>
                                        {i18n('customer.profile.reminder.save-failed')}
                                    </p>
                                )}

                                <ButtonGroup alignCenter spaceBelow={false}>
                                    <Button round outline purple onClick={this.onSave}>
                                        {i18n('general.save')}
                                    </Button>
                                </ButtonGroup>
                            </Form>
                        </PanelContent>
                    </>
                )}
            </Panel>
        );
    }

    render() {
        return renderThisCountry(this);
    }
}

ContactInfoAlert.propTypes = {
    showAlert: PropTypes.bool.isRequired,
    customerId: PropTypes.number,
    contactInformation: PropTypes.object.isRequired,
    getCustomer: PropTypes.func.isRequired,
    updateCustomerContactInfo: PropTypes.func.isRequired,
    updateCustomerContactInfoPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    updateCustomerContactInfoError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    clearUpdateCustomerContactInfoState: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    getCustomerPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getCustomerError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

ContactInfoAlert.defaultProps = {
    updateCustomerContactInfoError: null,
    getCustomerError: null,
    customerId: null, // avoid warning
};

const mapStateToProps = ({ customer }) => {
    return {
        customerId: customer.customer.id,
        contactInformation: customer.customer.contactInformation,
        updateCustomerContactInfoPending: customer.updateCustomerContactInfoPending,
        updateCustomerContactInfoError: customer.updateCustomerContactInfoError,
        getCustomerPending: customer.getCustomerPending,
        getCustomerError: customer.getCustomerError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCustomerContactInfo: (customerId, data) => dispatch(updateCustomerContactInfo(customerId, true, data)),
        clearUpdateCustomerContactInfoState: () => dispatch(clearUpdateCustomerContactInfoState()),
        getCustomer: customerId => dispatch(getCustomer(customerId, true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoAlert);
