// @ts-nocheck
/**
 * Scroll one element into view using provided CSS selector.
 * @param el {string|object}- A CSS selector or element to find the element
 * @param offset {number}- Add some addition offset
 * @param headerSelectors {array} - CSS query to calculate height of blocks above
 */

const scrollIntoView = (el, offset = 0, headerSelectors = []) => {
    const offsetHeaders = -headerSelectors.reduce((sum, headerSelector) => {
        const header = document.querySelector(headerSelector);

        return sum + (header ? header.getBoundingClientRect().height : 0);
    }, 0);

    const element = typeof el === 'string' ? document.querySelector(el) : el;
    if (!element) {
        return console.error(`Unable to locate ${el}`);
    }

    window.requestAnimationFrame(() => {
        // Give it a change to append the has-error class on the elements

        const coords = element.getBoundingClientRect();
        const y = coords.top + (offsetHeaders || 0) + offset;

        window.scrollBy({
            top: y,
            behavior: 'smooth',
        });
    });
};

export default scrollIntoView;
