import { put } from '@ecster/net/v2';
import {
    ACCOUNT_CLOSE_ACCOUNT_BEGIN,
    ACCOUNT_CLOSE_ACCOUNT_SUCCESS,
    ACCOUNT_CLOSE_ACCOUNT_FAILURE,
    ACCOUNT_CLOSE_ACCOUNT_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';
import { GET_ACCOUNT_URL, GET_ACCOUNTS_URL } from './urls';

const CLOSE_ACCOUNT_URL = (customerId, accountRef) =>
    `/rest/ess/customers/v1/${customerId}/accounts/${accountRef}/close`;

export const closeAccount = (customerId, accountRef) => async dispatch => {
    dispatch({
        type: ACCOUNT_CLOSE_ACCOUNT_BEGIN,
    });

    try {
        const res = await put(CLOSE_ACCOUNT_URL(customerId, accountRef));
        await cache.delete(GET_ACCOUNT_URL(customerId, accountRef));
        await cache.delete(GET_ACCOUNTS_URL(customerId));

        dispatch({
            type: ACCOUNT_CLOSE_ACCOUNT_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'closeAccount');

        dispatch({
            type: ACCOUNT_CLOSE_ACCOUNT_FAILURE,
            data: {
                error: {
                    message: err,
                    action: ACCOUNT_CLOSE_ACCOUNT_FAILURE,
                },
            },
        });
    }
};

export const dismissCloseAccountError = () => ({ type: ACCOUNT_CLOSE_ACCOUNT_DISMISS_ERROR });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_CLOSE_ACCOUNT_BEGIN:
            return {
                ...state,
                closeAccountPending: true,
                closeAccountError: null,
                closeAccountIsDone: false,
                closeAccountIsError: false,
            };

        case ACCOUNT_CLOSE_ACCOUNT_SUCCESS:
            return {
                ...state,
                closeAccountPending: false,
                closeAccountError: null,
                closeAccountIsDone: true,
            };

        case ACCOUNT_CLOSE_ACCOUNT_FAILURE:
            return {
                ...state,
                closeAccountPending: false,
                closeAccountError: action.data.error,
                closeAccountIsError: true,
            };

        case ACCOUNT_CLOSE_ACCOUNT_DISMISS_ERROR:
            return {
                ...state,
                closeAccountError: null,
                closeAccountIsError: false,
            };

        default:
            return state;
    }
}
