import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

interface TabProps {
    /** content to show when tab is active */
    children: ReactNode;

    /** text on tab */
    label: string;

    /** css id */
    id?: string;

    /** GA event label, id or label is used if not set */
    gaLabel?: string;
}

export const Tab = ({ children }: TabProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const fadeInClasses = classNames({
        'ec-tab-fade-in': true,
        visible: isVisible,
    });

    return (
        <div className="ec-tab-content">
            <div className={fadeInClasses}>{children}</div>
        </div>
    );
};
