import React, { Component, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import Panel, { PanelContent } from '../Panel';
import { Link, Button, ButtonGroup } from '../Clickable';
import './UserMessagePanel.scss';

interface UserMessagePanelProps {
    /** CSS classes */
    className?: string;

    /** A URL to an icon. SVG is recommended. Width will be 100px */
    icon?: string;

    /**
     * Add an icon to the left of link.
     *
     * Values are css names for the ecster-icon font
     */
    iconLeft?: string;

    /** The message header */
    header: string;

    /** Message body alt #1: A message text */
    text?: string;

    /** Message body alt #2: More complex markup / jsx */
    body?: ReactNode;

    /**
     * Alt #1: link text.
     * Use with 'link', don't combine with 'buttonText' + 'onButtonClick'
     */
    linkText?: string;

    /**
     * Alt #1: link address.
     * Use with 'linkText', don't combine with 'buttonText' + 'onButtonClick'
     */
    link?: string;

    /**
     * Alt #2: button text.
     * Use with 'onButtonClick', don't combine with 'link' + 'linkText'
     */
    buttonText?: string;

    /**
     * Alt #2: button action.
     * Use with 'buttonText', don't combine with 'link' + 'linkText'
     */
    onButtonClick?: MouseEventHandler<HTMLButtonElement>;

    /**
     * Alt #3: isLink.
     * Make a button look like a link
     */
    isLink?: boolean;

    /** optional prefix for IDs on link or button */
    idPrefix?: string;

    children?: ReactNode;
}

class UserMessagePanel extends Component<UserMessagePanelProps> {
    render() {
        const {
            className,
            icon,
            iconLeft,
            header,
            text,
            body,
            linkText,
            link,
            buttonText,
            onButtonClick,
            idPrefix,
            isLink,
            children,
        } = this.props;

        const classes = classNames({
            'ec-user-message-panel': true,
            [className]: className,
        });

        return (
            <Panel className={classes}>
                <PanelContent centered>
                    {icon && <img src={icon} aria-hidden="true" alt="" />}
                    {children}
                    <h1 className="h2">{header}</h1>
                    {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
                    {body}
                    {linkText && link && (
                        <Link
                            underline={false}
                            className="ec-back-link"
                            iconLeft="icon-chevron-left"
                            to={link}
                            id={`${idPrefix}-link`}
                            onClick={onButtonClick}
                        >
                            {linkText}
                        </Link>
                    )}
                    {buttonText && onButtonClick && (
                        <ButtonGroup alignCenter spaceBelow={false}>
                            <Button
                                iconLeft={iconLeft}
                                outline
                                round
                                onClick={onButtonClick}
                                id={`${idPrefix}-button`}
                                link={isLink}
                            >
                                {buttonText}
                            </Button>
                        </ButtonGroup>
                    )}
                </PanelContent>
            </Panel>
        );
    }
}

// @ts-expect-error class defaultProps
UserMessagePanel.defaultProps = {
    className: '',
    icon: undefined,
    iconLeft: '',
    linkText: undefined,
    link: undefined,
    buttonText: undefined,
    onButtonClick: undefined,
    isLink: false,
    idPrefix: 'user-message',
    text: undefined,
    body: undefined,
};

export default UserMessagePanel;
