import { get } from '@ecster/net/lib/v2/Ajax';
import {
    CONTRACT_GET_CONTRACT_TERMS_BEGIN,
    CONTRACT_GET_CONTRACT_TERMS_SUCCESS,
    CONTRACT_GET_CONTRACT_TERMS_FAILURE,
    CONTRACT_GET_CONTRACT_TERMS_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_CONTRACT_TERMS_STATE = 'CLEAR_GET_CONTRACT_TERMS_STATE';

const GET_CONTRACT_TERMS_URL = (customerId, contractNumber) =>
    `/rest/ess/customers/v1/${customerId}/contracts/${contractNumber}/details`;

export const getContractTerms = (customerId, contractNumber) => async dispatch => {
    dispatch({
        type: CONTRACT_GET_CONTRACT_TERMS_BEGIN,
    });

    try {
        const res = await get(GET_CONTRACT_TERMS_URL(customerId, contractNumber));

        dispatch({
            type: CONTRACT_GET_CONTRACT_TERMS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getContractTerms');

        dispatch({
            type: CONTRACT_GET_CONTRACT_TERMS_FAILURE,
            data: {
                error: {
                    message: err,
                    action: CONTRACT_GET_CONTRACT_TERMS_FAILURE,
                },
            },
        });
    }
};

export const dismissGetContractTermsError = () => ({ type: CONTRACT_GET_CONTRACT_TERMS_DISMISS_ERROR });

export const clearGetContractTermsState = () => ({ type: CLEAR_GET_CONTRACT_TERMS_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CONTRACT_GET_CONTRACT_TERMS_BEGIN:
            return {
                ...state,
                getContractTermsPending: true,
                getContractTermsError: null,
                getContractTermsIsDone: false,
                getContractTermsIsError: false,
            };

        case CONTRACT_GET_CONTRACT_TERMS_SUCCESS:
            return {
                ...state,
                currentContractTerms: action.data,
                getContractTermsPending: false,
                getContractTermsError: null,
                getContractTermsIsDone: true,
            };

        case CLEAR_GET_CONTRACT_TERMS_STATE:
            return {
                ...state,
                currentContractTerms: null,
                getContractTermsIsDone: false,
                getContractTermsIsError: false,
            };

        case CONTRACT_GET_CONTRACT_TERMS_FAILURE:
            return {
                ...state,
                getContractTermsPending: false,
                getContractTermsError: action.data.error,
                getContractTermsIsError: true,
            };

        case CONTRACT_GET_CONTRACT_TERMS_DISMISS_ERROR:
            return {
                ...state,
                getContractTermsError: null,
                getContractTermsIsError: false,
            };

        default:
            return state;
    }
}
