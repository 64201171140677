import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { LabelValue, Panel, Radio, RadioGroup } from '@ecster/components';
import { getCookie } from '@ecster/cookies';

import './CustomerSettingsPanel.scss';
import ChangeLanguageContext from './ChangeLanguageContext';

export default class CustomerSettingsPanel extends React.Component {
    static propTypes = {
        isEditMode: PropTypes.bool,
    };

    static defaultProps = {
        isEditMode: false,
    };

    state = {
        locale: getCookie('locale'),
    };

    onChangeLanguage = (event, changeLanguage) => {
        const { locale } = this.state;

        const {
            target: { value: newLocale },
        } = event;

        if (newLocale !== locale) {
            changeLanguage(newLocale);
            this.setState({ locale: newLocale });
        }
    };

    render() {
        const { locale } = this.state;
        const { isEditMode } = this.props;

        const selectedLanguage = {
            'sv-FI': i18n('customer.profile.edit-settings.change-language.swedish'),
            'fi-FI': i18n('customer.profile.edit-settings.change-language.finnish'),
            'sv-SE': i18n('customer.profile.edit-settings.change-language.swedish'),
        };

        return (
            <Panel className="customer-settings-panel" noBorder paddingLeftRight="none">
                <h3 className="left">{i18n('customer.profile.edit-settings.header')}</h3>
                <div>
                    <div className="mb-4x">{i18n('customer.profile.edit-settings.change-language.info')}</div>
                    {isEditMode && (
                        <ChangeLanguageContext.Consumer>
                            {changeLanguage => (
                                <RadioGroup
                                    selectedValue={locale}
                                    onChange={event => this.onChangeLanguage(event, changeLanguage)}
                                >
                                    <Radio value="fi-FI" label={i18n('customer.profile.edit-settings.change-language.finnish')}/>
                                    <Radio value="sv-FI" label={i18n('customer.profile.edit-settings.change-language.swedish')}/>
                                </RadioGroup>
                            )}
                        </ChangeLanguageContext.Consumer>
                    )}
                    {!isEditMode && (
                        <LabelValue
                            value={selectedLanguage[locale]}
                            label={i18n('customer.profile.edit-settings.change-language.header')}
                        />
                    )}
                </div>
            </Panel>
        );
    }
}
