import React, { MouseEventHandler, useState } from 'react';
import { i18n } from '@ecster/i18n';
import { Button, ButtonGroup, Checkbox, FadeIn, LinkButton, ResponsivePanel } from '@ecster/components';

interface Props {
    onCloseAccount: MouseEventHandler<HTMLButtonElement>;
    closeAccountPending: boolean;
    backUrl: string;
}
export const FeedbackForm = ({ onCloseAccount, closeAccountPending, backUrl }: Props) => {
    const [q1, setQ1] = useState(false);
    const [q2, setQ2] = useState(false);
    const [q3, setQ3] = useState(false);
    const [q4, setQ4] = useState(false);
    const [q5, setQ5] = useState(false);
    const [q6, setQ6] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const noneSelected = !q1 && !q2 && !q3 && !q4 && !q5 && !q6;

    const onButtonClick = (event: any) => {
        if (noneSelected) {
            setShowValidation(true);
        } else {
            onCloseAccount(event);
        }
    };

    if (!noneSelected && showValidation) setShowValidation(false);

    return (
        <>
            <h2 className="h3">{i18n('account.terminate.header')}</h2>
            <p>{i18n('account.terminate.info-text')}</p>
            <ResponsivePanel className="choices" verticalLines>
                <div className="checkbox-group">
                    <Checkbox name="terminate-account-q1" checked={q1} onChange={() => setQ1(!q1)}>
                        {i18n('account.terminate.q1')}
                    </Checkbox>
                    <Checkbox name="terminate-account-q2" checked={q2} onChange={() => setQ2(!q2)}>
                        {i18n('account.terminate.q2')}
                    </Checkbox>
                    <Checkbox name="terminate-account-q3" checked={q3} onChange={() => setQ3(!q3)}>
                        {i18n('account.terminate.q3')}
                    </Checkbox>
                </div>
                <div className="checkbox-group">
                    <Checkbox name="terminate-account-q4" checked={q4} onChange={() => setQ4(!q4)}>
                        {i18n('account.terminate.q4')}
                    </Checkbox>
                    <Checkbox name="terminate-account-q5" checked={q5} onChange={() => setQ5(!q5)}>
                        {i18n('account.terminate.q5')}
                    </Checkbox>
                    <Checkbox name="terminate-account-q6" checked={q6} onChange={() => setQ6(!q6)}>
                        {i18n('account.terminate.q6')}
                    </Checkbox>
                </div>
            </ResponsivePanel>
            <div className="user-msg-ctr">
                <FadeIn show={showValidation}>
                    <p>{i18n('account.terminate.none-selected-message')}</p>
                </FadeIn>
            </div>
            <ButtonGroup alignColumn spaceBelow={false}>
                <Button
                    ignoreClick={closeAccountPending}
                    spinnerRight={closeAccountPending}
                    round
                    onClick={onButtonClick}
                >
                    {i18n('account.terminate.terminate-my-account')}
                </Button>
                <LinkButton transparent to={backUrl} id="terminate-account-cancel">
                    {i18n('general.cancel')}
                </LinkButton>
            </ButtonGroup>
        </>
    );
};
