import { get } from '@ecster/net/v2';

import initialState from './initialState';
import {
    ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_BEGIN,
    ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_SUCCESS,
    ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_FAILURE,
    ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_DISMISS_ERROR,
} from './constants';
import { GET_ACCOUNT_PAYMENT_TERMS_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_ACCOUNT_PAYMENT_TERMS_STATE = 'CLEAR_GET_ACCOUNT_PAYMENT_TERMS_STATE';

export const getAccountPaymentTerms = amount => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_BEGIN,
    });

    try {
        const { applicationCountry, language } = window.EcsterConfig;

        const key = GET_ACCOUNT_PAYMENT_TERMS_URL(applicationCountry, language, amount);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);
        dispatch({
            type: ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getAccountPaymentTerms');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetAccountPaymentTermsError = () => ({ type: ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_DISMISS_ERROR });

export const clearGetAccountPaymentTermsState = () => ({ type: CLEAR_GET_ACCOUNT_PAYMENT_TERMS_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_BEGIN:
            return {
                ...state,
                getAccountPaymentTermsPending: true,
                getAccountPaymentTermsError: null,
                getAccountPaymentTermsIsDone: false,
                getAccountPaymentTermsIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_SUCCESS:
            return {
                ...state,
                accountPaymentTerms: action.data,
                getAccountPaymentTermsPending: false,
                getAccountPaymentTermsError: null,
                getAccountPaymentTermsIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_FAILURE:
            return {
                ...state,
                getAccountPaymentTermsPending: false,
                getAccountPaymentTermsError: action.data.error,
                getAccountPaymentTermsIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_DISMISS_ERROR:
            return {
                ...state,
                getAccountPaymentTermsError: null,
                getAccountPaymentTermsIsError: false,
            };

        case CLEAR_GET_ACCOUNT_PAYMENT_TERMS_STATE:
            return {
                ...state,
                accountPaymentTerms: initialState.accountPaymentTerms,
                getAccountPaymentTermsIsDone: false,
                getAccountPaymentTermsIsError: false,
            };
        default:
            return state;
    }
}
