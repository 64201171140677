export const validateEmail = (input, required) => {
    if (!input) {
        return !required;
    }

    const trimmedValue = input.trim();

    if (trimmedValue.indexOf(' ') !== -1) {
        return false;
    }

    const regex = /^[\w.-]+@([\wåäö-]+\.)+[\w-]+$/i;

    return regex.test(trimmedValue);
};

export default validateEmail;