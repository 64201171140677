var _calculateAge = function (birthday) {
    var ageDifMs = Date.now() - birthday.getTime(),
        ageDate = new Date(ageDifMs); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};


export const validateSwedishSsn = input => {
    // Check valid length & form
    if (!input) {
        return true;
    }

    // add hyphen if missing
    if (input.indexOf('-') === -1 && input.indexOf('+') === -1) {
        if (input.length === 10) {
            input = input.slice(0, 6) + '-' + input.slice(6);
        } else {
            input = input.slice(0, 8) + '-' + input.slice(8);
        }
    }

    // validate YYYYMMDD+XXXX
    if (input.length === 13 && input.indexOf('+') > -1) {
        var year = input.slice(0, 4),
            month = input.slice(4, 6),
            day = input.slice(6, 8);

        var birthday = new Date(year + '-' + month + '-' + day);
        var age = _calculateAge(birthday);

        if (age < 100) {
            return false;
        }
    }

    // validate century
    if (input.length >= 12) {
        var century = input.slice(0, 2);
        if (century < 19 || century > 20) {
            return false;
        }
    }

    if (!input.match(/^(\d{2})([0-1])(\d{1})(\d{2})[+-](\d{4})|(\d{4})([0-1])(\d{1})(\d{2})[+-](\d{4})$/)) {
        return false;
    }

    // Clean input
    input = input.replace('-', '');
    input = input.replace('+', '');
    if (input.length === 12) {
        input = input.substring(2);
    }

    // Declare variables
    var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5),
        (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6) - 1),
        ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
        sum = 0,
        numdigits = input.length,
        parity = numdigits % 2,
        i,
        digit;

    // Check valid date
    if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) {
        return false;
    }

    // Check luhn algorithm
    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(input.charAt(i), 10);
        if (i % 2 === parity) {
            digit *= 2;
        }
        if (digit > 9) {
            digit -= 9;
        }
        sum += digit;
    }

    return (sum % 10) === 0;
};

export default validateSwedishSsn;