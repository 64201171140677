const win: any = window;

// texts for confirm external link in app mode
const i18n = {
    sv: {
        confirm: {
            msg: 'Sidan öppnas i webbläsaren - du lämnar appen',
            ok: 'Ok',
            cancel: 'Avbryt',
        },
    },
    en: {
        confirm: {
            msg: "The page opens in the browser - you're leaving the app",
            ok: 'Ok',
            cancel: 'Cancel',
        },
    },
    fi: {
        confirm: {
            msg: 'Sivu avautuu selaimessa - olet poistumassa sovelluksesta',
            ok: 'Jatka',
            cancel: 'Peruuta',
        },
    },
};

const followLink = (linkComponent, el, e, isApp = false) => {
    const { onClick, href } = linkComponent.props;

    if (isApp) {
        win.cordova.InAppBrowser.open(href, '_system'); // open in device browser
    }

    if (typeof onClick === 'function') onClick(e);
};

export const linkOnClick = (linkComponent, e) => {
    const { target, href } = linkComponent.props;
    const el = linkComponent.linkRef.current;
    const hasHref = href?.trim().startsWith('http');

    const onConfirm = btnIndex => {
        if (btnIndex === 2) {
            followLink(linkComponent, el, e, true);
        }
    };

    // app and external link?
    if (win.navigator.notification && win.cordova.InAppBrowser && target === '_blank' && hasHref) {
        e.preventDefault();
        const lang = win.EcsterConfig?.language || win.navigator.language || 'sv'; // sv if not set
        const texts = i18n[lang.substring(0, 2)] || i18n.sv; // sv if unsupported language
        const title = href.replace(/https?:\/\//, '').replace(/\/.*/, ''); // domain only

        win.navigator.notification.confirm(
            texts.confirm.msg, // message
            onConfirm,
            title,
            [texts.confirm.cancel, texts.confirm.ok]
        );
        return false; // don't follow link, it is loaded in followLink(...) after confirm
    }

    // not an app or not an external link, just follow link
    followLink(linkComponent, el, e);
    return true;
};
