import { get } from '@ecster/net/v2'; // or post, put, del

import {
    ACCOUNT_GET_PROMISSORY_NOTES_BEGIN,
    ACCOUNT_GET_PROMISSORY_NOTES_SUCCESS,
    ACCOUNT_GET_PROMISSORY_NOTES_FAILURE,
    ACCOUNT_GET_PROMISSORY_NOTES_DISMISS_ERROR,
} from './constants';
import initialState from './initialState';
import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import { reportError } from '../../../common/reportError';

const GET_PROMISSORY_NOTES_URL = id => `/rest/ess/customers/v1/${id}/promissorynotes`;

const CLEAR_GET_PROMISSORY_NOTES_STATE = 'CLEAR_GET_PROMISSORY_NOTES_STATE';

export const getPromissoryNotes = id => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_PROMISSORY_NOTES_BEGIN,
    });

    try {
        const res = await get(GET_PROMISSORY_NOTES_URL(id));

        parseServerResponseStatus(res);

        dispatch({
            type: ACCOUNT_GET_PROMISSORY_NOTES_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getPromissoryNotes');

        dispatch({
            type: ACCOUNT_GET_PROMISSORY_NOTES_FAILURE,
            data: {
                error: {
                    message: err,
                    action: ACCOUNT_GET_PROMISSORY_NOTES_FAILURE,
                },
            },
        });
    }
};

export const dismissGetPromissoryNotesError = () => ({ type: ACCOUNT_GET_PROMISSORY_NOTES_DISMISS_ERROR });

export const clearGetPromissoryNotesState = () => ({ type: CLEAR_GET_PROMISSORY_NOTES_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_PROMISSORY_NOTES_BEGIN:
            return {
                ...state,
                getPromissoryNotesPending: true,
                getPromissoryNotesError: initialState.getPromissoryNotesError,
                getPromissoryNotesIsDone: false,
                getPromissoryNotesIsError: false,
            };

        case ACCOUNT_GET_PROMISSORY_NOTES_SUCCESS:
            return {
                ...state,
                promissoryNotes: action.data,
                getPromissoryNotesPending: false,
                getPromissoryNotesError: initialState.getPromissoryNotesError,
                getPromissoryNotesIsDone: true,
            };

        case ACCOUNT_GET_PROMISSORY_NOTES_FAILURE:
            return {
                ...state,
                getPromissoryNotesPending: false,
                promissoryNotes: initialState.promissoryNotes,
                getPromissoryNotesError: action.data.error,
                getPromissoryNotesIsError: true,
            };

        case ACCOUNT_GET_PROMISSORY_NOTES_DISMISS_ERROR:
            return {
                ...state,
                getPromissoryNotesError: initialState.getPromissoryNotesError,
                getPromissoryNotesIsError: false,
            };

        case CLEAR_GET_PROMISSORY_NOTES_STATE:
            return {
                ...state,
                promissoryNotes: null,
                getPromissoryNotesIsDone: false,
                getPromissoryNotesIsError: false,
            };

        default:
            return state;
    }
}
