/* eslint-disable jsx-a11y/href-no-hash */
import React from 'react';
import classNames from 'classnames';
import './Snackbar.scss';

interface SnackbarProps {
    show?: boolean;
    message?: string;
    error?: boolean;
}

const Snackbar = ({ show, message, error }: SnackbarProps) => {
    const className = classNames({
        snackbar: true,
        'ec-snackbar': true,
        'ec-snackbar--show': show,
    });

    return (
        <div className={className}>
            <div className={error ? 'ec-snackbar-content-error' : 'ec-snackbar-content'}>
                <div className="icon-wrapper">
                    {error ? <i className="icon-cross" /> : <i className="icon-check" />}
                </div>
                <div className="icon-delimiter" />
                <div>{message}</div>
            </div>
        </div>
    );
};

Snackbar.defaultProps = {
    show: false,
    message: '',
    error: false,
};

export default Snackbar;
