/* eslint-disable no-unused-expressions */
/*
 * copied from https://github.com/imbhargav5/rooks/blob/master/packages/shared/useKeyRef.ts
 */
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

interface Options {
    /**
     * Condition which if true, will enable the event listeners
     */
    when?: boolean;
    /**
     * Keyboardevent types to listen for. Valid options are keyDown, keyPress and keyUp
     */
    eventTypes?: Array<string | number>;
    /**
     * target ref on which the events should be listened. If no target is specified,
     * events are listened to on the window
     */
    // eslint-disable-next-line no-undef
    target?: RefObject<HTMLElement> | null;
}

const defaultOptions = {
    when: true,
    eventTypes: ['keydown'],
};

/**
 * useKey hook
 *
 * Fires a callback on keyboard events like keyDown, keyPress and keyUp
 *
 * @param {[string|number]} keyList
 * @param {function} callback
 * @param {Options} options
 */
function useKey(
    input: string | number | Array<string | number>,
    callback: (e: KeyboardEvent) => any,
    opts?: Options
): void {
    const keyList: Array<string | number> = useMemo(() => (Array.isArray(input) ? input : [input]), [input]);
    const options = { ...defaultOptions, ...opts };
    const { when, eventTypes } = options;
    const callbackRef = useRef<(e: KeyboardEvent) => any>(callback);
    const { target } = options;

    useEffect(() => {
        callbackRef.current = callback;
    });

    const handle = useCallback(
        (e: KeyboardEvent) => {
            // @ts-ignore
            if (keyList.some(k => e.key === k || e.code === k)) {
                callbackRef.current(e);
            }
        },
        [keyList]
    );

    useEffect((): any => {
        if (when && typeof window !== 'undefined') {
            const targetNode = target ? target.current : window;
            eventTypes.forEach(eventType => {
                // @ts-ignore tmp fix // joli44
                targetNode && targetNode.addEventListener(eventType, handle);
            });
            return () => {
                eventTypes.forEach(eventType => {
                    // @ts-ignore tmp fix // joli44
                    targetNode && targetNode.removeEventListener(eventType, handle);
                });
            };
        }

        return null;
    }, [when, eventTypes, keyList, target, callback]);
}

export { useKey };
