// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
    accountTerms: {},
    account: {},
    accountAllowedPartPayments: [],
    accountBillOverview: {}, // bills for all customer accounts, accountRef is key
    accountBillDetails: {}, // bills for all customer accounts, accountRef is key
    accountCampaignPurchases: [],
    accountCard: undefined, // undefined = important, used for test!
    extraCards: undefined, // undefined = important, used for test!
    accountTransactions: {},
    accountTransactionsFilter: { shortList: 3, offset: 0, maxRecords: 100, stepSize: 100 },
    accountReservedTransactions: {},
    accounts: [],
    accountsActive: [],
    accountPaymentTerms: { terms: [] },
    accountLimitRaiseTerms: {},
    partPaymentAmount: 0,
    partPaymentName: '',
    partPaymentTransId: '',

    // generated
    deleteAccountPending: false,
    deleteAccountError: null,
    getAccountTermsPending: false,
    getAccountTermsError: null,
    getAccountPending: false,
    getAccountError: null,
    getAccountAllowedPartPaymentsPending: false,
    getAccountAllowedPartPaymentsError: null,
    getAccountBillOverviewPending: false,
    getAccountBillOverviewError: null,
    getAccountCampaignPurchasesPending: false,
    getAccountCampaignPurchasesError: null,
    getAccountCardsPending: false,
    getAccountCardsError: null,
    getAccountTransactionsPending: false,
    getAccountTransactionsError: null,
    getAccountsPending: false,
    getAccountsError: null,
    getAccountPaymentTermsPending: false,
    getAccountPaymentTermsError: null,
    getAccountLimitRaiseTermsPending: false,
    getAccountLimitRaiseTermsError: null,
    hasZeroAccounts: false,
    updateAccountPending: false,
    updateAccountError: null,
    updateAccountTransactionPartPaymentPending: false,
    updateAccountTransactionPartPaymentError: null,
    updateAccountCardPending: false,
    updateAccountCardError: null,
    createAccountCardPending: false,
    createAccountCardError: null,
    getPromissoryNotesPending: false,
    getPromissoryNotesError: null,
    createAmortizationFreeMonthPending: false,
    createAmortizationFreeMonthError: null,
    getLoyaltyDetailsPending: false,
    getLoyaltyDetailsError: null,
    setLoyaltyDetailsPending: false,
    setLoyaltyDetailsError: null,
    closeAccountPending: false,
    closeAccountError: null,
    getEnrolledDevicesPending: false,
    getEnrolledDevicesError: null,
    getAccountBillDetailsPending: false,
    getAccountBillDetailsError: null,
};

export default initialState;
