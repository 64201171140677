let matchedCode; // don't do all the regexp matching every single time, save the first matched country code

const matchCountry = code => {
    if (matchedCode) {
        return code === matchedCode;
    }

    const conf = window.EcsterConfig;
    const wl = window.location;

    // -- WEB ---------
    // -- match browser URL

    // Prod + prodlabs
    // login.ecster.se | login.ecster.fi | secure.ecster.se | labs.ecster.se
    const prRegExp = new RegExp(`login\\.ecster\\.${code}|secure\\.ecster\\.${code}|labs\\.ecster\\.${code}`, 'i');

    // aws test, aws dev, wima secure + labs ft-ft5
    // login.se.test.ecster.com | login.fi... | login.se.dev... | secure*.ft.ecster.se | labs*.ft.ecster.se
    const testDevRegExp = new RegExp(
        `login\\.${code}|secure.*\\.ft\\.ecster.${code}|labs.*\\.ft\\.ecster.${code}`,
        'i'
    );

    // branch test
    // web.dev.aws.ecster.com/login_se/... | .../login_fi/...
    const branchRegExp = new RegExp(`login_${code}`, 'i');

    // localhost
    // query param: country=se | country=fi
    // hostname: localhost.login.se.shbmain.shb.biz | localhost.login.fi...
    const localQueryRegExp = new RegExp(`country=${code}`, 'i');
    const localHostRegExp = new RegExp(`login\\.localhost\\.ecster\\.${code}`, 'i');

    // BrowserStack requires bs-local as local testing URL
    const browserStackRegExp = /bs-local/i;

    switch (conf.env) {
        case 'prod':
        case 'prodlabs':
            if (wl.host.match(prRegExp)) {
                matchedCode = code;
                return true;
            }
            break;
        default:
            if (
                wl.hostname.match(testDevRegExp) ||
                wl.pathname.match(branchRegExp) ||
                wl.hostname.match(localHostRegExp) ||
                wl.hostname.match(browserStackRegExp) ||
                wl.search.match(localQueryRegExp)
            ) {
                matchedCode = wl.hostname.match(browserStackRegExp) ? 'se' : code; // Return SE for BrowserStack
                return true;
            }
            // fallback to SE
            if (wl.hostname === 'localhost' && code === 'se') {
                matchedCode = code;
                return true;
            }
    }

    return false;
};

export const isSE = () => matchCountry('se');
export const isFI = () => matchCountry('fi');
