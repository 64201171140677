// @ts-nocheck
import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TableRowColumn.scss';

interface TableRowColumnProps {
    /** CSS id */
    id?: string;

    /** Child elements */
    children?: ReactNode;

    /** @deprecated use alignRight instead */
    rightAlign?: boolean;
    alignRight?: boolean;
    alignCenter?: boolean;
    borderTop?: boolean;
    noBorder?: boolean;

    /** Additional css classes */
    className?: string;

    /** In most cases, use className and css instead */
    style?: CSSProperties;
    colSpan?: number;
    strong?: boolean;
    weak?: boolean;
    onClick?: Function;

    /**
     * Draw a timeline bullet only, children are not rendered
     */
    bullet?: boolean;
}

/** Table cell component */
const TableRowColumn = ({
    children,
    rightAlign,
    alignRight,
    alignCenter,
    noBorder,
    borderTop,
    className,
    style,
    colSpan,
    strong,
    weak,
    onClick,
    id,
    bullet,
}: TableRowColumnProps) => {
    const classes = classNames({
        'table-row-column': true,
        'table-row-column--border': !noBorder,
        'table-row-column--right-align': alignRight || rightAlign,
        'table-row-column--center-align': alignCenter,
        'table-row-column--border-top': borderTop,
        'table-row-column--strong': strong,
        'table-row-column--weak': weak,
        'table-row-column--bullet': bullet,
        [className]: className,
    });
    return (
        <td id={id} className={classes} style={style} colSpan={colSpan} onClick={onClick}>
            {bullet ? <div className="circle" /> : children}
        </td>
    );
};

TableRowColumn.propTypes = {
    /** CSS id */
    id: PropTypes.string,

    /** Child elements */
    children: PropTypes.node,

    /** @deprecated use alignRight instead */
    rightAlign: PropTypes.bool,
    alignRight: PropTypes.bool,
    alignCenter: PropTypes.bool,

    borderTop: PropTypes.bool,
    noBorder: PropTypes.bool,

    /** Additional css classes */
    className: PropTypes.string,

    /** In most cases, use className and css instead */
    style: PropTypes.shape(),
    colSpan: PropTypes.number,
    strong: PropTypes.bool,
    weak: PropTypes.bool,
    onClick: PropTypes.func,

    /**
     * Draw a timeline bullet only, children are not rendered
     */
    bullet: PropTypes.bool,
};

TableRowColumn.defaultProps = {
    id: '',
    rightAlign: false, // @deprecated
    alignRight: false,
    alignCenter: false,
    borderTop: false,
    noBorder: false,
    children: '',
    className: '',
    style: {},
    colSpan: null,
    strong: false,
    weak: false,
    onClick: () => {},
    bullet: false,
};

export default TableRowColumn;
