import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessagePanel } from '@ecster/components';

import {
    hideFullscreenDialog as hideFullscreenDialogAction,
    hideModalMessage as hideModalMessageAction,
    hideSnackbar as hideSnackbarAction,
} from './redux/actions';

import './MessagePanel.scss';

const MessagePanelWrapper = () => {
    const dispatch = useDispatch();

    const hideSnackbar = () => dispatch(hideSnackbarAction());
    const hideModalMessage = () => dispatch(hideModalMessageAction());
    const hideFullscreenDialog = () => dispatch(hideFullscreenDialogAction());

    const {
        fullscreenDialogBody,
        modalMessage,
        modalMessageHeader,
        modalMessageSubmit,
        modalMessageType,
        showFullscreenDialog,
        showModalMessage,
        showSnackbar,
        snackbarMessage,
        snackbarError,
    } = useSelector(state => {
        const {
            common: {
                snackbar: { show: showSnackbar, message: snackbarMessage = '', snackbarError = false },
                modalMessage: {
                    message: modalMessage = '',
                    show: showModalMessage,
                    type: modalMessageType = 'INFO',
                    header: modalMessageHeader = '',
                    onSubmit: modalMessageSubmit = () => {},
                },
                fullscreenDialog: { show: showFullscreenDialog, body: fullscreenDialogBody = <div /> },
            },
        } = state;

        return {
            fullscreenDialogBody,
            modalMessage,
            modalMessageHeader,
            modalMessageSubmit,
            modalMessageType,
            showFullscreenDialog,
            showModalMessage,
            showSnackbar,
            snackbarMessage,
            snackbarError,
        };
    });

    if (!showModalMessage && !showFullscreenDialog && !showSnackbar) return null;

    return (
        <MessagePanel
            showSnackbar={showSnackbar}
            snackbarMessage={snackbarMessage}
            hideSnackbar={hideSnackbar}
            showModalMessage={showModalMessage}
            modalMessageHeader={modalMessageHeader}
            modalMessage={modalMessage}
            modalMessageType={modalMessageType}
            hideModalMessage={hideModalMessage}
            modalMessageSubmit={modalMessageSubmit}
            showFullscreenDialog={showFullscreenDialog}
            hideFullscreenDialog={hideFullscreenDialog}
            fullscreenDialogBody={fullscreenDialogBody}
            snackbarError={snackbarError}
        />
    );
};

export default MessagePanelWrapper;
