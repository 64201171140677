// @ts-nocheck
import React, { useEffect } from 'react';

/**
 * Update Element with a className depending on the keyboard state
 * @param root {Element} - To append/removed the className from
 * @param isKeyboardVisible {Boolean} - state of the keyboard
 * @param className {string} - className to append/remove
 */
const handleKeyboardState = (root, isKeyboardVisible, className) => {
    if (isKeyboardVisible) {
        root.classList.add(className);
    } else {
        root.classList.remove(className);
    }
};

/**
 * Detects if a keyboard is visible and adds a class to the HTML-element in that case
 * @param callback {function | undefined} - Optional callback function to trigger when keyboard is shown/hidden
 * @param className {string | undefined} - Optional className to append to the HTML-element, default is "is-keyboard-visible"
 * @returns {string}
 * @constructor
 */
const IsKeyboardVisible = ({ callback = () => {}, className = 'is-keyboard-visible' }) => {
    const root = document.querySelector('html');

    useEffect(() => {
        document.addEventListener('focusin', checkFocus);
        document.addEventListener('focusout', checkFocus);

        return () => {
            document.removeEventListener('focusin', checkFocus);
            document.removeEventListener('focusout', checkFocus);
        };
    }, []);

    const checkFocus = ({ target, type }) => {
        const { nodeName: input } = target;
        const isKeyBoardVisible = (input === 'INPUT' || input === 'TEXTAREA') && type === 'focusin';

        handleKeyboardState(root, isKeyBoardVisible, className);
        callback(isKeyBoardVisible);
    };

    return '';
};

export default IsKeyboardVisible;
