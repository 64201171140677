import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';

import { detectDevice } from '@ecster/components';

import MessagePanel from '../MessagePanel';
import { isSE } from '../../../common/country';

class Wrapper extends React.Component {
    fallbackBgRef = React.createRef();

    componentDidMount() {
        // avoid fallback to be visible when real bg loads, fade in after a short delay
        setTimeout(() => {
            const bg = this.fallbackBgRef.current;

            if (bg) {
                bg.classList.add('visible');
            }
        }, 800);
    }

    render() {
        const { children, className, lazy } = this.props;

        const isDesktop =
            detectDevice().isDesktop &&
            // Edge supports CSS.supports and has CSS support for -ms-ime-align. IE11 Doesn't support CSS.supports
            !(window.CSS && window.CSS.supports && window.CSS.supports('-ms-ime-align', 'auto')) &&
            // IE11 doesn't support objectFit
            'objectFit' in document.documentElement.style;

        const widths = isDesktop ? [768, 1536, 1024, 2048, 1920, 3840] : [768, 1536, 1024, 1920];

        const viewport = isDesktop ? 'desktop' : 'mobile';
        const lang = isSE() ? 'se' : 'fi';

        return (
            <>
                <div ref={this.fallbackBgRef} className="login-page-fallback-background" />

                <img
                    src={`./images/backgrounds/${lang}/login-background-${viewport}.jpg`}
                    alt=""
                    className="login-page-background"
                />

                <article className={`common-login-page-template ${isDesktop ? 'is-desktop' : ''} ${className}`}>
                    {children}
                </article>
            </>
        );
    }
}

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    lazy: PropTypes.bool,
};

Wrapper.defaultProps = {
    className: '',
    lazy: true,
};

const LoginPageTemplate = ({ children, className, isNoMessages, lazy }) => (
    <>
        <Wrapper className={className} lazy={lazy}>
            {children}
        </Wrapper>
        {isNoMessages ? '' : <MessagePanel />}
    </>
);

LoginPageTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isNoMessages: PropTypes.bool,
    lazy: PropTypes.bool,
};

LoginPageTemplate.defaultProps = {
    className: '',
    isNoMessages: false,
    lazy: true,
};

export default LoginPageTemplate;
