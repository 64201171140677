import { COMMON_SET_REVIEW_MODE } from './constants';

export const setReviewMode = isReviewMode => ({
    type: COMMON_SET_REVIEW_MODE,
    isReviewMode,
});

// no arrow function, rekit gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case COMMON_SET_REVIEW_MODE:
            return {
                ...state,
                isReviewMode: action.isReviewMode,
            };

        default:
            return state;
    }
}
