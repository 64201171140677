import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_CARDS_BEGIN,
    ACCOUNT_GET_ACCOUNT_CARDS_SUCCESS,
    ACCOUNT_GET_ACCOUNT_CARDS_FAILURE,
    ACCOUNT_GET_ACCOUNT_CARDS_DISMISS_ERROR,
    CLEAR_GET_ACCOUNT_CARDS_REDUX_STATE,
    CLEAR_GET_ACCOUNT_CARDS_STATE,
} from './constants';

import { GET_ACCOUNT_CARDS_URL } from './urls';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

export const clearGetAccountCardsState = () => ({ type: CLEAR_GET_ACCOUNT_CARDS_STATE });

export const getAccountCards = (customerId, accountRef) => async dispatch => {
    dispatch(clearGetAccountCardsState());

    dispatch({
        type: ACCOUNT_GET_ACCOUNT_CARDS_BEGIN,
    });

    try {
        const key = GET_ACCOUNT_CARDS_URL(customerId, accountRef);
        const cached = await cache.get(key);
        const res = cached || (await get(key));

        if (!cached) cache.set(key, res);

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_CARDS_SUCCESS,
            data: res.response,
            accountRef,
        });
    } catch (err) {
        reportError(err, 'getAccountCards');

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_CARDS_FAILURE,
            data: { error: err },
        });
    }
};

export const dismissGetAccountCardsError = () => ({ type: ACCOUNT_GET_ACCOUNT_CARDS_DISMISS_ERROR });

export const clearGetAccountCardsReduxState = () => ({ type: CLEAR_GET_ACCOUNT_CARDS_REDUX_STATE });

/**
 * slice cards array into:
 * - one main card (or no main card)
 * - array of extra cards (or empty array)
 * @return { mainCard, extraCards }
 */
const sliceCards = cards => {
    if (cards && cards.length > 0) {
        const result = {};

        let mainCards = cards.filter(card => !card.extraCardInfo);
        // exactly one maincard
        if (mainCards && mainCards.length === 1) {
            result.mainCard = mainCards[0]; // eslint-disable-line prefer-destructuring
        }
        // more than one maincard, then filter out BLOCKED cards
        if (mainCards && mainCards.length > 1) {
            mainCards = mainCards.filter(card => card.status !== 'BLOCKED');
            // TODO: what if all cards are 'BLOCKED'?
            result.mainCard = mainCards[0]; // eslint-disable-line prefer-destructuring
        }
        result.extraCards = cards.filter(card => !!card.extraCardInfo);
        return result;
    }
    return { mainCard: undefined, extraCards: [] };
};

export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_ACCOUNT_CARDS_BEGIN:
            return {
                ...state,
                getAccountCardsPending: true,
                getAccountCardsError: null,
                getAccountCardsIsDone: false,
                getAccountCardsIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_CARDS_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const slicedCards = sliceCards(action.data.cards);
            return {
                ...state,
                accountCards: { ...state.accountCards, [action.accountRef]: slicedCards.mainCard },
                extraCards: { ...state.extraCards, [action.accountRef]: slicedCards.extraCards },
                getAccountCardsPending: false,
                getAccountCardsError: null,
                getAccountCardsIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_CARDS_FAILURE:
            return {
                ...state,
                getAccountCardsPending: false,
                getAccountCardsError: action.data.error,
                getAccountCardsIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_CARDS_DISMISS_ERROR:
            return {
                ...state,
                getAccountCardsError: null,
                getAccountCardsIsError: false,
            };

        case CLEAR_GET_ACCOUNT_CARDS_STATE:
            return {
                ...state,
                accountCards: null,
                extraCards: null,
                getAccountCardsPending: false,
                getAccountCardsError: null,
                getAccountCardsIsDone: false,
                getAccountCardsIsError: false,
            };

        case CLEAR_GET_ACCOUNT_CARDS_REDUX_STATE:
            return {
                ...state,
                getAccountCardsPending: false,
                getAccountCardsError: null,
                getAccountCardsIsDone: false,
                getAccountCardsIsError: false,
            };

        default:
            return state;
    }
}
