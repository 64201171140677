import moment from 'moment';
import { Badge } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { shoppingCartIcon, walletIcon, moneyBagIcon } from '@ecster/icons/H15/green-transparent';

import React from 'react';
import { formatDate } from '../../../common/util/format-date';
import { getBillProperties } from '../../account/util';
import { getContractHeader } from '../../contract/util';

export const getDueDatePresentation = dueDate => {
    const today = moment().startOf('day');

    const diff = moment(dueDate).diff(today, 'days');
    if (diff < 0) return formatDate(dueDate);
    if (diff === 0) return i18n('invoice.invoice-overview.due-date.today');

    return i18n('invoice.invoice-overview.due-date.days-left', { count: diff });
};

// one off invoices
export const mapOneOffInvoices = invoices => {
    return (
        invoices &&
        invoices.map(invoice => {
            return {
                invoiceNumber: invoice.invoiceNumber,
                amount: invoice.type === 'CREDIT' ? -1 * invoice.amount : invoice.amount,
                dueDate: invoice.dueDate,
                dueDateText: getDueDatePresentation(invoice.dueDate),
                description: invoice.storeName,
                url: `/customer/invoice/${invoice.invoiceId}`,
                invoice: true,
                type: invoice.type,
                icon: shoppingCartIcon,
            };
        })
    );
};

// account invoices
export const mapAccountInvoices = (bills, accounts) => {
    const invoices = [];

    if (!bills) return invoices;

    Object.keys(bills).forEach(accountRef => {
        const bill = bills[accountRef];

        if (!bill.payment) return;

        const lab3 = bill.payment.options.find(o => o.type === 'FULLPAYMENT');
        const account = accounts.find(o => o.reference === accountRef);
        const billProps = getBillProperties(bill);

        invoices.push({
            invoiceNumber: bill.ocrNumber, // account no
            amount: lab3.amount,
            dueDate: bill.payment.dueDate,
            dueDateText: getDueDatePresentation(bill.payment.dueDate),
            description: account?.product?.name,
            url: `/account/${accountRef}/invoice`,
            accountInvoice: true,
            accountRef,
            type: 'MONTHLY',
            badge: billProps.badge,
            icon: walletIcon,
            paymentStatus: billProps.paymentStatus,
        });
    });

    return invoices;
};

// contract invoices
export const mapContractInvoices = contracts => {
    const invoices = [];

    contracts
        .filter(contract => contract.status !== 'CONTACT_US' && contract.status !== 'COLLECTION')
        .forEach(contract => {
            const { contractNumber, invoicedAmount, dueDate, paymentStatus } = contract;

            if (dueDate) {
                invoices.push({
                    invoiceNumber: contractNumber,
                    amount: invoicedAmount,
                    dueDate,
                    dueDateText: getDueDatePresentation(dueDate),
                    description: getContractHeader(contract),
                    url: `/contract/${contractNumber}/invoice`,
                    contract: true,
                    type: 'CONTRACT',
                    badge: paymentStatus ? (
                        paymentStatus === 'PAID' ? (
                            <Badge>{i18n('invoice.general.paid')}</Badge>
                        ) : (
                            <Badge action>{i18n('invoice.general.unpaid')}</Badge>
                        )
                    ) : null,
                    icon: moneyBagIcon,
                });
            }
        });

    return invoices;
};
