import React, { ReactNode, useState } from 'react';
import IconButton from './IconButton';
import { copyIcon } from '@ecster/icons/H15/green';

import './CopyToClipboardButton.scss';

const { navigator: nav } = window;

const isIOS = !!/ipad|iphone|ipod/i.test(nav.userAgent);

// const supportsClipboardAPI = () => !!nav.clipboard;
const supportsInputFieldCopy = () => !isIOS; // ios flickers onscreen keyboard
const noSupport = () => !nav.clipboard && !supportsInputFieldCopy();

// copy with native API in newer browsers
const copyWithClipboardAPI = (text) => {
    nav.clipboard.writeText(text);
};

// copy with a tmp input field, fallback for browsers or apps not supporting APIs
const copyWithInputField = text => {
    const { document: doc } = window;

    const input = doc.createElement('input');

    input.setAttribute('value', text ? text.trim() : '');
    input.style.cssText = 'position: absolute; width: 10px; top: -25px; left: -25px;';

    doc.body.appendChild(input);
    input.select();

    document.execCommand('copy');

    setTimeout(() => {
        document.body.removeChild(input);
    }, 20);
};

export const copyToClipboard = text => {
    if  (nav.clipboard) {
        copyWithClipboardAPI(text);
    } else if (!isIOS) {
        console.log('input field copy: ', text);
        copyWithInputField(text);
    }
};

interface CopyToClipboardButtonProps {
    /**
     * Unique ID for this particular tip
     */
    id?: string;

    /**
     * Optional child elements, place to the left of the clipboard icon
     */
    children?: ReactNode;

    /**
     * Value to be copied
     */
    value: string;

    /**
     * Tooltip text
     */
    tooltip: string;

    /**
     * Tooltip text on copy success
     */
    tooltipSuccess?: string;

    /**
     * Disable the default copy icon
     */
    noIcon?: boolean;
}

/**
 * An IconButton that takes a value as property.
 * Value is copied to clipboard when button is clicked
 */
const CopyToClipboardButton = ({
    children = null,
    id,
    noIcon = false,
    value,
    tooltip,
    tooltipSuccess = '',
}: CopyToClipboardButtonProps) => {
    const [tooltipText, setTooltipText] = useState(tooltip);

    const [tooltipVisible, setTooltipVisible] = useState(false);

    const onClick = () => {
        copyToClipboard(value);

        if (tooltipSuccess) setTooltipText(tooltipSuccess);
        return false;
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            copyToClipboard(value);

            if (tooltipSuccess) setTooltipText(tooltipSuccess);
            setTooltipVisible(true);
            setTimeout(() => setTooltipVisible(false), 1000);
        }
        return false;
    };

    const onKeyUp = e => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            e.target.focus(); // Ensure the focus stays on the element
        }
    }

    const onMouseOver = () => {
        setTooltipVisible(true);
    }

    const onMouseOut = () => {
        setTooltipVisible(false);
        setTooltipText(tooltip);
    }

    if (noSupport()) {
        return <>{children}</>;
    }

    return (
        <span id={id} className={`ec-ctc-button ${tooltipVisible ? '' : 'has-overflow'}`}>
            <button className="ec-ctc-value"
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onClick={onClick}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}>
                {children}
                {!noIcon && (
                    <img className="ec-ctc-icon ml-2x" src={copyIcon} alt=""/>
                )}
            </button>
            <span className={`ec-ctc-tooltip ${tooltipVisible ? 'visible' : ''}`}>{tooltipText}</span>
        </span>
    );
};

export default CopyToClipboardButton;
