import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from '@ecster/i18n';
import { FadeIn, Spinner, GeneralErrorMessagePanel } from '@ecster/components';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';
// import above?
import { withRouter } from 'react-router-dom';

import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { getAccount, getAccountBillDetails, getAccountTerms } from './redux/actions';

import { AccountInvoicePageContent as PageContentSE } from './SE/AccountInvoicePageContent';
import { AccountInvoicePageContent as PageContentFI } from './FI/AccountInvoicePageContent';
import { renderThisCountry } from '../common/i18n/renderThisCountry';

const PageWrapper = ({ dataAvailable, children }) => {
    return (
        <AuthenticatedSubPageTemplate
            className="invoice-account-invoice-page page-wrapper"
            header={i18n('invoice.account-invoice.page-header')}
        >
            {dataAvailable ? (
                <FadeIn show={dataAvailable}>{children}</FadeIn>
            ) : (
                <Spinner id="account-invoice-spinner" isCenterPage isVisible />
            )}
        </AuthenticatedSubPageTemplate>
    );
};

PageWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    dataAvailable: PropTypes.bool.isRequired,
};

const ErrorMessage = () => (
    <PageWrapper dataAvailable>
        <GeneralErrorMessagePanel
            header={i18n('general.error.general.header')}
            body={i18n('general.error.general.body')}
            buttonText={i18n('general.back')}
            isLink
        />
    </PageWrapper>
);

export class AccountInvoicePage extends Component {
    state = {
        getAccountDone: false,
        getAccountBillDetailsIsDone: false,
        getAccountTermsDone: false,
        failure: false,
    };

    componentDidMount() {
        const {
            customerId,
            getAccount,
            getAccountBillDetails,
            getAccountTerms,
            accountRef,
            // router: {
            //     params: { accountRef },
            // },
        } = this.props;
        getAccount(customerId, accountRef);
        getAccountBillDetails(customerId, accountRef);
        getAccountTerms(customerId, accountRef);
    }

    componentDidUpdate(prevProps, { getAccountDone, getAccountBillDetailsIsDone, getAccountTermsDone, failure }) {
        /* eslint-disable react/no-did-update-set-state */
        // success
        if (reduxActionSucceeded('getAccount', prevProps, this.props) && !getAccountDone)
            this.setState({ getAccountDone: true });
        if (reduxActionSucceeded('getAccountBillDetails', prevProps, this.props) && !getAccountBillDetailsIsDone)
            this.setState({ getAccountBillDetailsIsDone: true });
        if (reduxActionSucceeded('getAccountTerms', prevProps, this.props) && !getAccountTermsDone) {
            this.setState({ getAccountTermsDone: true });
        }

        // failure
        if (!failure) {
            if (reduxActionFailed('getAccount', prevProps, this.props)) this.setState({ failure: true });
            if (reduxActionFailed('getAccountBillDetails', prevProps, this.props)) this.setState({ failure: true });
            if (reduxActionFailed('getAccountTerms', prevProps, this.props)) this.setState({ failure: true });
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderSE() {
        const {
            account,
            bills,
            accountTerms,
            accountRef,
            // router: {
            //     params: { accountRef },
            // },
        } = this.props;
        const { getAccountDone, getAccountBillDetailsIsDone, getAccountTermsDone, failure } = this.state;
        const dataAvailable = getAccountDone && getAccountBillDetailsIsDone && getAccountTermsDone;

        const bill = bills[accountRef];

        if (failure) return <ErrorMessage />;

        return (
            <PageWrapper dataAvailable={dataAvailable}>
                <PageContentSE account={account} bill={bill} accountRef={accountRef} accountTerms={accountTerms} />
            </PageWrapper>
        );
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderFI() {
        const {
            bills,
            accountRef,
            // router: {
            //     params: { accountRef },
            // },
        } = this.props;
        const { getAccountDone, getAccountBillDetailsIsDone, getAccountTermsDone, failure } = this.state;
        const dataAvailable = getAccountDone && getAccountBillDetailsIsDone && getAccountTermsDone;

        const bill = bills[accountRef];

        if (failure) return <ErrorMessage />;

        return (
            <PageWrapper dataAvailable={dataAvailable}>
                <PageContentFI bill={bill} />
            </PageWrapper>
        );
    }

    render() {
        return renderThisCountry(this);
    }
}

AccountInvoicePage.propTypes = {
    // router: PropTypes.shape({
    //     params: PropTypes.shape({
    //         accountRef: PropTypes.string.isRequired,
    //     }).isRequired,
    // }).isRequired,
    accountRef: PropTypes.string.isRequired,
    account: PropTypes.object,
    bills: PropTypes.object,
    accountTerms: PropTypes.object,
    getAccount: PropTypes.func.isRequired,
    getAccountBillDetails: PropTypes.func.isRequired,
    getAccountTerms: PropTypes.func.isRequired,

    // props are used in operationSucceeded
    getAccountPending: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    getAccountBillDetailsPending: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    getAccountTermsPending: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    getAccountError: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
    getAccountBillDetailsError: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types
    getAccountTermsError: PropTypes.shape(), // eslint-disable-line react/no-unused-prop-types

    customerId: PropTypes.number.isRequired,
};

AccountInvoicePage.defaultProps = {
    account: null,
    bills: {},
    accountTerms: null,
    getAccountPending: false,
    getAccountBillDetailsPending: false,
    getAccountTermsPending: false,
    getAccountError: null,
    getAccountBillDetailsError: null,
    getAccountTermsError: null,
};

const mapStateToProps = ({ account, authentication }, { match }) => {
    const { accountRef } = match.params;
    return {
        accountRef,
        account: account.account,
        bills: account.accountBillDetails,
        accountTerms: account.accountTerms,
        getAccountPending: account.getAccountPending,
        getAccountBillDetailsPending: account.getAccountBillDetailsPending,
        getAccountTermsPending: account.getAccountTermsPending,
        getAccountError: account.getAccountError,
        getAccountBillDetailsError: account.getAccountBillDetailsError,
        getAccountTermsError: account.getAccountTermsError,
        customerId: authentication.person.id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAccount: (customerId, accountRef) => dispatch(getAccount(customerId, accountRef)),
        getAccountBillDetails: (customerId, accountRef) => dispatch(getAccountBillDetails(customerId, accountRef)),
        getAccountTerms: (customerId, accountRef) => dispatch(getAccountTerms(customerId, accountRef)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInvoicePage));
