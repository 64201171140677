import React, { ReactNode } from 'react';
import DialogCloseButton from './DialogCloseButton';

import './DialogHeader.scss';

interface DialogHeaderProps {
    onRequestClose?: Function;
    children?: ReactNode;
    noCloseBtn?: boolean;
    ariaLabelClose?: string;
}

const DialogHeader = ({ onRequestClose, children, noCloseBtn, ariaLabelClose }: DialogHeaderProps) => {
    return (
        <header className="ec-dialog-header">
            {children}
            {!noCloseBtn && <DialogCloseButton ariaLabel={ariaLabelClose} onRequestClose={onRequestClose} />}
        </header>
    );
};

DialogHeader.defaultProps = {
    onRequestClose: () => {},
    children: '',
    noCloseBtn: false,
    ariaLabelClose: '',
};

export default DialogHeader;
