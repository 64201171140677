import { post } from '@ecster/net/v2';
import {
    CONTRACT_SEND_MAIL_BEGIN,
    CONTRACT_SEND_MAIL_SUCCESS,
    CONTRACT_SEND_MAIL_FAILURE,
    CONTRACT_SEND_MAIL_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

const CLEAR_SEND_MAIL_STATE = 'CLEAR_SEND_MAIL_STATE';

const SEND_MAIL_URL = (customerId, contractNumber) =>
    `/rest/ess/customers/v1/${customerId}/contracts/${contractNumber}/repayment/sendConfirmationMail`;

export const sendMail = (customerId, contractNumber, data) => async dispatch => {
    dispatch({
        type: CONTRACT_SEND_MAIL_BEGIN,
    });

    try {
        const res = await post(SEND_MAIL_URL(customerId, contractNumber), data);

        dispatch({
            type: CONTRACT_SEND_MAIL_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'sendMail');
        dispatch({
            type: CONTRACT_SEND_MAIL_FAILURE,
            data: {
                error: {
                    message: err,
                    action: CONTRACT_SEND_MAIL_FAILURE,
                },
            },
        });
    }
};

export const dismissSendMailError = () => ({ type: CONTRACT_SEND_MAIL_DISMISS_ERROR });

export const clearSendMailState = () => ({ type: CLEAR_SEND_MAIL_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case CONTRACT_SEND_MAIL_BEGIN:
            return {
                ...state,
                sendMailPending: true,
                sendMailError: null,
            };

        case CONTRACT_SEND_MAIL_SUCCESS:
            return {
                ...state,
                sendMailPending: false,
                sendMailError: null,
            };

        case CLEAR_SEND_MAIL_STATE:
            return {
                ...state,
                sendMailPending: false,
                sendMailError: null,
            };

        case CONTRACT_SEND_MAIL_FAILURE:
            return {
                ...state,
                sendMailPending: false,
                sendMailError: action.data.error,
            };

        case CONTRACT_SEND_MAIL_DISMISS_ERROR:
            return {
                ...state,
                sendMailError: null,
            };

        default:
            return state;
    }
}
