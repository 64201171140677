import { getCanaryValue } from './canaryValues';
import { get } from '@ecster/net/v2';

const { sessionStorage } = window;

const CONFIG = 'mypages-config';

export const fetchConfig = async () => {
    try {
        const { locale } = window.EcsterConfig;
        const cv = getCanaryValue();
        const { response } = await get(`/rest/eos/v1/config/mypages?locale=${locale}&canaryValue=${cv}`);
        return response;
    } catch (e) {
        console.error('Unable to get GUI Config: ', e);
        return {};
    }
};

export const setConfig = config => sessionStorage.setItem(CONFIG, JSON.stringify(config));

export const getConfig = () => JSON.parse(sessionStorage.getItem(CONFIG)) || {};

export const clearConfig = () => sessionStorage.removeItem(CONFIG);

export const isConfigInitialized = () => JSON.stringify(getConfig()) !== '{}'
