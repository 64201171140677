import React, { FormEventHandler, ReactNode, RefObject } from 'react';
import scrollIntoView from '../util/scroll-into-view';
import isElementInViewport from '../util/isElementInViewport';

interface FormProps {
    /**
     * Form inputs
     */
    children: ReactNode;

    /**
     * React refs to input fields to be validated
     */
    validateRefs?: RefObject<any>[];

    /**
     * CSS classes for form tag
     */
    className?: string;

    /**
     * What to do if form is submitted, e.g user hits return in an input field
     *
     * e.preventDefault() is recommended to avoid unexpected page reloads
     *
     * Note: The default implementation calls e.preventDefault()
     */
    onSubmit?: FormEventHandler<HTMLFormElement>;

    /**
     * @deprecated - use onSubmit
     */
    handleSubmit?: FormEventHandler<HTMLFormElement>;

    /**
     * scroll to first invalid field if validation fails and field is not in viewport
     */
    scrollToError?: boolean;

    /**
     * Used in combination with scrollToError to set the distance above the element it scrolls to
     */
    offset?: number;

    /**
     * Used in combination with scrollToError to calculate height of blocks above the form
     */
    headerSelectors?: string[];
}

class Form extends React.Component<FormProps> {
    formRef = React.createRef<HTMLFormElement>();

    validate() {
        const { validateRefs, scrollToError, offset, headerSelectors } = this.props;

        let isValid = true;
        let isThisValid = false;

        validateRefs.map(ref => {
            if (ref && ref.current) {
                const obj: any = ref.current;

                if (obj.doValidation && typeof obj.doValidation === 'function') {
                    isThisValid = obj.doValidation();
                }

                isValid = isValid && isThisValid;
            }
        });

        if (scrollToError && !isValid) {
            const formEl = this.formRef.current;
            if (formEl) {
                window.requestAnimationFrame(() => {
                    const elements = formEl.getElementsByClassName('has-error');
                    if (elements.length) {
                        const el = elements[0];
                        if (!isElementInViewport(el, headerSelectors)) {
                            scrollIntoView(el, offset, headerSelectors);
                        }
                    }
                });
            }
        }

        return isValid;
    }

    render() {
        const {
            children,
            className,
            onSubmit,
            handleSubmit,
            // remove from ...rest, hide from DOM
            validateRefs, // eslint-disable-line @typescript-eslint/no-unused-vars
            scrollToError, // eslint-disable-line @typescript-eslint/no-unused-vars
            offset, // eslint-disable-line @typescript-eslint/no-unused-vars
            headerSelectors, // eslint-disable-line @typescript-eslint/no-unused-vars
            ...rest
        } = this.props;

        const submitHandler = handleSubmit || onSubmit; // handleSubmit first alt for bw compatibility

        return (
            <form ref={this.formRef} className={className} onSubmit={submitHandler || onSubmit} {...rest}>
                {children}
            </form>
        );
    }
}

// @ts-expect-error class defaultProps
Form.defaultProps = {
    validateRefs: [],
    className: '',
    handleSubmit: null,
    onSubmit: e => {
        e.preventDefault();
    },
    scrollToError: false,
    offset: 0,
    headerSelectors: [],
};

export default Form;
