import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AccountHolderIcon.scss';

export const AccountHolderIcon = ({ num, inline, addTopMargin, cardHolder }) => {
    const classes = classNames({
        'account-holder-icon': true,
        [`account-holder-icon-${num} `]: true,
        inline,
        'mt-1x': addTopMargin,
    });

    return num ? (
        <div className={classes} title={cardHolder}>
            <span>{num}</span>
        </div>
    ) : null;
};

AccountHolderIcon.propTypes = {
    num: PropTypes.number,
    inline: PropTypes.bool,
    addTopMargin: PropTypes.bool,
    cardHolder: PropTypes.string.isRequired,
};

AccountHolderIcon.defaultProps = {
    num: null,
    inline: false,
    addTopMargin: false,
};
