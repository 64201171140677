import React, { ReactNode } from 'react';

import './LoginFooter.scss';

interface Props {
    text: ReactNode;
}

const LoginFooter = ({ text }: Props) => {
    return <footer className="login-footer">{text}</footer>;
};

export default LoginFooter;
