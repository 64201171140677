import React, { Component, RefObject } from 'react';
import classNames from 'classnames';
import { Select, Option } from '../Select';
import './CountryCodeSelect.scss';

interface CountryCodeSelectProps {
    ariaLabelCountryCode?: string;
    id?: string;
    className?: string;
    selectRef?: RefObject<any>;
    value?: string;
    label?: string;
    onChange: Function;
    displayOnly?: boolean;
    validationMessage?: string;
    language?: string;
    required?: boolean;
    name?: string;
}

type CountryCode = {
  countryName: string;
  phoneCode: string;
}

type CountryCodeSelectState = {
    countryCodes: Array<CountryCode>;
}


export class CountryCodeSelect extends Component<CountryCodeSelectProps, CountryCodeSelectState> {
    state = {
        countryCodes: [],
    }
   componentDidMount() {
        const { language } = this.props;
        import(`./countrycodes-${language}.json`).then(module => {
            this.setState({ "countryCodes": module.default });
        });
    }

    onChange = ({ target: { value } }) => {
        const { onChange } = this.props;
        onChange(value);
    };

    doValidation() {
        const { selectRef } = this.props;

        if (!selectRef) return true;

        return selectRef.current.doValidation();
    }

    render() {
        const {
            ariaLabelCountryCode,
            className,
            value,
            label,
            selectRef,
            id,
            displayOnly,
            validationMessage,
            required,
            name,
        } = this.props;

        const { countryCodes } = this.state;

        if (countryCodes?.length === 0) return null;

        const classes = classNames({
            'ec-country-code-select': true,
            'display-only': displayOnly,
            [className]: className,
        });

        const classesSelect = classNames({
            'select-country': true,
        });

        const country = countryCodes.filter(c => c.phoneCode === value);

        return (
            <div className={classes}>
                {displayOnly ? (
                    <span>
                        {country && country.length === 1
                            ? `${country[0].countryName} (${country[0].phoneCode})`
                            : value}
                    </span>
                ) : (
                    <Select
                        id={id}
                        ref={selectRef}
                        ariaLabel={ariaLabelCountryCode}
                        className={classesSelect}
                        label={label}
                        value={value}
                        required={required}
                        validationMessage={validationMessage}
                        onChange={this.onChange}
                        name={name}
                    >
                        {countryCodes.map(obj => (
                            <Option
                                key={`${obj.countryName} (${obj.phoneCode})`}
                                label={`${obj.countryName} (${obj.phoneCode})`}
                                value={obj.phoneCode}
                            />
                        ))}
                    </Select>
                )}
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
CountryCodeSelect.defaultProps = {
    id: '',
    className: '',
    selectRef: null,
    value: '',
    label: '',
    displayOnly: false,
    validationMessage: 'Välj en landskod',
    language: 'sv',
    required: false,
    name: 'countryCode',
};
