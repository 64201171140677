/* eslint-disable import/prefer-default-export */

/**
 * Add query params specified in the passed data object to a URL
 *
 *     import { addQueryParams } from '@ecster/net';
 *
 *     addQueryParams('/rest/accounts/transactions', { accountId: '12345', from: '2021-01-01', to: '2021-01-31' })
 *
 * @param {string} url The URL to add query params to. E.g: ```/rest/foo/bar```
 * @param {object} data Object with key value pairs, value can be a number, a string or an array. E.g: ```{ a: 12, b: [1,2,3]}```
 * @returns {string} The URL with query params. E.g: ```/rest/foo/bar?a=12&b=1&b=2&b=3```
 */
export const addQueryParams = (url: string, data: Record<string, unknown> | null) => {
    const sep = url.match(/\?/) ? '&' : '?';
    return !data
        ? url
        : `${url}${sep}${Object.keys(data)
              .map(key => {
                  if (Array.isArray(data[key])) {
                      // Set same key on all values for Array, example: store=1&store=2&store=3 etc
                      // @ts-expect-error
                      return data[key].reduce((res, value) => `${res}${key}=${value}&`, '');
                  }
                  if (typeof data[key] === 'number') {
                      return `${key}=${data[key]}&`;
                  }
                  return data[key] && `${key}=${data[key]}&`;
              })
              .join('')
              // remove trailing ? or &
              .replace(/&$/, '')
              .replace(/\?$/, '')}`;
};
