import { ProfilePage, ContactUsPage } from '.';
import { InvoiceOverviewPageV1, OneOffInvoicePageV1, InvoiceOverviewPageV2, OneOffInvoicePageV2 } from '../invoice';

const routes = {
    path: 'customer',
    name: 'Customer',
    childRoutes: [
        { path: 'profile', name: 'Profile page', component: ProfilePage },
        // invoices
        { path: 'invoices', name: 'Invoice overview', component: InvoiceOverviewPageV1 },
        { path: 'invoice/:invoiceId', name: 'One off invoice page', component: OneOffInvoicePageV1 },
        { path: 'related/invoice/:invoiceId', name: 'Related one off invoice page', component: OneOffInvoicePageV2 },
        // Invoices V2 BSF
        { path: 'v2/invoices/', name: 'One off invoice overview page v2', component: InvoiceOverviewPageV2 },
        { path: 'v2/invoice/:invoiceId', name: 'One off invoice page v2', component: OneOffInvoicePageV2 },
        // other
        { path: 'support', name: 'Contact us page', component: ContactUsPage },
    ],
};

export default routes;
