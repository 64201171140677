import React, { Component, MouseEventHandler, ReactNode } from 'react';
import './FullscreenDialog.scss';

interface FullscreenDialogProps {
    body?: ReactNode;
    children?: ReactNode;
    show?: boolean;
    isCloseButtonVisible?: boolean;
    onClose: MouseEventHandler<HTMLButtonElement>;
    closeButtonLabel?: string;
}

class FullscreenDialog extends Component<FullscreenDialogProps> {
    fsRef = React.createRef<HTMLDivElement>();

    render() {
        const { show, body, onClose, isCloseButtonVisible, closeButtonLabel, children } = this.props;

        const buttonCloselabel =
            closeButtonLabel !== ''
                ? closeButtonLabel
                : window.EcsterConfig && window.EcsterConfig.applicationCountry === 'FI'
                ? 'Sulje ohjeikkuna'
                : 'Stäng hjälpfönstret';

        if (show) {
            window.scrollTo(0, 0);
            // fade in dialog
            setTimeout(() => {
                this.fsRef?.current?.classList.add('show');
            }, 10);
        }

        if (!show) return null;

        return (
                <div className="fullscreen-dialog" ref={this.fsRef}>
                    <>
                        {isCloseButtonVisible && (
                            <button
                                aria-label={buttonCloselabel}
                                className="fullscreen-dialog-close"
                                name="fullscreen-dialog-close-button"
                                id="fullscreen-dialog-close-button"
                                onClick={onClose}
                            />
                        )}
                        {body}
                        {children}
                    </>
                </div>
            );
    }
}

// @ts-expect-error class defaultProps
FullscreenDialog.defaultProps = {
    body: null,
    children: null,
    show: false,
    isCloseButtonVisible: true,
    closeButtonLabel: '',
};

export default FullscreenDialog;
