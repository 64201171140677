import React, { ReactNode } from 'react';
import './DialogFooter.scss';

interface DialogFooterProps {
    children?: ReactNode;
}

const DialogFooter = ({ children }: DialogFooterProps) => {
    return <div className="ec-dialog-footer">{children}</div>;
}

DialogFooter.defaultProps = {
    children: <span />,
};

export default DialogFooter;
