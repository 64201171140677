import { ServerLog } from './ServerLog';

/** Log error information to external solution
 *
 * @param err Error object
 * @param operation String optional operation name
 */
export const reportError = (err, operation = '') => {
    ServerLog.error(operation, err);
    console.error(err);
};
