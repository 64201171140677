import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Panel.scss';

/**
 * Create a white box Panel.
 */

const { EcsterConfig: conf } = window;

interface PanelProps {
    /**
     * The panels child elements
     *
     * Add div elements only, classified according to this documentation.
     */
    children: ReactNode;

    /** Any extra classNames to add to the panel container div */
    className?: string;

    /**
     * *Will be deprecated* use paddingTopBottom and paddingLeftRight with PanelContent instead
     *
     * A panel with text only content, p tags only. Content gets a max width.
     *
     * Wrap content in a div.text-content.
     */
    withTextContent?: boolean;

    /**
     * *Will be deprecated* use paddingTopBottom and paddingLeftRight with PanelContent instead
     *
     * A panel with mixed content such as, icon + header + text or header + text.
     * Content gets a max width
     *
     * Wrap content in one or more div.mixed-content or div.two-col-content
     */
    withMixedContent?: boolean;

    /**
     * *Will be deprecated* use paddingTopBottom and paddingLeftRight with PanelContent instead
     *
     * A panel with table content. Content will be 100% wide
     *
     * Wrap content in a div.table-con©tent.
     */
    withTableContent?: boolean;

    /**
     * *Will be deprecated* use paddingTopBottom and paddingLeftRight with PanelContent instead
     *
     * A panel with content. Content will be 100% wide inside the panel.
     *
     * Wrap content in a div.full-width-content.
     */
    withFullWidthContent?: boolean;

    /**
     * Same as withFullWidthContent - kept for backward compatibility.
     * @deprecated
     *
     * Use paddingTopBottom and paddingLeftRight with PanelContent instead
     */
    compact?: boolean;

    /**
     * *Will be deprecated* use paddingTopBottom and paddingLeftRight with PanelContent instead
     *
     * A panel with side padding only. Gives you full control over space between panel top and bottom edges and content.
     */
    withSidePaddingOnly?: boolean;

    /**
     * *Will be deprecated* use paddingTopBottom and paddingLeftRight with PanelContent instead
     *
     * A panel with no padding at all. Gives you full control over space between panel edges and content.
     */
    withNoPadding?: boolean;

    /**
     * Same as withNoPadding - kept for backward compatibility.
     * @deprecated
     *
     * Use paddingTopBottom and paddingLeftRight with PanelContent instead
     */
    noPadding?: boolean;

    /**
     * Set a light green background color.
     *
     * Recommended text color $green120 or black
     */
    greenBG?: boolean;

    /**
     * Set a light blue background color.
     *
     * Recommended text color $blue120 or black
     */
    blueBG?: boolean;

    /**
     * Set a light purple background color.
     *
     * Recommended text color $purple120 or black
     */
    purpleBG?: boolean;

    /**
     * Set a light red background color.
     *
     * Recommended text color $red120 or black
     */
    redBG?: boolean;

    /**
     * Set a light green background color.
     *
     * Recommended text color $green120 or black
     */
    yellowBG?: boolean;

    /**
     * Set a light beige background color.
     *
     * Recommended text color $beige120/140 or black
     */
    beigeBG?: boolean;

    /**
     * Transparent background, no borders
     */
    transparent?: boolean;

    /**
     * Set a max width for the panel.
     *
     * Panel is centered on screen with auto margins.
     */
    maxWidth?: string;

    /**
     * Center all child elements of panel.
     */
    centeredContent?: boolean;

    /**
     * Stretch panel to 100% width in mobile only.
     *
     * Side borders and border radius are removed.
     */
    stretchInMobile?: boolean;

    /**
     * Stretch panel to 100% width in mobile only.
     *
     * Stretch extra with negative margins to compensate for page layouts with 15px padding.
     *
     * Side borders and border radius are removed.
     */
    stretchExtraInMobile?: boolean;

    /**
     * Add extra side margins in mobile.
     */
    sideMarginsInMobile?: boolean;

    /**
     * Change border color to light gray (for pages with gray background)
     */
    lightGrayBorder?: boolean;

    /**
     * Change border color to pure gray (for Ecster Pay and similar)
     */
    grayBorder?: boolean;

    /**
     * Remove the border
     */
    noBorder?: boolean;

    /**
     * Default top and bottom padding
     *
     * Replaces withXXX properties when used with PanelContent
     *
     * - none = 0px
     * - xSmall = 10px
     * - small = 15px
     * - medium = 20px
     * - large = 30px
     * - xLarge 40px
     * - xxLarge 50px
     */
    paddingTopBottom?: 'none' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';

    /**
     * Default left and right padding
     *
     * Replaces withXXX properties when used with PanelContent
     *
     * - none = 0px
     * - xSmall = 10px
     * - small = 15px
     * - medium = 20px
     * - large = 30px
     * - xLarge 40px
     * - xxLarge 50px
     */
    paddingLeftRight?: 'none' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';

    /**
     * Shorthand to set all paddings equal
     *
     * Replaces withXXX properties when used with PanelContent
     *
     * - none = 0px
     * - xSmall = 10px
     * - small = 15px
     * - medium = 20px
     * - large = 30px
     * - xLarge 40px
     * - xxLarge 50px
     */
    paddingAll?: 'none' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';

    id?: string;
}

const Panel = ({
    children,
    className,
    withTextContent,
    withMixedContent,
    withTableContent,

    withFullWidthContent,
    compact,

    withNoPadding,
    noPadding,

    withSidePaddingOnly,

    greenBG,
    blueBG,
    purpleBG,
    redBG,
    yellowBG,
    beigeBG,
    transparent,
    maxWidth,
    centeredContent,
    stretchInMobile,
    stretchExtraInMobile,
    sideMarginsInMobile,
    lightGrayBorder,
    grayBorder,
    noBorder,
    paddingTopBottom,
    paddingLeftRight,
    paddingAll,
    ...rest
}: PanelProps) => {
    const style = { maxWidth };

    const oldAPI =
        withTextContent ||
        withMixedContent ||
        withTableContent ||
        withFullWidthContent ||
        compact ||
        withNoPadding ||
        noPadding ||
        withSidePaddingOnly;

    if (oldAPI && conf?.env !== 'prod') {
        console.warn(
            "warning: you're using a deprecated Panel API - use the paddingTopBottom/LeftRight/All properties instead\n info: the withXxx, compact and noPadding properties are deprecated"
        );
    }

    return (
        <section
            {...rest}
            style={style}
            className={classNames({
                'centered-content': centeredContent,
                'ec-panel': true,
                'gray-border': grayBorder,
                'no-border': noBorder,
                'green-bg': greenBG,
                'blue-bg': blueBG,
                'purple-bg': purpleBG,
                'red-bg': redBG,
                'yellow-bg': yellowBG,
                'beige-bg': beigeBG,
                'has-max-width': maxWidth,
                'light-gray-border': lightGrayBorder,
                'side-margins-mobile': sideMarginsInMobile,
                'stretch-extra-mobile': stretchExtraInMobile,
                'stretch-mobile': stretchInMobile || stretchExtraInMobile,
                'with-full-width-content': withFullWidthContent || compact,
                'with-mixed-content': withMixedContent,
                'with-no-padding': withNoPadding || noPadding,
                'with-side-padding-only': withSidePaddingOnly,
                'with-table-content': withTableContent,
                'with-text-content': withTextContent,
                transparent,
                // top bottom padding
                'pad-tb-none':
                    !oldAPI &&
                    (paddingTopBottom === 'none' || paddingAll === 'none') &&
                    !(paddingAll && paddingAll !== 'none'),
                'pad-tb-x-small':
                    !oldAPI &&
                    (paddingTopBottom === 'xSmall' || paddingAll === 'xSmall') &&
                    !(paddingAll && paddingAll !== 'xSmall'),
                'pad-tb-small':
                    !oldAPI &&
                    (paddingTopBottom === 'small' || paddingAll === 'small') &&
                    !(paddingAll && paddingAll !== 'small'),
                'pad-tb-medium':
                    !oldAPI &&
                    (paddingTopBottom === 'medium' || paddingAll === 'medium') &&
                    !(paddingAll && paddingAll !== 'medium'),
                'pad-tb-large':
                    !oldAPI &&
                    (paddingTopBottom === 'large' || paddingAll === 'large') &&
                    !(paddingAll && paddingAll !== 'large'),
                'pad-tb-x-large':
                    !oldAPI &&
                    (paddingTopBottom === 'xLarge' || paddingAll === 'xLarge') &&
                    !(paddingAll && paddingAll !== 'xLarge'),
                'pad-tb-xx-large':
                    !oldAPI &&
                    (paddingTopBottom === 'xxLarge' || paddingAll === 'xxLarge') &&
                    !(paddingAll && paddingAll !== 'xxLarge'),
                // left right padding
                'pad-lr-none':
                    !oldAPI &&
                    (paddingLeftRight === 'none' || paddingAll === 'none') &&
                    !(paddingAll && paddingAll !== 'none'),
                'pad-lr-x-small':
                    !oldAPI &&
                    (paddingLeftRight === 'xSmall' || paddingAll === 'xSmall') &&
                    !(paddingAll && paddingAll !== 'xSmall'),
                'pad-lr-small':
                    !oldAPI &&
                    (paddingLeftRight === 'small' || paddingAll === 'small') &&
                    !(paddingAll && paddingAll !== 'small'),
                'pad-lr-medium':
                    !oldAPI &&
                    (paddingLeftRight === 'medium' || paddingAll === 'medium') &&
                    !(paddingAll && paddingAll !== 'medium'),
                'pad-lr-large':
                    !oldAPI &&
                    (paddingLeftRight === 'large' || paddingAll === 'large') &&
                    !(paddingAll && paddingAll !== 'large'),
                'pad-lr-x-large':
                    !oldAPI &&
                    (paddingLeftRight === 'xLarge' || paddingAll === 'xLarge') &&
                    !(paddingAll && paddingAll !== 'xLarge'),
                'pad-lr-xx-large':
                    !oldAPI &&
                    (paddingLeftRight === 'xxLarge' || paddingAll === 'xxLarge') &&
                    !(paddingAll && paddingAll !== 'xxLarge'),
                [className]: className,
            })}
        >
            {children}
        </section>
    );
};

export default Panel;

Panel.defaultProps = {
    className: undefined,

    withTextContent: false,
    withMixedContent: false,
    withTableContent: false,
    withFullWidthContent: false,
    compact: false, // same as withFullWidthContent, backward compatibility
    withNoPadding: false,
    noPadding: false,
    withSidePaddingOnly: false,

    greenBG: false,
    blueBG: false,
    purpleBG: false,
    redBG: false,
    yellowBG: false,
    beigeBG: false,
    transparent: false,

    maxWidth: undefined,
    centeredContent: false,

    stretchInMobile: false,
    stretchExtraInMobile: false,

    sideMarginsInMobile: false,
    lightGrayBorder: false,
    grayBorder: false,
    noBorder: false,

    paddingTopBottom: 'large',
    paddingLeftRight: 'small',
    paddingAll: '',
};
