import { put } from '@ecster/net/v2';

import {
    CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_BEGIN,
    CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_SUCCESS,
    CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_FAILURE,
    CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_DISMISS_ERROR,
    CLEAR_UPDATE_CUSTOMER_CONTACT_INFO_STATE,
} from './constants';
import { UPDATE_CUSTOMER_CONTACT_INFO_URL } from './urls';
import { getCustomer } from './getCustomer';
import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

export const updateCustomerContactInfo = (customerId, customerHasAccounts, data) => async dispatch => {
    dispatch({
        type: CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_BEGIN,
    });

    try {
        if (data.phoneNumber && data.phoneNumber.number) {
            // eslint-disable-next-line no-param-reassign
            data.phoneNumber.number = data.phoneNumber.number.replace(/\D/g, '');
        }

        await cache.clear();
        const res = await put(UPDATE_CUSTOMER_CONTACT_INFO_URL(customerId), data);

        parseServerResponseStatus(res);

        dispatch({
            type: CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_SUCCESS,
            data: res.response,
        });
        dispatch(getCustomer(customerId, customerHasAccounts));
    } catch (err) {
        reportError(err, 'updateCustomerContactInfo');

        dispatch({
            type: CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_FAILURE,
            data: { error: { status: err.status, statusText: err.statusText } },
        });
    }
};

export const dismissUpdateCustomerContactInfoError = () => ({
    type: CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_DISMISS_ERROR,
});

export const clearUpdateCustomerContactInfoState = () => ({
    type: CLEAR_UPDATE_CUSTOMER_CONTACT_INFO_STATE,
});

export function reducer(state, action) {
    switch (action.type) {
        case CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_BEGIN:
            return {
                ...state,
                updateCustomerContactInfoPending: true,
                updateCustomerContactInfoError: null,
                updateCustomerContactInfoIsDone: false,
                updateCustomerContactInfoIsError: false,
            };

        case CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_SUCCESS:
            return {
                ...state,
                updateCustomerContactInfoPending: false,
                updateCustomerContactInfoError: null,
                updateCustomerContactInfoIsDone: true,
            };

        case CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_FAILURE:
            return {
                ...state,
                updateCustomerContactInfoPending: false,
                updateCustomerContactInfoError: action.data.error,
                updateCustomerContactInfoIsError: true,
            };

        case CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_DISMISS_ERROR:
            return {
                ...state,
                updateCustomerContactInfoError: null,
                updateCustomerContactInfoIsError: false,
            };

        case CLEAR_UPDATE_CUSTOMER_CONTACT_INFO_STATE:
            return {
                ...state,
                updateCustomerContactInfoIsDone: false,
                updateCustomerContactInfoPending: false,
                updateCustomerContactInfoError: null,
                updateCustomerContactInfoIsError: false,
            };

        default:
            return state;
    }
}
