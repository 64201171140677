/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-did-update-set-state */
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import scrollIntoView from '../util/scroll-into-view';
import InteractiveElement from '../Clickable/InteractiveElement';
import './ExpandablePanel.scss';
import Button, { ButtonProps } from '../Clickable/Button';
import detectDevice from '../util/detect-device';
import chevronDown from './svg/chevron-down.svg';

interface ExpandablePanelProps {
    ariaLabel?: string;
    children: ReactNode;
    handleCollapse: Function;
    collapse?: boolean;
    isDisabled?: boolean;
    shouldScrollToTopOnExpand?: boolean;
    offsetHeaderSelectors?: string[];
    isCompact?: boolean;
    noBorder?: boolean;
    noPadding?: boolean;
    noAnimation?: boolean;
    noHeader?: boolean;
    style?: CSSProperties;
    icon?: string;
    showMoreLabel?: string | ReactNode;
    showLessLabel?: string | ReactNode;
    mainLabel?: string | ReactNode;
    id?: string;
    name?: string;
    showIcon?: boolean | null;
    className?: string;
    buttonProps?: ButtonProps;
    multiButtonsLabels?: string[] | ReactNode[];
    multiButtonsOnClick?: { (data?: any): void }[];
    showMultiButtonsWhenExpanded?: boolean;
    buttonLabel?: string;
    mainSubLabel?: string;
    buttonOnClick?: (data: any) => void;
}
export const ExpandablePanel = ({
    ariaLabel = '',
    collapse = false,
    showIcon = null,
    isDisabled = false,
    shouldScrollToTopOnExpand = false,
    offsetHeaderSelectors = ['.page-header', '.top-navigation'],
    isCompact = false,
    handleCollapse,
    noBorder = false,
    noPadding = false,
    noAnimation = false,
    noHeader = false,
    style = {},
    icon = '',
    showMoreLabel = 'Visa mer',
    showLessLabel = 'Visa mindre',
    mainLabel = '',
    buttonLabel = '',
    mainSubLabel = '',
    buttonOnClick = () => {},
    buttonProps = {} as ButtonProps,
    multiButtonsLabels = [],
    multiButtonsOnClick = [],
    showMultiButtonsWhenExpanded = false,
    id = '',
    name,
    className = '',
    children,
}: ExpandablePanelProps) => {
    const [isCollapsed, setIsCollapsed] = useState(collapse);
    const [showRightIcon, setShowRightIcon] = useState(null);
    const [height, setHeight] = useState('0px');
    const containerRef = useRef<HTMLDivElement>();
    const { isMobile } = detectDevice();

    useEffect(() => {
        // for handling backwards compatibility
        if (showIcon === null) {
            setShowRightIcon(mainLabel === '');
        } else {
            setShowRightIcon(showIcon);
        }
    }, []);

    useEffect(() => {
        if (noAnimation && !isCollapsed) setHeight('inherit');
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            // set for special elements that already have tabindex
            setNotFocusable(findElementsWithTabIndex());
            // and ordinary focusable elements
            setNotFocusable(findFocusableElements());
        } else {
            // set for special elements that already have tabindex
            setFocusable(findElementsWithTabIndex());
            // and ordinary focusable elements
            setFocusable(findFocusableElements());
        }

        if (!noAnimation) calculateHeight();
    }, []);

    useEffect(() => {
        if (noAnimation) setHeight('inherit');
    }, [isCollapsed]);

    useEffect(() => {
        if (collapse) {
            setNotFocusable(findElementsWithTabIndex());
        } else {
            setFocusable(findElementsWithTabIndex());
        }

        if (collapse && !isCollapsed) {
            // Recalculate current height before we collapse and will switch inherit -> calculated height -> 0px to start animating
            calculateHeight(() => {
                // RequestAnimationFrame 2 times to let DOM settle when changing height: inherit -> calculated height.
                // Because we only skip until BEFORE next frame and we need 1 frame to have rendered.
                window.requestAnimationFrame(() => window.requestAnimationFrame(() => setIsCollapsed(collapse)));
            });
        } else {
            // Expanded
            setIsCollapsed(collapse);
        }
    }, [collapse]);

    const onTransitionEnd = e => {
        // We have to remove the fixed height when done, so the box can grow if needed
        if (!isCollapsed) {
            setHeight('inherit');

            if (shouldScrollToTopOnExpand) {
                if (e.propertyName === 'height' && e.target.id === `parent${id}`) {
                    scrollIntoView(`#${id}`, 0, offsetHeaderSelectors);
                }
            }
        }
    };

    const onClick = () => {
        handleCollapse(isCollapsed);
    };

    const setTabIndex = (elements, value) => {
        if (elements && elements.length > 0) {
            elements.forEach(el => {
                el.setAttribute('tabindex', value);
            });
        }
    };

    // todo? should we backup (copy to a data-tabindex attribute) any "unconventional" tabindex values
    //     so they can be reset when setFocusable? I.e. tabindex values greater than 0
    const setNotFocusable = elements => {
        setTabIndex(elements, '-1');
    };

    const setFocusable = elements => {
        setTabIndex(elements, '0');
    };

    // common focusable elements
    const findFocusableElements = () => containerRef.current.querySelectorAll('input, textarea, select, button, a');

    // other focusable elements (with tabindex set)
    const findElementsWithTabIndex = () => containerRef.current.querySelectorAll('[tabindex]');

    const calculateHeight = (cb = () => {}) => {
        // eslint-disable-next-line
        setHeight(`${containerRef.current.offsetHeight}px`)
        setHeight(`${containerRef.current.offsetHeight}px`);
        cb();
    };

    const rootClasses = classNames({
        'ec-expandable-panel': true,
        'ec-expandable-panel--bordered': !noBorder,
        'ec-expandable-panel--no-bottom-padding': isCollapsed,
        'ec-expandable-panel--compact': isCompact,
        'ec-expandable-panel--no-padding': noPadding,
        'ec-is-disabled': isDisabled,
        [className]: className,
    });

    const contentClasses = classNames({
        'ec-expandable-panel__content': true,
        'ec-expandable-panel__content-noAnimation': noAnimation,
    });

    const wrapperClasses = classNames({
        'ec-expandable-panel__content-wrapper': true,
        'ec-expandable-panel__content-wrapper-noPadding': noPadding,
    });

    const expanderClasses = classNames({
        'ec-expandable-panel__expander': true,
        'ec-expandable-panel__expander-center': mainLabel,
        'ec-expandable-panel__expander-hidden': noHeader,
    });
    interface IMultiButtons {
        isMobile?: boolean;
    }

    const MultiButtons = ({ isMobile = false }: IMultiButtons) => (
        <div className="row">
            {multiButtonsLabels.map((label, index) => (
                <h4
                    onClick={multiButtonsOnClick?.[index]}
                    className={`ec-expandable-panel__multi-button${isMobile ? '__isMobile' : ''}`}
                >
                    {label}
                </h4>
            ))}
        </div>
    );

    const isJSXLabel = React.isValidElement(mainLabel);

    return (
        <div
            style={style}
            className={rootClasses}
            id={id}
            // @ts-expect-error name?
            name={name}
        >
            <div className="row">
                <InteractiveElement className={expanderClasses} ariaLabel={ariaLabel} onClick={onClick}>
                    {showMoreLabel !== '' && showLessLabel !== '' ? (
                        <div className={`col${isMobile ? '__isMobile' : ''}`}>
                            <div className="row__space">
                                <h3 className="ec-expandable-panel__show-more-text">
                                    {isCollapsed ? showMoreLabel : showLessLabel}
                                </h3>
                                {showRightIcon && isMobile && (
                                    <div
                                        onClick={() => handleCollapse(isCollapsed)}
                                        className="ec-expandable-panel__arrow-wrapper_isMobile"
                                    >
                                        {icon !== '' ? (
                                            <i className={`e-purple ${icon}`} />
                                        ) : (
                                            <img
                                                alt=""
                                                style={{ transform: `rotate(${isCollapsed ? 0 : 180}deg)` }}
                                                className="ec-expandable-panel__arrow"
                                                src={chevronDown}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            {isMobile && (
                                <div className="col">
                                    {buttonLabel && (
                                        <div className="button-wrapper">
                                            <Button
                                                xSmall
                                                className="ec-expandable-panel__main-button"
                                                outline
                                                green
                                                onClick={buttonOnClick}
                                                {...buttonProps}
                                            >
                                                {buttonLabel}
                                            </Button>
                                        </div>
                                    )}
                                    {showMultiButtonsWhenExpanded ? (
                                        !isCollapsed && <MultiButtons isMobile />
                                    ) : (
                                        <MultiButtons isMobile />
                                    )}
                                    <div className="row">
                                        <div className="ec-expandable-panel__main-label__mobile">{`${
                                            mainLabel && typeof mainLabel === 'string' ? mainLabel : ''
                                        } ${mainSubLabel && ` ${mainSubLabel}`}`}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        // Empty heading isn't allowed according to WCAG AA
                        <div className="h3">&nbsp;</div>
                    )}
                    <div className="row">
                        {isJSXLabel && !isMobile ? (
                            mainLabel
                        ) : (
                            <div className="ec-expandable-panel__main-label-wrapper">
                                {mainLabel && !isMobile && (
                                    <div className="ec-expandable-panel__main-label">{mainLabel}</div>
                                )}
                                {mainSubLabel && !isMobile && (
                                    <div className="ec-expandable-panel__main-sub-label">{mainSubLabel}</div>
                                )}
                            </div>
                        )}
                    </div>
                </InteractiveElement>
                {!isMobile ? showMultiButtonsWhenExpanded ? !isCollapsed && <MultiButtons /> : <MultiButtons /> : null}
                {buttonLabel && !isMobile && (
                    <Button
                        xSmall
                        className="ec-expandable-panel__main-button"
                        outline
                        green
                        onClick={buttonOnClick}
                        {...buttonProps}
                    >
                        {buttonLabel}
                    </Button>
                )}
                {showRightIcon && !isMobile && (
                    <div onClick={() => handleCollapse(isCollapsed)} className="ec-expandable-panel__arrow-wrapper">
                        {icon !== '' ? (
                            <i className={`e-purple ${icon}`} />
                        ) : (
                            <img
                                alt=""
                                style={{ transform: `rotate(${isCollapsed ? 0 : 180}deg)` }}
                                className="ec-expandable-panel__arrow"
                                src={chevronDown}
                            />
                        )}
                    </div>
                )}
            </div>
            <div
                id={`parent${id}`}
                className={contentClasses}
                onTransitionEnd={onTransitionEnd}
                style={{ height: isCollapsed ? '0px' : height }}
            >
                <section ref={containerRef} className={wrapperClasses}>
                    {children}
                </section>
            </div>
        </div>
    );
};

ExpandablePanel.defaultProps = {
    ariaLabel: '',
    collapse: false,
    isDisabled: false,
    shouldScrollToTopOnExpand: false,
    offsetHeaderSelectors: ['.page-header', '.top-navigation'],
    isCompact: false,
    noBorder: false,
    noPadding: false,
    noAnimation: false,
    noHeader: false,
    style: {},
    icon: '',
    showMoreLabel: 'Visa mer',
    showLessLabel: 'Visa mindre',
    mainLabel: '',
    buttonLabel: '',
    mainSubLabel: '',
    buttonOnClick: () => {},
    id: '',
    className: '',
    multiButtonsLabels: [],
    multiButtonsOnClick: [],
    buttonProps: {},
    showIcon: null,
    name: null,
    showMultiButtonsWhenExpanded: false,
};

export default ExpandablePanel;
