export const CUSTOMER_GET_CUSTOMER_BEGIN = 'CUSTOMER_GET_CUSTOMER_BEGIN';
export const CUSTOMER_GET_CUSTOMER_SUCCESS = 'CUSTOMER_GET_CUSTOMER_SUCCESS';
export const CUSTOMER_GET_CUSTOMER_FAILURE = 'CUSTOMER_GET_CUSTOMER_FAILURE';
export const CUSTOMER_GET_CUSTOMER_DISMISS_ERROR = 'CUSTOMER_GET_CUSTOMER_DISMISS_ERROR';

/** @deprecated */
export const CUSTOMER_GET_CUSTOMER_PROPERTIES_BEGIN = 'CUSTOMER_GET_CUSTOMER_PROPERTIES_BEGIN';
/** @deprecated */
export const CUSTOMER_GET_CUSTOMER_PROPERTIES_SUCCESS = 'CUSTOMER_GET_CUSTOMER_PROPERTIES_SUCCESS';
/** @deprecated */
export const CUSTOMER_GET_CUSTOMER_PROPERTIES_FAILURE = 'CUSTOMER_GET_CUSTOMER_PROPERTIES_FAILURE';
/** @deprecated */
export const CUSTOMER_GET_CUSTOMER_PROPERTIES_DISMISS_ERROR = 'CUSTOMER_GET_CUSTOMER_PROPERTIES_DISMISS_ERROR';

export const CUSTOMER_GET_CUSTOMER_PROPERTY_BEGIN = 'CUSTOMER_GET_CUSTOMER_PROPERTY_BEGIN';
export const CUSTOMER_GET_CUSTOMER_PROPERTY_SUCCESS = 'CUSTOMER_GET_CUSTOMER_PROPERTY_SUCCESS';
export const CUSTOMER_GET_CUSTOMER_PROPERTY_FAILURE = 'CUSTOMER_GET_CUSTOMER_PROPERTY_FAILURE';
export const CUSTOMER_GET_CUSTOMER_PROPERTY_DISMISS_ERROR = 'CUSTOMER_GET_CUSTOMER_PROPERTY_DISMISS_ERROR';

export const CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_BEGIN = 'CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_BEGIN';
export const CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_SUCCESS = 'CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_SUCCESS';
export const CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_FAILURE = 'CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_FAILURE';
export const CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_DISMISS_ERROR =
    'CUSTOMER_GET_CUSTOMER_EXTRA_CARD_HOLDERS_DISMISS_ERROR';
export const CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_BEGIN = 'CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_BEGIN';
export const CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_SUCCESS = 'CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_SUCCESS';
export const CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_FAILURE = 'CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_FAILURE';
export const CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_DISMISS_ERROR =
    'CUSTOMER_UPDATE_CUSTOMER_CONTACT_INFO_DISMISS_ERROR';
export const CLEAR_UPDATE_CUSTOMER_CONTACT_INFO_STATE = 'CLEAR_UPDATE_CUSTOMER_CONTACT_INFO_STATE';
export const CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_BEGIN =
    'CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_BEGIN';
export const CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_SUCCESS =
    'CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_SUCCESS';
export const CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_FAILURE =
    'CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_FAILURE';
export const CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_DISMISS_ERROR =
    'CUSTOMER_UPDATE_CUSTOMER_EXTRA_CARD_HOLDER_CONTACT_INFO_DISMISS_ERROR';
export const CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_BEGIN = 'CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_BEGIN';
export const CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_SUCCESS = 'CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_SUCCESS';
export const CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_FAILURE = 'CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_FAILURE';
export const CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_DISMISS_ERROR =
    'CUSTOMER_CREATE_CUSTOMER_SERVICE_REQUEST_DISMISS_ERROR';
