import React, { Component, CSSProperties, FocusEventHandler, MouseEventHandler, ReactNode } from 'react';
import getClassNames from './classNames';
import './Button.scss';

export interface ButtonProps {
    children: ReactNode;

    /**
     * Set this to true to not show chrome and firefox popups on required fields during submit
     */
    formNoValidate?: boolean;
    name?: string;

    /**
     * An optional numeric value to use for the onClick GA event.
     */
    gaValue?: number;
    id?: string;

    /**
     * The text shown to screen readers
     */
    ariaLabel?: string;
    className?: string;
    style?: CSSProperties;

    /**
     * Set the type to "submit" or "reset" to get equivalent behaviour
     */
    type?: 'button' | 'submit' | 'reset';

    // icons
    icon?: string;
    iconLeft?: string;
    iconRight?: string;
    spinnerLeft?: boolean;
    spinnerRight?: boolean;

    // colors

    /**
     * Green is the default button color if none is specified
     */
    green?: boolean;
    blue?: boolean;
    purple?: boolean;
    red?: boolean;
    beige?: boolean;
    gray?: boolean;
    lightGray?: boolean;
    white?: boolean;

    /**
     * Looks like a link, it has equal height and width and its clickable area is
     * like filled or outlined buttons, i.e it is clickable outside the text
     */
    transparent?: boolean;

    /**
     * Looks like a link, its clickable area is just on the text.
     *
     * Text aligns with other Buttons inside a ButtonGroup.
     */
    link?: boolean;
    bankId?: boolean;

    // size options
    small?: boolean;
    xSmall?: boolean;

    /** default size */
    normal?: boolean;
    large?: boolean;

    /** @deprecated - use large instead */
    xLarge?: boolean;

    // variant options
    outline?: boolean;
    round?: boolean;
    rounded?: boolean;
    circular?: boolean;
    square?: boolean;
    stretch?: boolean;
    block?: boolean;
    disabled?: boolean;
    ignoreClick?: boolean; // disable w/o disabled styling
    tabIndex?: number;

    // events
    onClick?: Function; // MouseEventHandler<HTMLButtonElement>;
    onBlur?: Function; // FocusEventHandler<HTMLButtonElement>;
}

export type ButtonPropsBase = Omit<ButtonProps, 'onClick'>;

interface SpinnerSvgProps {
    className: string;
}

const SpinnerSvg = ({ className }: SpinnerSvgProps) => (
    <span className={`ec-button-spinner ${className}`}>
        <svg width="21.408" height="22.511" viewBox="0 0 5.664 5.956" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-30.579 -104.224)">
                <path d="M36.24 107.348c-.061 1.386-1.372 2.534-2.645 2.461-1.274-.05-2.36-1.3-2.277-2.46.034-1.005.927-1.908 1.847-2.072l-.123-.508-.122-.509c-1.382.39-2.375 1.696-2.34 3.088.083 1.61 1.517 2.881 3.015 2.83 1.497-.072 2.707-1.444 2.645-2.83z" />
                <circle cx="33.123" cy="104.753" r=".529" />
            </g>
        </svg>
    </span>
);

/**
 * A Button component.
 */
class Button extends Component<ButtonProps> {
    buttonRef = React.createRef<HTMLButtonElement>();

    onClickTimeout = undefined; // Used as object prop instead of State for simplicity and no async problems

    onClick = e => {
        const that = this;
        const { ignoreClick } = this.props;
        if (this.onClickTimeout || ignoreClick) {
            // Cool down for spamming Button
            e.preventDefault(); // prevent form submit
            return;
        }

        const { onClick } = this.props;

        // Set 250ms cool down on button click
        this.onClickTimeout = setTimeout(() => {
            clearTimeout(that.onClickTimeout);
            that.onClickTimeout = undefined;
        }, 250);

        if (typeof onClick === 'function') onClick(e);
    };

    focus = () => {
        this.buttonRef.current.focus();
    };

    render() {
        const {
            iconLeft,
            iconRight,
            spinnerLeft,
            spinnerRight,
            icon,
            id,
            name,
            style,
            disabled,
            type,
            children,
            tabIndex,
            onBlur,
            formNoValidate,
            ariaLabel,
            ...rest
        } = this.props;

        const leftIcon = spinnerLeft ? (
            <SpinnerSvg className="spinner-left" />
        ) : (
            (iconLeft || icon) && <i className={iconLeft || icon} />
        );
        const rightIcon = spinnerRight ? (
            <SpinnerSvg className="spinner-right" />
        ) : (
            iconRight && <i className={iconRight} />
        );

        /* eslint-disable react/button-has-type */
        return (
            <button
                id={id}
                aria-label={ariaLabel}
                formNoValidate={formNoValidate}
                name={name}
                onClick={this.onClick}
                onBlur={() => onBlur()}
                style={style}
                disabled={disabled}
                type={type}
                className={getClassNames(rest)}
                ref={this.buttonRef}
                tabIndex={tabIndex}
            >
                {leftIcon} <span>{children}</span> {rightIcon}
            </button>
        );
    }
}

// @ts-expect-error class defaultProps
Button.defaultProps = {
    children: undefined,
    formNoValidate: false,
    name: '',
    id: undefined,
    ariaLabel: '',
    className: '',
    style: {},
    type: 'button',

    // icons
    icon: undefined,
    iconLeft: undefined,
    iconRight: undefined,
    spinnerLeft: false,
    spinnerRight: false,

    // color tweaks
    green: false,
    blue: false,
    purple: false,
    red: false,
    beige: false,
    gray: false,
    lightGray: false,
    white: false,
    transparent: false,
    link: false,
    bankId: false,

    // size options
    xSmall: false,
    small: false,
    normal: false,
    large: false,
    xLarge: false,

    // variant options
    outline: false,
    round: false,
    rounded: false,
    circular: false,
    square: false,
    stretch: false,
    block: false,

    disabled: false,
    ignoreClick: false,

    tabIndex: undefined,

    onBlur: () => {},
};

export default Button;
