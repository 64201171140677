import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Message } from '@ecster/components';

import './NotificationPanel.scss';

class NotificationPanel extends Component {
    static propTypes = {
        notifications: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
                title: PropTypes.string.isRequired,
                message: PropTypes.string.isRequired,
                severity: PropTypes.oneOf(['info', 'warning']),
            })
        ),
    };

    static defaultProps = {
        notifications: [],
    };

    render() {
        const { notifications } = this.props;

        return (
            notifications.length > 0 && (
                <div className="notifications">
                    {notifications.map(notification => {
                        const { title, message, id, severity } = notification;
                        return (
                            <Message
                                purple={severity !== 'info'}
                                blue={severity === 'info'}
                                key={`id${id}`}
                                header={title}
                                icon={false}
                                data-testid={`message-${id}`}
                            >
                                {message}
                            </Message>
                        );
                    })}
                </div>
            )
        );
    }
}

export default NotificationPanel;
