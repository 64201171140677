import React from 'react';
import { LabelValue, FullscreenDialog } from '@ecster/components';
import { i18n } from '@ecster/i18n';

// TODO: move to @ecster/icons
import { SvgIconConstruction } from './images';
import './BackendIsDown.scss';

const BackendIsDown = () => (
    <div className="backend-is-down">
        <FullscreenDialog show isCloseButtonVisible={false} onClose={() => {}}>
            <div className="flex flex-col justify-center">
                <SvgIconConstruction />
                <h1>{i18n('status.backend.down.header')}</h1>
                {i18n('status.backend.down.body', 'div')}
                <footer className="mb-6x">
                    <h2>{i18n('status.backend.down.block-card.header')}</h2>
                    <div className="">
                        <LabelValue
                            label={i18n('status.backend.down.block-card.domestic.label')}
                            value={i18n('status.backend.down.block-card.domestic.phone', { danger: true })}
                        />
                        <LabelValue
                            label={i18n('status.backend.down.block-card.abroad.label')}
                            value={i18n('status.backend.down.block-card.abroad.phone', { danger: true })}
                        />
                    </div>
                    <div />
                </footer>
            </div>
        </FullscreenDialog>
    </div>
);

export default BackendIsDown;
