import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { formatDate, formatAccount } from '@ecster/util';
import { Panel, PanelContent, LabelValue, CopyToClipboardButton, TabletOrDesktop } from '@ecster/components';

import * as invoiceStatus from '../../invoiceStatus';
import * as paymentStatus from '../../paymentStatus';
import ErrorBoundary from '../../../../common/ErrorBoundary';
import OcrScan from '../../ocr-scan/OcrScan';

export const InvoiceDetailsPanel = ({ invoice }) => {
    const { status, paymentStatus: invoicePaymentStatus, purchaseDate, paymentDate } = invoice;

    const isTransferred = status === invoiceStatus.TRANSFERRED;
    const isPaid = invoicePaymentStatus === paymentStatus.PAID;

    const showPurchaseDate = !!purchaseDate;
    const showDueDate = !isPaid && !isTransferred;
    const showPayLatestDate = status === invoiceStatus.REMINDED && !isTransferred && !isPaid;
    const showPaidDate = isPaid && !isTransferred;
    const showPaymentInfo = !isPaid && !isTransferred;
    const showTransferredInfo = !!isTransferred;
    const showOcrScan = !isTransferred && !isPaid;

    return (
        <div className="invoice-invoice-details-panel" data-testid="invoice-details">
            <Panel stretchInMobile>
                <PanelContent narrow>
                    <h3 className="centered">{i18n('invoice.one-off-invoice.invoice-details.header')}</h3>

                    <div className="pt-2x">
                        {showPurchaseDate && (
                            <LabelValue
                                label={i18n('invoice.one-off-invoice.invoice-details.purchase-date')}
                                value={formatDate(purchaseDate)}
                            />
                        )}
                        {showDueDate && (
                            <LabelValue
                                label={i18n('invoice.one-off-invoice.invoice-details.due-date')}
                                value={
                                    status === invoiceStatus.REMINDED ? (
                                        formatDate(
                                            status === invoiceStatus.REMINDED
                                                ? invoice.originalDueDate
                                                : invoice.dueDate
                                        )
                                    ) : (
                                        <CopyToClipboardButton
                                            id="invoice-details-duedate-value"
                                            noIcon
                                            tooltip={i18n('invoice.one-off-invoice.invoice-details.copy-date')}
                                            tooltipSuccess={i18n(
                                                'invoice.one-off-invoice.invoice-details.copy-success'
                                            )}
                                            value={formatDate(
                                                status === invoiceStatus.REMINDED
                                                    ? invoice.originalDueDate
                                                    : invoice.dueDate
                                            )}
                                        >
                                            {formatDate(
                                                status === invoiceStatus.REMINDED
                                                    ? invoice.originalDueDate
                                                    : invoice.dueDate
                                            )}
                                        </CopyToClipboardButton>
                                    )
                                }
                            />
                        )}
                        {showPayLatestDate && (
                            <LabelValue
                                label={i18n('invoice.one-off-invoice.invoice-details.pay-latest-date')}
                                value={
                                    <CopyToClipboardButton
                                        id="invoice-details-paylatestdate-value"
                                        noIcon
                                        tooltip={i18n('invoice.one-off-invoice.invoice-details.copy-date')}
                                        tooltipSuccess={i18n('invoice.one-off-invoice.invoice-details.copy-success')}
                                        value={formatDate(invoice.dueDate)}
                                    >
                                        {formatDate(invoice.dueDate)}
                                    </CopyToClipboardButton>
                                }
                            />
                        )}
                        {showPaidDate && (
                            <LabelValue
                                label={i18n('invoice.one-off-invoice.invoice-details.paid-date')}
                                value={formatDate(paymentDate)}
                            />
                        )}
                        {showPaymentInfo && (
                            <>
                                <LabelValue
                                    label={i18n('invoice.one-off-invoice.invoice-details.ocr')}
                                    value={
                                        <CopyToClipboardButton
                                            id="invoice-details-ocr-value"
                                            noIcon
                                            tooltip={i18n('invoice.one-off-invoice.invoice-details.copy-ocr')}
                                            tooltipSuccess={i18n(
                                                'invoice.one-off-invoice.invoice-details.copy-success'
                                            )}
                                            value={invoice?.ocr?.replace(/ /g, '')}
                                        >
                                            {formatAccount(invoice.ocr)}
                                        </CopyToClipboardButton>
                                    }
                                />
                                <LabelValue
                                    label={i18n('invoice.one-off-invoice.invoice-details.bg')}
                                    value={
                                        <CopyToClipboardButton
                                            id="invoice-details-bg-value"
                                            noIcon
                                            tooltip={i18n('invoice.one-off-invoice.invoice-details.copy-bg')}
                                            tooltipSuccess={i18n(
                                                'invoice.one-off-invoice.invoice-details.copy-success'
                                            )}
                                            value={i18n('general.bgNumber.account')}
                                        >
                                            {i18n('general.bgNumber.invoice')}
                                        </CopyToClipboardButton>
                                    }
                                />
                                <LabelValue
                                    label={i18n('invoice.one-off-invoice.invoice-details.recipient')}
                                    value="Ecster AB"
                                />
                            </>
                        )}
                        {showTransferredInfo && (
                            <>
                                <p className="strong">
                                    {i18n('invoice.one-off-invoice.invoice-details.transferred.header')}
                                </p>
                                <p>
                                    {i18n('invoice.one-off-invoice.invoice-details.transferred.text', { danger: true })}
                                </p>
                            </>
                        )}
                        {showOcrScan && (
                            <TabletOrDesktop>
                                <div className="mt-8x flex">
                                    <ErrorBoundary name="InvoiceDetailsPanel OcrScan">
                                        <OcrScan
                                            text={i18n('invoice.ocr-scan.text')}
                                            ocrNumber={invoice.ocr}
                                            bgNumber={i18n('general.bgNumber.invoice')}
                                            amount={invoice.amount}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </TabletOrDesktop>
                        )}
                    </div>
                </PanelContent>
            </Panel>
        </div>
    );
};

InvoiceDetailsPanel.propTypes = {
    invoice: PropTypes.object.isRequired,
};

InvoiceDetailsPanel.defaultProps = {};

export default InvoiceDetailsPanel;
