import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_ACCOUNT_TRANSACTIONS_BEGIN,
    ACCOUNT_GET_ACCOUNT_TRANSACTIONS_SUCCESS,
    ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
    ACCOUNT_GET_ACCOUNT_TRANSACTIONS_DISMISS_ERROR,
    APPLY_ACCOUNT_TRANSACTIONS_FILTER,
} from './constants';
import { GET_ACCOUNT_TRANSACTIONS_URL } from './urls';
import parseServerResponseStatus from '../../../common/util/parse-server-response-status';
import returnUniqueErrors from '../../../common/util/return-unique-errors';
import { reportError } from '../../../common/reportError';
import { cache } from '../../../common/cacheHandler';

const CLEAR_GET_ACCOUNT_TRANSACTIONS_STATE = 'CLEAR_GET_ACCOUNT_TRANSACTIONS_STATE';

const applyAccountTransactionsFilter = filter => ({
    type: APPLY_ACCOUNT_TRANSACTIONS_FILTER,
    filter,
});

/**
 * Concatenate the array values into the source object key/value store if previous key with values exists.
 * Otherwise just add an array to the key.
 * @param source {object} Actual key value store, not a copy.
 * @param key {string} Key to placement in the object
 * @param values {array} Array of values to store
 * @param isShortList {boolean} Should we only retrieve a short list
 * @returns {{}} Returns a new object that hasn't mutated anything.
 */
const concatIfExists = (source, key, values = [], isShortList = false) => ({
    ...source,
    [key]: source[key] && !isShortList ? source[key].concat(values) : values,
});

export const getAccountTransactions =
    (customerId, accountRef, filter, isShortList = false, concat = true) =>
    async (dispatch, getState) => {
        await dispatch(applyAccountTransactionsFilter(filter));

        dispatch({
            type: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_BEGIN,
        });

        const { offset, maxRecords, shortList } = getState().account.accountTransactionsFilter;
        const { language } = window.EcsterConfig;

        const getNoOfRecords = isShortList ? shortList + 1 : maxRecords;

        try {
            const key = GET_ACCOUNT_TRANSACTIONS_URL(customerId, accountRef, offset, getNoOfRecords, language);
            const cached = await cache.get(key);
            const res = cached || (await get(key));

            parseServerResponseStatus(res);
            if (!cached) cache.set(key, res);

            const { transactions, hasMoreTransactions } = res.response;

            dispatch({
                type: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_SUCCESS,
                transactions,
                hasMoreTransactions,
                accountRef,
                isShortList,
                concat,
            });
        } catch (err) {
            reportError(err, 'getAccountTransactions');

            dispatch({
                accountRef,
                type: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
                data: {
                    accountRef,
                    error: {
                        message: err,
                        action: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE,
                    },
                },
            });
        }
    };

export const dismissGetAccountTransactionsError = () => ({ type: ACCOUNT_GET_ACCOUNT_TRANSACTIONS_DISMISS_ERROR });

export const clearGetAccountTransactionsState = () => ({ type: CLEAR_GET_ACCOUNT_TRANSACTIONS_STATE });

export function reducer(state, action) {
    switch (action.type) {
        case APPLY_ACCOUNT_TRANSACTIONS_FILTER:
            return {
                ...state,
                accountTransactionsFilter: { ...state.accountTransactionsFilter, ...action.filter },
            };
        case ACCOUNT_GET_ACCOUNT_TRANSACTIONS_BEGIN:
            return {
                ...state,
                getAccountTransactionsPending: true,
                getAccountTransactionsError: null,
                receivedAllTransactions: false,
                getAccountTransactionsIsDone: false,
                getAccountTransactionsIsError: false,
            };

        case ACCOUNT_GET_ACCOUNT_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                accountTransactions: concatIfExists(
                    action.concat ? state.accountTransactions : {},
                    action.accountRef,
                    action.transactions,
                    action.isShortList
                ),
                receivedAllTransactions: !action.hasMoreTransactions,
                getAccountTransactionsPending: false,
                getAccountTransactionsIsDone: true,
            };

        case ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE:
            return {
                ...state,
                getAccountTransactionsPending: false,
                getAccountTransactionsError: returnUniqueErrors({
                    errors: state.getAccountTransactionsError,
                    key: 'accountRef',
                    action,
                }),
                getAccountTransactionsIsError: true,
            };

        case ACCOUNT_GET_ACCOUNT_TRANSACTIONS_DISMISS_ERROR:
            return {
                ...state,
                getAccountTransactionsError: null,
                getAccountTransactionsIsError: false,
            };

        case CLEAR_GET_ACCOUNT_TRANSACTIONS_STATE:
            return {
                ...state,
                accountTransactions: {},
                receivedAllTransactions: false,
                getAccountTransactionsIsDone: false,
                getAccountTransactionsIsError: false,
            };

        default:
            return state;
    }
}
