import React, { useEffect, useState } from 'react';
import { Message } from '@ecster/components';
import axios from 'axios';
import { i18n } from '@ecster/i18n';
import './ComCheck.scss';
import { ServerLog } from '../../common/ServerLog';

export const ComCheck = () => {
    const [hasOnlineStatus, setHasOnlineStatus] = useState(window.navigator.onLine);

    useEffect(() => {
        const verifyIsOnline = async () => {
            try {
                // use vanilla axios to avoid @ecster/net baseURL handling
                // ping.txt lives in AWS S3/CloudFront not in NETX BE
                await axios.get(`files/ping.txt`);
                setHasOnlineStatus(prevOnlineStatus => {
                    if (prevOnlineStatus === false) ServerLog.info('network status changed from offline to online');
                    return true;
                });
            } catch (e) {
                setHasOnlineStatus(false);
            }
        };

        const setIsOnline = () => {
            verifyIsOnline();
        };
        const setIsOffline = () => {
            setHasOnlineStatus(false);
        };

        window.addEventListener('offline', setIsOffline, false);
        window.addEventListener('online', setIsOnline, false);

        return () => {
            window.removeEventListener('offline', setIsOffline);
            window.removeEventListener('online', setIsOnline);
        };
    }, []);

    return (
        !hasOnlineStatus && (
            <Message
                id="network-is-down"
                className="com-check-message"
                red
                header={i18n('com-check.header')}
                message={i18n('com-check.message')}
            />
        )
    );
};

ComCheck.propTypes = {};

ComCheck.defaultProps = {};

export default ComCheck;
