import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import { Panel, PanelContent, ExpandablePanel, LabelValue } from '@ecster/components';
import md5 from '@ecster/util/lib/md5';
import { formatAmount } from '../../../../common/util/format-amount';
import { formatDate } from '../../../../common/util/format-date';

const getId = pp => md5(JSON.stringify(pp));

// NOTE 2023-09-06: Shows ongoing part payments on the account invoice spec page
export default class PartPaymentsPanel extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        campaignPurchases: PropTypes.array.isRequired,
        header: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        header: undefined,
    };

    state = {
        expanded: [],
    };

    componentDidMount() {
        const { campaignPurchases } = this.props;
        if (campaignPurchases && campaignPurchases.length === 1) {
            this.setState({ expanded: [getId(campaignPurchases[0])] });
        }
    }

    onExpand = id => {
        const { expanded } = this.state;
        const newArr = [...expanded];
        if (newArr.includes(id)) {
            newArr.splice(newArr.indexOf(id), 1);
        } else {
            newArr.push(id);
        }
        this.setState({ expanded: newArr });
    };

    isExpanded = id => {
        const { expanded } = this.state;
        return expanded.includes(id);
    };

    render() {
        const { campaignPurchases, className, header } = this.props;
        const { expanded } = this.state;
        const classes = classNames({
            'account-part-payments-panel': true,
            [className]: className,
        });

        // assign an id that is most likely unique
        const purchases = campaignPurchases.map(pp => ({ ...pp, id: getId(pp) }));

        return (
            <Panel paddingLeftRight="small" paddingTopBottom="large" className={classes} stretchInMobile>
                <h2 className="centered h3">{header || i18n('account.part-payment.part-payments')}</h2>
                <PanelContent fullWidth className="wrapper">
                    {purchases.map(pp => {
                        const showLabel = pp.placeOfPurchase || formatDate(pp.date);
                        const mainLabel =
                            pp.numberOfMonthsLeft > 0
                                ? i18n('account.part-payment.months-left', {
                                      count: pp.numberOfMonthsLeft,
                                  })
                                : '';
                        return (
                            <section key={pp.id}>
                                <ExpandablePanel
                                    ariaLabel={i18n('general.expand')}
                                    collapse={!expanded.includes(pp.id)}
                                    handleCollapse={() => this.onExpand(pp.id)}
                                    className="more-info header"
                                    Component="div"
                                    showMoreLabel={showLabel}
                                    showLessLabel={showLabel}
                                    mainLabel={mainLabel}
                                    noBorder
                                    noPadding
                                    showIcon
                                    icon={`arrow-icon ${
                                        this.isExpanded(pp.id) ? 'e-gray icon-chevron-up' : 'e-gray icon-chevron-down'
                                    }`}
                                >
                                    <LabelValue
                                        label={i18n('account.part-payment.left')}
                                        value={formatAmount(pp.remainingDebt, { strip00: true })}
                                    />
                                    <LabelValue
                                        label={i18n('account.part-payment.amount')}
                                        value={i18n('account.part-payment.per-month', {
                                            value: formatAmount(pp.monthlyAmount),
                                        })}
                                    />
                                    <LabelValue
                                        label={i18n('account.part-payment.purchase-date')}
                                        value={formatDate(pp.date)}
                                    />
                                    <LabelValue
                                        label={i18n('account.part-payment.fees')}
                                        value={formatAmount(pp.adminFee)}
                                    />
                                    <LabelValue
                                        label={i18n('account.part-payment.rate')}
                                        value={`${pp.interestRate} %`}
                                    />
                                </ExpandablePanel>
                            </section>
                        );
                    })}
                </PanelContent>
            </Panel>
        );
    }
}
