import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import { i18n } from '@ecster/i18n';
import { Button, Form, Input, PhoneNumberInput } from '@ecster/components';
import { GDPRInfo } from '@ecster/components/app';
import { validateMobilePhoneNumberFI, validateMobilePhoneNumberSE } from '@ecster/validation';
import { isFI } from '../../common/country';
import './ActivateCardForm.scss';

const CVC_REGEX = /^\d{3}$/;

export default class ActivateCardForm extends React.Component {
    formRef = React.createRef();

    phoneRef = React.createRef();

    cvcRef = React.createRef();

    state = {
        cvc: '',
        phone: {},
    };

    componentDidMount() {
        const { isWrongCvc, card } = this.props;
        const state = { isWrongCvc };
        if (card.extraCardInfo) state.phone = card.extraCardInfo.phoneNumber;
        this.setState(state);
    }

    // eslint-disable-next-line camelcase
    componentDidUpdate() {
        const { isWrongCvc } = this.state;

        if (this.props.isWrongCvc !== isWrongCvc) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isWrongCvc: this.props.isWrongCvc });
        }
    }

    onChange = ({ target }) => {
        this.setState({ cvc: target.value, isWrongCvc: false });
    };

    onPhoneChange = value => {
        this.setState({ phone: value });
    };

    onSubmit = e => {
        e.preventDefault(); // don't submit form!

        const { onSubmitForm, card } = this.props;

        const { cvc, phone } = this.state;

        this.setState({ isWrongCvc: false });

        if (this.formRef.current.validate()) {
            onSubmitForm(cvc);

            if (card.extraCardInfo) {
                const {
                    updateCustomerExtraCardHolderContactInfo,
                    card: {
                        holder: name,
                        extraCardInfo: { ssn },
                    },
                    customerId,
                } = this.props;
                updateCustomerExtraCardHolderContactInfo(customerId, {
                    name,
                    ssn,
                    phoneNumber: phone,
                });
            }
        }
    };

    render() {
        const { cvc, isWrongCvc, phone } = this.state;
        const { card, disableButton } = this.props;
        const validationMessageKey = !CVC_REGEX.test(cvc)
            ? 'card.activate-card.validation-message'
            : isWrongCvc
              ? 'card.activate-card.wrong-cvc'
              : 'blank';

        return (
            <div className="activate-card-form">
                <Form
                    className="activate-form"
                    ref={this.formRef}
                    validateRefs={[this.cvcRef, this.phoneRef]}
                    onSubmit={this.onSubmit}
                >
                    {card.extraCardInfo && (
                        <PhoneNumberInput
                            name="phoneNumber"
                            ariaLabelCountryCode="phoneNumberCountryCode"
                            ref={this.phoneRef}
                            value={phone}
                            label={i18n('general.address.mobile')}
                            onChange={this.onPhoneChange}
                            numberValidator={isFI() ? validateMobilePhoneNumberFI : validateMobilePhoneNumberSE}
                            validationMessage={i18n('general.validation.phone')}
                            validationMessageCountryCode={i18n('general.validation.country-code')}
                            required
                        />
                    )}

                    {card.extraCardInfo && <GDPRInfo className="gdpr-info" onlineExtraCardActivation />}

                    <div className="cvc-activate">
                        <Input
                            type="tel"
                            maxLength="3"
                            label={i18n('card.activate-card.input-label')}
                            className={isWrongCvc ? 'has-error' : 'cvc-input'}
                            value={cvc}
                            onChange={this.onChange}
                            validator={(value, isWrongCvc) => {
                                return CVC_REGEX.test(value) && !isWrongCvc;
                            }}
                            validationMessage={i18n(validationMessageKey)}
                            ref={this.cvcRef}
                            id="activate-card-input"
                        />
                        <Button
                            className="activate-button"
                            round
                            type="submit"
                            onClick={() => {}}
                            ignoreClick={disableButton}
                            spinnerRight={disableButton}
                            id="activate-card-button"
                        >
                            {i18n('card.activate-card.button')}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

ActivateCardForm.propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    isWrongCvc: PropTypes.bool.isRequired,
    card: PropTypes.shape().isRequired,
    updateCustomerExtraCardHolderContactInfo: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    disableButton: PropTypes.bool.isRequired,
};
