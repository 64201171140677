import { get } from '@ecster/net/v2';
import {
    AUTHENTICATION_KEEP_ALIVE_SESSION_BEGIN,
    AUTHENTICATION_KEEP_ALIVE_SESSION_SUCCESS,
    AUTHENTICATION_KEEP_ALIVE_SESSION_FAILURE,
    AUTHENTICATION_KEEP_ALIVE_SESSION_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

const CLEAR_KEEP_ALIVE_SESSION_STATE = 'CLEAR_KEEP_ALIVE_SESSION_STATE';

export const KEEP_ALIVE_SESSION_URL = sessionKey => `/rest/sessions/v2/${sessionKey}`;

export const keepAliveSession = sessionKey => async dispatch => {
    dispatch({
        type: AUTHENTICATION_KEEP_ALIVE_SESSION_BEGIN,
    });

    try {
        await get(KEEP_ALIVE_SESSION_URL(sessionKey));

        dispatch({
            type: AUTHENTICATION_KEEP_ALIVE_SESSION_SUCCESS,
        });
    } catch (err) {
        reportError(err, 'keepAliveSession');

        dispatch({
            type: AUTHENTICATION_KEEP_ALIVE_SESSION_FAILURE,
            data: {
                error: {
                    message: err,
                    action: AUTHENTICATION_KEEP_ALIVE_SESSION_FAILURE,
                },
            },
        });
    }
};

export const dismissKeepAliveSessionError = () => ({ type: AUTHENTICATION_KEEP_ALIVE_SESSION_DISMISS_ERROR });

export const clearKeepAliveSessionState = () => ({ type: CLEAR_KEEP_ALIVE_SESSION_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case AUTHENTICATION_KEEP_ALIVE_SESSION_BEGIN:
            return {
                ...state,
                keepAliveSessionPending: true,
                keepAliveSessionError: null,
                keepAliveSessionIsDone: false,
                keepAliveSessionIsError: false,
            };

        case AUTHENTICATION_KEEP_ALIVE_SESSION_SUCCESS:
            return {
                ...state,
                keepAliveSessionPending: false,
                keepAliveSessionError: null,
                keepAliveSessionIsDone: true,
            };

        case CLEAR_KEEP_ALIVE_SESSION_STATE:
            return {
                ...state,
                keepAliveSessionIsDone: false,
                keepAliveSessionIsError: false,
            };

        case AUTHENTICATION_KEEP_ALIVE_SESSION_FAILURE:
            return {
                ...state,
                keepAliveSessionPending: false,
                keepAliveSessionError: action.data.error,
                keepAliveSessionIsError: true,
            };

        case AUTHENTICATION_KEEP_ALIVE_SESSION_DISMISS_ERROR:
            return {
                ...state,
                keepAliveSessionError: null,
                keepAliveSessionIsError: false,
            };

        default:
            return state;
    }
}
