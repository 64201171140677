import { getCookie, removeCookie, setCookie } from '@ecster/cookies';
import { X_ECSTER_SESSION_KEY_NAME } from '@ecster/net';

export const createSessionCookie = value => {
    setCookie(X_ECSTER_SESSION_KEY_NAME, value, 1);
};

export const getSessionCookie = () => {
    return getCookie(X_ECSTER_SESSION_KEY_NAME);
};

export const deleteSessionCookie = () => {
    removeCookie(X_ECSTER_SESSION_KEY_NAME);
};
