// @ts-nocheck
/**
 * DON'T USE THIS
 * Saved because screen.js use it. But code is not good with ...args and declaration of timeout in every function scope
 * @param {} func
 * @param {*} wait
 */

export default function debounce(func, wait) {
    let timeout;

    return (...args) => {
        const later = () => {
            timeout = undefined;
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
