/* eslint-disable no-undef,quote-props */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './FadeIn.scss';

interface FadeInProps {
    show?: boolean;
    fast?: boolean;
    slow?: boolean;
    children: ReactNode;
}

const FadeIn = (props: FadeInProps) => {
    const { children, fast, slow, show } = props;

    const classes = classNames({
        'ec-fade-in': true,
        'fade-in-fast': fast,
        'fade-in-slow': slow,
        'fade-in-show': show,
    });
    return <div className={classes}>{children}</div>;
};

FadeIn.defaultProps = {
    show: false,
    fast: false,
    slow: false,
};

export default FadeIn;
