import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';

interface OptionProps {
    value: string | number;
    label: string | ReactNode;
    disabled?: boolean;
}

const Option = ({ value, label, disabled = false, ...rest }: OptionProps) => (
    <option value={value} disabled={disabled} {...rest}>
        {label}
    </option>
);

Option.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

export default Option;
