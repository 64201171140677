import React, { Component, CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import Option from './Option';
import './Select.scss';

interface SelectProps {
    ariaLabel?: string;
    className?: string;
    style?: CSSProperties;
    value?: string | number;
    label?: string;
    id?: string;
    name?: string;
    onChange?: Function;
    children: ReactNode;
    small?: boolean;
    disabled?: boolean;
    defaultOption?: string;
    defaultOptionSelectable?: boolean;
    // validation
    required?: boolean;
    validationMessage?: string;
    noDefault?: boolean;
    border?: boolean;
}

type SelectState = {
    originalValue: string;
    hasError: boolean;
};

export default class Select extends Component<SelectProps, SelectState> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            originalValue: props.value,
        };
    }

    onBlur = () => {
        this.doValidation();
    };

    onChange = e => {
        const { onChange } = this.props;

        this.doValidation();
        onChange(e);
    };

    isDirty = () => {
        const { originalValue } = this.state;
        const { value } = this.props;
        return value !== originalValue;
    };

    doValidation() {
        const select = this.selectRef.current;

        const { value, required } = select;

        const isValid = required ? value : true;
        this.setState({ hasError: !isValid });
        return isValid;
    }

    selectRef = React.createRef<HTMLSelectElement>();

    render() {
        const {
            ariaLabel,
            children,
            label,
            className,
            style,
            small,
            disabled,
            defaultOption,
            defaultOptionSelectable,
            required,
            validationMessage,
            noDefault,
            border,
            name,
            value,
            id,
        } = this.props;

        const { hasError } = this.state;

        const classes = classNames({
            'ec-select': true,
            'has-error': hasError,
            'is-disabled': disabled,
            'no-border': !border,
            small,
            [className]: className,
        });

        const thisId = id || name || ' ';

        return (
            <div className={classes}>
                {label && <label htmlFor={thisId}>{label}</label>}
                <div className="select-container">
                    <select
                        role="combobox"
                        aria-label={ariaLabel}
                        disabled={disabled}
                        value={value}
                        id={thisId}
                        name={name}
                        onChange={this.onChange}
                        required={required}
                        style={{ ...style }}
                        ref={this.selectRef}
                        onBlur={this.onBlur}
                    >
                        {!noDefault && <Option value="" label={defaultOption} disabled={!defaultOptionSelectable} />}
                        {children}
                    </select>
                </div>
                <div className="ec-select-error">{validationMessage}</div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
Select.defaultProps = {
    ariaLabel: '',
    className: '',
    style: {},
    label: '',
    value: '',
    name: '',
    id: '',
    onChange: () => {},
    small: false,
    disabled: false,
    defaultOption: '',
    defaultOptionSelectable: true,
    // validation
    required: false,
    validationMessage: 'Välj ett värde',
    noDefault: false,
    border: true,
};
