// based on react-datepicker
// - props: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
// - usage: https://reactdatepicker.com/

import React, { useEffect, useImperativeHandle, useState } from 'react';
import sv from 'date-fns/locale/sv';
import fi from 'date-fns/locale/fi';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import dayjs from 'dayjs';

import device from '../util/detect-device';
import Input from '../Input/Input';
import { IconButton } from '../Clickable';
import calendarIcon from './DatePicker.svg';
import disabledCalendarIcon from './DatePickerDisabled.svg';
import { formatToUnicodeTokens } from './formatToUnicodeTokens';
import './DatePicker.scss';

registerLocale('sv', sv);
registerLocale('fi', fi);

interface DatePickerProps {
    /**
     * disable modal calendar in mobile
     */
    noDialogInMobile?: boolean;

    /**
     * Popper.js props
     */
    popperProps?: object;

    /**
     * Used if DatePicker should disable the alternative to choose weekends
     * By default: false
     */
    disableWeekends?: boolean;

    /**
     * Backward compatibility with the old Pikaday implementation.
     * Migrate to top level properties.
     * @deprecated
     */
    config?: any;

    /**
     * Minimum date that can be chosen in calender
     *
     */
    minDate?: string | Date;

    /**
     * Maximum date that can be chosen in calender
     *
     */
    maxDate?: string | Date;

    // year selection
    /**
     * Earliest selectable year in the select year dropdown. Defaults to 15 years back in time.
     */
    yearSelectMin?: number;

    /**
     * Latest selectable year in the select year dropdown. Defaults to 15 years ahead in time.
     */
    yearSelectMax?: number;

    /**
     * The displayed format of the chosen date in field
     */
    format?: string | string[];

    /**
     * Localization - a valid ISO language code, sv or fi
     * By default Swedish language is shown in calender
     */
    language?: string;

    /**
     * The text shown to screen readers
     * Should be used especially when the label from isn't available
     */
    ariaLabel?: string;

    /**
     * Added as dom id.
     */
    id?: string;

    /**
     * Configure autocompletion of field. "off" to turn off.
     *
     * Possible values: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-autocomplete
     */
    autocomplete?: string;

    /**
     * Input fields label
     */
    label?: string;

    /**
     * Show label as placeholder, label "floats" to upper left corner
     * on focus or when field has a value.
     */
    floatLabel?: boolean;

    /**
     * Show label inside field (field becomes alignRight)
     */
    labelInside?: boolean;

    /**
     * The Input components name
     */
    name?: string;

    /**
     * Called when field changes
     */
    onChange: Function;

    /**
     * Called when field gets focus
     */
    onFocus?: Function;

    /**
     * Called when field loses focus
     */
    onBlur?: Function;

    /**
     * Called when a key is released inside field
     */
    onKeyUp?: Function;

    /**
     * Field placeholder. Can't be used in combination with floatLabel
     */
    placeholder?: string;

    /**
     * The field value
     */
    value?: string | Date;

    /**
     * Disable field (true) or enable field (false or undefined)
     */
    disabled?: boolean;

    /**
     * Right align text in field.
     */
    alignRight?: boolean;

    /**
     * Make field smaller (height)
     */
    small?: boolean;

    /**
     * Make field larger (height)
     */
    large?: boolean;

    /**
     * Add a classname to the components container element.
     */
    className?: string;

    // validation

    /**
     * Readonly removes input visually but not the value
     */
    readonly?: boolean;

    /**
     * Validation: a value is required
     */
    required?: boolean;

    /**
     * Validation: the validation message to show if field foes not validate
     */
    validationMessage?: string;

    /**
     * Validation: function called when validation is done.
     *
     * property "name" must be specified when "onValidation" is used
     *
     * @param string, field name
     * @param bool, field is valid or not
     */
    onValidation?: Function;

    /**
     * Validation: a validation function for special validation
     *
     * E.g. validateMobilePhone
     */
    validator?: Function;

    /**
     * Validation: validate field on focus blur.
     *
     * Use validateOnBlur=false to postpone validation to forma validation
     */
    validateOnBlur?: boolean;

    /**
     * Validation: a value that field value must be equal to
     *
     * Example usage: verify two password fields
     */
    eq?: string;

    /**
     * Validation: a value that field value must be less than
     *
     * Example usage: first field for a date interval
     *
     * Note: string comparison is used
     */
    lt?: string;

    /**
     * Validation: a value that field value must be less than
     *
     * Example usage: second field for a date interval
     *
     * Note: string comparison is used
     */
    gt?: string;

    /**
     * Validation: a value that field value must be less or equal than
     *
     * Note: string comparison is used
     */
    le?: string;

    /**
     * Validation: a value that field value must be greater or equal than
     *
     * Note: string comparison is used
     */
    ge?: string;

    /**
     * Validation: a value that field value can not be equal to
     */
    ne?: string;

    /**
     * Validation: use in combination with eq, lt, gt, le, ge or ne
     *
     * Disables eq, lt, gt, le, ge or ne validation when field is empty
     */
    orNull?: boolean;
}

const ESC_KEY = 27;

const monthNames = {
    en: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    sv: [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
    ],
    fi: [
        'Tammikuu',
        'Helmikuu',
        'Maaliskuu',
        'Huhtikuu',
        'Toukokuu',
        'KesäKuu',
        'HeinäKuu',
        'Elokuu',
        'Syyskuu',
        'Lokakuu',
        'Marraskuu',
        'Joulukuu',
    ],
    da: [
        'Januar',
        'Februar',
        'Marts',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'December',
    ],
    no: [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember',
    ],
};

const yearOptions = (min, max) => {
    const options = [];
    // eslint-disable-next-line no-plusplus
    for (let year = min; year <= max; year++) {
        options.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }
    return options;
};

const monthOptions = monthNames => {
    const options = [];
    monthNames.forEach((month, index) => {
        options.push(
            <option key={month} value={index}>
                {month}
            </option>
        );
    });
    return options;
};

interface CustomHeaderProps {
    date: Date;
    changeYear: (year: string) => any;
    changeMonth: (month: string) => any;
    decreaseMonth: (e) => any;
    increaseMonth: (e) => any;
    prevMonthButtonDisabled: boolean;
    nextMonthButtonDisabled: boolean;
}

/**
 * Function to get a function that renders a custom header and handles onChange properly
 *
 * Based on the non working example https://reactdatepicker.com/#example-custom-header
 * (which is the reason for the 'function to get a function' solution)
 *
 * @param yearSelectMin the earliest selectable year
 * @param yearSelectMax the latest selectable year
 * @param monthNames array of month names ['Januari', 'Februari', etc]
 * @param onChange called when month or year changes, should update component value state
 */
const getRenderCustomHeader =
    (yearSelectMin, yearSelectMax, monthNames, onChange) =>
    ({
        date, // eslint-disable-line react/prop-types
        changeYear, // eslint-disable-line react/prop-types
        changeMonth, // eslint-disable-line react/prop-types
        decreaseMonth, // eslint-disable-line react/prop-types
        increaseMonth, // eslint-disable-line react/prop-types
        prevMonthButtonDisabled, // eslint-disable-line react/prop-types
        nextMonthButtonDisabled, // eslint-disable-line react/prop-types
    }: CustomHeaderProps) =>
        (
            <div className="ec-date-picker-header">
                <IconButton
                    icon="icon-chevron-left"
                    medium
                    green
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                />
                <div className="ec-dp-select-ctr">
                    <select
                        className="ec-dp-select-month"
                        value={date.getMonth()}
                        onChange={({ target: { value } }) => {
                            changeMonth(value);
                            onChange(dayjs(date.setMonth(Number(value))).format('YYYY-MM-DD'));
                        }}
                    >
                        {monthOptions(monthNames)}
                    </select>

                    <select
                        className="ec-dp-select-year"
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => {
                            changeYear(value);
                            onChange(dayjs(date.setFullYear(Number(value))).format('YYYY-MM-DD'));
                        }}
                    >
                        {yearOptions(yearSelectMin, yearSelectMax)}
                    </select>
                </div>
                <IconButton
                    icon="icon-chevron-right"
                    medium
                    green
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                />
            </div>
        );

export const DatePicker = React.forwardRef(
    (
        {
            id,
            className,
            onChange,
            value,
            minDate,
            maxDate,
            yearSelectMin,
            yearSelectMax,
            config,
            language,
            format,
            placeholder,
            disabled,
            disableWeekends,
            required,
            name,
            noDialogInMobile,
            popperProps,
            ...rest
        }: DatePickerProps,
        inputRef: any
    ) => {
        const isTouchDevice = device().isTablet || device().isMobile; // when mobile or tablet is used, show a portal version

        // value - String or Date

        const theValue =
            !value || !dayjs(value).isValid() ? '' : typeof value === 'string' ? dayjs(value).toDate() : value;

        // min and maxDate - two prop alternatives
        const minDateValue = minDate || config?.minDate;
        const maxDateValue = maxDate || config?.maxDate;
        // min and maxDate - String or Date
        const minDateDateValue = !minDateValue
            ? undefined
            : typeof minDateValue === 'string'
            ? dayjs(minDateValue).toDate()
            : minDateValue;
        const maxDateDateValue = !maxDateValue
            ? undefined
            : typeof maxDateValue === 'string'
            ? dayjs(maxDateValue).toDate()
            : maxDateValue;

        const rdpFormat = Array.isArray(format)
            ? format
            : formatToUnicodeTokens(format || config?.format || 'YYYY-MM-DD');
        const [rdpRef, setRdpRef] = useState<ReactDatePicker>();
        useImperativeHandle(inputRef, () => rdpRef?.input, [rdpRef?.input]);

        // filter away weekends if 'disableWeekends'
        const dayIsEnabled = value => {
            if (disableWeekends || config?.disableWeekends) {
                const day = value.getDay();
                return day !== 0 && day !== 6;
            }
            return true;
        };

        // when date is selected in popper
        const onChangeDate = value => {
            onChange({ target: { value: dayjs(value).format('YYYY-MM-DD'), name } });
        };

        const closePopper = () => rdpRef.setOpen(false);

        const onInputKeyUp = ({ keyCode }) => {
            if (keyCode === ESC_KEY) {
                closePopper();
            }
        };

        // on value update
        useEffect(() => {
            inputRef?.current?.doValidation(true); // force a validation to hide 'required' validation msg
        }, [inputRef, value]);

        return (
            <div className={`ec-date-picker2 ${className}`}>
                <ReactDatePicker
                    renderCustomHeader={getRenderCustomHeader(
                        yearSelectMin,
                        yearSelectMax,
                        monthNames[language || config?.lang || 'sv'],
                        value => onChange({ target: { value } })
                    )}
                    ref={setRdpRef}
                    locale={language || config?.lang}
                    dateFormat={rdpFormat}
                    selected={theValue}
                    onChange={onChangeDate}
                    closeOnScroll
                    customInput={
                        // @ts-ignore
                        <Input
                            {...rest}
                            onKeyUp={onInputKeyUp}
                            iconUrl={disabled ? disabledCalendarIcon : calendarIcon}
                        />
                    }
                    required={required}
                    popperPlacement="bottom"
                    popperProps={popperProps}
                    withPortal={noDialogInMobile ? false : isTouchDevice}
                    disabled={config?.disabled || disabled}
                    placeholderText={placeholder || config?.placeholder}
                    minDate={minDateDateValue}
                    maxDate={maxDateDateValue}
                    filterDate={dayIsEnabled}
                    id={id}
                    name={name}
                />
            </div>
        );
    }
);

const randomString = () => `ec-date-picker-${Math.floor(Math.random() * 99999) + 999}`;

DatePicker.defaultProps = {
    id: randomString(),
    autocomplete: 'on',
    label: '',
    floatLabel: false,
    labelInside: false,
    name: '',
    placeholder: '',
    value: '',
    disabled: false,
    alignRight: false,
    readonly: false,

    noDialogInMobile: false,
    popperProps: undefined,

    small: false,
    large: false,
    className: '',

    onFocus: null,
    onBlur: null,
    onKeyUp: null,

    minDate: null,
    maxDate: null,
    yearSelectMin: new Date().getFullYear() - 15,
    yearSelectMax: new Date().getFullYear() + 15,
    disableWeekends: false,
    config: undefined,
    ariaLabel: '',
    format: '',
    language: 'sv',

    // validation
    required: false,
    eq: undefined,
    lt: undefined,
    gt: undefined,
    le: undefined,
    ge: undefined,
    ne: undefined,
    orNull: false,
    validateOnBlur: true,
    validationMessage: 'Välj ett datum',
    onValidation: null,
    validator: null,
};

export default DatePicker;
