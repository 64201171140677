export const AUTHENTICATION_DELETE_SESSION_BEGIN = 'AUTHENTICATION_DELETE_SESSION_BEGIN';
export const AUTHENTICATION_DELETE_SESSION_SUCCESS = 'AUTHENTICATION_DELETE_SESSION_SUCCESS';
export const AUTHENTICATION_DELETE_SESSION_FAILURE = 'AUTHENTICATION_DELETE_SESSION_FAILURE';
export const AUTHENTICATION_DELETE_SESSION_DISMISS_ERROR = 'AUTHENTICATION_DELETE_SESSION_DISMISS_ERROR';
export const AUTHENTICATION_CREATE_SESSION_BEGIN = 'AUTHENTICATION_CREATE_SESSION_BEGIN';
export const AUTHENTICATION_CREATE_SESSION_SUCCESS = 'AUTHENTICATION_CREATE_SESSION_SUCCESS';
export const AUTHENTICATION_CREATE_SESSION_FAILURE = 'AUTHENTICATION_CREATE_SESSION_FAILURE';
export const AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR = 'AUTHENTICATION_CREATE_SESSION_DISMISS_ERROR';
export const AUTHENTICATION_GET_SESSION_BEGIN = 'AUTHENTICATION_GET_SESSION_BEGIN';
export const AUTHENTICATION_GET_SESSION_SUCCESS = 'AUTHENTICATION_GET_SESSION_SUCCESS';
export const AUTHENTICATION_GET_SESSION_FAILURE = 'AUTHENTICATION_GET_SESSION_FAILURE';
export const AUTHENTICATION_GET_SESSION_DISMISS_ERROR = 'AUTHENTICATION_GET_SESSION_DISMISS_ERROR';
export const AUTHENTICATION_RESET_LOGIN_STATE = 'AUTHENTICATION_RESET_LOGIN_STATE';
export const AUTHENTICATION_CLEAR_JUST_LOGGED_OUT = 'AUTHENTICATION_CLEAR_JUST_LOGGED_OUT';
export const AUTHENTICATION_SET_LOGIN_STATS_DONE = 'AUTHENTICATION_SET_LOGIN_STATS_DONE';
export const AUTHENTICATION_KEEP_ALIVE_SESSION_BEGIN = 'AUTHENTICATION_KEEP_ALIVE_SESSION_BEGIN';
export const AUTHENTICATION_KEEP_ALIVE_SESSION_SUCCESS = 'AUTHENTICATION_KEEP_ALIVE_SESSION_SUCCESS';
export const AUTHENTICATION_KEEP_ALIVE_SESSION_FAILURE = 'AUTHENTICATION_KEEP_ALIVE_SESSION_FAILURE';
export const AUTHENTICATION_KEEP_ALIVE_SESSION_DISMISS_ERROR = 'AUTHENTICATION_KEEP_ALIVE_SESSION_DISMISS_ERROR';
