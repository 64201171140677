import React from 'react';
import classNames from 'classnames';
import Dialog, { DialogBody, DialogHeader, DialogFooter } from '../Dialog';
import { Button, ButtonGroup } from '../Clickable'
import { messageTypes } from './ModalMessageTypes';
import './ModalMessage.scss';
import withMediaQueries from '../MediaQuery/withMediaQueries';

interface ModalMessageProps {
    show?: boolean;
    header?: string;
    message: any;
    type?: 'ERROR' | 'INFO' | 'WARNING' | 'CONFIRM';
    hideModalMessage: Function;
    onSubmit?: Function;
    okText?: string;
    cancelText?: string;
    continueText?: string;
}

const ModalMessage = ({
    show,
    message,
    header,
    type,
    hideModalMessage,
    onSubmit,
    // @ts-expect-error media is injected by HoC withMediaQueries
    media,
    okText,
    cancelText,
    continueText,
}: ModalMessageProps) => {
    const typeStyle = `modalmessage--${type}`;
    const className = classNames({
        modalmessage: true,
        'ec-modalmessage': true,
        'modalmessage--show': show,
        [typeStyle]: true,
        'modalmessage--mobile': media.onMobile,
        'modalmessage--tablet': media.onTablet,
        'modalmessage--desktop': media.onDesktop,
    });

    const confirmButtons = (hideModalMessage: Function, onSubmit: Function) => (
        <ButtonGroup style={{ padding: '0' }} align="center">
            <Button outline onClick={hideModalMessage}>
                {cancelText}
            </Button>
            <Button onClick={onSubmit}>{continueText}</Button>
        </ButtonGroup>
    );

    const defaultButtons = (onSubmit: Function) => (
        <ButtonGroup style={{ padding: '0' }} align="center">
            <Button onClick={onSubmit}>{okText}</Button>
        </ButtonGroup>
    );

    const submit = () => {
        hideModalMessage();
        if(typeof onSubmit === 'function') onSubmit();
    };

    return (
        <Dialog open={show} className={className} onRequestClose={hideModalMessage}>
            {header && header !== '' ? <DialogHeader>{header}</DialogHeader> : null}
            <DialogBody>
                <div>{message}</div>
            </DialogBody>
            <DialogFooter>
                {type === messageTypes.CONFIRM ? confirmButtons(hideModalMessage, submit) : defaultButtons(submit)}
            </DialogFooter>
        </Dialog>
    );
};

ModalMessage.defaultProps = {
    show: false,
    header: '',
    type: messageTypes.INFO as 'INFO',
    onSubmit: () => {},
    okText: 'Ok',
    cancelText: 'Avbryt',
    continueText: 'Fortsätt',
};

export default withMediaQueries<ModalMessageProps>(ModalMessage);
