// @ts-nocheck
import React, { Component, ComponentType, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RadioGroupContext } from './RadioGroupContext';

interface RadioGroupProps {
    children: ReactNode;
    className?: string;
    Component?: ComponentType;
    name?: string;
    onChange?: Function;
    required?: boolean;
    selectedValue?: string | number | boolean;
    validationMessage?: string;
}

export class RadioGroup extends Component<RadioGroupProps> {
    state = {
        hasErrors: false,
    };

    radioGroup = React.createRef();

    onChange = (val, event) => {
        const { onChange } = this.props;
        onChange(event);
        this.doValidation();
    };

    doValidation() {
        const { required, selectedValue } = this.props;
        const radioButtons = Array.from(this.radioGroup.current.querySelectorAll('[type="radio"]'));
        const radiosValid = radioButtons.reduce((isValid, radioButton) => radioButton.checkValidity(), true);
        const emptyWhenRequired = required && !selectedValue;
        this.setState({ hasErrors: !radiosValid || emptyWhenRequired });
        return radiosValid && !emptyWhenRequired;
    }

    render() {
        const { Component, className, name, selectedValue, required, validationMessage, children, ...rest } =
            this.props;

        const { hasErrors } = this.state;

        const classes = classNames({
            'ec-radio-group': true,
            'has-error': hasErrors,
            [className]: className,
        });

        return (
            <div className={classes}>
                <Component role="radiogroup" ref={this.radioGroup} {...rest}>
                    <RadioGroupContext.Provider value={{ name, selectedValue, required, onChange: this.onChange }}>
                        {children}
                    </RadioGroupContext.Provider>
                </Component>
                {hasErrors && <div className="ec-radio-group-error">{validationMessage}</div>}
            </div>
        );
    }
}

RadioGroup.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    Component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    validationMessage: PropTypes.string,
};

RadioGroup.defaultProps = {
    onChange: () => {},
    className: '',
    Component: 'div',
    required: false,
    validationMessage: 'Välj ett värde',
    selectedValue: null,
    name: '',
};
