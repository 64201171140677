import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { Message, LabelValue } from '@ecster/components';
import ActivateCardForm from './ActivateCardForm';

export default class ShowExtraCardSubpanel extends React.Component {
    static propTypes = {
        card: PropTypes.shape().isRequired,
        customerId: PropTypes.number.isRequired,
        updateAccountCard: PropTypes.func.isRequired,
        updateAccountCardPending: PropTypes.bool.isRequired,
        updateCustomerExtraCardHolderContactInfo: PropTypes.func.isRequired,
        isWrongCvc: PropTypes.bool.isRequired,
        mainCardIsTemporarilyBlocked: PropTypes.bool,
    };

    static defaultProps = { mainCardIsTemporarilyBlocked: false };

    onSubmitForm = cvc => {
        const { customerId, updateAccountCard, card } = this.props;
        updateAccountCard(customerId, card, cvc);
    };

    render() {
        const {
            card,
            updateCustomerExtraCardHolderContactInfo,
            updateAccountCardPending,
            customerId,
            isWrongCvc,
            mainCardIsTemporarilyBlocked,
        } = this.props;

        const tmpBlock = mainCardIsTemporarilyBlocked || card.status === 'TEMPORARILY_BLOCKED';
        const cardStatus = tmpBlock ? 'TEMPORARILY_BLOCKED' : card.status;

        if (card.status === 'ORDERED') {
            return (
                <Message
                    blue
                    whiteBg
                    header={i18n('card.apply-for-card.pending.header')}
                    message={i18n('card.apply-for-card.pending.info')}
                />
            );
        }

        return (
            <div className="card-show-extra-card-subpanel" key={card.cardNumber}>
                <LabelValue label={i18n('card.general.card-holder')} value={card.holder} />
                <LabelValue label={i18n('card.general.card-number')} value={card.cardNumber} />
                {!tmpBlock && (
                    <LabelValue
                        label={i18n('card.general.valid-to')}
                        value={`${card.expires.year} / ${card.expires.month}`}
                    />
                )}
                <LabelValue label={i18n('card.general.status')} value={i18n(`card.show-card.${cardStatus}`)} />

                {card.status !== 'INACTIVE' && (
                    <LabelValue
                        label={i18n('general.address.mobile')}
                        value={`${card.extraCardInfo?.phoneNumber?.countryCallingCode} ${card.extraCardInfo?.phoneNumber?.number}`}
                    />
                )}

                {!tmpBlock && card.status === 'INACTIVE' && (
                    <ActivateCardForm
                        onSubmitForm={this.onSubmitForm}
                        idPrefix="activate-extra-card"
                        isWrongCvc={isWrongCvc}
                        card={card}
                        updateCustomerExtraCardHolderContactInfo={updateCustomerExtraCardHolderContactInfo}
                        customerId={customerId}
                        disableButton={updateAccountCardPending}
                    />
                )}
            </div>
        );
    }
}
