import PropTypes from 'prop-types';
import React from 'react';
import { formatAmount } from '../../../common/util/format';

export const Amount = ({ trans }) => (
    <span className="tx-amount">
        {formatAmount(
            (!trans.type && !trans.transactionType) ||
                trans.type === 'CREDIT' ||
                trans.transactionType === 'CREDIT' ||
                trans.transactionType === 'RESERVED_AMOUNT_CREDIT'
                ? trans.amount
                : -trans.amount,
            {
                strip00: false,
                style: 'decimal',
                decimals: 2,
                hasCurrency: false,
            }
        )}
    </span>
);
Amount.propTypes = {
    trans: PropTypes.object.isRequired,
};

Amount.defaultProps = {};
