export const validateClearingNumber = (input) => {
    if (!input) {
        return true;
    }

    const trimmedValue = input.trim();

    const regex = /^[0-9]{4,6}$/;

    return regex.test(trimmedValue);
};

export default validateClearingNumber;