import { formatNumber as utilFormatNumber } from '@ecster/util';

/**
 *
 * @param value
 * @param options
 *    decimals: 0 | 1 | 2 | etc
 *    strip00: true | false (default)
 *    round: true | false (default)
 *    roundUp: true | false (default)
 *    roundDown: true | false (default)
 *    positive: true | false (default)
 * @returns {*|string}
 */
export const formatNumber = (value, options) => {
    const { locale } = window.EcsterConfig;

    return utilFormatNumber(value, locale, options);
};
