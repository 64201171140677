import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { getOptions } from './get-options';
import svFi from './sv-fi';

import 'dayjs/locale/sv';
import 'dayjs/locale/fi';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

// get a correct representation of the date before formatting
// - converted to local time if utcToLocal option is true
// - prepared with the correct locale
const getDate = (date, options) => {
    // TODO: remove conditional assignment and use of localDef when/if dayjs supports
    //    sv-fi - https://github.com/iamkun/dayjs/pull/1522
    //    fallback to sv for sv-se - https://github.com/iamkun/dayjs/pull/1524
    //    case insensitive locales - https://github.com/iamkun/dayjs/pull/1524
    const { locale, localeDef } = options.locale.match(/fi-fi$/i)
        ? { locale: 'fi' }
        : options.locale.match(/^sv-se/i)
        ? { locale: 'sv' }
        : options.locale.match(/^sv-fi/i)
        ? { locale: 'sv-fi', localeDef: svFi }
        : options.locale.match(/en-us/i)
        ? { locale: 'en' }
        : options.locale.match(/en-gb/i)
        ? { locale: 'en-gb' }
        : { locale: options.locale.toLowerCase() };
    return options.utcToLocal
        ? dayjs
              .utc(date)
              .local()
              .locale(locale, localeDef)
        : dayjs(date).locale(locale, localeDef);
};

/**
 * Format date using dayjs built in formatter. E.g. YYYY-MM-DD / DD.MM.YYYY etc
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 * - format - {string} (optional) a valid dayjs format that overrides the default locale based format
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatDate = (date, options, global = window) => {
    const opt = getOptions(options, global);
    const theDate = getDate(date, opt);
    return theDate.format(opt.format || 'L');
};

/**
 * Format date using dayjs built in formatter. E.g. YYYY-MM-DD HH:MM / DD.MM.YYYY HH.MM etc
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 * - seconds - {boolean} (optional) false is default, show time with seconds HH:MM:SS
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatDateTime = (date, options, global = window) => {
    const opt = getOptions(options, global);
    const theDate = getDate(date, opt);
    return `${theDate.format('L')} ${theDate.format(opt.seconds ? 'LTS' : 'LT')}`;
};

/**
 * Short date format. E.g. 4 jan, 4. tammi
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 *
 * Backward compatibility note:
 *  - options.language = sv => locale set to sv-SE
 *  - options.language = fi => locale set to fi-FI
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatDateShort = (date, options, global = window) => {
    const opt = getOptions(options, global);
    if (options && options.language === 'fi') opt.locale = 'fi-FI';
    if (options && options.language === 'sv') opt.locale = 'sv-SE';
    const isFI = opt.locale.match(/fi$/i);
    const theDate = getDate(date, opt);
    return theDate.format(isFI ? 'D. MMM' : 'D MMM');
};

/**
 * Short date format with year. E.g. 4 jan 2020, 4. tammi 2020
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 *
 * Backward compatibility note:
 *  - options.language = sv => locale set to sv-SE
 *  - options.language = fi => locale set to fi-FI
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatDateShortWithYear = (date, options, global = window) => {
    const opt = getOptions(options, global);
    if (options && options.language === 'fi' && !options.locale) opt.locale = 'fi-FI';
    if (options && options.language === 'sv' && !options.locale) opt.locale = 'sv-SE';
    const theDate = getDate(date, opt);
    return theDate.format('ll');
};

/**
 * Long date format. E.g. 4 januari, 4. helmikuuta
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 *
 * Backward compatibility note:
 *  - options.language = sv => locale set to sv-SE
 *  - options.language = fi => locale set to fi-FI
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatDateLong = (date, options, global = window) => {
    const opt = getOptions(options, global);
    if (options && options.language === 'fi') opt.locale = 'fi-FI';
    if (options && options.language === 'sv') opt.locale = 'sv-SE';
    const isFiFi = opt.locale.match(/fi-fi$/i);
    const isSvFi = opt.locale.match(/sv-fi$/i);
    const theDate = getDate(date, opt);
    return theDate.format(isFiFi ? 'D. MMMM[ta]' : isSvFi ? 'D. MMMM' : 'D MMMM');
};

/**
 * Long date format with year. E.g. 4 januari 2021, 4. helmikuuta 2021
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 *
 * Backward compatibility note:
 *  - options.language = sv => locale set to sv-SE
 *  - options.language = fi => locale set to fi-FI
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatDateLongWithYear = (date, options, global = window) => {
    const opt = getOptions(options, global);
    if (options && options.language === 'fi' && !options.locale) opt.locale = 'fi-FI';
    if (options && options.language === 'sv' && !options.locale) opt.locale = 'sv-SE';
    const theDate = getDate(date, opt);
    return theDate.format('LL');
};

/**
 * Long month name. E.g. januari, august, helmikuu
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatMonth = (date, options, global = window) => {
    const opt = getOptions(options, global);
    const theDate = getDate(date, opt);
    return theDate.format('MMMM');
};

/**
 * Short month name. E.g. jan, oct, helmi
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 *
 * Backward compatibility note:
 *  - options.language = sv => locale set to sv-SE
 *  - options.language = fi => locale set to fi-FI
 *
 * @param {Date | string} date A Date object or an ISO formatted date string (2020-04-10)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatMonthShort = (date, options, global = window) => {
    const opt = getOptions(options, global);
    const theDate = getDate(date, opt);
    return theDate.format('MMM');
};

/**
 * Format time part of a date/time representation, HH:MM, HH:MM:SS
 *
 * Available options to send in the options parameter are:
 * - locale - {string} (optional) override both the default value sv-SE and value in EcsterConfig
 * - utcToLocal - {boolean} (optional) false is default, date value is parsed as a UTC date and formatted in the local TZ
 * - seconds - {boolean} (optional) false is default, show time with seconds HH:MM:SS
 *
 * @param {Date | string} date A Date/time object or an ISO formatted date/time string (2020-04-10 12:34:56)
 * @param {object} options An options object with locale, sv-SE is default, locale in EcsterConfig overrides default, options parameter overrides both
 * @param {object} global Window is default, pass only when testing
 * @returns {string}
 */
export const formatTime = (date, options, global = window) => {
    const opt = getOptions(options, global);
    const theDate = getDate(date, opt);
    return theDate.format(opt.seconds ? 'LTS' : 'LT');
};
