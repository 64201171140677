/*
 * Based on this solution: https://github.com/msudgh/react-email-autocomplete/blob/master/src/index.js
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import Input, { InputRef } from './Input';
import { protectedKeyCodes, emailServicesDomains } from './constants/emailDomains';

interface EmailInputProps {
    onChange: Function;
    domains?: string[];
    className?: string;
    value?: string;
}

interface EmailInputState {
    value: string;
    suggestion: string;
}

export class EmailInput extends Component<EmailInputProps, EmailInputState> {
    inputRef = React.createRef<InputRef>();

    constructor(props) {
        super(props);
        const { value } = this.props;

        this.state = {
            value,
            suggestion: '',
        };
    }

    getInputEl() {
        return this.inputRef.current.getInputEl();
    }

    replaceLast = (value, what, replacement) => {
        if (!value && !what && !replacement) return ''; // Happens when you erase everything with Backspace
        const pieces = value.split(what);
        const lastPiece = pieces.pop();
        return pieces.join(what) + replacement + lastPiece;
    };

    handleChange = (event) => {
        const { onChange } = this.props;
        const { value: emailAddress } = event.target;
        const suggest = this.suggest(emailAddress);

        if (typeof suggest === 'undefined' || suggest.length < 1) {
            this.setState({ value: emailAddress, suggestion: suggest }, () => this.selectText());
        } else {
            this.setState({ value: `${emailAddress}${suggest}`, suggestion: suggest }, () => this.selectText());
        }

        if (onChange) {
            onChange(event);
        }
    };

    selectText = () => {
        const { suggestion, value } = this.state;

        if (typeof suggestion !== 'undefined' && suggestion.length > 0) {
            const startPos = value.lastIndexOf(suggestion);
            const endPos = startPos + suggestion.length;
            this.getInputEl().setSelectionRange(startPos, endPos);
        }
    };

    onBlur = () => {
        const { onChange } = this.props;
        const { value } = this.state;
        onChange({ target: { value, name: 'email' } });
    };

    getSuggest = (event) => {
        if (protectedKeyCodes.indexOf(event.keyCode) >= 0) return;
        const { suggestion } = this.state;
        const { value } = event.target;

        if (event.keyCode === 8) {
            this.setState({
                value: this.replaceLast(value, suggestion, ''),
            });
        }
    };

    suggest = (str) => {
        let string = str;
        const strArr = string.split('@');
        if (strArr.length - 1 !== 0) {
            string = strArr.pop();
        } else {
            return '';
        }

        const { domains } = this.props;

        const match =
            domains
                .filter((domain) => {
                    return domain.indexOf(string) === 0;
                })
                .shift() || '';

        return match.replace(string, '');
    };

    doValidation() {
        return this.inputRef.current.doValidation();
    }

    render() {
        const { className, ...rest } = this.props;
        const { value } = this.state;

        const classes = classNames({
            'ec-email-input': true,
            [className]: className,
        });

        return (
            <div className="ec-email-autocomplete">
                <Input
                    {...rest}
                    className={classes}
                    value={value}
                    onChange={this.handleChange}
                    onKeyUp={this.getSuggest}
                    onBlur={this.onBlur}
                    ref={this.inputRef}
                    name="email"
                    type="text" // type email trigger an error because it does not support setSelectionRange
                    inputMode="email"
                />
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
EmailInput.defaultProps = {
    domains: emailServicesDomains,
    className: '',
    value: '',
};
