import React from 'react';

import { Select, Option } from '../Select';

const texts = {
    en: {
        label: 'Employment',
        permanent: 'Permanent employment',
        probationary: 'Probationary employment',
        retired: 'Retired',
        seekingEmployment: 'Seeking employment',
        selfEmployed: 'Self employed',
        student: 'Student',
        temporary: 'Temporary employment',
        other: 'Other employment',
    },
    fiFI: {
        label: '',
        permanent: 'Vakituinen',
        probationary: 'Koeajalla oleva',
        retired: 'Eläkeläinen',
        seekingEmployment: 'Työnhakija',
        selfEmployed: 'Yrittäjä',
        student: 'Opiskelija',
        temporary: 'Osa-aikainen',
        other: 'Muu',
    },
    svFI: {
        label: 'Anställningsform',
        permanent: 'Tillsvidareanställd',
        probationary: 'Provanställning',
        retired: 'Pensionär',
        seekingEmployment: 'Arbetssökande',
        selfEmployed: 'Egen företagare',
        student: 'Student',
        temporary: 'Visstidsanställning / vikarie',
        other: 'Annan anställningsform',
    },
    svSE: {
        label: 'Anställningsform',
        permanent: 'Tillsvidareanställd',
        probationary: 'Provanställning',
        retired: 'Pensionär',
        seekingEmployment: 'Arbetssökande',
        selfEmployed: 'Egen företagare',
        student: 'Student',
        temporary: 'Visstidsanställning / vikarie',
        other: 'Annan anställningsform',
    },
};

interface EmploymentTypeProps {
    id?: string;

    /**
     * Override default label, default label depends on language and country
     */
    label?: string;

    /**
     * Override default validation maessage, default message depends on language and country
     */
    validationMessage?: string;

    /**
     * The selected value
     */
    value: string;

    /**
     * onChange event handler
     */
    onChange: Function;

    /**
     * The preferred language, lower case ISO code - sv, fi, en etc
     *
     * language and country must combine into a valid locale
     */
    language?: string;

    /**
     * The country, upper case ISO code - SE, FI etc
     *
     * Options may vary depending on country
     *
     * language and country must combine into a valid locale
     */
    country?: string;

    /**
     * Is this value required?
     */
    required?: boolean;

    /**
     * Enable option 'other employment'
     */
    enableOtherEmployment?: boolean;
}

export const EmploymentType = React.forwardRef<Select, EmploymentTypeProps>(
    (
        {
            country,
            enableOtherEmployment,
            id,
            label,
            language,
            onChange,
            required,
            validationMessage,
            value,
            ...rest
        }: EmploymentTypeProps,
        ref
    ) => {
        const languageKey = language === 'en' ? 'en' : language.toLowerCase() + country.toUpperCase();
        const i18n = key => texts[languageKey][key];

        const theLabel = label || i18n('label');
        const theValidationMessage = validationMessage || i18n('validationMessage');

        return (
            <Select
                id={id}
                ref={ref}
                label={theLabel}
                value={value}
                onChange={onChange}
                required={required}
                validationMessage={theValidationMessage}
                {...rest}
            >
                <Option label={i18n('permanent')} value="PERMANENT" key="PERMANENT" />
                <Option label={i18n('selfEmployed')} value="SELFEMPLOYED" key="SELFEMPLOYED" />
                <Option label={i18n('temporary')} value="TEMPORARY_EMPLOYMENT" key="TEMPORARY_EMPLOYMENT" />
                <Option label={i18n('probationary')} value="TRYOUT_EMPLOYED" key="TRYOUT_EMPLOYED" />
                <Option label={i18n('retired')} value="RETIRED" key="RETIRED" />
                <Option label={i18n('student')} value="STUDENT" key="STUDENT" />
                <Option label={i18n('seekingEmployment')} value="SEEKING_EMPLOYMENT" key="SEEKING_EMPLOYMENT" />
                {enableOtherEmployment && <Option label={i18n('other')} value="OTHER" key="OTHER" />}
            </Select>
        );
    }
);

EmploymentType.defaultProps = {
    id: 'ec-employmet-type',
    label: '',
    validationMessage: '',
    language: 'sv',
    country: 'SE',
    required: false,
    enableOtherEmployment: false,
};
